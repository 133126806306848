import React, {useContext} from 'react';

import {Delete, Sentiment, Status} from './styles';

import {ContextReducer} from '../../reducer/AdminReducer';
import {changeReview} from "../../actions/ReviewsAction";
import {GrClose} from 'react-icons/gr';

const Tags = props => {
    const {state, dispatch} = useContext(ContextReducer);

    const renderUppercaseFirsLetter = (text) => {
        return text?.charAt(0).toUpperCase() + text?.slice(1).toLowerCase();
    }

    const renderStatus = (status) => {
        switch (status) {
            case 'APPROVED':
                return state?.i18n?.tags.APPROVED
            case 'REJECTED':
                return state?.i18n?.tags.REJECTED
            case 'DELETED':
                return state?.i18n?.tags.DELETED
            case 'PENDING':
                return state?.i18n?.tags.PENDING
            default:
                return props?.tagsItem[props.index]?.translatedTag ? renderUppercaseFirsLetter(props?.tagsItem[props.index]?.translatedTag) : renderUppercaseFirsLetter(props?.tagsItem[props.index]?.originalTag);
        }
    }

    const renderType = (type) => {
        switch (type) {
            case 'negative':
                return <Sentiment type={'negative'}/>
            case 'positive':
                return <Sentiment type={'positive'}/>
            case 'mixed':
                return <Sentiment type={'mixed'}/>
            default:
                return <Sentiment/>
        }

    }

    const objFilter = (obj, condition) => {
        let newObj = []

        obj.forEach((item) => {
            if(condition(item.originalTag)){
                newObj = [...newObj, item]
            }
        })

        return newObj
    }

    const handleClickDelete = () => {
        const _arr = objFilter(props?.tagsItem, (value) => value !== props?.tagsItem[props.index].originalTag);
        props.setTagsItem(_arr);

        let _obj = { };

        _arr.forEach(item => {
            _obj[item.originalTag] = { sentiment: item.sentiment, author: item.author }
        })

        changeReview(state.actualRoute, dispatch, [
            {
                "id": props.id,
                "author": props.author
            }
        ], 'tags', _obj, state.filters.status, state?.userInfo?.token, state?.userInfo?.ecommerce, state?.actualArea, state?.filters);
    }

    const renderDelete = () => {
        return <Delete onClick={handleClickDelete}><GrClose/></Delete>
    }

    return (
        <Status
            status={props?.type}
            StatusBorder={props?.answerList ? true : false}
            nomargin={(props?.nomargin && props?.answerList) ? props?.nomargin : false }
            authorSystem={props?.answerList ? false : (props?.tagsItem[props.index]?.author === 'system' ? true : false) }
        >
            {!props?.answerList ? renderType(props?.tagsItem[props.index]?.sentiment) : renderType('default')}
            {renderStatus(props?.type)}
            {!props?.answerList ? renderDelete() : ''}
        </Status>
    )
}

export default Tags;
