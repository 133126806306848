import styled, {css} from 'styled-components';

export const Container = styled.div`
    margin-left: 15px;
    position: relative;
    display: flex;
    max-width: 100px;
    width: 100%;
    overflow: hidden;
    height: 20px;

    ${props => props.nps && css`
        max-width: 200px;
    `}
`

export const Content = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
`

export const ContentInactive = styled(Content)`
    z-index: 1;
`

export const Item = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
`