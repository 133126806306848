import React, {useContext, useEffect} from 'react';
import {ContextReducer} from '../../../reducer/AdminReducer';
import { getEcomm } from '../../../actions/ReviewsAction';
import NoItems from '../../NoItems';
import Cards from '../../Cards';
import Input from '../../Input'
import Warn from './Warn';
import {Wrapper, Subtitle, ContentFilter, Filter} from './styles';
import useRefreshToken from '../../../hooks/useRefreshToken';


const WrapperIntegratedStores = () => {
    const {state, dispatch} = useContext(ContextReducer);

    useEffect(() => {
        getEcomm(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, 'marketplace', true, '', '', '', true);
    }, []);

    useRefreshToken('wrapperIntegrated');

    const renderCards = () => {
        if (state.ecommerce?.ecommerces?.length > 0) {
            return state.ecommerce.ecommerces.map((val, i) => {
                if(val?.marketplace){
                    return <Cards key={val?.id} type={'integratedStores'} check={state.config?.ecommerce?.marketplacesChecked} card={val} key={i} index={i} />
                } else {
                    return <></>
                }
            })

        } else {
            return <NoItems/>
        }
    }

    const renderFilters = () => {
        if(state.config?.ecommerce?.tags) {
            return state.config?.ecommerce?.tags?.map((val, i) => {
                return <Filter key={i}>{val}</Filter>
            })
        }
    }

    return (
        <>
            <Subtitle>{state?.i18n?.configuration?.integratedStores?.subtitle}</Subtitle>
            <Input from={'integrated'} />
            {!state?.integratedStores?.name && <ContentFilter>
                {renderFilters()}
            </ContentFilter>}
            {state?.integratedStores?.name && <Warn />}
            {!state?.integratedStores?.name &&
                <Wrapper>
                    {renderCards()}
                </Wrapper>
            }
        </>
    )
};

export default WrapperIntegratedStores;
