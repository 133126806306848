import React from 'react';
import { Container } from './styles';

const Button = ({text, cb, type}) => {
    return (
        <Container
            onClick={() => cb(type)}
            type={type}
        >
            {text}
        </Container>
    );
};

export default Button;
