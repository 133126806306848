
import React, {useContext} from 'react';
import { ContextReducer } from '../../../reducer/AdminReducer';
import { Container, Table } from '../styles';

const TableInteractions = ({from, handleExportData}) => {
    const { state } = useContext(ContextReducer);

    const placeholderToEmptyValues = state?.i18n?.exportTable?.placeholderToEmptyValues;
    const placeholderToFullValues = state?.i18n?.exportTable?.placeholderToFullValues
    const placeholderItemsi18nInteractions = state?.i18n?.exportTable?.items;

    const itemstoRemoveColumns = ['comments', 'answers', 'questions'];

    const renderTags = (items, from = 'tagsOperator') => {
        let _tags = [];
        const renderPlace = from === 'tagsOperator' ? items?.tags : items?.liked;

        if(renderPlace) {
            renderPlace?.forEach((item) => {
                _tags = [ ..._tags, (item.translatedTag && from === 'tagsOperator') ? item.translatedTag : item];
            })
        }

        return _tags.toString().replace(/,/g, ', ');
    }


    const renderBody = () => {
        return state?.reviews?.items.map((obj, idx) => {
            return (
                <tr
                    style={{border: '1px solid #000'}}
                    key={idx}
                >
                    <td>{!obj?.productId ? placeholderToEmptyValues?.noProductId : obj?.productId}</td>
                    {from === 'nps' && <td>{!obj?.npsType ? placeholderToEmptyValues?.noNpsType : obj?.npsType === 'delivery' ? placeholderToFullValues?.deliveryTypeNps : placeholderToFullValues?.purchaseTypeNps}</td>}
                    <td>{obj?.status}</td>
                    <td>{!obj?.productInfo?.name ? placeholderToEmptyValues?.noName : obj?.productInfo?.name}</td>
                    <td>{!obj?.description ? placeholderToEmptyValues?.noDescription : obj?.description}</td>
                    { from === 'comments' && <td>{!obj?.parentInfo?.description ? placeholderToEmptyValues?.noAnsweredReview : obj?.parentInfo?.description}</td>}
                    { from === 'answers' && <td>{!obj?.parentInfo?.description ? placeholderToEmptyValues?.noAnsweredQuestion : obj?.parentInfo?.description}</td>}
                    {!itemstoRemoveColumns.includes(from) && <td>{obj?.reviewRating}</td>}
                    {from === 'reviews' && <td>{!obj?.productInfo?.ratingValue ? placeholderToEmptyValues?.noAverageRating : obj?.productInfo?.ratingValue}</td>}
                    {from === 'reviews' && <td>{!obj?.productInfo?.ratingCount ? placeholderToEmptyValues?.noRatingCountProduct : obj?.productInfo?.ratingCount}</td>}
                    <td>{!obj?.productInfo?.seller ? placeholderToEmptyValues?.noSellerName : obj?.productInfo?.seller }</td>
                    <td>{!obj?.productInfo?.brand ? placeholderToEmptyValues?.noBrandName : obj?.productInfo?.brand}</td>
                    <td>{obj?.tqi}</td>
                    <td>{obj?.unhelpful}</td>
                    <td>{obj?.helpful}</td>
                    { (from !== 'questions' && from !== 'comments') && <td>{!obj?.transaction?.id ? placeholderToEmptyValues?.transactionId : obj?.transaction?.id}</td>}
                    { (from !== 'questions' && from !== 'comments') && <td>{!obj?.transaction?.date ? placeholderToEmptyValues?.transactionDate : obj?.transaction?.date}</td>}
                    <td>{obj?.datePublished}</td>
                    <td>{!obj?.authorInfo?.name ? placeholderToEmptyValues?.noName : obj?.authorInfo?.name}</td>
                    { (from === 'comments' || from === 'answers') && <td>{!obj?.is_store ? placeholderToEmptyValues?.noProfileVerified : placeholderToFullValues?.profileVerified}</td>}
                    <td>{!obj?.location?.city ? placeholderToEmptyValues?.noCity : obj?.location?.city}</td>
                    {from !== 'nps' && <td>{!obj?.productInfo?.url ? placeholderToEmptyValues?.noUrl : obj?.productInfo?.url}</td>}
                    <td>{!renderTags(obj) ? placeholderToEmptyValues?.noTags : renderTags(obj)}</td>
                    {(from === 'reviews' || from === 'nps') && <td>{!renderTags(obj, 'tagsUser') ? placeholderToEmptyValues?.noTags : renderTags(obj, 'tagsUser')}</td>}
                    {from === 'reviews' && <td>{!obj?.attachment ? placeholderToEmptyValues?.noImageReview : obj?.attachment[0]?.value}</td>}
                    {(from !== 'comments' && from !== 'questions') && <td>{!obj?.verified ? placeholderToEmptyValues?.purchaseDontVerified : placeholderToFullValues?.purchaseVerified}</td>}
                </tr>
            )
        })
    }

    if (state?.exportData?.loading) {
        return (
            <Container id="tableID">
                <meta charSet="utf-8"/>
                <Table border={'1'}>
                    <thead>
                        <tr style={{border: '1px solid #000'}}>
                            <th>{placeholderItemsi18nInteractions?.items1}</th>
                            {from === 'nps' && <th>{placeholderItemsi18nInteractions?.itemsNpsType}</th>}
                            <th>{placeholderItemsi18nInteractions?.items2}</th>
                            <th>{placeholderItemsi18nInteractions?.items3}</th>
                            <th>{placeholderItemsi18nInteractions?.items4}</th>
                            {from === 'comments' && <th>{placeholderItemsi18nInteractions?.itemReviewAnswered}</th>}
                            {from === 'answers' && <th>{placeholderItemsi18nInteractions?.itemQuestionAnswered}</th>}
                            {!itemstoRemoveColumns.includes(from) && <th>{placeholderItemsi18nInteractions?.items5}</th>}
                            {from === 'reviews' && <th>{ placeholderItemsi18nInteractions?.itemAverageRating}</th>}
                            {from === 'reviews' && <th>{placeholderItemsi18nInteractions?.itemratingCountProduct}</th>}
                            <th>{placeholderItemsi18nInteractions?.itemSeller}</th>
                            <th>{placeholderItemsi18nInteractions?.itemBrand}</th>
                            <th>{placeholderItemsi18nInteractions?.items6}</th>
                            <th>{placeholderItemsi18nInteractions?.items7}</th>
                            <th>{placeholderItemsi18nInteractions?.items8}</th>
                            {(from !== 'comments' && from !== 'questions') && <th>{placeholderItemsi18nInteractions?.itemsIdTransaction}</th>}
                            {(from !== 'comments' && from !== 'questions') && <th>{placeholderItemsi18nInteractions?.itemsDateOfTransaction}</th>}
                            <th>{placeholderItemsi18nInteractions?.items9}</th>
                            <th>{placeholderItemsi18nInteractions?.items10}</th>
                            {(from === 'comments' || from === 'answers') && <th>{placeholderItemsi18nInteractions?.itemProfileVerified}</th>}
                            <th>{placeholderItemsi18nInteractions?.items14}</th>
                            {from !== 'nps' && <th>{placeholderItemsi18nInteractions?.items12}</th>}
                            <th>{placeholderItemsi18nInteractions?.items13}</th>
                            {(from === 'reviews' || from === 'nps') && <th>{placeholderItemsi18nInteractions?.items15}</th>}
                            {from === 'reviews' && <th>{placeholderItemsi18nInteractions?.itemReviewImageAttachment}</th>}
                            {(from !== 'comments' && from !== 'questions') && <th>{placeholderItemsi18nInteractions?.items16}</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {renderBody()}
                    </tbody>
                </Table>
            </Container>
        )
    }


    handleExportData();

    return <></>;
};

export default TableInteractions;
