import React, {useContext, useEffect, useRef, useState} from 'react';
import {ContextReducer} from "../../reducer/AdminReducer";

import {getHistoric, setAnnotation} from '../../actions/ReviewsAction';

import {ReactComponent as IcoPublish} from '../../assets/ico_publish.svg';
import {ReactComponent as IcoDelete} from '../../assets/ico_delete.svg';
import {ReactComponent as IcoTrash} from '../../assets/ico_trash.svg';
import {ReactComponent as IcoNote} from '../../assets/ico_note.svg';

import {Action, Annotation, Date, Email, Item, Items, Note, Send, Svg} from './styles';

const Historic = props => {

    const {state, dispatch} = useContext(ContextReducer);
    const [active, setActive] = useState(false);
    const inputRef = useRef();


    useEffect(() => {
        getHistoric(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, props.id, props.author, renderAreas(state.actualRoute), props.index)
    }, []);

    useEffect(() => {
        if (inputRef.current.value.length > 0) {
            setActive(true)
        } else {
            setActive(false)
        }
    }, [])


    const renderAreas = (type) => {
        switch (type) {
            case 'reviews':
                return 'review'
            case 'comments':
                return 'comment'
            case 'answers':
                return 'answer'
            case 'questions':
                return 'question'
            case 'nps':
                return 'nps'
            default:
                return ''
        }
    }

    const renderIcons = (action) => {
        switch (action) {
            case 'approved':
                return <IcoPublish/>
            case 'rejected':
                return <IcoDelete/>
            case 'pending':
                return <IcoDelete/>
            case 'annotation':
                return <IcoNote/>
            case 'deleted':
                return <IcoTrash/>
            default:
                return <></>
        }
    }

    const renderHistoric = () => {

        if (state?.historic[props.index]?.items?.length > 0) {
            return state?.historic[props.index]?.items.map((val, key) => {
                return (
                    <Item key={key}>
                        <div>
                            <Svg>{renderIcons(val.type)}</Svg>
                            <Action>{state?.i18n?.historic[val.type]}</Action>
                            <Email>{val.authorInfo?.email}</Email>
                            <Date>{val.created}</Date>
                        </div>
                        <Annotation>{val.annotation && val.annotation}</Annotation>
                    </Item>
                )
            })
        } else {
            return <span>{state?.i18n?.historic?.noinfo}</span>
        }
    }

    const handleAnnotation = e => {
        e.preventDefault();


        if (inputRef.current.value.length > 0) {
            setAnnotation(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, props.id, props.author, renderAreas(state.actualRoute), props.index, inputRef.current.value);
        }

        inputRef.current.value = '';
        setActive(false)
    }

    const handleActive = () => {
        if (inputRef.current.value.length > 0) {
            setActive(true)
        } else {
            setActive(false)
        }
    }

    return (
        <Items>
            {renderHistoric()}
            <Note
                placeholder={state?.i18n?.historic?.placeholder + state.userInfo.email}
                type='text'
                onChange={handleActive}
                ref={inputRef}
            />
            <Send type={'button'} active={active} onClick={handleAnnotation}>{state?.i18n?.historic?.button}</Send>
        </Items>
    );
}

export default Historic;
