import styled, {css} from 'styled-components';

export const Container = styled.div`
    width: 100%;
    min-height: 60px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    transition: all ease-in-out 0.5s;
    padding: 12px 0px;

    ${props => !props.active && css`
        opacity: 0.3;
        transition: all ease-in-out 0.5s;
    `}

    @media screen and (max-width: 1040px) {
        flex-direction: column;
        align-items: flex-start;
    }
`

export const Initials = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    border: 1px solid #707070;
    font-weight: bold;
    font-size: 17px;
`
export const WrapperInfos = styled.div`
    min-width: 180px;
    width: auto;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`
export const NameAndLast = styled.div`
    margin-left: 10px;
    color: #000;
    width: auto;
    min-width: 124px;

    h5 {
        font-size: 14px;
    }
    span {
        font-size: 10px;
        opacity: 0.4;
    }

    @media screen and (max-width: 1040px) {
        margin: 5px 0px 0px 5px;
    }
`
export const Email = styled.div`
    width: auto;
    min-width: 240px;
    height: inherit;
    display: flex;
    text-align: left;
    flex-direction: column;
    margin: 0px 10px 0px 10px;
    color: #000;

    span {
        font-size: 12px;
        opacity: 0.4;

        :nth-child(2){
            font-size: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    @media screen and (max-width: 1040px) {
        margin: 10px 0 0 0;
    }
`
export const Actions = styled.div`
    width: auto;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    div {
        display: flex;
    }

    @media screen and (max-width: 1040px) {
        width: 100%;
        flex-wrap: wrap;
        margin: 10px 0px 0px 0px;
        justify-content: space-between;
    }
`

export const Toggle = styled.div`
    width: 30px;
    height: 14px;
    border-radius: 30px;
    background-color: #000;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 5px;

    @media screen and (max-width: 1040px) {
        margin-right: 0px;
    }
`
export const Roll = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #000;
    border: 2px solid #fff;
    margin: 0px 2px 0px 2px;
    transition: transform 0.5s;
    transform: ${props => (props.active ? 'translateX(16px)' : 'translateX(0%)')};
`;
