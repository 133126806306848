const getTransformDpr = (param) => {
    const _devicePixelRatio = Math.ceil(param);

    if (_devicePixelRatio < 4)
        return _devicePixelRatio;
    else
        return 4;
}

export default getTransformDpr;
