import styled, {css} from "styled-components"

const getActions = (actions) => {
    switch (actions) {
        case 'publicar':
            return css`
            background-color: #000000;
            color: #ffff;
        `
        case 'arquivar':
            return css`
            background-color: #ED5D5D;
            color: #ffff;

            svg {
                path {
                    stroke: #fff !important;
                }
            };
        `
        default:
            return css`
            background-color: #ff7200;

            svg {
                path {
                    stroke: #fff !important;
                    stroke-width: 3px !important;
                }
            };
        `
    }
}

const getActionsTexts = (actions) => {
    switch (actions) {
        case 'publicar':
            return css`
            color: #000000;
        `
        case 'arquivar':
            return css`
            color: #EB5E61;
        `
        default:
            return css`
            color: #FD7222;
            `
    }
}

export const Actions = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    background-color: #fff;
    border: none;
    margin-left: 5px;
    cursor: pointer;
    transition: all ease .10s;
    position: relative;

    ${props => props.allItems && css`
        margin-bottom: 7px;
        margin-left: 0;
    `}

    ${props => props.border === 'dotted' && css`
        border: 1px dotted #000;
    `}

    &:hover {
        ${props => getActions(props.actions)}
        transform: scale(1.2);
    }

    ${props => props.act && css`
        ${props => getActions(props.actions)}
    `}

    &:nth-child(2) {

        svg {

            path {
                stroke-width: 3px !important;
            }
        }
    }

    &:first-child {
        margin-left: 0;
    }

`

export const Tooltip = styled.div`
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    font-family: 'Open Sans';
    text-transform: uppercase;
    border-radius: 5px;
    padding: 5px;

    ${props => props.allItems && css`
        transform: initial;
        left: 25px;
        margin-left: 10px;
        bottom: initial;
    `}

    ${props => getActionsTexts(props.actions)};

    white-space: nowrap;
    display: none;

    ${Actions}:hover & {
        display: block;
    }
`
