import React, {useContext, useState} from 'react';
import {
    Avatar,
    Button,
    Container,
    ContainerModal,
    Content,
    ContentLeft,
    ContentQuestion,
    ContentQuestionOther,
    ContentRight,
    Initials,
    InitialsSeller,
    Modal,
    Sold,
    TextArea,
    Wrapper,
    WrapperLeft,
    WrapperModal,
    WrapperPillCancel,
    WrapperQuestion,
    WrapperTypography
} from './styles';
import {setAnswerForm, setModal, setSuccess} from '../../actions/ReviewsAction';
import {ContextReducer} from '../../reducer/AdminReducer';
import ButtonClose from './ButtonClose';
import getTransformDpr from "../../utils/TransformDpr";


const ModalResponse = (props) => {
    const {state, dispatch} = useContext(ContextReducer);
    const [textArea, setTexArea] = useState('');
    const handleModalSubimit = (value) => {
        let _obj = {
            "ecommerceId": value.ecommerceId,
            "productId": value.productId,
            "description": textArea,
            "is_store": true,
            "parentId": value.author,
            "author": {
                "email": state?.userInfo?.email
            },
            "listType": state?.actualArea
        }

        setAnswerForm(dispatch, _obj, state?.userInfo?.token, state?.userInfo?.ecommerce, state.modal.type)
        setModal(dispatch, {index: '', status: false, type: ''})
        setSuccess(dispatch, false);
        setTexArea('');
    }

    const handleChangeTextArea = e => {
        const {value} = e.target;

        setTexArea(value);
    }

    const renderName = (name) => {
        if (name) {
            let _name = name.split(' ');
            let _namesplit = '';

            if (_name.length >= 3 && _name[_name.length - 1].length > 0) {
                _namesplit = _name[0] + ' ' + _name[_name.length - 1];
            } else if (_name.length > 2 && _name[_name.length - 1].length === 0) {
                _namesplit = _name[0] + ' ' + _name[1];
            } else {
                _namesplit = name;
            }

            return _namesplit;
        }
    }


    const renderInitials = (initials) => {
        let _initials = renderName(initials).trim();


        _initials = _initials.split(' ').reduce((ac, inn) => ac + inn[0], '').toUpperCase();

        return _initials;
    }

    const renderAvatar = (url, name) => {
        let _styles = {};

        if (url) {
            _styles.background = `url(https://${state?.userInfo?.ecommerce.domain}/image/upload/t_thumbs_dpr_${getTransformDpr(window.devicePixelRatio)}/f_auto/${url}.jpeg) center center / contain no-repeat`;
        }

        if (url && url !== ' ') {
            return <Avatar style={_styles}/>
        } else if (name && name !== ' ') {
            return <Initials>{renderInitials(name)}</Initials>
        } else {
            return <Initials></Initials>
        }
    }

    const renderModal = () => {
        return (
            <Container>
                <Wrapper>
                    <WrapperTypography>
                        <WrapperPillCancel>
                            <WrapperQuestion>
                                <span>{state?.i18n?.modalResponse?.title[state?.actualRoute]}</span>
                                <ContentQuestion>
                                    <ContentLeft>
                                        {renderAvatar(props.values?.authorInfo?.picture?.id, props.values?.authorInfo?.name)}
                                    </ContentLeft>
                                    <ContentRight>
                                        <span>{props?.values?.authorInfo?.name}</span>
                                        <p>{props?.values?.description}</p>
                                    </ContentRight>
                                </ContentQuestion>
                            </WrapperQuestion>
                            <ButtonClose/>
                        </WrapperPillCancel>
                    </WrapperTypography>
                    <ContentQuestionOther>
                        <ContentLeft>
                            {state?.config?.ecommerce?.authorInfo?.picture?.url !== " " ?
                                <Avatar
                                    style={{background: `url(https://${state.userInfo.ecommerce.domain}/image/upload/t_favicons_dpr_${getTransformDpr(window.devicePixelRatio)}/f_auto/${state?.config?.ecommerce?.authorInfo.picture.id}.jpeg) center center / contain no-repeat`}}/>
                                :
                                <InitialsSeller>{renderInitials(state?.config?.ecommerce?.name)}</InitialsSeller>
                            }
                        </ContentLeft>
                        <ContentRight>
                            <span>{state?.config?.ecommerce?.name}</span>
                            <TextArea onChange={handleChangeTextArea}
                                      placeholder={state?.i18n?.modalResponse?.textArea}/>
                        </ContentRight>
                    </ContentQuestionOther>
                </Wrapper>
                <ContainerModal>
                    <Content>
                        <WrapperLeft>
                            <img src={props?.values?.productInfo?.image} height="50" width="50" alt="foto produto"/>
                            <WrapperModal>
                                <span>{state?.i18n?.modalResponse?.writeAbout}</span>
                                <span>{props?.values?.productInfo?.name}</span>
                                <Sold>{state?.i18n?.listItem?.sold} {props?.values?.productInfo ? props?.values?.productInfo?.ecommerceName : ''}</Sold>
                            </WrapperModal>
                        </WrapperLeft>
                        <Button
                            onClick={() => handleModalSubimit(props.values)}>{state?.i18n?.modalResponse[state.actualRoute]}</Button>
                    </Content>
                </ContainerModal>
            </Container>
        )
    }
    return (
        <Modal active={props.active}>
            {renderModal()}
        </Modal>
    );
}

export default ModalResponse;
