import React, { useContext } from 'react';
import { ContextReducer } from '../../../../../../reducer/AdminReducer';
import { WrapperStatus, ContainerTexts, Text} from './styles.js'
import { ReactComponent as SuccessSend } from '../../../../../../assets/Icon-success-send.svg';

const Status = ({storeName}) => {
    const { state } = useContext(ContextReducer);

    return (
        <WrapperStatus>
            <SuccessSend style={{ marginRight: '12.88px'}} />
            <ContainerTexts>
                <Text bigText={true}>{state?.i18n?.configuration?.integratedStores?.connected}</Text>
                <Text smallText={true}>{state?.i18n?.configuration?.integratedStores?.yourVurdereIsConnected + ' ' + storeName} </Text>
            </ContainerTexts>
        </WrapperStatus>
    );
};

export default Status;
