import React, {useContext, useEffect, useRef, useState} from 'react';
import {BoxFilter, BoxZoom, Container, Division} from './styles';

import Input from '../Input';
import SelectInputFilter from '../SelectInputFilter';
import {FiSearch} from 'react-icons/fi';
import {ContextReducer} from '../../reducer/AdminReducer';
import {setFilter} from '../../actions/ReviewsAction';
import useShouldRender from '../../hooks/useShouldRender';

const BoxFIlterInput = () => {
    const {state, dispatch} = useContext(ContextReducer);
    const [openInput, setOpenInput] = useState(false);
    const filterhasValue = state?.filters?.listFiltersHeader[state?.filters?.orderSelectSearch];

    const openRefTransition = useRef();
    const { shouldRender } = useShouldRender(openRefTransition, openInput);

    useEffect(() => {
        const handleEnter = (event) => {
            if (openInput) {
                if (event.key === "Escape" || event.key === "Escape") {
                    handleActive();
                }
            }
        };

        window.addEventListener('keydown', handleEnter);

        return () => {
            window.removeEventListener('keydown', handleEnter);
        };

    }, [openInput])


    const handleActive = (listFiltersHeader = '') => {
        setOpenInput(prevState => !prevState);

        let _interactions = (state?.actualRoute === 'reviews' || state?.actualRoute === 'comments' || state?.actualRoute === 'questions' || state?.actualRoute === 'answers') && 'interactions';
        let initialSelect;

        if (state.actualRoute === 'nps' || state.actualRoute === 'newsletter') {
            initialSelect = 'transactionId';
        } else if (_interactions) {
            initialSelect = 'productName';
        } else if (state.actualRoute === 'users') {
            initialSelect = 'userName';
        }

        const _filter = {
            orderInputUser: '',
            listFiltersHeader: listFiltersHeader ? listFiltersHeader : {...state?.filters?.listFiltersHeader},
            orderSelectSearch: initialSelect
        };

        if (filterhasValue) {
            _filter.listFiltersHeader = {};
        }

        setFilter(dispatch, _filter);
    }


    const renderIfActive = () => {
        if (!shouldRender) {
            return null;
        }

        return (
            <BoxFilter open={openInput} ref={openRefTransition}>
                <SelectInputFilter/>
                <Division/>
                <Input onHandleBoxActive={handleActive}/>
            </BoxFilter>
        )
    }


    return (
        <>
            <Container
                visible={state?.visible}
                open={openInput}
            >
                <BoxZoom open={openInput} onClick={() => handleActive()} visible={state?.visible}>
                    <FiSearch/>
                    {!state?.visible && <span>{!shouldRender && state?.i18n?.searchButton}</span>}
                </BoxZoom>
                {renderIfActive()}
            </Container>
        </>
    );
};

export default BoxFIlterInput;
