import React from 'react';
import {ButtonBack, ContainerError, Wrapper} from './styles';
import {ReactComponent as IconSad} from '../../assets/IconSadVurdere.svg';

const Error = () => {

    return (
        <ContainerError>
            <Wrapper>
                <IconSad/>
                <h2>Ops, ocorreu um erro.</h2>
                <h2>Atualize a página e tente novamente.</h2>
                <span>Já estamos trabalhando para solucionar.</span>
                <ButtonBack onClick={() => window.history.go(0)}>VOLTAR</ButtonBack>
            </Wrapper>
        </ContainerError>
    );
}

export default Error;
