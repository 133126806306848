import React, {useContext, useEffect} from 'react';

import {Container, ListHeaderItem} from '../../Containers/styles';
import {ContextReducer} from "../../reducer/AdminReducer";
import Checkbox from "../Checkbox";

const ListHeader = props => {

    const {state} = useContext(ContextReducer);
    let setUncheck;
    let setCheck;

    useEffect(() => {
        if(!props.selectAll) {
            setUncheck();
        }
    }, [ props.selectAll ])

    useEffect(() => {
        if (state.selecteds.length === 0) {
            setUncheck();
        } else {
            setCheck();
        }
    }, [state.selecteds, state?.typeEcommerce?.store])

    const renderCheckbox = () => {
        return (
            <ListHeaderItem
                width='50'
                align='center'
            >
                <Checkbox
                    type={'checkbox'}
                    handleClick={props.handleClickCheckBox}
                    uncheck={uncheck => setUncheck = uncheck}
                    check={check => setCheck = check}
                    from={'header'}
                    status={state?.filters?.status}
                />
            </ListHeaderItem>
        )
    }

    const renderImageRoom = () => {
        if (state.actualRoute === 'nps') return;

        return (
            <ListHeaderItem width='100'></ListHeaderItem>
        )
    }

    const renderDescription = () => {

        return (
            <ListHeaderItem type="description" align='left'>
                <span>{state?.i18n?.listHeader?.comments}</span>
            </ListHeaderItem>
        )
    }

    return (
        <Container area='listheader'>
            {renderCheckbox()}
            {renderImageRoom()}
            {renderDescription()}
            <ListHeaderItem width='120' align='center'>
                <span>{state?.i18n?.listHeader?.actions}</span>
            </ListHeaderItem>
        </Container>
    );
}

export default ListHeader;
