import React, {useContext, useEffect, useState} from 'react';
import {ArrowLeft, ArrowRight, Pag} from "./styles";
import {ContextReducer} from "../../reducer/AdminReducer";
import {
    clearChecked,
    getEcomm,
    getFeatured,
    getListUsersAccess,
    getNewsletters,
    getReviews,
    getUsers,
    setExportData
} from "../../actions/ReviewsAction";

import { format } from 'date-fns';

import {GrFormNext, GrFormPrevious} from 'react-icons/gr';

const Pagination = props => {
    const {state, dispatch} = useContext(ContextReducer)
    const [location, setLocation] = useState('reviews');

    useEffect(() => {
        if (state.actualRoute === 'users') {
            setLocation('users')
        } else if (state.actualRoute === 'insights') {
            setLocation('featured')
        } else if (state.actualRoute === 'newsletter') {
            setLocation('newsletters')
        } else if (state.actualRoute === 'stores') {
            setLocation('ecommerce')
        } else if (state.actualRoute === 'configuration') {
            setLocation('usersAccessList')
        } else {
            setLocation('reviews')
        }
    }, [state.actualRoute, state.featuredType, state.typeChangeStore])

    const handleClickArrow = type => {
        clearChecked(dispatch);

        if (props.handlePagination) {
            props.handlePagination();
        }

        const nextPage = type === 'left' ? -1 : 1;

        if (state.actualRoute === 'users') {
            const lastEvaluatedKey = state.users.keys[state.users.page + nextPage]
            getUsers(dispatch, state.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, lastEvaluatedKey, nextPage, '', (state.rangePeriod[0].startDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', ( state.rangePeriod[0].endDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '');
        } else if (state.actualRoute === 'insights') {
            const lastEvaluatedKey = state.featured.keys[state.featured.page + nextPage]
            getFeatured(dispatch, state.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, lastEvaluatedKey, nextPage, state.featuredType, (state.rangePeriod[0].startDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', ( state.rangePeriod[0].endDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '');
        } else if (state.actualRoute === 'newsletter') {
            const lastEvaluatedKey = state.newsletters.keys[state.newsletters.page + nextPage]
            getNewsletters(dispatch, state.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, lastEvaluatedKey, nextPage, '', (state.rangePeriod[0].startDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', ( state.rangePeriod[0].endDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '');
        } else if (state.actualRoute === 'stores') {
            const lastEvaluatedKey = state.ecommerce.keys[state.ecommerce.page + nextPage]
            getEcomm(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, state.typeChangeStore, true, state?.searchName, lastEvaluatedKey, nextPage);
        } else if (state.actualRoute === 'configuration') {
            const lastEvaluatedKey = state?.usersAccessList?.keys[state.usersAccessList.page + nextPage];
            getListUsersAccess(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, lastEvaluatedKey, nextPage);
        } else {
            const lastEvaluatedKey = state.reviews.keys[state.reviews.page + nextPage]
            getReviews(state.actualRoute, dispatch, state.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, lastEvaluatedKey, nextPage, '', (state.rangePeriod[0].startDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', ( state.rangePeriod[0].endDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '');
        }

        setExportData(dispatch, {
            loading: false,
            done: false
        })

    }

    const handleQuantityItemsPage = () => {
        if (location === 'usersAccessList') {
            return 10;
        } else {
            return 100;
        }
    }

    if (state?.reviews?.items.length || state?.users?.items.length || state?.featured?.items.length || state?.newsletters?.items.length || state?.ecommerce?.ecommerces.length || state?.usersAccessList?.items.length) {
        return (
            <Pag>
                {state[location].page > 0 &&
                    <ArrowLeft onClick={() => handleClickArrow('left')}><GrFormPrevious/></ArrowLeft>}
                <strong>{1 + (handleQuantityItemsPage() * state[location].page)}</strong> - <span>{handleQuantityItemsPage() + (handleQuantityItemsPage() * state[location].page)}</span>
                {state[location].lastEvaluatedKey &&
                    <ArrowRight onClick={() => handleClickArrow('right')}><GrFormNext/></ArrowRight>}
            </Pag>
        );
    } else {
        return <></>
    }
}

export default Pagination;
