import React from 'react';
import {Container} from './styles';

const LoaderBox = ({from}) => {
    return (
        <Container from={from}/>
    );
};

export default LoaderBox;
