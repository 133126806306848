import styled, {css} from "styled-components";

export const TitleFilter = styled.h2`
    color: #000000;
    font-size: 20px;
    font-weight: bold;

    ${(props => props.sActualRoute === 'insights') && css`
        margin-right: 20px;
    `}

    @media (max-width: 768px) {
        margin-bottom: 20px;
    }

    p {
        color: #fff;
    }
`