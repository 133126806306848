import styled, {css, keyframes} from 'styled-components'

export const Animation = keyframes`
    0%{background-position:0% 50%}
    50%{background-position:100% 51%}
    100%{background-position:0% 50%}
`

export const Fake = styled.div`
    width: 100%;
    height: 20px;
    background: linear-gradient(130deg, rgba(244,244,244,1) 0%, rgba(195,195,195,1) 50%, rgba(244,244,244,1) 100%);
    background-size: 600% 600%;
    animation: ${Animation} 2.2s ease infinite;


    ${props => props.height && css`
        height: ${props => props.height}px;
    `}
`

