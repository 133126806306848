import React, {useContext} from 'react';

import {TitleFilter} from './styles';
import {ContextReducer} from '../../reducer/AdminReducer';

const Title = (props) => {
    const {state, dispatch} = useContext(ContextReducer);

    const renderTitle = () => {
        const _tags = state?.filters?.status;
        if (state.actualRoute === 'reviews') {
            return state?.i18n?.titleTags?.evaluation + ' ' + state?.i18n?.actionTitle?.female[_tags]
        } else if (state.actualRoute === 'questions') {
            return state?.i18n?.titleTags?.questions + ' ' + state?.i18n?.actionTitle?.female[_tags]
        } else if (state.actualRoute === 'answers') {
            return state?.i18n?.titleTags?.answers + ' ' + state?.i18n?.actionTitle?.female[_tags]
        } else if (state.actualRoute === 'nps') {
            return state?.i18n?.titleTags?.nps + ' ' + state?.i18n?.actionTitle?.female[_tags]
        } else if (state.actualRoute === 'comments') {
            return state?.i18n?.titleTags?.comments + ' ' + state?.i18n?.actionTitle?.male[_tags]
        } else if ((state.actualRoute === 'insights' || state.actualRoute === '') && !props.secondTitle) {
            return state?.i18n?.titleTags?.insights
        } else if (props.secondTitle && (state.actualRoute === 'insights' || state.actualRoute === '')) {
            return state?.i18n?.titleTags?.insights2
        }
    }

    return (
        <TitleFilter sActualRoute={state.actualRoute}>{state?.i18n && renderTitle()}</TitleFilter>
    )
}

export default Title;
