import React from 'react';
import { Container } from './styles';
import {ReactComponent as StarStatistics } from '../../../assets/StarStatistics.svg';

const Statistics = (props) => {
    const { productInfo } = props.values;

    const renderRatingCount = () => {
        const count = productInfo?.ratingCount;
        if(count > 0){
            return(
                <span>({count})</span>
            )
        }
    }

    return (
        <Container>
            <StarStatistics />
            <h4>{productInfo?.ratingValue}</h4>
            {renderRatingCount()}
        </Container>
    );
};

export default Statistics;
