import styled, {css} from 'styled-components';

import startLogin from '../../assets/starLogin.svg';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F8F9FA;
`
export const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;

    @media (max-width: 768px) {
        flex-flow: column;
    }
`
export const WrapperLeft = styled.div`
   width: 40%;
   background-color: #EFF0F0;
   display: flex;
   align-items: center;
   justify-content: center;

   span {
        display: block;
        margin-bottom: 30px;
        font-size: 30px;
        font-weight: bold;
   }

    @media (max-width: 768px) {
        width: 100%;
        min-height: 470px;
    }
`
export const WrapperTop = styled.div`
   width: 100%;
   position: absolute;
   top: 30px;
   left: 30px;

    @media (max-width: 768px) {
        left: 0;
        text-align: center;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        padding-top: 30px;
        background: #F8F9FA;
        z-index: 4;
    }
`
export const ButtonLogin = styled.button`
    width: 370px;
    height: 48px;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 5px;
    background-color: #000000;
    border: none;
    font-weight: bold;
    transition: all ease .25s;
    color: #fff;
    font-size: 16px;
    box-sizing: border-box;

    &:hover{
        background: #fff;
        border: 2px solid #000000;
        color: #000000;
        transition: 0.5;
    }
`
export const WrapperLogin = styled.div`
   display: flex;
   justify-content: center;
   flex-direction: column;
   position: relative;

   form {
        display: flex;
        flex-flow: wrap;
        justify-content: center;
        width: 100%;
        align-items: center;
        max-width: 370px;
    }

   @media (max-width: 768px) {
        display: none;
    }
`

export const WrapperMobile = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    display: none;

    @media (max-width: 768px) {
        display: block;
    }

    svg {
        margin-bottom: 20px;
    }

    h4 {
        color: #000000;
        font-size: 20px;
    }

    p {
        color: #000000;
        font-size: 16px;
    }
`

export const ContentInput = styled.div`
    display: flex;
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
    position: relative;

    svg {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
`

export const Input = styled.input`
    width: 370px;
    height: 50px;
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    background: #fff;
    padding: 0 10px;
    font-size: 14px;
    margin-bottom: 15px;

    ${props => props.erro && css`
        border: 1px solid #ff0d23;
    `}

    ${props => props.completed && css`
        background-color: #cecece !important;
    `}
`

export const WrapperRight = styled.div`
    position: relative;
    width: 60%;
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        width: 100%;
        min-height: 345px;
        align-items: start;
    }
`

export const WrapperStar = styled.div`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 576px;
    height: 742px;
    z-index: 1;

    @media (max-width: 768px) {
        width: 210px;
        height: 178px;
    }
`

export const Star = styled.div`
    background-image: url(${startLogin});
    background-repeat: no-repeat;
    width: 576px;
    height: 742px;

    @media (max-width: 768px) {
        width: 210px;
        height: 178px;
        background-size: cover;
    }
`

export const WrapperInfos = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    font-size: 20px;
    color: #fff;
    position: relative;
    z-index: 2;

    @media (max-width: 768px) {
        padding: 30px;
    }
`
export const Infos = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 45px;
    font-weight: bold;
    flex-wrap: nowrap;

    &:last-child {
        @media (max-width: 768px) {
            margin-bottom: 0;
        }
    }

    @media (max-width: 768px) {
        margin-bottom: 30px;
    }

    p {
        font-size: 20px;
        margin-left: 15px;

        @media (max-width: 768px) {
            font-size: 16px;
        }
    }
`
export const Img = styled.div`
    width: 26px;
    height: 26px;
`

export const MsgError = styled.p`
    color: #ff0d23;
    font-size: 14px;
    margin-top: 15px;
    display: block;
    text-align: center;
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
`
