import styled, { css, keyframes} from 'styled-components';

const growAndSlide = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const decreaseAndSlide = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;
export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: auto;

    padding: 0;
    background-color: #EFF0F0;
    margin: 0px 10px 20px 0px;

    ${props => props.active && css`
        animation: ${growAndSlide} 0.5s ease-in;
    `}
    ${props => !props.active && css`
        animation: ${decreaseAndSlide} 0.5s ease-in-out;
    `}
`
