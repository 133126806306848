import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    top:0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; 
    z-index: 14;
`
export const Background = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.6);
    opacity: 1;
    transition: all .2s ease-out;
`
export const Content = styled.div`
    position: absolute;
    width: 600px;
    height: 600px;
    text-align: center;
    z-index: 15;

    img {
        width: auto;
        height: 100%;
    }
    
`
export const WrapperIcon = styled.div`
    position: absolute;
    top: 40px;
    right: 90px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    svg {  
        path {
            fill: #fff !important;
            stroke: #fff !important;    
        }
    }
`
