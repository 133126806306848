import React, { useContext } from 'react';
import { ContextReducer } from '../../../reducer/AdminReducer';
import { Container } from './styles';

const Sold = (props) => {
    const { state } = useContext(ContextReducer);

    const { npsType, productInfo } = props.values;

    const renderContainer = () => {
        const isNps = state.actualRoute === 'nps';
        const isDelivery = npsType === 'delivery';
        const isDeliveryNoSellerName = npsType === 'delivery' && !productInfo?.seller;

        const ecommerceNameDifferentSellerAndSeller = productInfo.ecommerceName !== productInfo?.seller && productInfo?.seller;
        const ecommerceNameDifferentSeller = productInfo.ecommerceName !== productInfo?.seller;

        if(isNps){
            return (
                <Container>
                    {isDeliveryNoSellerName && state?.i18n?.listItem?.sellAndShippingNoSellerName}
                    {(isDelivery && !isDeliveryNoSellerName) && state?.i18n?.listItem?.sellAndShipping}{ !isDelivery && state?.i18n?.listItem?.purchaseExperience}
                    {(isDelivery && !isDeliveryNoSellerName) ? productInfo?.seller : ''} {!isDelivery && productInfo?.ecommerceName}
                </Container>
            )
        }

        return (
            <Container>
                {state?.i18n?.listItem?.sold}
                {productInfo ? productInfo.ecommerceName : ''}
                {ecommerceNameDifferentSellerAndSeller ? state?.i18n?.listItem?.soldBy : ''}{ecommerceNameDifferentSeller ? productInfo?.seller : ''}
            </Container>
        )
    }

    return renderContainer()
};

export default Sold;
