import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';
import { ContextReducer } from '../../../reducer/AdminReducer';

import { ListHeaderItem } from '../../../Containers/styles';
import { ContainerTags, FormButtonsTags, Input, ButtonTypeTagsContent, ButtonTypeTags, MoreContent } from './styles';

import Tags from '../../Tags';

import { changeReview, sendConfigurantion } from '../../../actions/ReviewsAction';

const EditTags = (props) => {
    const { state, dispatch} = useContext(ContextReducer);
    const [openAddTag, setOpenAddTag] = useState(false);
    const [activeBtnTag, setActiveBtnTag] = useState(false);
    const [tagsItem, setTagsItem] = useState([]);
    const inputRef = useRef();

    const { tags, id, author } = props.values;

    useEffect(() => {
        if(tags) {
            setTagsItem(tags);
        }

        return () => {
            setTagsItem([]);
        }
    }, [tags]);

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                setOpenAddTag(false)
            }
        };

        window.addEventListener('keydown', handleEsc);

        return () => {

            window.removeEventListener('keydown', handleEsc);
        };

    }, []);

    useEffect(() => {
        if(openAddTag) {
            inputRef.current.focus();
        }
    }, [openAddTag])

    const handleActiveButtons = () => {
        if(inputRef.current.value.length > 0) {
            setActiveBtnTag(true)
        } else {
            setActiveBtnTag(false)
        }

    }

    const handleSubmit = (event, type) => {
        event.preventDefault();

        let _inputValue = inputRef.current.value;
        let _obj = {};
        let _arr = [];

        if(_inputValue.length === 0) return;

        _arr = [ ...tagsItem, { author: "operator", sentiment: type, originalTag: _inputValue}];

        setTagsItem(_arr);

        _arr.forEach(item => {
            _obj[item.originalTag] = { sentiment: item.sentiment, author: item.author }
        })


        if(props.type === 'configuration') {
            sendConfigurantion(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, null, null, null, null, null,  [...state?.config?.ecommerce?.tags ? state?.config?.ecommerce?.tags : [], _inputValue])
        } else {
            changeReview(state.actualRoute, dispatch, [
                {
                    "id": id,
                    "author": author
                }
            ], 'tags', _obj, state.filters.status, state?.userInfo?.token, state?.userInfo?.ecommerce, state?.actualArea, state?.filters);
        }

        inputRef.current.value = '';
        setOpenAddTag(false);
    }

    const handleCloseAddTag = (e) => {
        e.preventDefault();

        if(inputRef.current.value.length === 0) {
            setOpenAddTag(false)
        } else {
            setOpenAddTag(true)
        }
    }


    const renderTags = useMemo(() => {
        return tagsItem.map((item, index) => {
            return (
                <Tags
                    type={'default'}
                    key={index}
                    index={index}
                    author={author}
                    id={id}
                    setTagsItem={setTagsItem}
                    tagsItem={tagsItem}
                />
            )
        })
    }, [tagsItem, id, author])


    const handleOpenAdd = e => {
        e.preventDefault();

        setOpenAddTag(!openAddTag)
    }

    return (
        <div>
            <ListHeaderItem
                align='left'
                type={'tags'}
                activeTags={props.showMore === props.indexItem}
            >
                <ContainerTags>
                    <FormButtonsTags onSubmit={(event) => handleSubmit(event, 'neutral')}>
                        <Input
                            ref={inputRef}
                            placeholder={state?.i18n?.tags?.placeholder}
                            active={openAddTag}
                            onChange={handleActiveButtons}
                            onBlur={(event) => handleCloseAddTag(event)}
                        />
                        <ButtonTypeTagsContent>
                            {(props.type !== 'configuration' && openAddTag) &&
                                <ButtonTypeTags
                                    act={activeBtnTag}
                                    type="button"
                                    typeButton={'positive'}
                                    onClick={(event) => handleSubmit(event, 'positive')}
                                />
                            }
                            {(props.type !== 'configuration' && openAddTag) &&
                                <ButtonTypeTags
                                    act={activeBtnTag}
                                    type="button"
                                    typeButton={'mixed'}
                                    onClick={(event) => handleSubmit(event, 'mixed')}
                                />
                            }
                            {(props.type !== 'configuration' && openAddTag) &&
                                <ButtonTypeTags
                                    act={activeBtnTag}
                                    type="button"
                                    typeButton={'negative'}
                                    onClick={(event) => handleSubmit(event, 'negative')}
                                />
                            }
                            <ButtonTypeTags
                                type="button"
                                act={activeBtnTag}
                                typeButton={'add'}
                                active={openAddTag}
                                onClick={openAddTag ? (event) => handleSubmit(event, 'neutral') : handleOpenAdd}
                            />
                        </ButtonTypeTagsContent>
                    </FormButtonsTags>
                    {tagsItem && renderTags}
                </ContainerTags>
            </ListHeaderItem>
        </div>
    );
};

export default EditTags;
