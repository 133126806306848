import React, { useContext } from 'react';
import { ContextReducer } from '../../../../reducer/AdminReducer';
import { Container, Sentiment } from './styles';
import { setExportData, setFilter } from '../../../../actions/ReviewsAction';
import { ImCheckmark } from "react-icons/im";

const TagsItem = ({ value, setOpen, filtersRef }) => {
    const { state, dispatch } = useContext(ContextReducer);

    const _translatedTag = value?.translatedTag;

    const handleActive = (value) => {
        const _filter = {...state.filters};

        _filter.tag = value?.originalTag;
        _filter.authorTag = value?.author;
        _filter.sentimentTag = value?.sentiment;

        _filter.listFiltersHeader = {
            ...state.filters.listFiltersHeader,
            tag: value?.translatedTag
        }

        setFilter(dispatch, _filter);

        setOpen(false);
        filtersRef.current.scrollTop = 0;

        setExportData(dispatch, {
            loading: false,
            done: false
        })
    }

    const userUtil = value?.author === 'user' && value?.sentiment === 'neutral';

    return (
        <Container
            named={value}
            type="button"
            author={value?.author}
            onClick={() => handleActive(value)}
        >
            {userUtil && <ImCheckmark style={{ marginRight: '5px', width: '11px', height: '9px' }}/>}
            {!userUtil && <Sentiment sentiment={value?.sentiment} />}
            {`${_translatedTag?.charAt(0).toUpperCase() + _translatedTag?.slice(1).toLowerCase()} (${value?.count})`}
        </Container>
    );
};

export default TagsItem;
