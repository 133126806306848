import React, {useContext} from 'react';
import {MdReply} from "react-icons/md";

import {AnswerReview} from './styles';

import {ContextReducer} from "../../reducer/AdminReducer";

const Answer = () => {
    const {state} = useContext(ContextReducer);

    return (
        <AnswerReview>
            <MdReply style={{width: '32px', height: '32px', color: '#FFA100', WebkitTransform: 'scaleX(-1)', transform: 'scaleX(-1)'}}/>
            <span>{state?.i18n?.listItem[state.actualRoute]}</span>
        </AnswerReview>
    );
}

export default Answer;
