import styled from 'styled-components';

export const Container = styled.div`
    display: flex;

    input[type=checkbox] {
        width: 20px;
        height: 20px;
        border: 1px solid #DDD;
        border-radius: 5px;
    }

    label {
        margin: 0px 25px 0px 5px;
        font-size: 16px;
        opacity: 0.8;
        color: #000;
    }
`
