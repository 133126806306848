import styled, {css} from 'styled-components';

export const Modal = styled.div`
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    top: 0;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 11;
    opacity: 0;
    max-width: 0;
    max-height: 0;
    overflow: hidden;
    transition: all ease .25s;
    transform: translateX(-50%);

    ${props => props.active && css`
        opacity: 1;
        width: 100%;
        height: 100%;
        max-width: 100vw;
        max-height: 100vh;
    `}
`

export const Container = styled.div`
    position: relative;
    width: 80%;
    height: 80%;
    max-width: 1550px;
    max-height: 670px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transition: opacity ease-out .5s;
`
export const Wrapper = styled.div`
    width: 85%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
`
export const WrapperTypography = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
`
export const WrapperPillCancel = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    `
export const WrapperQuestion = styled.div`
    width: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    span{
        font-weight: bold;
        margin-bottom: 10px;
        color: #212529;
    }

    p {
        color: #73818f;
        font-size: 16px;
        font-weight: 400;
    }
`

export const TextArea = styled.textarea`
    font-size: 16px;
    border: 1px solid #bebebe;
    border-radius: 5px;
    width: 100%;
    height: 250px;
    box-sizing: border-box;
    resize: none;
    outline: none;
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 10px;
    font-family: 'Open Sans',sans-serif;
`

export const ContainerModal = styled.div`
    width: 100%;
    display: flex;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    align-items: center;
    height: 90px;
    position: absolute;
    box-shadow: 0px -5px 11px -6px rgb(0 0 0 / 30%);
    background-color: #fff;
    border-radius: 10px;
    border-top-left-radius: unset;
    border-top-right-radius: unset;
`
export const Content = styled.div`
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
}
`
export const WrapperLeft = styled.div`
   display: flex;
   justify-content: flex-start;
   align-items: center;
}
`
export const WrapperModal = styled.div`
   display: flex;
   justify-content: flex-start;
   align-items: start;
   flex-direction: column;
   margin-left: 20px;

   span {
       font-weight: bold;
       font-family: 'Open Sans',sans-serif;
   }
}
`
export const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 266px;
    height: 48px;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 5px;
    background-color: #FFA100;
    font-weight: bold;
    transition: background .2s ease-out,border .2s ease-out,color .2s ease-out;


    &:hover{
        background: #fff;
        border: 2px solid #FFA100;
        color: #212529;
        font-size: 16px;
        transition: 0.5;
    }
}
`

export const Sold = styled.div`
    font-size: 11px;
    color: #808080;
    margin-top: 5px;
`

export const ContentQuestion = styled.div`
    display: flex;
    margin-top: 10px;
`

export const ContentQuestionOther = styled.div`
    display: flex;
    margin-top: 30px;
`

export const ContentLeft = styled.div`
    margin-right: 10px;
`

export const ContentRight = styled.div`
    display: flex;
    flex-flow: column;
    width: 100%;

    span {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        display: block;
        margin-bottom: 10px;
    }

    p {
        font-size: 16px;
        color: #000000;
    }
`

export const Avatar = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 0.1px solid #d8d8d8;
    margin-right: 5px;

    ${props => props.small && css`
        width: 20px;
        height: 20px;
    `}
`

export const Initials = styled.div`
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    border-radius: 50%;
    margin-right: 5px;
    background-color: #000000;
    color: #fff;
    cursor: pointer;

    @media (max-width: 768px) {
        max-width: 30px;
        min-width: 30px;
    }

    ${props => props.small && css`
        width: 20px;
        height: 20px;
        font-size: 10px;
    `}

    ${props => props.pointer && css`
        cursor: pointer;
    `}
`

export const InitialsSeller = styled.div`
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    border-radius: 50%;
    margin-right: 5px;
    background-color: #fff;
    color: #000000;
    border: 1px solid #000;
    cursor: pointer;

    @media (max-width: 768px) {
        max-width: 30px;
        min-width: 30px;
    }

    ${props => props.small && css`
        width: 20px;
        height: 20px;
        font-size: 10px;
    `}

    ${props => props.pointer && css`
        cursor: pointer;
    `}
`
