import styled from 'styled-components';

export const Container = styled.div`
    width: auto;
    height: auto;

    select {
        width: ${props => props.sizePlaceholder + 'px'};
        max-width: 130px;
        height: 20px;
        border: none;
        font-size: 12px;
        font-weight: bold;
    }
`
