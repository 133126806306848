import {useEffect, useReducer } from "react";
import {AdminReducer, ContextReducer, initialUserState} from "../reducer/AdminReducer";
import Router from '../config/routes';
import GlobalStyles from "../config/GlobalStyles";
import {
    clearEcomm,
    clearFeatured,
    clearReviews,
    setCachePin,
    setExportData,
    setFilter,
    setUser,
    setActualArea
} from "../actions/ReviewsAction";

export const App = () => {
    const [state, dispatch] = useReducer(AdminReducer, initialUserState);

    useEffect(() => {
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = "f084a065-03d1-40fd-abea-6906cf6f343a";

        (function () {
            var d = document;
            var s = d.createElement("script");

            s.src = "https://client.crisp.chat/l.js";
            s.async = 1;
            d.getElementsByTagName("head")[0].appendChild(s);
        })();

    }, [])

    useEffect(() => {
        if (window.$crisp && state.userInfo.email) {
            window.$crisp.push(["set", "user:email", state?.userInfo?.email ? state?.userInfo?.email : 'null'])

            window.$crisp.push(["set", "session:data", [
                [
                    ["company", state?.userInfo?.ecommerce?.name ? state?.userInfo?.ecommerce?.name : 'null'],
                    ["is_store", state?.userInfo?.ecommerce?.store ? state?.userInfo?.ecommerce?.store : 'null'],
                    ["is_marketplace", state?.userInfo?.ecommerce?.marketplaces ? state?.userInfo?.ecommerce?.marketplaces : 'null'],
                    ["is_brand", state?.userInfo?.ecommerce?.mentions ? state?.userInfo?.ecommerce?.mentions : 'null']
                ]
            ]])
        }
    }, [state?.userInfo])

    useEffect(() => {

        if (localStorage.getItem('vurdere_session'))
            setUser(dispatch, JSON.parse(localStorage.getItem('vurdere_session')))

        if (localStorage.getItem('vurdere_session'))
            setCachePin(dispatch, JSON.parse(localStorage.getItem('vurdere_ecomm')))

        import('../i18n/i18n-pt-br.js').then(({i18n}) => {
            dispatch({
                type: "SET_I18N",
                payload: i18n
            })
        })

    }, [])

    const handleListType = () => {
        switch(window.localStorage.getItem('vurdere-type')) {
            case 'marketplaces':
                return 'marketplaces'
            case 'mentions':
                return 'mentions'
            default:
                return 'store'
        }
    }

    useEffect(() => {
        document.body.scrollTop = 0;
        setActualArea(dispatch, handleListType());

        setFilter(dispatch, {
            status: 'PENDING',
            order: 'newer',
            tags: null,
            last: '-1',
            orderUsers: 'newer',
            orderFeatured: 'reviews',
            sortFeatured: 'desc',
            listType: handleListType(),
            orderNewsletter: 'transactionDate',
            sortNewsletter: 'desc'
        });

        clearReviews(dispatch);
        clearFeatured(dispatch);
        clearEcomm(dispatch, {
            store: state?.ecommerce?.totals['store'],
            marketplace: state?.ecommerce?.totals['marketplace'],
            marketplaces: state?.ecommerce?.totals['marketplaces'],
            mentions: state?.ecommerce?.totals['mentions']
        });
        setExportData(dispatch, {
            loading: false,
            done: false
        })

    }, [state.actualRoute, state.userInfo, state.typeChangeStore]);


    return (
        <ContextReducer.Provider value={{state, dispatch}}>
            <GlobalStyles padding={state?.actualRoute}/>
            <Router />
        </ContextReducer.Provider>

    )
}
