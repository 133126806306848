import React from 'react';
import ReactDOM from 'react-dom';
import {ErrorBoundary} from 'react-error-boundary';
import {App} from './Containers/index';
import Error from './Containers/Error';
import {register as registerServiceWorker} from './serviceWorkerRegistration';

const ErrorHandler = ({error}) => {
    localStorage.setItem('error', error);
    localStorage.setItem('error message', error.message);
    return <Error/>
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <App />
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root')
);

registerServiceWorker();
