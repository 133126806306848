import React, { useContext } from 'react';
import { ContextReducer } from '../../../../../reducer/AdminReducer';
import {Container} from './styles';

const Checkbox = ({type, check, handlePatchAccess}) => {
    const { state } = useContext(ContextReducer);

    const handleDisabled = (type) => {
        if(type === 'reader') return true;

        if (type === 'writer' && check === 'admin') {
            return true;
        }

        return false;
    }

    const handleChecked = (type) => {
        if(type === 'reader') return true

        if ((type === 'writer' && check === 'writer') || (type === 'writer' && check === 'admin')) {
            return true;
        } else if (type === 'admin' && check === 'admin') {
            return true;
        }

        return false;
    }


    const handleChangeChecked = (e) => {
        if (check === e.target.value) {
            handlePatchAccess('reader');
        } else {
            handlePatchAccess(e.target.value);
        }
    }

    return (
        <Container>
            <input type="checkbox"
                disabled={handleDisabled(type)}
                value={type}
                onChange={(e) => handleChangeChecked(e)}
                checked={handleChecked(type)}
            />
            <label>{state?.i18n?.peopleAccess?.checkboxType[type]}</label>
        </Container>
    );
};

export default Checkbox;
