import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    width: 100px;
    height: 100%;
    position: relative;
    overflow: hidden;

    img {
        width: 100%;
        height: auto;
    }

    @media(max-width: 768px) {
        margin: 0 auto;
    }
`

export const IconSellerPhotoNps = styled.div`
    width: 30px;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    background-color: #E4E7ED;
    z-index: 2;
    position: absolute;
    bottom: 0;
    right: 0;
`

export const NoPhoto = styled.div`
    width: 100px;
    height: 100px;
    background-color: rgba(15, 28, 59, .1);
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;

    svg {
        font-size: 100px;
        position: absolute;
        opacity: .1;
    }
`
