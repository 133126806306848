import styled, {css} from 'styled-components'

export const Container = styled.div`
    background: linear-gradient(130deg, rgba(244,244,244,1) 0%, rgba(195,195,195,1) 50%, rgba(244,244,244,1) 100%);
    animation: blink 2.2s ease infinite;
    background-size: 600% 600%;
    margin: 0 20px;
    border-radius: 5px;

    @keyframes blink {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 51%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    ${props => (props.from === 'button' || props.from === 'select') && css`
        width: 190px;
        height: 55px;
    `}
    ${props => props.from === 'box' && css`
        width: 311px;
        height: 55px;
    `}
    ${props => props.from === 'abas' && css`
        margin: 0px 0px 30px 0px;
        width: 87%;
        height: 40px;
    `}
    ${props => props.from === 'stores' && css`
        margin: 0px 0px 30px 0px;
        width: 50%;
        height: 40px;
    `}
    ${props => props.from === 'input' && css`
        margin: 0px 0px 30px 0px;
        width: 162px;
        height: 55px;
    `}
    ${props => props.from === 'title' && css`
        margin: 0px 0px 30px 0px;
        width: 257px;
        height: 36px;
    `}
    ${props => props.from === 'titleUsers' && css`
        margin: 0px 0px 30px 0px;
        width: 163px;
        height: 24px;
    `}
    ${props => props.from === 'logo' && css`
        margin: 0px;
        width: 160px;
        height: 30px;
    `}
    ${props => props.from === 'boxnews' && css`
        margin: 0px;
        margin: 0px 20px 20px 0px;
        width: 220px;
        height: 120px;

    `}
    ${props => props.from === 'barinfos' && css`
        margin: 0px;
        margin: 0px 0px 10px 0px;
        width: 100%;
        height: 60px;
    `}
`
