import styled from 'styled-components';

export const ContainerInitial = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #000000;
    height: 100%;

    h2 {
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
        font-family: 'Open Sans', sans-serif;
        display: block;
        line-height: 27px;
    }

    h3 {
        color: #fff;
        font-family: 'Open Sans', sans-serif;
        opacity: .5;
        font-size: 16px;
        font-weight: normal;
        display: block;
        line-height: 22px;
    }
`

export const Wrapper = styled.div`
    max-width: 420px;
    width: 100%;
`
export const ButtonBack = styled.div`
    max-width: 300px;
    width: 100%;
    border: 1px solid #fff;
    border-radius: 5px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #000;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 30px;

    a {
        display: block;
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 60px;
    }
`