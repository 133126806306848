import React, {useContext} from 'react';
import {ContextReducer} from '../../../reducer/AdminReducer';
import {Container} from './styles';

import {ReactComponent as SocialAnswers} from '../../../assets/Social-Answers.svg';
import {ReactComponent as SocialReviews} from '../../../assets/Social-Reviews.svg';
import {ReactComponent as SocialQuestions} from '../../../assets/Social-Questions.svg';
import {ReactComponent as SocialUsers} from '../../../assets/Social-Users.svg';
import {ReactComponent as SocialNps} from '../../../assets/Social-NPS.svg';
import {ReactComponent as SocialComments} from '../../../assets/Social-Comments.svg';
import {ReactComponent as SocialNewsletter} from '../../../assets/Social-Newsletters.svg';
import {ReactComponent as SocialIntelligence} from '../../../assets/Social-Intelligence.svg';

const HeaderTypeTitle = () => {
    const {state} = useContext(ContextReducer);

    const renderIconTypeRoute = () => {
        switch (state.actualRoute) {
            case 'reviews':
                return <SocialReviews/>
            case 'answers':
                return <SocialAnswers/>
            case 'comments':
                return <SocialComments/>
            case 'users':
                return <SocialUsers/>
            case 'newsletter':
                return <SocialNewsletter/>
            case 'questions':
                return <SocialQuestions/>
            case 'nps':
                return <SocialNps/>
            case 'insights':
                return <SocialIntelligence/>
            default:
                return ''
        }
    }

    return (
        <Container>
            {renderIconTypeRoute()}
            <span>{state?.i18n?.headerType?.socialplus}</span>
            <strong>{state?.i18n?.headerType[state.actualRoute]}</strong>
        </Container>
    );
};

export default HeaderTypeTitle;
