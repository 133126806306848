import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ContextReducer } from '../../reducer/AdminReducer';

import Pagination from '../../components/Pagination';
import {Initials} from '../../components/Items/UserInfo/styles';

import LoaderInfos from '../../components/LoaderInfos';

import {Container, ListHeaderItem} from '../styles'
import {Content, ContentUsers, Data, Email, Flex, Id, Items, Name, Picture, Title, Tooltip} from './styles';
import NoItems from "../../components/NoItems";
import HeaderFilter from "../../components/HeaderFilter";
import getTransformDpr from "../../utils/TransformDpr";
import LoaderBox from '../../components/LoaderBox';
import useInitializeInfos from '../../hooks/useInitializeInfos';
import ListFiltersSearch from '../../components/Filter/ListFiltersSearch';

const Users = () => {

    const { state } = useContext(ContextReducer);
    const [copy, setCopy] = useState(false);
    const [activeId, setActiveId] = useState('');
    const [noEmail, setNoEmail] = useState(false);

    const hasUsersTitle = () => state?.i18n?.users;
    useInitializeInfos();

    useEffect(() => {
        const copyTimeout = setTimeout(() => {
            setCopy(false)
        }, 5000)

        return () => {
            clearTimeout(copyTimeout);
        }
    }, [copy])

    const handleCopy = (email, index) => {
        if (email.length > 0) {
            navigator.clipboard.writeText(email);
            navigator.clipboard.readText().then(clipText => {
                if (clipText.length > 0) {
                    setActiveId(index)
                    setCopy(true)
                }
            })
            setNoEmail(false)
        } else {
            navigator.clipboard.writeText('');
            setActiveId(index)
            setCopy(true)
            setNoEmail(true)
        }
    }

    const renderName = (name) => {
        if (name) {
            let _name = name.split(' ');
            let _namesplit = '';

            if (_name.length >= 3 && _name[_name.length - 1].length > 0) {
                _namesplit = _name[0] + ' ' + _name[_name.length - 1];
            } else if (_name.length > 2 && _name[_name.length - 1].length === 0) {
                _namesplit = _name[0] + ' ' + _name[1];
            } else {
                _namesplit = name;
            }

            return _namesplit;
        }
    }

    const renderInitials = (initials) => {
        let _initials = renderName(initials).trim();

        _initials = _initials.split(' ').reduce((ac, inn) => ac + inn[0], '').toUpperCase();
        return _initials;
    }

    const renderAvatar = (url, name, email, index) => {
        let _styles = {};

        if (url) {
            _styles.background = `url(https://${state?.userInfo?.ecommerce.domain}/image/upload/t_thumbs_dpr_${getTransformDpr(window.devicePixelRatio)}/f_auto/${url}.jpeg) center center / contain no-repeat`;
        }

        if (url && url !== ' ') {
            return <Picture style={_styles} onClick={() => handleCopy(email, index)}/>
        } else if (name && name !== ' ') {
            return <Initials onClick={() => handleCopy(email, index)} pointer={true}>{renderInitials(name)}</Initials>
        } else {
            return <Initials onClick={() => handleCopy(email, index)} pointer={true}></Initials>
        }
    }

    const renderUsers = () => {
        if (state?.users?.items.length > 0) {
            return state?.users?.items?.map((val, index) => {
                return (
                    <Items key={index}>
                        <Flex porcentage="20">
                            {val?.profile?.picture && renderAvatar(val?.profile?.picture?.id, val?.profile?.name, val?.profile?.email, index)}
                            <Name onClick={() => handleCopy(val?.profile?.email, index)}>{val?.profile?.name}</Name>
                        </Flex>
                        <Email porcentage="20" onClick={() => handleCopy(val?.profile?.email, index)}>
                            <p>{val?.profile?.email}</p>
                            {index === activeId && copy &&
                                <Tooltip>{noEmail ? state?.i18n?.user?.noemail : state?.i18n?.user?.copy}</Tooltip>}
                        </Email>
                        <Id porcentage="20">{val?.loginType}</Id>
                        <Id porcentage="20">{val?.userId}</Id>
                        <Data porcentage="20">{val?.created}</Data>
                    </Items>
                )
            });
        } else {
            return <NoItems/>
        }
    }

    const renderUsersCount = () => {
        return state?.users?.total ? ` (${state?.users?.total})` : ' (...)'
    }

    return useMemo(() => {
        return (
            <>
                <HeaderFilter/>
                <Content>
                    {hasUsersTitle() ? <Title>{state?.i18n?.users + renderUsersCount()} </Title> :
                        <LoaderBox from={'titleUsers'}/>}
                    <ListFiltersSearch/>
                </Content>
                <Container>
                    <Pagination/>
                </Container>
                <ContentUsers>
                    <Container area="listheader">
                        <ListHeaderItem width='20' porcentage={true}>
                            <p>{state?.i18n?.user?.user}</p>
                        </ListHeaderItem>
                        <ListHeaderItem width='20' porcentage={true}>
                            <p>{state?.i18n?.user?.email}</p>
                        </ListHeaderItem>
                        <ListHeaderItem width='20' porcentage={true}>
                            <p>{state?.i18n?.user?.type}</p>
                        </ListHeaderItem>
                        <ListHeaderItem width='20' porcentage={true}>
                            <p>{state?.i18n?.user?.id}</p>
                        </ListHeaderItem>
                        <ListHeaderItem width='20' porcentage={true}>
                            <p>{state?.i18n?.user?.date}</p>
                        </ListHeaderItem>
                    </Container>
                </ContentUsers>
                <Container area='users'>
                    {!state?.isLoad ? <LoaderInfos/> : renderUsers()}
                </Container>
                <Container>
                    <Pagination/>
                </Container>
            </>
        );
    }, [state?.users, state.actualRoute, copy, activeId, noEmail])
}

export default Users;
