import { useEffect, useContext, useRef } from 'react'
import { sendConfigurantion, setTokenAuthMkt, setIntegratedStores } from '../actions/ReviewsAction';
import { ContextReducer } from '../reducer/AdminReducer';

const useRefreshToken = (from, tabClicked = '', setOpen = '') => {

    const { state, dispatch } = useContext(ContextReducer);

    const firstRenderRef = useRef(true);
    const url = new URLSearchParams(window.location.search);
    const token = url.get('refresh_token');

    useEffect(() => {
        if(!firstRenderRef.current || from !== 'auth') return;

        if(firstRenderRef.current && token) {

            setTokenAuthMkt(dispatch, token);
            firstRenderRef.current = false;
            return
        }
    }, [])

    useEffect(() => {
        if(from !== 'config') return;


        if(state?.tokenAuthMkt) {
            setOpen('integratedStores');

            if(tabClicked) {
                tabClicked.current = 'integratedStores';
            }

            sendConfigurantion(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, null, null, null, null, null, null, null, state?.tokenAuthMkt, 'mlv');
        }
    }, []);

    useEffect(() => {
        if(from !== 'wrapperIntegrated') return;

        if((state?.tokenAuthMkt !== 'false' && state?.tokenAuthMkt) && state.ecommerce?.ecommerces && state.ecommerce?.ecommerces[0]?.marketplace) {
            setIntegratedStores(dispatch, state.ecommerce?.ecommerces.find(item => item.ecommerceId === 'mlv'));
            setTokenAuthMkt(dispatch, '');
        }
    }, [dispatch, state?.tokenAuthMkt, state.ecommerce?.ecommerces, from])

}

export default useRefreshToken;
