import styled from "styled-components";

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 80vh;

    h3 {
        color: #000000;
        font-size: 20px;
        font-weight: bold;
        margin-top: 20px;
    }

    h5 {
        color: #000000;
        font-size: 16px;
        font-weight: normal;
    }
`