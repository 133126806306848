import React, { useContext } from 'react';
import { ContextReducer } from '../../../../reducer/AdminReducer';
import Checkbox from './Checkbox';
import { Container, WrapperCheckBox } from './styles';

const Item = ({side, domain, domainChecked, personalChecked, personalEmail, handleCheckboxValue, setDomain, setPersonalEmail}) => {
    const { state } = useContext(ContextReducer);

    const renderActive = (from) => {
        if (from === 'domain') {
            return (personalEmail.length > 0 || personalChecked) ? false : true
        }
        return (domain.length > 0 || domainChecked) ? false : true
    }

    const handleChangeInput = (e, from) => {
        if (from === 'domain') {
            setDomain(e.target.value);
        } else {
            setPersonalEmail(e.target.value);
        }
    }

    const renderListCheckBox = () => {
        const types = ['reader', 'writer', 'admin'];
        let _arr = [];

        for(let i = 0; i < types.length; i++) {
            _arr.push(
                <Checkbox
                    key={types[i]}
                    from={side}
                    type={types[i]}
                    domain={domain}
                    domainChecked={domainChecked}
                    personalChecked={personalChecked}
                    personalEmail={personalEmail}
                    handleCheckboxValue={handleCheckboxValue}
                />
            )
        }

        return _arr;
    }

    return (
        <Container active={renderActive(side)}>
            <span>{state?.i18n?.peopleAccess?.textsInputAccess[side]?.title}</span>
            <label>{state?.i18n?.peopleAccess?.textsInputAccess[side]?.subtitle}</label>
            <input type="text"
                value={side === 'domain' ? domain : personalEmail}
                onChange={(e) => handleChangeInput(e, side)}
                placeholder={state?.i18n?.peopleAccess?.textsInputAccess[side]?.placeholder}
            />
            <WrapperCheckBox>
                {renderListCheckBox()}
            </WrapperCheckBox>
        </Container>
    );
};

export default Item;
