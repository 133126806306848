import styled, {css} from 'styled-components';

export const Content = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    
    
    ${props => props.type === 'high' && css`
        ${Item} {
            background: linear-gradient(90deg, rgba(255, 0, 0, 0.3) 0%, rgba(255, 206, 0, 0.3) 50%, rgba(20, 196, 81, 1) 100%);
        }
        ${Text} {
            color: #1EC44F;
        }
    `}

    ${props => props.type === 'medium' && css`
        ${Item} {
            background: linear-gradient(90deg, rgba(255, 0, 0, 0.3) 0%, rgba(255, 206, 0, 1) 50%, rgba(20, 196, 81, 0.3) 100%);
        }
        ${Text} {
            color: #FDA629;
        }
    `}

    ${props => props.type === 'low' && css`
        ${Item} {
            background: linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(255, 206, 0, 0.3) 50%, rgba(20, 196, 81, 0.3) 100%);
        }
        ${Text} {
            color: #FF0000;
        }
    `}
`

export const Item = styled.div`
    width: 250px;
    height: 10px;
    border-radius: 10px;
    margin-right: 10px;
`

export const Text = styled.span`
    font-size: 16px;
    font-weight: bold;
`

