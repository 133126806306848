import styled, { css } from 'styled-components';

export const WrapperWarn = styled.div`
    display: flex;
    flex-flow: column;
    width: 100%;
    margin: 20px 0;

    strong {
        font-size: 14px;
        display: block;
        margin-bottom: 10px;
    }
`

export const Button = styled.button`
    width: 295px;
    height: 40px;
    border: 1px solid #000;
    border-radius: 5px;
    text-align: center;
    line-height: 40px;
    background: transparent;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    color: #000;
    transition: all ease .25s;
    display: flex;
    align-items: center;
    justify-content: center;

    ${props => props.active && css`
        background-color: #000;
        color: #fff;
    `}

    &:hover {
        background-color: #000;
        color: #fff;
    }
`

export const Content = styled.div`
    display: flex;
    align-items: center;
    margin: 20px 0 30px 0;
`

export const Logo = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    border: 1px solid #DDDDDD;
`

export const Back = styled.button`
    border: none;
    background: transparent;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    cursor: pointer;
`
