import styled, {css} from 'styled-components';

export const Container = styled.div`
    margin: 20px 0px 5px;
    position: relative;
    display: flex;
    height: 20px;
`

export const Content = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
`

export const ContentInactive = styled(Content)`
    z-index: 1;
`

export const Item = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    width: 22px;
    color:#738090;
    font-size:12px;
    border:1px solid #E7E8EB;
    border-radius:100%;
    margin-right:5px;

    ${props => props.type === 'low' && css`
        color:#FFFFFF;
        background:#FF3665;
        border:none;
    `}

    ${props => props.type === 'medium' && css`
        color:#FFFFFF;
        background:#FFBE00;
        border:none;
    `}

    ${props => props.type === 'high' && css`
        color:#FFFFFF;
        background:#00B981;
        border:none;
    `}
`
