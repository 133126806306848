import styled from 'styled-components';

export const Container = styled.div`
    width: 73px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0px 20px 22px 0px;

    > label {
        font-size: 16px;
        margin-left: 5px;
    }

    > input[type=checkbox] {
        width: 20px;
        height: 20px;
        border: 1px solid #DDD;
        border-radius: 3px;
        cursor: pointer;
    }
`
