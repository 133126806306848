import styled, {css} from 'styled-components';

export const ButtonExport = styled.div`
    width: 113px;
    height: 55px;
    margin: 0 10px;
    border-radius: 5px;
    background: #000;
    color:#fff;
    font-weight: bold;
    cursor: pointer;
    transition: all .5s ease-out;
    position: relative;
    text-align: center;
    line-height: 50px;
    font-size: 12px;

    display: flex;
    justify-content: center;
    align-items: center;


    ${props => props.inactive && css`
        background-color: #D6D8E0;
        color: #919191;
        border:2px solid #D6D8E0;
        cursor: not-allowed;
    `}

    ${props => props.visible && css`
        margin: 0 15px;
    `}

    ${props => props.dash && css`
        margin-right: 0;
    `}

    ${props => props.loadingButton && css`
        line-height: initial;
        text-align: center;
        padding-right: 10px;
    `}

    ${props => props.done && css`
        font-size: 14px;
        line-height: initial;

        svg {
            margin: 10px;
        }
    `}


    &:hover {
        border: 2px solid #000000;
        background: #fff;
        color: #000;
        transition: all ease-in 0.5s;

        svg {
            path {
                background-color: #000;
            }
        }

        ${props => props.inactive && css`
            background-color: #D6D8E0;
            color: #919191;
            border:2px solid #D6D8E0;
        `}
    }

    ${props => (props.visible && !props.itemDownDash) && css`
        width: inherit;
        background: #fff;
        transition: none;
        color: #000;

        svg {
            stroke: #000;
        }

        &:hover {
            border: none;
            background: #fff;
            color: #000;
            transition: none;
        }
    `}
`

export const ButtonLoadingExport = styled.div`
    border:2px solid #FFA100;
    width: 1px;
    height: 100%;
    border-radius: 5px;
    position: absolute;
    transition:all .2s ease;
    background-color: #FFA100;
    top: -2px;
    left: -2px;
`

export const LogoClientContent = styled.div`
    max-width: 160px;
    position: relative;
`

export const LogoClient = styled.img`
    width: 100%;
    height: auto;
`

export const Container = styled.div`
    display: none;
`

export const Table = styled.table`
    display: none;
`

export const ContentFilters = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-flow: column;
    }
`
