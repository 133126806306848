import styled, { css } from "styled-components";

export const Container = styled.div`
    width: 30px;
    height: 14px;
    border-radius: 30px;
    background-color: #000;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 5px;
    background-color: #00000033;
    transition: all ease-in 0.5s;

    @media screen and (max-width: 1040px) {
        margin-right: 0px;
    }

    ${props => props.from === 'newsletterConfig' && css`
        width: 45px;
        height: 21px;
    `}

    ${props => props.active && css`
        transition: all ease-in 0.5s;
        background-color: #000;
    `}
`
export const Roll = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #fff;
    margin: 0px 2px 0px 2px;
    transition: transform 0.5s;
    transform: translateX(0%);

    ${props => props.from === 'newsletterConfig' && css`
        width: 15px;
        height: 15px;
        border: 3px solid #fff;
        margin: 0px 5px 0px 5px;
    `}

    ${props => props.active && css`
        transition: transform 0.5s;
        transform: ${props => (props.active ? 'translateX(16px)' : 'translateX(0%)')};
        background-color: #000;

        ${props => props.from === 'newsletterConfig' && css`
            transform: ${props => (props.active ? 'translateX(20px)' : 'translateX(0%)')};
        `}
    `}

`;

