import { useEffect, useContext } from 'react';
import { setTagsHeader } from '../actions/ReviewsAction';
import { format } from 'date-fns';
import { ContextReducer } from '../reducer/AdminReducer';

const useResizeObjectsTags = (ref, open, fromData = 'sendReview', setMore, handleSearching, setOpen) => {
    const controller = new AbortController();
    const signal = controller.signal;

    const { state, dispatch } = useContext(ContextReducer);

    useEffect(() => {
        if(fromData !== 'sendReview') return;

        const _filterRef = ref.current;

        const handleSend = () => {
            if(open && (_filterRef.scrollHeight - _filterRef.scrollTop === _filterRef.clientHeight) && state?.totalTags?.lastEvaluatedKey) {
                setTagsHeader(state.actualRoute, dispatch, state.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, state?.totalTags?.lastEvaluatedKey, signal, (state.rangePeriod[0].startDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', ( state.rangePeriod[0].endDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '', state?.tagsFilterHeader, state?.totalTags?.tags, handleSearching);
                resizeObserver.unobserve(_filterRef);
            }
        }

        const resizeObserver = new ResizeObserver(item => {
            for (let entry of item) {
            if (entry.target === _filterRef) {
                handleSend();
            }
          }
        });

        if (_filterRef) {
          resizeObserver.observe(_filterRef);
        }

        handleSend();

        return () => {
            if (_filterRef) {
              resizeObserver.unobserve(_filterRef);
            }

            controller.abort();
        };
    }, [open, state.actualRoute, state.filters, state.rangePeriod, state?.tagsFilterHeader, state?.totalTags?.lastEvaluatedKey, state?.totalTags?.tags, state?.userInfo?.ecommerce, state?.userInfo?.token]);

    useEffect(() => {
        if(fromData === 'sendReview') return;

        let _filterRef = ref.current;

        const checkHeight = () => {
            if (_filterRef.scrollHeight > 120) {
                setMore(true);

                if(_filterRef.scrollHeight < 160 && open) {
                    setOpen(null)
                }
            } else {
                if(open) {
                    setOpen(null);
                }
                setMore(false);
            }
        };

        const resizeObserver = new ResizeObserver(item => {
            for (let entry of item) {
            if (entry.target === _filterRef) {
              checkHeight();
            }
          }
        });

        if (_filterRef) {
          resizeObserver.observe(_filterRef);
        }

        checkHeight();

        return () => {
          if (_filterRef) {
            resizeObserver.unobserve(_filterRef);
          }
        };
      }, [state?.totalTags?.tags, open]);
};

export default useResizeObjectsTags;
