import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Link} from "react-router-dom";

import CountUp from 'react-countup';
import {Container} from '../styles';
import {
    Aba,
    Abas,
    Arrow,
    Avg,
    AvgGradient,
    AvgInfos,
    AvgScore,
    AvgText,
    Bar,
    Box,
    Col,
    ImageProduct,
    Infos,
    Item,
    ItemText,
    Loader,
    Num,
    Numbers,
    Small,
    Table,
    Text,
    Texts,
    Types
} from './styles';
import HeaderFilter from "../../components/HeaderFilter";
import Title from '../../components/Title';
import Pagination from "../../components/Pagination";
import ExportTable from "../../components/ExportTable";
import NoItems from "../../components/NoItems";
import ListFiltersSearch from '../../components/Filter/ListFiltersSearch';
import {ReactComponent as SocialReviews} from '../../assets/Social-Reviews.svg';
import {ReactComponent as SocialQuestions} from '../../assets/Social-Questions.svg';
import {ReactComponent as SocialUsers} from '../../assets/Social-Users.svg';
import {ReactComponent as SocialNps} from '../../assets/Social-NPS.svg';
import {ReactComponent as SocialAnswers} from '../../assets/Social-Answers.svg';

import {ContextReducer} from "../../reducer/AdminReducer";
import {
    clearFeatured,
    getFeatured,
    setFeaturedType,
    setFilter,
    setFirstAccess,
    setOrderInsightsId,
    setOrderInsightsItem,
    setRoute
} from "../../actions/ReviewsAction";
import useInitializeInfos from '../../hooks/useInitializeInfos';

const Dashboard = () => {
    const {state, dispatch} = useContext(ContextReducer);
    const [activeReviews, setActiveReviews] = useState(false);
    const [activeNps, setActiveNps] = useState(false);
    const [activeQuestions, setActiveQuestions] = useState(false);
    const [activeAnswers, setActiveAnswers] = useState(false);
    const [activeUsers, setActiveUsers] = useState(false);

    useInitializeInfos();

    const handleOrder = (odr, idex, type) => {
        const _filter = {...state.filters}
        if (state?.filters?.orderFeatured !== odr) {
            setOrderInsightsItem(dispatch, true);
            _filter.sortFeatured = 'desc';
        } else if (state?.filters?.orderFeatured === odr && state?.orderInsightsItem) {
            _filter.sortFeatured = 'asc';
            setOrderInsightsItem(dispatch, false);
        } else {
            _filter.sortFeatured = 'desc';
            setOrderInsightsItem(dispatch, true);
        }

        setOrderInsightsId(dispatch, idex);

        _filter.orderFeatured = odr;
        setFilter(dispatch, _filter);
    }

    const handleClickLink = (link) => {
        setRoute(dispatch, link.split('/')[1])
        setFilter(dispatch, {
            status: 'PENDING',
            order: 'newer',
            tags: null,
            orderUsers: 'newer',
            orderFeatured: 'reviews',
            sortFeatured: 'desc',
            orderNewsletter: 'transactionDate',
            sortNewsletter: 'desc'
        });
        setFirstAccess(dispatch, false);
    }

    const handleAbas = (e, name) => {
        e.preventDefault();
        setFeaturedType(dispatch, name)
        setFilter(dispatch, {
            status: 'PENDING',
            order: 'newer',
            tags: null,
            orderUsers: 'newer',
            orderFeatured: 'reviews',
            sortFeatured: 'desc',
            orderNewsletter: 'transactionDate',
            sortNewsletter: 'desc'
        });
        setOrderInsightsId(dispatch, 0)
        setOrderInsightsItem(dispatch, true)
        clearFeatured(dispatch)
        getFeatured(dispatch, state?.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, null, 0, name)
    }

    const renderTableBody = () => {
        if (state?.featured?.items?.length > 0) {
            if (state.featuredType === 'featured-products') {
                return state?.featured?.items?.map((val, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                {val.imageURL && <ImageProduct bg={val.imageURL}/>}
                                <span>{val.productId !== 'undefined' ? val.productId : state?.i18n?.featured?.store}</span>
                            </td>
                            <td>{val.reviewTotal}</td>
                            <td>{val.commentTotal}</td>
                            <td>{val.questionTotal}</td>
                            <td>{val.answerTotal}</td>
                            <td>{val.cxWs}</td>
                            <td>{val.cxHs}</td>
                            <td>{val.reviewAvg}</td>
                        </tr>
                    )
                })
            } else if (state.featuredType === 'featured-sellers') {
                return state?.featured?.items?.map((val, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <span>{val.seller !== 'undefined' ? val.seller : state?.i18n?.featured?.sellers}</span>
                            </td>
                            <td>{val.reviewTotal}</td>
                            <td>{val.totalSkus}</td>
                            <td>{val.commentTotal}</td>
                            <td>{val.questionTotal}</td>
                            <td>{val.answerTotal}</td>
                            <td>{val.cxWs}</td>
                            <td>{val.cxHs}</td>
                            <td>{val.reviewAvg}</td>
                            <td>{val.reviewRatingStd}</td>
                        </tr>
                    )
                })
            } else if (state.featuredType === 'featured-brands') {
                return state?.featured?.items?.map((val, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <span>{val.brand !== 'undefined' ? val.brand : state?.i18n?.featured?.brands}</span>
                            </td>
                            <td>{val.reviewTotal}</td>
                            <td>{val.totalSkus}</td>
                            <td>{val.commentTotal}</td>
                            <td>{val.questionTotal}</td>
                            <td>{val.answerTotal}</td>
                            <td>{val.cxWs}</td>
                            <td>{val.cxHs}</td>
                            <td>{val.reviewAvg}</td>
                            <td>{val.reviewRatingStd}</td>
                        </tr>
                    )
                })
            }
        } else {
            return <tr align="center">
                <td colSpan="10"><NoItems nomargin={true}/></td>
            </tr>
        }
    }

    const renderTitleTable = () => {
        if (state.featuredType === 'featured-products') {
            return 'Número do SKU'
        } else if (state.featuredType === 'featured-sellers') {
            return 'Seller'
        } else if (state.featuredType === 'featured-brands') {
            return 'Marca'
        }
    }

    const renderTableHead = () => {
        return (
            <thead>
            <tr>
                <Col act={state?.orderInsightsId === 7 && (state?.orderInsightsItem || !state?.orderInsightsItem)}
                     onClick={() => handleOrder('name', 7)}>{renderTitleTable()}<Arrow
                    flip={state?.orderInsightsId === 7 && state?.orderInsightsItem}>{renderArrow()}</Arrow></Col>
                <Col act={state?.orderInsightsId === 0 && (state?.orderInsightsItem || !state?.orderInsightsItem)}
                     onClick={() => handleOrder('reviews', 0)}>{state?.i18n?.exportTable?.featured?.featured1}<Arrow
                    flip={state?.orderInsightsId === 0 && state?.orderInsightsItem}>{renderArrow()}</Arrow></Col>
                {state.featuredType !== 'featured-products' &&
                    <Col act={state?.orderInsightsId === 8 && (state?.orderInsightsItem || !state?.orderInsightsItem)}
                         onClick={() => handleOrder('totalSkus', 8)}>{state?.i18n?.exportTable?.featured?.featured2}
                        <Arrow
                            flip={state?.orderInsightsId === 8 && state?.orderInsightsItem}>{renderArrow()}</Arrow></Col>}
                <Col act={state?.orderInsightsId === 1 && (state?.orderInsightsItem || !state?.orderInsightsItem)}
                     onClick={() => handleOrder('comments', 1)}>{state?.i18n?.exportTable?.featured?.featured3} <Arrow
                    flip={state?.orderInsightsId === 1 && state?.orderInsightsItem}>{renderArrow()}</Arrow></Col>
                <Col act={state?.orderInsightsId === 2 && (state?.orderInsightsItem || !state?.orderInsightsItem)}
                     onClick={() => handleOrder('questions', 2)}>{state?.i18n?.exportTable?.featured?.featured4} <Arrow
                    flip={state?.orderInsightsId === 2 && state?.orderInsightsItem}>{renderArrow()}</Arrow></Col>
                <Col act={state?.orderInsightsId === 3 && (state?.orderInsightsItem || !state?.orderInsightsItem)}
                     onClick={() => handleOrder('answers', 3)}>{state?.i18n?.exportTable?.featured?.featured5} <Arrow
                    flip={state?.orderInsightsId === 3 && state?.orderInsightsItem}>{renderArrow()}</Arrow></Col>
                <Col act={state?.orderInsightsId === 4 && (state?.orderInsightsItem || !state?.orderInsightsItem)}
                     onClick={() => handleOrder('cxWs', 4)}>{state?.i18n?.exportTable?.featured?.featured10} <Arrow
                    flip={state?.orderInsightsId === 4 && state?.orderInsightsItem}>{renderArrow()}</Arrow></Col>
                <Col act={state?.orderInsightsId === 5 && (state?.orderInsightsItem || !state?.orderInsightsItem)}
                     onClick={() => handleOrder('cxHs', 5)}>{state?.i18n?.exportTable?.featured?.featured11} <Arrow
                    flip={state?.orderInsightsId === 5 && state?.orderInsightsItem}>{renderArrow()}</Arrow></Col>
                <Col act={state?.orderInsightsId === 6 && (state?.orderInsightsItem || !state?.orderInsightsItem)}
                     onClick={() => handleOrder('reviewsAvg', 6)}>{state?.i18n?.exportTable?.featured?.featured8} <Arrow
                    flip={state?.orderInsightsId === 6 && state?.orderInsightsItem}>{renderArrow()}</Arrow></Col>
                {state.featuredType !== 'featured-products' &&
                    <Col act={state?.orderInsightsId === 9 && (state?.orderInsightsItem || !state?.orderInsightsItem)}
                         onClick={() => handleOrder('reviewRatingStd', 9)}>{state?.i18n?.exportTable?.featured?.featured9}
                        <Arrow
                            flip={state?.orderInsightsId === 9 && state?.orderInsightsItem}>{renderArrow()}</Arrow></Col>}
            </tr>
            </thead>
        )
    }

    const renderArrow = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="9.696" height="5.544" viewBox="0 0 9.696 5.544">
            <defs></defs>
            <path className="a" fill="#000000"
                  d="M11.037,15.119,14.7,11.45a.69.69,0,0,1,.979,0,.7.7,0,0,1,0,.981l-4.154,4.157a.692.692,0,0,1-.955.02L6.39,12.434a.693.693,0,0,1,.979-.981Z"
                  transform="translate(-6.188 -11.246)"/>
        </svg>
    }

    const renderAvgSource = (score) => {
        if (score <= 6) {
            return '#FC2A1C'
        } else if (score <= 8) {
            return '#FDA629'
        } else {
            return '#2BC549'
        }
    }

    const renderPorcentage = (total, valor) => {
        if (state.insights.data) {
            if ((total === 0 || total === 'undefined') && (valor === 0 || valor === 'undefined')) {
                return 0
            } else {
                return (valor * 100) / total
            }
        } else {
            return 0
        }
    }

    const renderPorcentageUser = (typ) => {
        if (typ === 'email') {
            return renderPorcentage(state?.insights?.data?.users?.total, state?.insights?.data?.users?.email)
        } else if (typ === 'facebook') {
            return renderPorcentage(state?.insights?.data?.users?.total, state?.insights?.data?.users?.facebook)
        } else if (typ === 'anonym') {
            return renderPorcentage(state?.insights?.data?.users?.total, state?.insights?.data?.users?.anonym)
        }
    }

    return useMemo(() => {
        return (
            <>
                <HeaderFilter/>
                <Container area='actions-insights'>
                    <ListFiltersSearch />
                </Container>
                <Container area='insights'>
                    <Box>
                        <Infos>
                            <SocialReviews/>
                            <Texts>
                                <Text>{state?.i18n?.insights?.reviews}</Text>
                                <Small>{state?.i18n?.insights?.subtitle?.reviews}</Small>
                            </Texts>
                        </Infos>
                        <Numbers>
                            <Num><CountUp start={0} duration={1} delay={0}
                                          end={state.insights.last === -1 ? state?.insights?.data?.total?.review : state?.insights?.data?.total?.review}/></Num>
                            <Link to={'/reviews'} key={'1'} onClick={() => handleClickLink('/reviews')}>
                                {state?.i18n?.insights?.all}
                            </Link>
                        </Numbers>
                        <Avg></Avg>
                        <Types>
                            <Item>
                                <ItemText>{state?.i18n?.insights?.pending} ({state.insights.last === -1 ? state?.insights?.data?.pending?.review : state?.insights?.data?.pending?.review})</ItemText>
                                <Bar border={'1px solid #000'} colors={'#ffffff'} textColor={'#000000'}
                                     porcentage={parseInt(renderPorcentage(state.insights.last === -1 ? state?.insights?.data?.total?.review : state?.insights?.data?.total?.review, state.insights.last === -1 ? state?.insights?.data?.pending?.review : state?.insights?.data?.pending?.review))}></Bar>
                            </Item>
                            <Item>
                                <ItemText>{state?.i18n?.insights?.approved} ({state.insights.last === -1 ? state?.insights?.data?.approved?.review : state?.insights?.data?.approved?.review})</ItemText>
                                <Bar colors={'#000000'} textColor={'#000000'}
                                     porcentage={parseInt(renderPorcentage(state.insights.last === -1 ? state?.insights?.data?.total?.review : state?.insights?.data?.total?.review, state.insights.last === -1 ? state?.insights?.data?.approved?.review : state?.insights?.data?.approved?.review))}></Bar>
                            </Item>
                            <Item>
                                <ItemText>{state?.i18n?.insights?.rejected} ({state.insights.last === -1 ? state?.insights?.data?.rejected?.review : state?.insights?.data?.rejected?.review})</ItemText>
                                <Bar colors={'#ff7200'} textColor={'#ff7200'}
                                     porcentage={parseInt(renderPorcentage(state.insights.last === -1 ? state?.insights?.data?.total?.review : state?.insights?.data?.total?.review, state.insights.last === -1 ? state?.insights?.data?.rejected?.review : state?.insights?.data?.rejected?.review))}></Bar>
                            </Item>
                            <Item>
                                <ItemText>{state?.i18n?.insights?.deleted} ({state.insights.last === -1 ? state?.insights?.data?.deleted?.review : state?.insights?.data?.deleted?.review})</ItemText>
                                <Bar colors={'#ED5D5D'} textColor={'#ED5D5D'}
                                     porcentage={parseInt(renderPorcentage(state.insights.last === -1 ? state?.insights?.data?.total?.review : state?.insights?.data?.total?.review, state.insights.last === -1 ? state?.insights?.data?.deleted?.review : state?.insights?.data?.deleted?.review))}></Bar>
                            </Item>
                        </Types>
                    </Box>
                    <Box>
                        <Infos>
                            <SocialNps/>
                            <Texts>
                                <Text>{state?.i18n?.insights?.nps}</Text>
                                <Small>{state?.i18n?.insights?.subtitle?.nps}</Small>
                            </Texts>
                        </Infos>
                        <Numbers>
                            <Num><CountUp start={0} duration={1} delay={0}
                                          end={state.insights.last === -1 ? state?.insights?.data?.total?.nps : state?.insights?.data?.total?.nps}/></Num>
                            <Link to={'/nps'} key={'5'} onClick={() => handleClickLink('/nps')}>
                                {state?.i18n?.insights?.all}
                            </Link>
                        </Numbers>
                        <Avg>
                            <AvgInfos>
                                <AvgText>{state?.i18n?.insights?.subtitle?.npsAvg}</AvgText>
                                <AvgScore
                                    color={renderAvgSource(state.insights.last === -1 ? state?.insights?.data?.total?.npsAvg : state?.insights?.data?.total?.npsAvg)}>{state.insights.last === -1 ? state?.insights?.data?.total?.npsAvg : state?.insights?.data?.total?.npsAvg}</AvgScore>
                            </AvgInfos>
                            <AvgGradient/>
                        </Avg>
                        <Types>
                            <Item>
                                <ItemText>{state?.i18n?.insights?.pending} ({state.insights.last === -1 ? state?.insights?.data?.pending?.nps : state?.insights?.data?.pending?.nps})</ItemText>
                                <Bar border={'1px solid #000'} colors={'#ffffff'} textColor={'#000000'}
                                     porcentage={parseInt(renderPorcentage(state.insights.last === -1 ? state?.insights?.data?.total?.nps : state?.insights?.data?.total?.nps, state.insights.last === -1 ? state?.insights?.data?.pending?.nps : state?.insights?.data?.pending?.nps))}></Bar>
                            </Item>
                            <Item>
                                <ItemText>{state?.i18n?.insights?.approved} ({state.insights.last === -1 ? state?.insights?.data?.approved?.nps : state?.insights?.data?.approved?.nps})</ItemText>
                                <Bar colors={'#000000'} textColor={'#000000'}
                                     porcentage={parseInt(renderPorcentage(state.insights.last === -1 ? state?.insights?.data?.total?.nps : state?.insights?.data?.total?.nps, state.insights.last === -1 ? state?.insights?.data?.approved?.nps : state?.insights?.data?.approved?.nps))}></Bar>
                            </Item>
                            <Item>
                                <ItemText>{state?.i18n?.insights?.rejected} ({state.insights.last === -1 ? state?.insights?.data?.rejected?.nps : state?.insights?.data?.rejected?.nps})</ItemText>
                                <Bar colors={'#ff7200'} textColor={'#ff7200'}
                                     porcentage={parseInt(renderPorcentage(state.insights.last === -1 ? state?.insights?.data?.total?.nps : state?.insights?.data?.total?.nps, state.insights.last === -1 ? state?.insights?.data?.rejected?.nps : state?.insights?.data?.rejected?.nps))}></Bar>
                            </Item>
                            <Item>
                                <ItemText>{state?.i18n?.insights?.deleted} ({state.insights.last === -1 ? state?.insights?.data?.deleted?.nps : state?.insights?.data?.deleted?.nps})</ItemText>
                                <Bar colors={'#ED5D5D'} textColor={'#ED5D5D'}
                                     porcentage={parseInt(renderPorcentage(state.insights.last === -1 ? state?.insights?.data?.total?.nps : state?.insights?.data?.total?.nps, state.insights.last === -1 ? state?.insights?.data?.deleted?.nps : state?.insights?.data?.deleted?.nps))}></Bar>
                            </Item>
                        </Types>
                    </Box>
                    <Box>
                        <Infos>
                            <SocialQuestions/>
                            <Texts>
                                <Text>{state?.i18n?.insights?.questions}</Text>
                                <Small>{state?.i18n?.insights?.subtitle?.questions}</Small>
                            </Texts>
                        </Infos>
                        <Numbers>
                            <Num><CountUp start={0} duration={1} delay={0}
                                          end={state.insights.last === -1 ? state?.insights?.data?.total?.question : state?.insights?.data?.total?.question}/></Num>
                            <Link to={'/questions'} key={'3'} onClick={() => handleClickLink('/questions')}>
                                {state?.i18n?.insights?.all}
                            </Link>
                        </Numbers>
                        <Avg/>
                        <Types>
                            <Item>
                                <ItemText>{state?.i18n?.insights?.pending} ({state.insights.last === -1 ? state?.insights?.data?.pending?.question : state?.insights?.data?.pending?.question})</ItemText>
                                <Bar border={'1px solid #000'} colors={'#ffffff'} textColor={'#000000'}
                                     porcentage={parseInt(renderPorcentage(state.insights.last === -1 ? state?.insights?.data?.total?.question : state?.insights?.data?.total?.question, state.insights.last === -1 ? state?.insights?.data?.pending?.question : state?.insights?.data?.pending?.question))}></Bar>
                            </Item>
                            <Item>
                                <ItemText>{state?.i18n?.insights?.approved} ({state.insights.last === -1 ? state?.insights?.data?.approved?.question : state?.insights?.data?.approved?.question})</ItemText>
                                <Bar colors={'#000000'} textColor={'#000000'}
                                     porcentage={parseInt(renderPorcentage(state.insights.last === -1 ? state?.insights?.data?.total?.question : state?.insights?.data?.total?.question, state.insights.last === -1 ? state?.insights?.data?.approved?.question : state?.insights?.data?.approved?.question))}></Bar>
                            </Item>
                            <Item>
                                <ItemText>{state?.i18n?.insights?.rejected} ({state.insights.last === -1 ? state?.insights?.data?.rejected?.question : state?.insights?.data?.rejected?.question})</ItemText>
                                <Bar colors={'#ff7200'} textColor={'#ff7200'}
                                     porcentage={parseInt(renderPorcentage(state.insights.last === -1 ? state?.insights?.data?.total?.question : state?.insights?.data?.total?.question, state.insights.last === -1 ? state?.insights?.data?.rejected?.question : state?.insights?.data?.rejected?.question))}></Bar>
                            </Item>
                            <Item>
                                <ItemText>{state?.i18n?.insights?.deleted} ({state.insights.last === -1 ? state?.insights?.data?.deleted?.question : state?.insights?.data?.deleted?.question})</ItemText>
                                <Bar colors={'#ED5D5D'} textColor={'#ED5D5D'}
                                     porcentage={parseInt(renderPorcentage(state.insights.last === -1 ? state?.insights?.data?.total?.question : state?.insights?.data?.total?.question, state.insights.last === -1 ? state?.insights?.data?.deleted?.question : state?.insights?.data?.deleted?.question))}></Bar>
                            </Item>
                        </Types>
                    </Box>
                    <Box>
                        <Infos>
                            <SocialAnswers/>
                            <Texts>
                                <Text>{state?.i18n?.insights?.answers}</Text>
                                <Small>{state?.i18n?.insights?.subtitle?.answers}</Small>
                            </Texts>
                        </Infos>
                        <Numbers>
                            <Num><CountUp start={0} duration={1} delay={0}
                                          end={state.insights.last === -1 ? state?.insights?.data?.total?.answer : state?.insights?.data?.total?.answer}/></Num>
                            <Link to={'/answers'} key={'4'} onClick={() => handleClickLink('/answers')}>
                                {state?.i18n?.insights?.all}
                            </Link>
                        </Numbers>
                        <Avg/>
                        <Types>
                            <Item>
                                <ItemText>{state?.i18n?.insights?.pending} ({state.insights.last === -1 ? state?.insights?.data?.pending?.answer : state?.insights?.data?.pending?.answer})</ItemText>
                                <Bar border={'1px solid #000'} colors={'#ffffff'} textColor={'#000000'}
                                     porcentage={parseInt(renderPorcentage(state.insights.last === -1 ? state?.insights?.data?.total?.answer : state?.insights?.data?.total?.answer, state.insights.last === -1 ? state?.insights?.data?.pending?.answer : state?.insights?.data?.pending?.answer))}></Bar>
                            </Item>
                            <Item>
                                <ItemText>{state?.i18n?.insights?.approved} ({state.insights.last === -1 ? state?.insights?.data?.approved?.answer : state?.insights?.data?.approved?.answer})</ItemText>
                                <Bar colors={'#000000'} textColor={'#000000'}
                                     porcentage={parseInt(renderPorcentage(state.insights.last === -1 ? state?.insights?.data?.total?.answer : state?.insights?.data?.total?.answer, state.insights.last === -1 ? state?.insights?.data?.approved?.answer : state?.insights?.data?.approved?.answer))}></Bar>
                            </Item>
                            <Item>
                                <ItemText>{state?.i18n?.insights?.rejected} ({state.insights.last === -1 ? state?.insights?.data?.rejected?.answer : state?.insights?.data?.rejected?.answer})</ItemText>
                                <Bar colors={'#ff7200'} textColor={'#ff7200'}
                                     porcentage={parseInt(renderPorcentage(state.insights.last === -1 ? state?.insights?.data?.total?.answer : state?.insights?.data?.total?.answer, state.insights.last === -1 ? state?.insights?.data?.rejected?.answer : state?.insights?.data?.rejected?.answer))}></Bar>
                            </Item>
                            <Item>
                                <ItemText>{state?.i18n?.insights?.deleted} ({state.insights.last === -1 ? state?.insights?.data?.deleted?.answer : state?.insights?.data?.deleted?.answer})</ItemText>
                                <Bar colors={'#ED5D5D'} textColor={'#ED5D5D'}
                                     porcentage={parseInt(renderPorcentage(state.insights.last === -1 ? state?.insights?.data?.total?.answer : state?.insights?.data?.total?.answer, state.insights.last === -1 ? state?.insights?.data?.deleted?.answer : state?.insights?.data?.deleted?.answer))}></Bar>
                            </Item>
                        </Types>
                    </Box>
                    <Box>
                        <Infos>
                            <SocialUsers/>
                            <Texts>
                                <Text>{state?.i18n?.insights?.users}</Text>
                                <Small>{state?.i18n?.insights?.subtitle?.users}</Small>
                            </Texts>
                        </Infos>
                        <Numbers>
                            <Num><CountUp start={0} duration={1} delay={0}
                                          end={state.insights.last === -1 ? state?.insights?.data?.users?.total : state?.insights?.data?.users?.total}/></Num>
                            <Link to={'/users'} key={'10'} onClick={() => handleClickLink('/users')}>
                                {state?.i18n?.insights?.all}
                            </Link>
                        </Numbers>
                        <Avg/>
                        <Types>
                            <Item>
                                <ItemText>{state?.i18n?.usersType?.email} ({state?.insights?.data?.users?.email})</ItemText>
                                <Bar colors={'#000000'}
                                     textColor={'#000000'}
                                     porcentage={parseInt(renderPorcentageUser('email'))}
                                ></Bar>
                            </Item>
                            <Item>
                                <ItemText>{state?.i18n?.usersType?.facebook} ({state?.insights?.data?.users?.facebook})</ItemText>
                                <Bar colors={'#000000'} textColor={'#000000'}
                                     porcentage={parseInt(renderPorcentageUser('facebook'))}
                                ></Bar>
                            </Item>
                            <Item>
                                <ItemText>{state?.i18n?.usersType?.anonym} ({state?.insights?.data?.users?.anonym})</ItemText>
                                <Bar colors={'#000000'}
                                     textColor={'#000000'}
                                     porcentage={parseInt(renderPorcentageUser('anonym'))}
                                ></Bar>
                            </Item>
                        </Types>
                    </Box>
                </Container>
                <Container area='actions'>
                    <Title secondTitle/>
                    <ExportTable dash={true} itemDownDash={true} />
                </Container>
                <Container area='pagination-dash'>
                    <Abas>
                        <Aba actived={state.featuredType === 'featured-products'}
                             onClick={(e) => handleAbas(e, 'featured-products')}>Produtos</Aba>
                        <Aba actived={state.featuredType === 'featured-sellers'}
                             onClick={(e) => handleAbas(e, 'featured-sellers')}>Sellers</Aba>
                        <Aba actived={state.featuredType === 'featured-brands'}
                             onClick={(e) => handleAbas(e, 'featured-brands')}>Marcas</Aba>
                    </Abas>
                    <Pagination/>
                </Container>
                <Container area="table-dash">
                    <Table cellSpacing="0" cellPadding="0">
                        {renderTableHead()}
                        {!state.isLoad ?
                            <tbody>
                            <tr align="center">
                                <td colSpan="10" align='center'><Loader/></td>
                            </tr>
                            </tbody>
                            :
                            <tbody>
                            {renderTableBody()}
                            </tbody>
                        }
                    </Table>
                </Container>
                <Container area='pagination'>
                    <Pagination/>
                </Container>
            </>
        )
    }, [activeReviews, activeNps, activeQuestions, state?.config, activeAnswers, activeUsers, state.insights, state.insights.all, state.featured, state.filters, state.filters.sortFeatured, state.filters.orderFeatured, state.orderInsightsItem, state.orderInsightsId, state.graphic, state.actualRoute, state?.userInfo?.ecommerce, state.featuredType])
}

export default Dashboard;
