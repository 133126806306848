import React from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";


import Auth from "../Containers/Auth"
import Login from "../Containers/Login"
import Reviews from "../Containers/Reviews"
import Nps from "../Containers/Nps";
import Questions from "../Containers/Questions";
import Answers from "../Containers/Answers";
import Users from "../Containers/Users"
import Error from "../Containers/Error";
import CommingSoon from "../components/CommingSoon";
import Comments from '../Containers/Comments';
import Dashboard from "../Containers/Dashboard";
import Newsletter from "../Containers/Newsletter";
import Stores from "../Containers/Stores";
import Configuration from "../Containers/Configuration";
import InitialScreen from "../Containers/InitialScreen";

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Auth/>}>
                    <Route element={<InitialScreen/>} exact path="/store"/>
                    <Route element={<InitialScreen/>} exact path="/marketplaces"/>
                    <Route element={<InitialScreen/>} exact path="/mentions"/>
                    <Route element={<Dashboard/>} exact path="/insights"/>
                    <Route element={<Reviews/>} exact path="/reviews"/>
                    <Route element={<Nps/>} exact path="/nps" />
                    <Route element={<Questions/>} exact path="/questions"/>
                    <Route element={<Answers/>} exact path="/answers"/>
                    <Route element={<Users/>} exact path="/users"/>
                    <Route element={<Comments/>} exact path={"/comments"}/>
                    <Route element={<Newsletter/>} exact path="/newsletter"/>
                    <Route element={<CommingSoon/>} exact path="/collections"/>
                    <Route element={<CommingSoon/>} exact path="/gamification"/>
                    <Route element={<Configuration/>} exact path={"/configuration"} />
                    <Route element={<CommingSoon/>} exact path="/monetisation"/>
                    <Route element={<Stores/>} exact path="/stores"/>
                    <Route element={<Navigate to="insights" replace={true}/>} index forceRefresh={true}/>
                    <Route element={<Navigate to="/insights" replace={true}/>} path="*"/>
                    <Route element={<Error/>} exact path={"/error"}/>
                </Route>
                <Route element={<Navigate to="/login" replace={true}/>} path="*"/>
                <Route element={<Login/>} path={"/login"}/>
            </Routes>
        </BrowserRouter>
    )
}

export default Router;
