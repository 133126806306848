import styled, {css} from 'styled-components';

export const Content = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 30px;
    position: relative;
    margin: 30px auto 30px auto;
    align-items: center;

    @media (max-width: 768px) {
        flex-flow: column;
        margin: 30px auto 30px auto;
    }
`

export const ContentUsers = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 30px;
    position: relative;
    margin: 0 auto;
    align-items: center;

`

export const Title = styled.h2`
    color: #000000;
    font-size: 20px;
    font-weight: bold;
    margin: 20px 0;
`

export const Items = styled.div`
    width: 100%;
    padding: 0 30px;
    position: relative;
    margin: 0 auto 10px auto;
    background-color: #fff;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;

    @media (max-width: 768px) {
        flex-flow: column;
    }
`
export const Name = styled.p`
    font-size:14px;
    font-weight: bold;
    color: #000000;
    margin: 0 15px;
    cursor: pointer;
`


export const Picture = styled.div`
    width: 30px;
    height: 30px;
    border-radius:100%;
    overflow: hidden;
    position: relative;
    cursor: pointer;
`

export const Email = styled.div`
    cursor: pointer;
    position: relative;

    p {
        font-size:14px;
        color: #000000;
    }

    ${props => props.porcentage && css`
        width: ${props => props.porcentage}%;

        @media (max-width: 768px) {
            width: 100%;
            margin-top: 10px;
        }
    `}
`

export const Data = styled.p`
    font-size:14px;
    color: #000000;

    ${props => props.porcentage && css`
        width: ${props => props.porcentage}%;

        @media (max-width: 768px) {
            width: 100%;
        }
    `}
`
export const Id = styled.p`
    font-size:14px;
    color: #000000;
    display: block;
    padding: 0 5px;

    ${props => props.porcentage && css`
        width: ${props => props.porcentage}%;

        @media (max-width: 768px) {
            width: 100%;
            margin: 10px 0;
        }
    `}
`

export const Flex = styled.div`
    display: flex;
    align-items: center;

    ${props => props.porcentage && css`
        width: ${props => props.porcentage}%;

        @media (max-width: 768px) {
            width: 100%;
        }
    `}
`

export const Tooltip = styled.div`
    background-color: #d4edda;
    color: #155724;
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: 12px;
    border-radius: 5px;
    position: absolute;
    top: -40px;
    left: 0;

    &:before {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #d4edda;
    }
`

export const Input = styled.input`
    border:none;
    background: #fff !important;
    border-radius: 5px;
    margin-right: 15px;
    padding: 0 15px;
    height: 55px;
    line-height: 55px;
    width: 210px;
    box-sizing: border-box;
    font-size: 12px;
`
