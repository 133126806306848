import styled, { css } from 'styled-components';

export const AttachmentProduct = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
export const ImageAttachment = styled.div`
    width: 70px;
    height: 70px;
    position: relative;
    cursor: pointer;

    ${props => props.active && css`
        opacity: 0.3;
    `}

    img {
        width: 100%;
        height: auto;
    }
`
export const AttachmenteProduct = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export const WrapperLookAttachment = styled.div`
    width: auto;
    width: auto;
    margin-top: 11px;
`
