import React, { useContext, useState } from 'react';
import { sendEmailTest } from '../../../../../actions/ReviewsAction';
import { Button } from '../../../styles';
import { ContextReducer } from '../../../../../reducer/AdminReducer';

const EmailTest = () => {
    const { state, dispatch} = useContext(ContextReducer);
    const [ status, setStatus ] = useState({ sending: false, done: false, error: false})

    const handleDone = (status) => {
        setStatus({ sending: false, done: status, error: false});
    }

    const handleSending = (status) => {
        setStatus({ sending: status, done: false, error: false});
    }

    const handleError = (status) => {
        setStatus({ sending: false, done: false, error: status});
    }

    const handleSendEmailTest = (e) => {
        e.preventDefault();
        sendEmailTest(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, handleDone, handleSending, handleError);
    }


    const renderTextEmailTest = () => {
        if(status.sendingsending) {
            return state?.i18n?.buttonSendEmailTest.loading;
        } else if(status.done) {
            return state?.i18n?.buttonSendEmailTest.done;
        } else if(status.error){
            return state?.i18n?.buttonSendEmailTest.error;
        } else {
            return state?.i18n?.buttonSendEmailTest.receiveEmailTest;
        }
    }

    return (
        <Button
            onClick={(e) => handleSendEmailTest(e)}
            emailTest={true}
            disabled={status.sending || status.done || status.error}
            loading={status.sending || status.done || status.error}
        >
            {renderTextEmailTest()}
        </Button>
    );
};

export default EmailTest;
