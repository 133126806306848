import React, { useContext } from 'react';
import { ContextReducer } from '../../../reducer/AdminReducer';
import { Container, Table } from '../styles';

const TableUsers = ({ handleExportData }) => {
    const { state } = useContext(ContextReducer);

    const placeholderI18nUser = state?.i18n?.exportTable?.user;
    const placeholderToEmptyValues = state?.i18n?.exportTable?.placeholderToEmptyValues;

    const renderBody = () => {
        return state?.users?.items.map((obj, idx) => {
            return (
                <tr
                    key={idx}
                    style={{border: '1px solid #000'}}
                >
                    <td>{!obj?.profile.name ? placeholderToEmptyValues?.noName : obj?.profile.name}</td>
                    <td>{!obj?.loginType ? placeholderToEmptyValues?.noTypeLogin : obj?.loginType}</td>
                    <td>{!obj?.profile.userId ? placeholderToEmptyValues?.noUserId : obj?.profile.userId}</td>
                    <td>{!obj?.created ? placeholderToEmptyValues?.noCreationDate : obj?.created}</td>
                </tr>
            )
        })
    }

    if (state?.exportData?.loading) {
        return (
            <Container id="tableID">
                <meta charSet="utf-8"/>
                <Table border={'1'}>
                    <thead>
                        <tr style={{border: '1px solid #000'}}>
                            <th>{placeholderI18nUser?.user1}</th>
                            <th>{placeholderI18nUser?.user3}</th>
                            <th>{placeholderI18nUser?.user4}</th>
                            <th>{placeholderI18nUser?.user5}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderBody()}
                    </tbody>
                </Table>
            </Container>
        )
    }

    handleExportData();

    return <></>;
};

export default TableUsers;
