import React, {useContext} from 'react';
import { ContextReducer } from '../../../../../reducer/AdminReducer';
import { Container } from './styles';

const Checkbox = ({from, type, domain, domainChecked, personalChecked, personalEmail, handleCheckboxValue}) => {
    const {state} = useContext(ContextReducer);

    const handleCheckedCheckbox = (from, type) => {
        const isFromDomain = from === 'domain';
        const isFromPersonal = from === 'personal';
        const domainSideNothingActivated = !domain && !domainChecked;
        const personalSideNothingActivated = !personalEmail && !personalChecked;
        const stateValue = isFromDomain ? domainChecked : personalChecked;
        const isReaderActive = type === 'reader' && ((isFromDomain && personalSideNothingActivated) || (isFromPersonal && domainSideNothingActivated));

        const isActiveBasedOnType = (type) => {
            switch (type) {
                case 'writer':
                    return stateValue === 'writer' || stateValue === 'admin';
                case 'reader':
                    return stateValue === 'writer' || stateValue === 'reader' || stateValue === 'admin';
                case 'admin':
                    return stateValue === 'admin' ;
                default:
                    return false;
            }
        };

        return isActiveBasedOnType(type) || isReaderActive;
    }

    const handleDisabled = (type) => {
        if(type === 'reader') return true;

        switch(type) {
            case 'writer':
                return personalChecked === 'admin' || domainChecked === 'admin';
            default:
                return false
        }
    }


    return (
        <Container>
            <input type="checkbox"
                value={type}
                disabled={handleDisabled(type)}
                checked={handleCheckedCheckbox(from, type)}
                onChange={(e) => handleCheckboxValue(e, from)}
            />
            <label>{state?.i18n?.peopleAccess?.checkboxType[type]}</label>
        </Container>
    );
};

export default Checkbox;
