import styled from 'styled-components';

export const Container = styled.div`
    label {
        margin-right: 10px;
        margin-left: 5px;
        font-size: 13px;
        color: #000;
        opacity: 0.8;
    }

    input {
        width: 15px;
        height: 15px;
        border: 1px solid #DDD;
        border-radius: 5px;
    }
`
