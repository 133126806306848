import styled from 'styled-components';

export const Subtitle = styled.span`
    font-size: 14px;
    display: block;
    margin-bottom: 20px;
`

export const Wrapper = styled.div`
    display: flex;
    flex-flow: column;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 20px 0;
`

export const ContentFilter = styled.div`
    display: flex;
    width: 100%;
    margin-top: 20px;
`

export const Filter = styled.button`
    border: 1px solid #000000;
    min-width: 30px;
    width: auto;
    min-height: 30px;
    box-sizing: border-box;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    border-radius: 5px;
    position:relative;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 25px 5px 25px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    transition: all ease .25s;
    cursor: pointer;

    &:hover {
        background-color: #000;
        color: #fff;
    }
`
