import styled, {css} from 'styled-components';

export const Content = styled.div`
    position: relative;

    .rdrInputRanges {
        display: none;
    }
`

export const Button = styled.button`
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    width: 100px;
    border: none;
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;
    cursor: pointer;

    ${props => props.isNewsletter && css`
        margin-left: 0;
    `}

    ${props => props.visible && css`
        width: inherit;
        margin-left: 15px;
    `}

    &:focus-visible {
        outline: none;
    }
`

export const BoxCalendar = styled.div`
    border-radius: 5px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 10px;
    position: absolute;
    z-index: 99;
    top: 60px;
    right: 0;
    border: 1px solid #eff2f7;
`

export const ContentButtons = styled.div`
    width: 100%;
    border-top: 1px solid #EDEEF0;
    padding: 15px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: end;
`

export const Buttons = styled.div`
    font-size: 12px;
    height: 30px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;

    ${props => props.type === 'cancel' && css`
        background-color: #F8F8F8;
        color: #0F1C3B;
    `}

    ${props => props.type === 'select' && css`
        background-color: #000;
        color: #fff;
        margin-left: 20px;
    `}
`
