import styled, {css} from "styled-components";
import LogoVurdere from '../../assets/logoVurdere.svg';

export const Content = styled.div`
    width: 100%;
    padding: 0 30px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: relative;

    @media(max-width: 768px) {
        justify-content: space-between;
    }
`

export const Header = styled.div`
    background-color: #000000;
    width: 100%;
    box-sizing: border-box;
    min-height: 60px;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;

    @media (max-width: 768px) {
        justify-content: space-between;
    }
`

export const Logo = styled.div`
    width: 190px;
    height: 25px;
    background: url(${LogoVurdere});
    background-size: 190px 25px;
`


export const Menu = styled.div`
    width: 100%;
    max-width: 300px;
    height: 100vh;
    position: fixed;
    z-index: 9;
    left: -301px;
    top: 60px;
    background-color: #fff;
    transition: all .25s ease;
    box-sizing: border-box;

    ${props => props.active && css`
        left: 0;
    `}
`

export const List = styled.ul`
    position: relative;
    margin-bottom: 100px;
    max-height: 70%;
    overflow-y: auto;
    overflow-x: hidden;
`

export const Items = styled.li`
    a {
        padding: 15px;
        color: #000000;
        border-bottom: 1px solid #dfdfdf;
        cursor: pointer;
        transition: all ease .25s;
        display: flex;
        align-items: center;
        font-size: 16px;

        ${props => !props.comming && css`
            color: rgba(0, 0, 0, .3);
        `}
    }

    a > svg {
        font-size: 16px;
        display: block;
        margin-right: 10px;

        ${props => !props.comming && css`
            opacity: .3;
        `}
    }

    ${props => props.comming && css`
        &:hover {
            a {
                color: #fff;
                background-color: #000000;
            }

            a > svg {
                path{
                    fill: #fff;
                }
            }
        }
    `}

    ${props => !props.active && css`
        a > svg {
            path{
                fill: #000;
            }
        }
    `}

    ${props => props.active && css`
        a  {
            color: #fff;
            background-color: #000000;
            border-bottom: 1px solid #000000;
            border-top: 1px solid #dfdfdf;
        }

        a > svg {
            path{
                fill: #fff;
            }
        }
    `}

    a > strong {
        text-transform: uppercase;
        margin-left: 5px;
        font-size: 16px;
    }
`
export const User = styled.div`
    position: absolute;
    right: 30px;
    top: 0;
    display: flex;
    align-items: center;
    height: 30px;

    @media (max-width: 768px) {
        position: relative;
        right: 0;
        top: 0;
    }
`
export const ContentDeskUser = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 30px;
    cursor: pointer;
    height: 60px;
    padding: 15px;

    &:hover {
        background-color: #fff;

        p {
            color: #000;
        }
    }


    @media(max-width: 768px) {
        display: none;
    }
`

export const Logout = styled.div`
    background-color: #fff;
    padding: 5px;
    width: 100%;
    position: absolute;
    top: 30px;
    left: 0;
    display: flex;
    align-items: center;
    border-radius: 3px;

    @media(max-width: 768px) {
        width: 55px;
    }

    ${ContentDeskUser}:hover & {
        display: flex;
    }

    p {
        font-size: 14px;
        color: #000000;
    }

    svg {
        margin-right: 5px;
    }
`
export const UserName = styled.p`
    color: #fff;
    font-size: 14px;
`
export const Mask = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8;
`
export const Help = styled.button`
    border: none;
    background-color: #FFA100;
    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 30px;
    bottom: 70px;
    font-size: 22px;
    cursor: pointer;
    z-index: 7;
    transition: all ease .25s;
    display: none;

    &:hover {
        transform: scale(1.2);
    }

    svg {
        stroke: #fff !important;

        path {
            stroke: #fff !important;
            stroke-width: 3px !important;
        }
    }

    &:focus {
        outline: none;
    }
`

export const IconMenu = styled.div`
    width: 30px;
    height: 30px;
    margin-right: 20px;
    cursor: pointer;

    @media(max-width: 768px) {
        margin-right: 0;
    }
`

export const ContentMobile = styled.div`
    display: none;

    @media(max-width: 768px) {
        display: flex;
        font-size: 30px;
        color: #fff;
    }
`

export const ContentDesk = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    height: 60px;
    padding: 15px;
    cursor: pointer;
    white-space: nowrap;
    min-width: 180px;
    width: 100%;

    &:hover {
        background-color: #fff;

        span {
            color: #000;
        }
    }


    @media(max-width: 768px) {
        display: none;
    }

    span {
        color: #fff;
        font-size: 14px;
        cursor: pointer;
    }

    select {
        cursor: pointer;
    }
`


export const ListSeller = styled.div`
    display: flex;
    margin-bottom: 30px;
    padding: 20px;
    background-color: #000;
    justify-content: space-between;
`

export const ItemSeller = styled.div`
    a {
        display: flex;
        align-items: center;
        flex-flow: column;
        cursor: pointer;

        ${props => props.active && css`
            svg path {
                fill: #FFA100 !important;
            }
        `}

        &:hover {
            svg path {
                fill: #FFA100 !important;
            }

            strong {
                color: #FFA100;
            }
        }

        strong {
            color: #fff;
            display: block;
            margin-top: 5px;
            font-size: 10px;

            ${props => props.active && css`
                color: #FFA100;
            `}
        }
    }
`
export const ContentAreaTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const TitleArea = styled.span`
    color: #FFA100;
    font-size: 14px;
    text-transform: capitalize;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid #FFA100;
    min-height: 30px;
    line-height: 30px;
`

export const CircleUser2 = styled.div`
    background-color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
`

export const CircleUser = styled.div`
    background-color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: relative;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    line-height: 35px;
    text-align: center;
`

export const CircleBG = styled.div`
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
`

export const ContentCircles = styled.div`
    width: 30px;
    height: 30px;
    margin-right: 10px;
    position: relative;
    border: 0.1px solid #4d4d4d;
    border-radius: 50%;
`

export const ContentOver = styled.div`
    position: absolute;
    z-index: 1;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #fff;
    min-width: 120px;
    cursor: pointer;
    display: none;

    ${ContentDesk}:hover & {
        cursor: pointer;
        display: block;
    }

    ${ContentDeskUser}:hover & {
        cursor: pointer;
        display: block;
    }

    a {
        cursor: pointer;
        display: block;
        font-size: 16px;
        text-transform: uppercase;
        text-align: center;
        padding: 20px 15px;
        border-top: 1px solid #E2E2E2;
        color: #000;
        width: 100%;
        height: 100%;

        span {
            display: block;
            color: #FF4421 !important;
            font-size: 10px;
        }
    }
`
export const Error = styled.div`
    position: absolute;
    top: -10px;
    right: -5px;
    z-index: 5;
`
