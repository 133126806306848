import styled, { css } from "styled-components";

export const Container = styled.div`
    position: relative;
    width: 100%;
    margin-top: 5px;

    svg {
        position: absolute;
        top: 5px;
        right: 5px;
    }
`

export const Comment = styled.p`
    font-size: 14px;
    color: #000000;
    line-height: 19px;
    position: relative;
    padding: 5px 30px 5px 0;
    transition: all ease .25s;

    &:focus-visible {
        outline: none;
        border-radius: 5px;
        background-color: rgba(15,28,59,.1);
        padding-left: 5px;
    }

    ${props => props.published && css`
        background-color: #d4edda;
        border-radius: 5px;
        padding-left: 5px;
    `}
`
export const ButtonSaveComment = styled.button`
    position: absolute;
    right: 0;
    width: auto;
    height: auto;
    background-color: #000000;
    border: none;
    border-radius: 5px;
    padding: 3px 10px;
    margin-top: 5px;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    z-index: 99;

    &:hover {
        background-color: #fff;
        border: 1px solid #000000;
        color: #000000;
    }

`
