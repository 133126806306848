import React from 'react';
import {PillItem, CircleScoreOrCase} from './styles';
import { ImCheckmark } from "react-icons/im";

const Pills = props => {

    const renderUseCasesPills = () => {
        if(!props.use_cases) return <></>;

        return props.use_cases.map((item, index) => {
            return (
                <PillItem key={index}>
                    <ImCheckmark style={{ marginRight: '7.5px', width: '11px', height: '9px' }}/>
                    {item}
                </PillItem>
            )
        })
    }

    const renderNormalPills = () => {
        if (!props.pills) return <></>;

        return props.pills?.map((value, index) => {
            return (
                <PillItem key={index}>
                    <CircleScoreOrCase recommend={props.recommend}/>
                    {value}
                </PillItem>
            )
        })
    }

    const renderCategories = () => {
        if(!props?.categories) return <></>;

        return props?.categories.map((value, index) => {
            return (
                <PillItem key={index}>
                    {value}
                </PillItem>
            )
        })
    }

    return (
        <>
            {renderNormalPills()}
            {renderUseCasesPills()}
            {renderCategories()}
        </>
    )
}

export default Pills;
