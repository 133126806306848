import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Container, Item} from './styles';


import {ContextReducer} from '../../reducer/AdminReducer';
import {setFilter} from '../../actions/ReviewsAction';

import {IoIosArrowDown, IoIosArrowUp} from 'react-icons/io';
import {ImTruck} from 'react-icons/im';
import {BiLaptop} from 'react-icons/bi';
import {FiEye} from 'react-icons/fi';

const SelectOrderNps = () => {
    const {state, dispatch} = useContext(ContextReducer);
    const [active, setActive] = useState(false);
    const [options, setOptions] = useState([]);
    const [selectedItemLength, setSelectedItemLength] = useState(9);

    const filters = state?.config?.ecommerce?.availableFilters[state.actualRoute];
    const filtersObjectItem = filters?.filter(item => typeof item === 'object')[0]?.values;

    const componentSelectRef = useRef();

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (componentSelectRef.current && !componentSelectRef.current.contains(e.target)) {
                setActive(false);
            }
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };

    }, []);

    useEffect(() => {
        if (filtersObjectItem) {
            const updatedOptions = ['showAll', ...filtersObjectItem];
            setOptions(updatedOptions);
        }
    }, [dispatch, filtersObjectItem])


    const handleSetFirstOption = useCallback((index, item) => {
        if (!active) {
            setActive(prevState => !prevState);
        } else {
            setActive(prevState => !prevState);
        }

        setSelectedItemLength(state?.i18n?.tagsAbas?.nps?.headerFilterExperience[item].length);

        if (index !== 0) {
            options.splice(index, 1);
            options.unshift(item);

            if (item === 'showAll') {
                const _filter = {orderSelectExperienceNps: ''}
                setFilter(dispatch, _filter);

                return
            }

            const _filter = {orderSelectExperienceNps: item}
            setFilter(dispatch, _filter);
        }
    }, [options, active, dispatch, state?.i18n?.tagsAbas?.nps?.headerFilterExperience])

    const renderArrowDownOrUp = useCallback(() => {
        return active ? <IoIosArrowUp style={{width: '14px', height: '14px', marginRight: '1px'}}/> :
            <IoIosArrowDown style={{width: '14px', height: '14px', marginRight: '1px'}}/>

    }, [active])


    const renderIconsSelect = (type) => {
        if (type === 'showAll') {
            return <FiEye style={{width: '30px', height: '21px'}}/>
        } else if (type === 'delivery') {
            return (
                <ImTruck style={{width: '30px', height: '21px'}}/>
            )
        } else if ('purchase') {
            return (
                <BiLaptop style={{width: '30px', height: '21px'}}/>
            )
        }
    }

    const renderNameItems = (item) => {
        if(!state?.visible) {
            return <span>{state?.i18n?.tagsAbas?.nps?.headerFilterExperience[item]}</span>
        } else {
            if(state?.visible && active) {
                return <span>{state?.i18n?.tagsAbas?.nps?.headerFilterExperience[item]}</span>
            } else if (state?.visible && !active) {
                return <></>
            }
        }
    }

    const renderItems = useMemo(() => {
        if (!!options) {
            return options.map((item, index) => {
                return (
                    <Item key={index}
                          onClick={() => handleSetFirstOption(index, item)}
                          showOnlyFirstItem={index !== 0 && !active}
                          isFirstItemAlone={index === 0 && !active}
                          active={active}
                          visible={state?.visible}
                    >
                        <div>
                            <span>{renderIconsSelect(item)}</span>
                            {renderNameItems(item)}
                        </div>
                        {index === 0 ? renderArrowDownOrUp() : ''}
                    </Item>
                )
            })
        }
    }, [active, renderArrowDownOrUp, handleSetFirstOption, options, state?.i18n?.tagsAbas?.nps?.headerFilterExperience, state?.visible])


    return (
        <Container
            ref={componentSelectRef}
            noItemsSelect={!!filtersObjectItem}
            active={active}
            optionsWidth={((selectedItemLength * 10) + 10) <= 190 ? 149 : (selectedItemLength * 8.5)}
            visible={state?.visible}
        >
            {renderItems}
        </Container>
    );
};

export default SelectOrderNps;
