import styled, { css } from 'styled-components';

export const Container = styled.div`
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    max-height: 64px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;

    ${props => props.open && css`
        height: auto;
        max-height: initial;
    `}
`

export const WrapperMain = styled.div`
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;

    ${props => props.open && css`
        border-bottom: 1px solid #70707033;
    `}
`
export const ContainerSecondary = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: auto;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 61px;
`
