import styled, {css} from "styled-components";

export const MoreInfo = styled.div`
    border-radius: 5px;
    width: inherit;
    height: inherit;
    color: #000;
    font-size: 22px;
    text-transform: uppercase;
    transition: all .25s ease;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-left: 5px;

    svg {
        width: 9.7px;
        height: 5.54px;
        transform: rotate(180deg);

        path {
            fill: #000 !important;
        }

        ${props => props.active && css`
            transform: rotate(360deg);
        `}
    }
`

export const MoreInfoDefaut = styled.div`
    display: flex;
    align-items: center;
    height: 20px;
    cursor: pointer;
    opacity: .5;

    @media (max-width: 768px) {
        display: none;
    }

    span {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        margin-left: 7px;
    }
`
