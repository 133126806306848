import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: #000000;
    opacity: 0.8;
    z-index: 9999999999;
    position: absolute;
    left: 0;
    top: 0;
`
export const Wrapper = styled.div`
    width: 410px;
    height: 227px;
    background-color: #FFFFFF;
    border-radius: 5px;
    z-index: 9999999999999;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: 36%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


    span {
        text-align: center;
        font-size: 14px;
        margin: 10px 20px 0px;
        color: #000;
        opacity: 0.8;
    }

    svg {
        width: 31.34px;
        height: 27.33px;
        margin-top: 32px;
    }
`

export const WrapperButtons = styled.div`
    width: 247.07px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px 33px;
`
