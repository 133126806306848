import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {ContextReducer} from "../../reducer/AdminReducer";
import parse from 'html-react-parser';
import {Container} from '../styles';
import {
    Block,
    Button,
    ButtonFake,
    ButtonLoading,
    ContentBlock,
    ContentButtons,
    ContentLeft,
    ContentRight,
    ContentSelect,
    EmailPreview,
    Fake,
    FakeLogo,
    FakeProfile,
    FormNews,
    Img,
    ImgLogo,
    ImgProfile,
    InputColorContent,
    IPFile,
    IPFileFake,
    IPText,
    Item,
    Label,
    Name,
    Picture,
    Preview,
    PreviewLeft,
    PreviewRight,
    Previews,
    SmallError,
    SubBlock,
    SubTitle,
    Title,
    TextArea,
    Tag,
    ContentTag,
    Delete
} from './styles'
import {BsUpload} from 'react-icons/bs';
import {MdDeleteForever} from 'react-icons/md';
import {GrClose} from 'react-icons/gr';
import {getConfig, getEmailPreview, sendConfigurantion, setLoading} from "../../actions/ReviewsAction";
import BG1 from '../../assets/pre1.png'
import BG2 from '../../assets/pre2.png'
import {ReactComponent as SocialIsStore} from '../../assets/icon-isStore.svg';
import {FiChevronDown, FiChevronUp} from 'react-icons/fi'

//Components
import WrapperInputsAccess from "../../components/AccessConfig/WrapperInputsAccess";
import WrapperListAccess from "../../components/AccessConfig/WrapperListAccess";
import WrapperIntegratedStores from "../../components/IntegratedStores/WrapperIntegratedStores"
import ListIntegratedStores from "../../components/IntegratedStores/ListIntegratedStores";
import EditTags from "../../components/Items/EditTags";

import useRefreshToken from '../../hooks/useRefreshToken';
import LanguageCheckbox from "./LanguagesCheckbox";
import ModalConfirmationLang from "./LanguagesCheckbox/ModalConfirmationLang";
import SendingEmail from "./SendingEmail";

const Configuration = () => {
    const {state, dispatch} = useContext(ContextReducer);

    const checkRef = useRef();
    const fileRefProfile = useRef()
    const fileRefLogo = useRef()
    const fileRefEmail = useRef()

    const [name, setName] = useState(null);
    const [description, setDescription] = useState(null)
    const [url, setUrl] = useState({});

    const [profilePicturePre, setProfilePicturePre] = useState(false);
    const [profilePictureName, setProfilePictureName] = useState(false);
    const [profilePicture, setProfilePicture] = useState(false);
    const [changeProfile, setChangeProfile] = useState(false);

    const [logoPicture, setLogoPicture] = useState(false);
    const [logoPictureName, setLogoPictureName] = useState(false);
    const [logoPicturePre, setLogoPicturePre] = useState(false);
    const [changeLogo, setChangeLogo] = useState(false);

    const [emailLogoPicture, setEmailLogoPicture] = useState(false);
    const [emailLogoPicturePre, setEmailLogoPicturePre] = useState(false);
    const [emailLogoPictureName, setEmailLogoPicturName] = useState(false);
    const [changeEmail, setChangeEmail] = useState(false);

    const [currentRadioValue1, setCurrentRadioValue1] = useState(state?.config?.ecommerce?.transactionEmailConfig?.enableEmail)
    const [currentRadioValue2, setCurrentRadioValue2] = useState(state?.config?.ecommerce?.transactionEmailConfig?.sendEmailInDays)

    const [color, setColor] = useState({})
    const [config, setConfig] = useState({})

    const [check, setCheck] = useState(true);

    const [open, setOpen] = useState('geral')

    const [flag, setFlag] = useState(false);

    const [buttonRadius, setButtonRadius] = useState(null)

    const tabClicked = useRef('geral')

    const [colorApplied, setColorApplied] = useState(false);

    const [selectedLanguages, setSelectedLanguages] = useState([]);

    const [ saveButton, setSaveButton ] = useState({ layout: false, product: false, service: false});

    useEffect(() => {
        getConfig(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token);
    }, []);

    useRefreshToken('config', tabClicked, setOpen);

    useEffect(() => {

        if (check) {
            setEmailLogoPicture(logoPicture)
            setEmailLogoPicturePre(logoPicturePre)
            setEmailLogoPicturName(logoPicture ? state?.i18n?.configuration?.photo?.emailLogoPicture : state?.i18n?.configuration?.input)
        }

    }, [check, logoPicture, logoPicturePre, logoPictureName])

    useEffect(() => {
        if (state?.config?.ecommerce?.authorInfo?.picture.url !== " ") {
            setProfilePicture(state?.config?.ecommerce?.authorInfo?.picture.url)
            setProfilePictureName(state?.i18n?.configuration?.photo?.picture)
            setProfilePicturePre(state?.config?.ecommerce?.authorInfo?.picture.url)
        }
        if (state?.config?.ecommerce?.authorInfo?.logoPicture.url !== " ") {
            setLogoPicture(state?.config?.ecommerce?.authorInfo?.logoPicture.url)
            setLogoPictureName(state?.i18n?.configuration?.photo?.logoPicture)
            setLogoPicturePre(state?.config?.ecommerce?.authorInfo?.logoPicture.url)
        }
        if (state?.config?.ecommerce?.authorInfo?.emailLogoPicture.url !== " ") {
            setEmailLogoPicture(state?.config?.ecommerce?.authorInfo?.emailLogoPicture.url)
            setEmailLogoPicturName(state?.i18n?.configuration?.photo?.emailLogoPicture)
            setEmailLogoPicturePre(state?.config?.ecommerce?.authorInfo?.emailLogoPicture.url)
        }
        setChangeProfile(false)
        setChangeLogo(false)
        setChangeEmail(false)

    }, [state.config])

    useEffect(() => {
        getEmailPreview(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, config);
    }, [config])

    const handleName = e => {
        setName(e.target.value)
    }

    const handleDescription = e => {
        setDescription(e.target.value)
    }

    const handleUrl = e => {
        let _obj = {}

        _obj[e.target.name] = e.target.value

        setUrl({...url, ..._obj})
    }

    const handleProfilePicture = e => {
        setChangeProfile(true)
        setProfilePictureName(state?.i18n?.configuration?.photo?.picture)
        setProfilePicture(e.target.files[0])

        getBase64(e.target.files[0]).then((resp) => {
            setProfilePicturePre(resp)
        })

    }

    const handleLogoPicture = e => {
        setChangeLogo(true)
        if (check) setChangeEmail(true)
        setLogoPictureName(state?.i18n?.configuration?.photo?.logoPicture)
        setLogoPicture(e.target.files[0])

        getBase64(e.target.files[0]).then((resp) => {
            setLogoPicturePre(resp)
        })
    }

    const handleEmailPicture = e => {
        setChangeEmail(true)
        setEmailLogoPicturName(state?.i18n?.configuration?.photo?.emailLogoPicture)
        setEmailLogoPicture(e.target.files[0])

        getBase64(e.target.files[0]).then((resp) => {
            setEmailLogoPicturePre(resp)
        })
    }

    const getBase64 = (file) => {
        return new Promise(resolve => {
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    const handleSubmitForm = () => {
        setTimeout(() => {
            setFlag(false)
        }, 2000)

        setLoading(dispatch, {
            loading: true,
            done: false
        });

        let _obj = {}

        if (changeProfile) _obj.profilePicture = profilePicture
        if (changeLogo) _obj.logoPicture = logoPicture
        if (changeEmail) _obj.emailLogoPicture = emailLogoPicture
        if (check) _obj.useLogoAsEmailLogo = check
        if(selectedLanguages.length > 0) _obj.locales = selectedLanguages

        sendConfigurantion(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, _obj, name ? name : null, config, null, description ? description : null, null, url)

        setColorApplied(false);
    }

    const handleCheckBox = async event => {
        setCheck(!check)
        setChangeEmail(true)
        if (event.target.checked) {
            const file = await handleTransformImage(state?.config?.ecommerce?.authorInfo?.logoPicture?.url, state?.config?.ecommerce?.ecommerceId)
            getBase64(file).then((resp) => {
                setEmailLogoPicturePre(resp)
            })
            setEmailLogoPicture(file)
        }
    }

    const handleTransformImage = async (url, name) => {
        const response = await fetch(url);
        const data = await response.blob();
        return new File([data], name, {
            type: data.type,
        });
    }

    const handleClearInput = (type) => {
        if (type === 'profile') {
            fileRefProfile.current.value = ''
            setProfilePicturePre(false)
            setProfilePictureName(false)
            setProfilePicture(false)
            setChangeProfile(true)
        } else if (type === 'logo') {
            fileRefLogo.current.value = ''
            setLogoPicture(false)
            setLogoPictureName(false)
            setLogoPicturePre(false)
            setChangeLogo(true)
            if (check) {
                fileRefEmail.current.value = ''
                setEmailLogoPicture(false)
                setEmailLogoPicturePre(false)
                setEmailLogoPicturName(false)
                setChangeEmail(true)
            }
        } else if (type === 'email') {
            if (!check) {
                fileRefEmail.current.value = ''
                setEmailLogoPicture(false)
                setEmailLogoPicturePre(false)
                setEmailLogoPicturName(false)
                setChangeEmail(true)
            }
        }
    }

    const applyColorToFields = (color, fields) => {
        let newConfig = {...config};
        fields.forEach(field => {
            newConfig[field] = color;
        });
        setConfig(newConfig);
        setColorApplied(true);
    }


    const handleSetConfig = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setTimeout(() => {
            setFlag(true)
        }, 2000)

        if (name === 'styleHeaderBorderTopColor' && !colorApplied) {
            setSaveButton({...saveButton, layout: true});
            applyColorToFields(value, [
                'styleHeaderBorderTopColor',
                'styleReviewButtonFontColor',
                'styleReviewButtonBorderColor',
                'styleReviewButtonHover',
                'styleNpsBgColor',
                'styleNpsButtonBorderColor',
                'styleNpsButtonFontColor',
                'styleNpsButtonHover'
            ]);
        } else if (name === 'styleReviewButtonFontColor' && !colorApplied) {
            setSaveButton({...saveButton, layout: true});
            applyColorToFields(value, [
                'styleReviewButtonFontColor'
            ]);
        } else if (name === 'styleReviewButtonBorderColor' && !colorApplied) {
            setSaveButton({...saveButton, layout: true});
            applyColorToFields(value, [
                'styleReviewButtonBorderColor',
                'styleReviewButtonHover',
                'styleNpsBgColor',
                'styleNpsButtonBorderColor',
                'styleNpsButtonFontColor',
                'styleNpsButtonHover'
            ]);
        } else if (name === 'styleReviewButtonHover' && !colorApplied) {
            setSaveButton({...saveButton, layout: true});
            applyColorToFields(value, [
                'styleReviewButtonHover',
                'styleNpsBgColor',
                'styleNpsButtonBorderColor',
                'styleNpsButtonFontColor',
                'styleNpsButtonHover'
            ]);
        } else if (name === 'styleNpsBgColor' && !colorApplied) {
            setSaveButton({...saveButton, layout: true});
            applyColorToFields(value, [
                'styleNpsBgColor',
                'styleNpsButtonBorderColor',
                'styleNpsButtonFontColor',
                'styleNpsButtonHover'
            ]);
        } else if (name === 'styleNpsButtonBorderColor' && !colorApplied) {
            setSaveButton({...saveButton, layout: true});
            applyColorToFields(value, [
                'styleNpsButtonBorderColor',
                'styleNpsButtonFontColor',
                'styleNpsButtonHover'
            ]);
        } else if (name === 'styleNpsButtonFontColor' && !colorApplied) {
            setSaveButton({...saveButton, layout: true});
            applyColorToFields(value, [
                'styleNpsButtonFontColor',
                'styleNpsButtonHover'
            ]);
        } else {
            const _product = ['bounceMinDays', 'sendEmailDays', 'maxAttempts'];
            const _layout = ['styleHeaderBgColor', 'styleHeaderLogoDarkShadowColor', 'styleHeaderLogoDarkShadowOpacity', 'styleReviewButtonBg', 'styleNpsBgColor', 'styleNpsButtonFontColor', 'styleNpsButtonHover', 'styleNpsButtonBg']

            if(_product.includes(name)){
                setSaveButton({...saveButton, product: true});
            } else if(_layout.includes(name)) {
                setSaveButton({...saveButton, layout: true});
            }

            setConfig({...config, [name]: value});
        }

        if (e.target.name === 'styleButtonsBorderRadius') {
            setSaveButton({...saveButton, layout: true});
            setButtonRadius(e.target.value);
        }
    }

    const handleItemMenu = (type) => {
        document.body.scrollTop = 0;
        
        if (tabClicked.current === type) {
            setOpen('')
            tabClicked.current = '';
            return
        }
        setOpen(type)
        tabClicked.current = type;

        setColor(state?.config?.ecommerce?.transactionEmailConfig?.style)
        setButtonRadius(state?.config?.ecommerce?.transactionEmailConfig?.style?.styleButtonsBorderRadius);
    }

    const objFilter = (arr, fn) => {
        let filteredValues = [];
        for (let i = arr.length - 1; i >= 0; i--) {
            if (fn(arr[i])) {
                filteredValues.push(arr.splice(i, 1)[0]);
            }
        }
        return filteredValues;
    }

    const handleClickDelete = (item) => {
        const _arr = objFilter(state?.config?.ecommerce?.tags, (value) => value !== item);
        sendConfigurantion(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, null, null, null, null, null,  _arr)
    }

    const renderNameButton = () => {
        if (state?.buttonLoading?.loading) {
            return state?.i18n?.buttonSave.loading
        } else if (state?.buttonLoading?.done) {
            return state?.i18n?.buttonSave.done
        } else {
            return state?.i18n?.buttonSave.save
        }
    }

    const renderConfigNewsletter = () => {
        return (
            <FormNews onChange={(e) => handleSetConfig(e)}>
                <SendingEmail
                    config={config}
                    setConfig={setConfig}
                    color={color}
                    buttonRadius={buttonRadius}
                    handleSubmitForm={handleSubmitForm}
                    setSaveButton={setSaveButton}
                    saveButton={saveButton}
                />
            </FormNews>
        )
    }

    const renderConfigGeral = () => {
        return (
            <>
                <Block>
                    <Name>{state?.i18n?.configuration?.name1}</Name>
                    <IPText type={'text'} onChange={handleName}
                            placeholder={state?.config?.ecommerce?.name ? state?.config?.ecommerce?.name : state?.i18n?.configuration?.inputName}/>
                    {!state?.config?.ecommerce?.name &&
                        <SmallError>{state?.i18n?.configuration.error.noNameForm}</SmallError>}
                </Block>
                <Block>
                    <Name>{state?.i18n?.configuration?.description}</Name>
                    <TextArea onChange={handleDescription}
                            placeholder={state?.config?.ecommerce?.description ? state?.config?.ecommerce?.description : state?.i18n?.configuration?.textarea}/>
                </Block>
                <Block>
                    <Name>{state?.i18n?.configuration?.tags}</Name>
                    <EditTags values={state.config} type={'configuration'} />
                    <ContentTag>
                        {state?.config?.ecommerce?.tags &&
                            state?.config?.ecommerce?.tags?.map((item, index) => {
                                return (
                                    <Tag key={index}>{item} <Delete onClick={() => handleClickDelete(item)}><GrClose/></Delete></Tag>
                                )
                            })
                        }
                    </ContentTag>
                </Block>
                <Block>
                    <Name>{state?.i18n?.configuration?.name2}</Name>
                    <IPFile type={'file'} ref={fileRefProfile} onChange={handleProfilePicture}/>
                    <IPFileFake
                        fake={profilePicture}><span>{profilePictureName ? profilePictureName : state?.i18n?.configuration?.input}</span>{profilePicture ?
                        <MdDeleteForever onClick={() => handleClearInput('profile')}
                                         style={{height: '20px', width: '20px', color: '#000000'}}/> : <BsUpload/>}
                    </IPFileFake>
                    {profilePicture ? <></> :
                        <SmallError>{state?.i18n?.configuration.error.noProfilePicture}</SmallError>}
                </Block>
                <Block>
                    <Name>{state?.i18n?.configuration?.name3}</Name>
                    <IPFile type={'file'} ref={fileRefLogo} onChange={handleLogoPicture}/>
                    <IPFileFake
                        fake={logoPicture}><span>{logoPictureName ? logoPictureName : state?.i18n?.configuration?.input}</span>{logoPicture ?
                        <MdDeleteForever onClick={() => handleClearInput('logo')}
                                         style={{height: '20px', width: '20px', color: '#000000'}}/> : <BsUpload/>}
                    </IPFileFake>
                    {logoPicture ? <></> : <SmallError>{state?.i18n?.configuration.error.noLogoPicture}</SmallError>}
                </Block>
                <Block>
                    <Name>{state?.i18n?.configuration?.name4}</Name>
                    <ContentSelect>
                        <input type="checkbox" id="logoAsEmailLogo" ref={checkRef} defaultChecked={check}
                               onChange={handleCheckBox}/>
                        <label for="logoAsEmailLogo">{state?.i18n?.configuration?.select}</ label>
                    </ContentSelect>
                    <IPFile type={'file'} ref={fileRefEmail} disabled={check} onChange={handleEmailPicture}/>
                    <IPFileFake email fake={emailLogoPicture}
                                disable={check}><span>{emailLogoPictureName ? emailLogoPictureName : state?.i18n?.configuration?.input}</span>{emailLogoPicture ?
                        <MdDeleteForever onClick={() => handleClearInput('email')}
                                         style={{height: '20px', width: '20px', color: '#000000'}}/> : <BsUpload/>}
                    </IPFileFake>
                    {((emailLogoPicture) || (!check && emailLogoPicture)) ? <></> :
                        <SmallError>{state?.i18n?.configuration.error.noEmailLogoPictureForm}</SmallError>}
                </Block>
                <Block>
                    <Name>{state?.i18n?.configuration?.privacyPoliceUrl}</Name>
                    <IPText type={'text'} name={'privacyPoliceUrl'} onChange={handleUrl}
                            placeholder={state?.config?.ecommerce?.privacyPoliceUrl ? state?.config?.ecommerce?.privacyPoliceUrl : state?.i18n?.configuration?.url}/>
                </Block>
                <Block>
                    <Name>{state?.i18n?.configuration?.termsOfUseUrl}</Name>
                    <IPText type={'text'} name={'termsOfUseUrl'} onChange={handleUrl}
                            placeholder={state?.config?.ecommerce?.termsOfUseUrl ? state?.config?.ecommerce?.termsOfUseUrl : state?.i18n?.configuration?.url}/>
                </Block>
                <LanguageCheckbox
                    selectedLanguages={selectedLanguages}
                    setSelectedLanguages={setSelectedLanguages}
                />
            </>
        )
    }

    const renderEmailPreview = () => {
        return (
            <>
                <EmailPreview>
                    {parse(state?.previewEmail.emailPreview)}
                    <ContentButtons>
                        <Button
                            type="button"
                            done={state?.buttonLoading.done}
                            loading={state?.buttonLoading.loading}
                            disabled={(state?.buttonLoading.loading || state?.buttonLoading.done)}
                            onClick={handleSubmitForm}
                        >
                            <strong>{renderNameButton()}</strong>
                            {state?.buttonLoading?.loading && <ButtonLoading/>}
                        </Button>
                    </ContentButtons>
                </EmailPreview>
            </>
        )
    }

    const renderPreviews = () => {
        return (
            <>
                <Previews>
                    <Preview vw={'350'} vh={'310'} bg={BG1}>
                        {emailLogoPicturePre ? <ImgProfile img={emailLogoPicturePre}/> : <FakeProfile/>}
                    </Preview>
                    <Preview vw={'330'} vh={'160'} bg={BG2} pre2 mg={'20'}>
                        {logoPicturePre ? <ImgLogo img={logoPicturePre}/> : <FakeLogo/>}
                    </Preview>
                    <Preview vw={'330'} vh={'130'} min>
                        <PreviewLeft>
                            <Picture>
                                {profilePicturePre ? <Img img={profilePicturePre}/> : <Fake/>}
                            </Picture>
                        </PreviewLeft>
                        <PreviewRight>
                            <strong>{name ? name : state?.config?.ecommerce?.name}</strong>
                            <span><SocialIsStore/> {state.i18n?.type?.is_store}</span>
                        </PreviewRight>
                    </Preview>
                </Previews>
                <ContentButtons>
                    <Button
                        type="button"
                        done={state?.buttonLoading.done}
                        loading={state?.buttonLoading.loading}
                        disabled={(state?.buttonLoading.loading || state?.buttonLoading.done)}
                        onClick={handleSubmitForm}
                    >
                        <strong>{renderNameButton()}</strong>
                        {state?.buttonLoading?.loading && <ButtonLoading/>}
                    </Button>
                </ContentButtons>
            </>
        )
    }

    const handleTitle = () => {
        if (open === 'peopleAccess' || open === '') {
            return ''
        } else if (open === 'integratedStores') {
            return <Title>{state?.i18n?.configuration?.integratedStores?.title}</Title>
        } else {
            return (
                <>
                    <Title>{state?.i18n?.configuration?.title2}</Title>
                    <SubTitle>{state?.i18n?.configuration?.pre}</SubTitle>
                </>
            )
        }
    }

    return useMemo(() => {
        return (
            <>
                {state?.modalConfirmationLang.active && <ModalConfirmationLang />}
                <Container area='config'>
                    <ContentLeft>
                        <Title>{state?.i18n?.configuration?.title}</Title>
                        <Item active={open === 'geral'}>
                            <span onClick={() => handleItemMenu('geral')}>
                                {state?.i18n?.configuration?.menu?.geral}
                                {open === 'geral' ?
                                    <FiChevronUp style={{width: '25px', height: '25px'}}/> :
                                    <FiChevronDown style={{width: '25px', height: '25px'}}/>
                                }
                            </span>
                            {open === 'geral' && renderConfigGeral()}
                        </Item>
                        <Item active={open === 'news'}>
                            <span onClick={() => handleItemMenu('news')}>
                                {state?.i18n?.configuration?.menu?.newsletter}
                                {open === 'news' ?
                                    <FiChevronUp style={{width: '25px', height: '25px'}}/> :
                                    <FiChevronDown style={{width: '25px', height: '25px'}}/>
                                }
                            </span>
                            {open === 'news' && renderConfigNewsletter()}
                        </Item>
                        <Item active={open === 'peopleAccess'}>
                            <span onClick={() => handleItemMenu('peopleAccess')}>
                                {state?.i18n?.configuration?.menu?.access}
                                {open === 'peopleAccess' ?
                                    <FiChevronUp style={{width: '25px', height: '25px'}}/> :
                                    <FiChevronDown style={{width: '25px', height: '25px'}}/>
                                }
                            </span>
                            {open === 'peopleAccess' && <WrapperInputsAccess/>}
                        </Item>
                        <Item active={open === 'integratedStores'}>
                            <span onClick={() => handleItemMenu('integratedStores')}>
                                {state?.i18n?.configuration?.menu?.integratedStores}
                                {open === 'integratedStores' ?
                                    <FiChevronUp style={{width: '25px', height: '25px'}}/> :
                                    <FiChevronDown style={{width: '25px', height: '25px'}}/>
                                }
                            </span>
                            {open === 'integratedStores' && <ListIntegratedStores />}
                        </Item>
                    </ContentLeft>
                    <ContentRight>
                        {handleTitle()}
                        {open === 'geral' && renderPreviews()}
                        {open === 'news' && renderEmailPreview()}
                        {open === 'peopleAccess' && <WrapperListAccess/>}
                        {open === 'integratedStores' && <WrapperIntegratedStores />}
                    </ContentRight>
                </Container>
            </>
        )
    }, [state.config, url, state.tagsConfiguration, color, config, flag, buttonRadius, state?.buttonLoading, state.previewEmail, state?.i18n, state.userInfo, open, currentRadioValue1, currentRadioValue2, changeProfile, changeLogo, changeEmail, checkRef, fileRefProfile, fileRefLogo, fileRefEmail, check, name, description, profilePictureName, logoPictureName, emailLogoPictureName, profilePicture, logoPicture, emailLogoPicture, logoPicturePre, emailLogoPicturePre, profilePicturePre, state.modalConfirmationLang, selectedLanguages]);
}

export default Configuration;
