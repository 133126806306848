import React from 'react';

import { Content, Item, Text } from './styles';

const Bar = ({avg}) => {

    const renderAvg = () => {
        if (avg >= 9) {
            return 'high'
        } else if (avg >= 7 && avg < 9) {
            return 'medium'
        } else {
            return 'low'
        }
    }

    return (
        <Content type={renderAvg()}>
            <Item />
            <Text>{avg}</Text>
        </Content>
    );
}

export default Bar;
