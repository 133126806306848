import styled, {css} from "styled-components";

export const Infos = styled.p`
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 75px;

    ${props => props.margin && css`
        margin-top: 0;
    `}
`
