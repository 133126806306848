import React, {useContext, useEffect, useState} from 'react';
import {Container} from './styles'
import {setFilter} from '../../actions/ReviewsAction';
import {ContextReducer} from '../../reducer/AdminReducer';

const SelectInputFilter = () => {
    const {state, dispatch} = useContext(ContextReducer);
    const [sizeOption, setSizeOption] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');

    useEffect(() => {
        let _interactions = (state?.actualRoute === 'reviews' || state?.actualRoute === 'comments' || state?.actualRoute === 'questions' || state?.actualRoute === 'answers') && 'interactions';

        if (state.actualRoute === 'nps' || state.actualRoute === 'newsletter') {
            setSelectedOption('transactionId');
        } else if (_interactions) {
            setSelectedOption('productName');
        } else if (state.actualRoute === 'users') {
            setSelectedOption('userName');
        }
    }, [])

    const handleFilters = () => {
        let _route;
        let _interactions = ((state?.actualRoute === 'reviews') || (state?.actualRoute === 'comments') || (state?.actualRoute === 'questions') || (state?.actualRoute === 'answers')) && 'interactions';

        if (_interactions) {
            _route = _interactions;
        } else if (state?.actualRoute === 'newsletter') {
            _route = 'newsletters'
        } else {
            _route = state.actualRoute;
        }

        let _availableFilters = state?.config?.ecommerce?.availableFilters[_route];

        if (_availableFilters) {
            return _availableFilters.map((item) => {
                if (typeof item === 'object') return '';
                if(item === 'reviewRating' && (state.actualRoute !== 'reviews' && state.actualRoute !== 'nps')) return '';

                return (
                    <option
                        key={item}
                        value={item}
                    >
                        {state.i18n.filtersSelectInput[item]}
                    </option>
                )
            })
        } else {
            return <option>{state?.i18n?.ellipsis}</option>
        }
    }

    const handleSelectFilter = (e) => {
        e.preventDefault();

        setSelectedOption(e.target.value);
        setSizeOption(state.i18n.filtersSelectInput[e.target.value].replace(' ', '').trim().length);

        const _filter = {orderSelectSearch: e.target.value}
        setFilter(dispatch, _filter);
    }

    const handleSizePlaceholderCalc = () => {
        if (sizeOption) {
            if (sizeOption <= 8) {
                return (sizeOption * 9) + 20;
            } else {
                return (sizeOption * 10) - 12;
            }
        } else {
            return 130;
        }
    }

    return (
        <Container sizePlaceholder={handleSizePlaceholderCalc()}>
            <select value={selectedOption} onChange={(e) => handleSelectFilter(e)}>
                {handleFilters()}
            </select>
        </Container>
    );

};

export default SelectInputFilter;
