import React, {useContext} from 'react';

import {ContextReducer} from "../../reducer/AdminReducer";

import {ReactComponent as IconLoading} from "../../assets/IconLoading.svg"
import {Content} from './styles';

const CommingSoon = () => {
    const {state} = useContext(ContextReducer);

    return (
        <Content>
            <IconLoading/>
            <h3>Em breve! :)</h3>
            <h5>Trabalhamos incessantemente para disponibilizar-lhe mais inovações Vurdere Social+</h5>
        </Content>
    );
}

export default CommingSoon;
