import React from 'react';
import { Container } from './styles';
import BoxProduct from './BoxProduct';
import BoxService from './BoxService';
import BoxLayout from './BoxLayout';

const SendingEmail = ({ config, color, buttonRadius, setConfig, handleSubmitForm, setSaveButton, saveButton }) => {

    return (
        <Container>
            <BoxProduct
                config={config}
                setConfig={setConfig}
                handleSubmitForm={handleSubmitForm}
                setSaveButton={setSaveButton}
                saveButton={saveButton}
            />
            <BoxService
                config={config}
                setConfig={setConfig}
                handleSubmitForm={handleSubmitForm}
                setSaveButton={setSaveButton}
                saveButton={saveButton}
            />
            <BoxLayout
                config={config}
                color={color}
                buttonRadius={buttonRadius}
                handleSubmitForm={handleSubmitForm}
                setSaveButton={setSaveButton}
                saveButton={saveButton}
            />
        </Container>
    );
};

export default SendingEmail;
