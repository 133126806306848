import React, { useContext, useState } from 'react';
import { ContextReducer } from '../../../../reducer/AdminReducer';
import EmailTest from './EmailTest';
import ContentColors from './ContentColors';
import {  Container, WrapperMain, ContainerSecondary} from './styles';
import { Button, ButtonLoading } from '../../styles';

const BoxLayout = ({ config, color, buttonRadius, handleSubmitForm, setSaveButton, saveButton }) => {
    const [open, setOpen] = useState(false);
    const [statusButton, setStatusButton ] = useState({ loading: false, done: false});

    const { state } = useContext(ContextReducer);

    const handlePromise = async () => {
        return new Promise((resolve) => {
            handleSubmitForm()
            resolve();
        })
    }

    const handleClickSave = async () => {
        setStatusButton({...statusButton, loading: true})
        await handlePromise();
        setStatusButton({...statusButton, done: true, loading: false});

        setTimeout(() => {
            setStatusButton({...statusButton, done: false});
        }, 3000);

        setSaveButton({...saveButton, layout: false});
    }

    const renderNameButton = () => {
        if (statusButton.loading) {
            return state?.i18n?.buttonSave.loading
        } else if (statusButton.done) {
            return state?.i18n?.buttonSave.done
        } else {
            return state?.i18n?.buttonSave.save
        }
    }

    return (
        <>
            <Container open={open}>
                <WrapperMain
                    onClick={() => setOpen((prevState) => !prevState)}
                    open={open}
                >
                    <span>{state?.i18n?.configuration?.newsletter?.layout}</span>
                </WrapperMain>
                {open &&
                    <ContainerSecondary>
                        <ContentColors
                            config={config}
                            color={color}
                            buttonRadius={buttonRadius}
                        />
                        <EmailTest />
                    </ContainerSecondary>
                }
            </Container>
            {(saveButton['layout'] || (statusButton.loading || statusButton.done)) &&
                <Button
                    from={'newsletter'}
                    type="button"
                    done={statusButton.done}
                    loading={statusButton.loading}
                    disabled={(statusButton.loading || statusButton.done)}
                    onClick={() => handleClickSave()}
                >
                    <strong>{renderNameButton()}</strong>
                    {statusButton.loading && <ButtonLoading/>}
                </Button>
            }
        </>
    );
};

export default BoxLayout;
