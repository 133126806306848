import styled from 'styled-components';

export const Item = styled.div`
    display: flex;
    align-items: center;
    margin-left: 15px;

    span {
        font-size: 12px;
        font-family: Ubuntu, arial;
        color: #40AA60;
        margin-left: 5px;
    }
`
