import React, {useContext, useMemo, useState} from 'react';
import {setFilter, setOrderNewsletterId, setOrderNewsletterItem} from "../../actions/ReviewsAction";
import {ContextReducer} from '../../reducer/AdminReducer';

import Pagination from '../../components/Pagination';
import LoaderInfos from '../../components/LoaderInfos';

import {Container, ListHeaderItem} from '../styles'
import {Arrow, Box, Boxes, ContentUsers, Id, Items, ItemsProducts, ListItem} from './styles';
import More from '../../components/More';
import NoItems from "../../components/NoItems";
import HeaderFilter from "../../components/HeaderFilter";
import {BsCheckCircleFill} from 'react-icons/bs';
import {RiCloseCircleFill} from 'react-icons/ri';

import useInitializeinfos from '../../hooks/useInitializeInfos'
import LoaderBox from '../../components/LoaderBox';
import ListFiltersSearch from '../../components/Filter/ListFiltersSearch';

const Newsletter = () => {

    const {state, dispatch} = useContext(ContextReducer);
    const [listProducts, setListProducts] = useState({});

    useInitializeinfos();

    const handleOrder = (odr, idex, type) => {
        const _filter = {...state.filters}
        if (state?.filters?.orderNewsletter !== odr) {
            setOrderNewsletterItem(dispatch, true);
            _filter.sortNewsletter = 'desc';
        } else if (state?.filters?.orderNewsletter === odr && state?.orderNewsletterItem) {
            _filter.sortNewsletter = 'asc';
            setOrderNewsletterItem(dispatch, false);
        } else {
            _filter.sortNewsletter = 'desc';
            setOrderNewsletterItem(dispatch, true);
        }

        setOrderNewsletterId(dispatch, idex);

        _filter.orderNewsletter = odr;
        setFilter(dispatch, _filter);
    }

    const handleProducts = (idx) => {
        setListProducts({
            ...listProducts,
            [idx]: !listProducts[idx]
        })
    }

    const renderArrow = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="9.696" height="5.544" viewBox="0 0 9.696 5.544">
            <defs></defs>
            <path className="a" fill="#000000"
                  d="M11.037,15.119,14.7,11.45a.69.69,0,0,1,.979,0,.7.7,0,0,1,0,.981l-4.154,4.157a.692.692,0,0,1-.955.02L6.39,12.434a.693.693,0,0,1,.979-.981Z"
                  transform="translate(-6.188 -11.246)"/>
        </svg>
    }


    const renderProducts = (idx, item) => {
        if (item.productList.length > 0) {
            return item.productList.map((val, index) => {
                return (
                    <ItemsProducts key={index} className={idx} active={listProducts[idx]}>
                        <Id porcentage="10">{<img src={val?.image} loading="lazy"/>}</Id>
                        <Id porcentage="10">{val?.name}</Id>
                        <Id porcentage="10">ID: {val?.id}</Id>
                        <Id porcentage="10"></Id>
                        <Id porcentage="10"></Id>
                        <Id porcentage="10"></Id>
                        <Id porcentage="10">{val?.review ?
                            <BsCheckCircleFill style={{width: '20px', height: '20px', color: '#000000'}}/> :
                            <RiCloseCircleFill style={{width: '26px', height: '26px', color: 'rgba(0,0,0,.6)'}}/>}</Id>
                        <Id porcentage="10">{val?.nps ?
                            <BsCheckCircleFill style={{width: '20px', height: '20px', color: '#000000'}}/> :
                            <RiCloseCircleFill style={{width: '26px', height: '26px', color: 'rgba(0,0,0,.6)'}}/>}</Id>
                    </ItemsProducts>
                )
            })
        }
    }

    const renderNewsletters = () => {
        if (state?.newsletters?.items.length > 0) {
            return state?.newsletters?.items?.map((val, index) => {
                return (
                    <div
                        className={index}
                        key={index}
                        onClick={() => handleProducts(index)}
                    >
                        <Items>
                            <Id porcentage="10">#{val?.transactionId} <More more={listProducts[index]}/></Id>
                            <Id porcentage="10">{val?.transactionDate}</Id>
                            <Id porcentage="10" upper={true}
                                ativo={val?.status}>{state?.i18n?.newsletter[val?.status]}</Id>
                            <Id porcentage="10">{val?.emailSentDate}</Id>
                            <Id porcentage="10">{val?.opened ?
                                <BsCheckCircleFill style={{width: '20px', height: '20px', color: '#000000'}}/> :
                                <RiCloseCircleFill
                                    style={{width: '26px', height: '26px', color: 'rgba(0,0,0,.6)'}}/>}</Id>
                            <Id porcentage="10">{val?.attempts} {val?.attempts <= 1 ? state?.i18n?.newsletter?.singular : state?.i18n?.newsletter?.plural}</Id>
                            <Id porcentage="10">{val?.review}</Id>
                            <Id porcentage="10">{val?.nps ?
                                <BsCheckCircleFill style={{width: '20px', height: '20px', color: '#000000'}}/> :
                                <RiCloseCircleFill
                                    style={{width: '26px', height: '26px', color: 'rgba(0,0,0,.6)'}}/>}</Id>
                        </Items>
                        {renderProducts(index, val)}
                    </div>
                )
            });
        } else {
            return <NoItems/>
        }
    }


    const renderBoxOrLoading = () => {
        if (state?.i18n?.newsletter?.box1) {
            return (
                <>
                    <Box>
                        <span>{state?.i18n?.newsletter?.box1}</span>
                        <strong>{state?.newsletterCards?.registeredOrders}</strong>
                    </Box>
                    <Box>
                        <span>{state?.i18n?.newsletter?.box2}</span>
                        <strong>{state?.newsletterCards?.sentEmails}</strong>
                    </Box>
                    <Box>
                        <span>{state?.i18n?.newsletter?.box3}</span>
                        <strong>{state?.newsletterCards?.openRate}%</strong>
                    </Box>
                    <Box>
                        <span>{state?.i18n?.newsletter?.box4}</span>
                        <strong>{state?.newsletterCards?.conversionRate}%</strong>
                    </Box>
                </>
            )
        } else {
            let items = [];

            for (let i = 0; i < 4; i++) {
                items.push(<LoaderBox key={i} from={'boxnews'}/>)
            }
            return <>{items}</>;
        }
    }


    const renderContainerInfos = () => {
        if (state?.i18n?.newsletter?.table1) {
            return (
                <>
                    <Container area="listheaderNewsletter">
                        <ListHeaderItem width='12' porcentage={true}>
                            <ListItem
                                act={state?.orderNewsletterId === 1 && (state?.orderNewsletterItem || !state?.orderNewsletterItem)}
                                onClick={() => handleOrder('transactionId', 1)}>{state?.i18n?.newsletter?.table1}<Arrow
                                flip={state?.orderNewsletterId === 1 && state?.orderNewsletterItem}>{renderArrow()}</Arrow></ListItem>
                        </ListHeaderItem>
                        <ListHeaderItem width='10' porcentage={true}>
                            <ListItem
                                act={state?.orderNewsletterId === 0 && (state?.orderNewsletterItem || !state?.orderNewsletterItem)}
                                onClick={() => handleOrder('transactionDate', 0)}>{state?.i18n?.newsletter?.table2}<Arrow
                                flip={state?.orderNewsletterId === 0 && state?.orderNewsletterItem}>{renderArrow()}</Arrow></ListItem>
                        </ListHeaderItem>
                        <ListHeaderItem width='10' porcentage={true}>
                            <ListItem
                                act={state?.orderNewsletterId === 2 && (state?.orderNewsletterItem || !state?.orderNewsletterItem)}
                                onClick={() => handleOrder('status', 2)}>{state?.i18n?.newsletter?.table4}<Arrow
                                flip={state?.orderNewsletterId === 2 && state?.orderNewsletterItem}>{renderArrow()}</Arrow></ListItem>
                        </ListHeaderItem>
                        <ListHeaderItem width='10' porcentage={true}>
                            <ListItem
                                act={state?.orderNewsletterId === 3 && (state?.orderNewsletterItem || !state?.orderNewsletterItem)}
                                onClick={() => handleOrder('emailSentDate', 3)}>{state?.i18n?.newsletter?.table5}<Arrow
                                flip={state?.orderNewsletterId === 3 && state?.orderNewsletterItem}>{renderArrow()}</Arrow></ListItem>
                        </ListHeaderItem>
                        <ListHeaderItem width='10' porcentage={true}>
                            <ListItem
                                act={state?.orderNewsletterId === 4 && (state?.orderNewsletterItem || !state?.orderNewsletterItem)}
                                onClick={() => handleOrder('opened', 4)}>{state?.i18n?.newsletter?.table6}<Arrow
                                flip={state?.orderNewsletterId === 4 && state?.orderNewsletterItem}>{renderArrow()}</Arrow></ListItem>
                        </ListHeaderItem>
                        <ListHeaderItem width='10' porcentage={true}>
                            <ListItem
                                act={state?.orderNewsletterId === 5 && (state?.orderNewsletterItem || !state?.orderNewsletterItem)}
                                onClick={() => handleOrder('attempts', 5)}>{state?.i18n?.newsletter?.table7}<Arrow
                                flip={state?.orderNewsletterId === 5 && state?.orderNewsletterItem}>{renderArrow()}</Arrow></ListItem>
                        </ListHeaderItem>
                        <ListHeaderItem width='10' porcentage={true}>
                            <ListItem
                                act={state?.orderNewsletterId === 6 && (state?.orderNewsletterItem || !state?.orderNewsletterItem)}
                                onClick={() => handleOrder('review', 6)}>{state?.i18n?.newsletter?.table8}<Arrow
                                flip={state?.orderNewsletterId === 6 && state?.orderNewsletterItem}>{renderArrow()}</Arrow></ListItem>
                        </ListHeaderItem>
                        <ListHeaderItem width='10' porcentage={true}>
                            <ListItem
                                act={state?.orderNewsletterId === 7 && (state?.orderNewsletterItem || !state?.orderNewsletterItem)}
                                onClick={() => handleOrder('nps', 7)}>{state?.i18n?.newsletter?.table9}<Arrow
                                flip={state?.orderNewsletterId === 7 && state?.orderNewsletterItem}>{renderArrow()}</Arrow></ListItem>
                        </ListHeaderItem>
                    </Container>
                </>
            )
        } else {
            return (
                <LoaderBox from={'barinfos'}/>
            )
        }
    }

    return useMemo(() => {
        return (
            <>
                <HeaderFilter/>
                <Container area='actions-insights' isNewsletter={state.actualRoute === 'newsletter'}>
                    <ListFiltersSearch/>
                </Container>
                <Container>
                    <Boxes>
                        {renderBoxOrLoading()}
                    </Boxes>
                </Container>
                <Container>
                    <Pagination/>
                </Container>
                <ContentUsers>
                    {renderContainerInfos()}
                </ContentUsers>
                <Container area='newsletters'>
                    {!state?.isLoad ? <LoaderInfos/> : renderNewsletters()}
                </Container>
                <Container>
                    <Pagination/>
                </Container>
            </>
        );
    }, [state.i18n, state?.newsletters, state.actualRoute, state.newsletterCards, state.isLoad, listProducts, state.filters, state.orderNewsletterId, state.orderNewsletterItem])
}

export default Newsletter;
