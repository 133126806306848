import React, { useContext, useEffect, useState, useRef } from 'react';
import { ContextReducer } from '../../../../reducer/AdminReducer.js';
import { Container } from './styles.js';

const BoxMessage = ({ config }) => {
    const { state } = useContext(ContextReducer);
    const [ configs, setConfigs] = useState({bounceMinDays: state?.config?.ecommerce?.bounceMinDays || 0, maxAttempts: state?.config?.ecommerce?.maxAttempts || 0 });

    const _i18nTitleBox = state?.i18n?.configuration?.newsletter?.titleBoxMessage;
    const firstrenderRef = useRef(true);

    useEffect(() => {
        if(firstrenderRef.current) {
            firstrenderRef.current = false;
            return
        }

        setConfigs({
            bounceMinDays: parseInt(config.bounceMinDays) || state?.config?.ecommerce?.bounceMinDays,
            maxAttempts: config.maxAttempts || state?.config?.ecommerce?.maxAttempts
        })


        return () => {
            setConfigs({bounceMinDays: state?.config?.ecommerce?.bounceMinDays || 0, maxAttempts: state?.config?.ecommerce?.maxAttempts || 0 });
        }
    }, [config.bounceMinDays, config.maxAttempts, state?.config?.ecommerce?.maxAttempts, state?.config?.ecommerce?.bounceMinDays])



    const calcMonths = () => {
        return (configs.bounceMinDays && configs.maxAttempts) ? Math.round((configs.bounceMinDays * configs.maxAttempts) / 31) : 0;
    }

    return (
        <Container>
            <span>{_i18nTitleBox.part1}</span>
            <strong>{calcMonths() + (calcMonths() <= 1 ? _i18nTitleBox.oneMonth : _i18nTitleBox.months)}</strong>
            <span>{_i18nTitleBox.part2}</span>
        </Container>
    );
};

export default BoxMessage;
