import React, { useContext } from 'react';
import { Container } from './styles.js';
import ButtonIntegrate from './ButtonIntegrate';
import { ContextReducer } from '../../../../../reducer/AdminReducer.js';
import Status from './Status/index.js';

const ContainerButtonAndStatus = ({ storeName }) => {
    const { state } = useContext(ContextReducer);
    const configTokenMkt = state?.config?.ecommerce[`${state?.integratedStores?.id}Token`];

    return (
        <Container>
            {(configTokenMkt && configTokenMkt !== 'false') &&
                <Status storeName={storeName} />
            }
            <ButtonIntegrate />
        </Container>
    );
};

export default ContainerButtonAndStatus;
