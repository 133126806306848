import React from 'react';
import Radio from '../Radio';
import { Label, SubBlock, Name, Block } from '../../styles';
import { Container } from './styles.js';

const Wrapper = ({ array, defaultChecked, name, placeholder = '', placeHolderFunc, title, from = '' }) => {

    const renderRadios = () => {
        return array?.map(item => {
            return (
                <Label
                    key={item}
                    from={from}
                >
                    <Radio
                        value={item}
                        defaultChecked={defaultChecked?.toString() === item}
                        name={name}
                    />
                    <span>
                        {(name !== 'bounceMinDays' ? item : parseInt(item) / 7) + ' ' + (name === 'bounceMinDays' ? placeHolderFunc(parseInt(item / 7).toString()) : placeholder)}
                    </span>
                </Label>
            )
        })
    }

    return (
        <Container from={from}>
            <Block from={'emailConfigs'}>
                <Name>{title}</Name>
                <SubBlock>
                    {renderRadios()}
                </SubBlock>
            </Block>
        </Container>
    );
};

export default Wrapper;
