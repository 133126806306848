import React, {useContext} from 'react';
import {GrClose} from 'react-icons/gr';
import {IconClose} from './styles';
import {setModal} from '../../../actions/ReviewsAction';
import {ContextReducer} from '../../../reducer/AdminReducer';

const ButtonClose = () => {
    const {state, dispatch} = useContext(ContextReducer);
    return (
        <IconClose onClick={() => setModal(dispatch, {index: '', status: false})}>
            <GrClose style={{height: '20px', width: '20px', color: 'rgba(0,0,0,.6)'}}/>
        </IconClose>
    );
}

export default ButtonClose;
