import React, {useContext} from 'react';
import {Container} from './styles';
import {ContextReducer} from '../../../reducer/AdminReducer';

const ButtonAddFilter = (props) => {
    const {state} = useContext(ContextReducer);
    const filterhasValue = state?.filters?.listFiltersHeader[state?.filters?.orderSelectSearch];

    const renderText = () => {
        return state?.i18n?.AddListHeaderButtonText;
    }


    return (
        <Container
            active={filterhasValue}
            onClick={props.onAddHeaderfilter}
        >
            {renderText()}
        </Container>
    );
};

export default ButtonAddFilter;
