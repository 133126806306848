import styled, {css} from 'styled-components';

export const ButtonExport = styled.div`
    width: 190px;
    height: 55px;
    margin-right: 15px;
    border-radius: 5px;
    background:#000000;
    color:#fff;
    font-weight: bold;
    cursor:pointer;
    transition:all .2s ease-out;
    border:2px solid #000000;
    position: relative;
    text-align: center;
    line-height: 50px;
    font-size: 14px;

    ${props => props.inactive && css`
        background-color: #D6D8E0;
        color: #919191;
        border:2px solid #D6D8E0;
        cursor: not-allowed;
    `}

    &:hover{
        border:2px solid #000000;
        background:#fff;
        color:#000000;

        ${props => props.inactive && css`
            background-color: #D6D8E0;
            color: #919191;
            border:2px solid #D6D8E0;
        `}
    }
`

export const ButtonLoadingExport = styled.div`
    border:2px solid #FFA100;
    width: 1px;
    height: 100%;
    border-radius: 5px;
    position: absolute;
    transition:all .2s ease;
    background-color: #FFA100;
    top: -2px;
    left: -2px;
`

export const LogoClientContent = styled.div`
    max-width: 160px;
    position: relative;
`

export const LogoClientContentName = styled.div`
    max-width: 300px;
    position: relative;
    width: 100%;
`

export const LogoClient = styled.img`
    width: 100%;
    height: auto;
`

export const Html = styled.html`
    display: none;
`

export const Table = styled.table`
    display: none;
`

export const ContentFilters = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 55px;

    @media (max-width: 768px) {
        flex-wrap: wrap;
        align-items: flex-start;
        margin-top: 15px;
        padding: 10px;
        height: auto;
    }
`

export const TitleStores = styled.h4`
    font-size: 30px;
    color: #000;
    font-weight: bold;
    margin: 0;
    padding: 0;
`
export const Name = styled.h3`
    font-size: 30px;
    font-weight: bold;
    line-height: 40px;
    color: #000;
    position: relative;
    white-space: normal;
    display: inline-block;
`

export const Error = styled.span`
    color: #FF4421;
    font-size: 12px;
    font-weight: normal;
    white-space: nowrap;
`

export const ContentHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
