import React, {useContext, useEffect, useRef} from 'react';
import {ContentFilters, ContentHeader, TitleStores} from './styles';
import {ContextReducer} from "../../reducer/AdminReducer";
import {Container} from "../../Containers/styles";
import ExportTable from '../ExportTable';
import Input from "../Input";
import Select from "../Select";
import Period from "../Period";
import LoaderBox from '../LoaderBox';
import SelectOrderNps from '../SelectOrderNps';
import HeaderTypeTitle from './HeaderTypeTitle';
import ListFiltersSearch from '../Filter/ListFiltersSearch';
import { setVisible } from '../../actions/ReviewsAction';
import BoxFIlterInput from '../BoxFIlterInput';

const HeaderFilter = () => {
    const {state, dispatch} = useContext(ContextReducer);
    const hasAbasTabs = () => state?.i18n?.tagsAbas;
    const hasStoresTitle = () => state?.i18n?.stores?.title;
    const hasUsersTitle = () => state?.i18n?.users;
    const refHeaderFilter = useRef();


    useEffect(() => {
        const handleScroll = () => {
            if(document.body.scrollTop > 97.4) {
                setVisible(dispatch, true)
            } else if(state.visible && document.body.scrollTop === 0) {
                setVisible(dispatch, false)
            }
        }

        document.body.addEventListener('scroll', handleScroll);

        return () => {
            document.body.removeEventListener('scroll', handleScroll);
        };
    }, [state.visible])


    const renderStoresHeader = () => {
        return (
            <ContentHeader>
                {hasStoresTitle() ?
                    <TitleStores>{state?.i18n?.stores?.title}</TitleStores> :
                    <LoaderBox from={'title'}/>
                }
                {hasStoresTitle() ?
                    <Input/> :
                    <LoaderBox from={'input'}/>
                }
            </ContentHeader>
        )
    }

    const renderBoxFilterInput = () => {
        if (state.actualRoute === 'stores') {
            return <></>
        } else {
            return (hasAbasTabs() || hasUsersTitle()) ? <BoxFIlterInput/> : <LoaderBox from={'box'}/>
        }
    }

    const renderExportTable = () => {
        if (state.actualRoute !== 'stores') {
            return (hasAbasTabs() || hasUsersTitle()) ? <ExportTable/> : <LoaderBox from={'button'}/>
        } else {
            return <></>
        }
    }


    const renderSelect = () => {
        if (state.actualRoute === 'stores' && state.actualRoute === 'newsletter' && state.actualRoute === 'insights') {
            return <></>
        } else {
            return (hasAbasTabs() || hasUsersTitle()) ? <Select nomargin={true}/> : <LoaderBox from={'select'}/>
        }
    }

    const renderFilters = () => {
        return (
            <ContentFilters isNps={state.actualRoute === 'nps'}>
                {state.actualRoute === 'nps' && (state?.i18n?.tagsAbas?.nps?.headerFilterExperience?.showAll ?
                    <SelectOrderNps/> :
                    <LoaderBox from={'select'}/>)}
                { state.actualRoute !== 'insights' && renderBoxFilterInput()}
                {state.actualRoute !== 'stores' && renderSelect()}
                { state.actualRoute !== 'stores' && <Period i18n={state?.i18n} visible={state?.visible} />}
                { state.actualRoute !== 'insights' && renderExportTable()}
                { (state.actualRoute === 'insights' && state.visible) && renderExportTable()}
            </ContentFilters>
        )
    }

    return (
        <Container
            area='actions'
            isStore={state.actualRoute === 'stores'}
            visible={state?.visible}
            ref={refHeaderFilter}
        >
            {state.actualRoute === 'stores' ? renderStoresHeader() : <HeaderTypeTitle/>}
            {state?.visible && <ListFiltersSearch />}
            {renderFilters()}
        </Container>
    );
}


export default HeaderFilter;
