import {Navigate, Outlet} from "react-router-dom";
import React, {useContext, useMemo } from "react";
import {ContextReducer} from "../../reducer/AdminReducer";
import useRefreshToken from "../../hooks/useRefreshToken";

import Wrapper from '../Wrapper'

const Auth = () => {
    const {state} = useContext(ContextReducer);

    useRefreshToken('auth');

    return useMemo(() => {
        if (!state.userInfo.userId) {
            return <Navigate to={"/login"}/>
        }

        return (
            <Wrapper>
                <Outlet/>
            </Wrapper>
        )
    }, [state.userInfo, state.actualRoute]);
}

export default Auth;
