import styled, { css } from 'styled-components';

export const Container = styled.button`
    width: 117px;
    height: 50px;
    background: #000000;
    color: #fff;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    border: 2px solid #000;
    cursor: pointer;

    :hover {
        border: 2px solid #000;
        background-color: #fff;
        color: #000;
        transition: all ease-in-out 0.5s;
    }

    ${props => props.type === 'change' && css`
        border: 2px solid #000;
        color: #000;
        background-color: #fff;
        margin-left: 9.33px;

        :hover {
            background: #000000;
            color: #fff;
        }
    `}
`
