import React, { useContext } from 'react';
import { ContextReducer } from '../../../reducer/AdminReducer';
import { Image } from '../styles';
import { Wrapper, IconSellerPhotoNps, NoPhoto } from './styles';
import { ListHeaderItem } from '../../../Containers/styles';

import { ImTruck } from 'react-icons/im';
import { BiLaptop } from 'react-icons/bi';
import { MdOutlineDangerous } from "react-icons/md";

import getTransformDpr from "../../../utils/TransformDpr";
import renderInitials from "../../../utils/renderInitials";

const ImageProductOrSeller = (props) => {
    const { state } = useContext(ContextReducer);

    const { productInfo, npsType, productIdPicture } = props.values;

    const renderImageProductOrSellerImage = () => {
        let _style = {};

        if(state.actualRoute !== 'nps') {
            _style.background = `url(${productInfo?.image}) center center / contain no-repeat`
            return productInfo?.image ? <Image style={_style} /> : <NoPhoto><MdOutlineDangerous />{state?.i18n?.listItem.noImage}</NoPhoto>

        } else {
            if(npsType === 'purchase') {
                _style.background = `url(https://${state.userInfo.ecommerce.domain}/image/upload/t_favicons_dpr_${getTransformDpr(window.devicePixelRatio)}/f_auto/${state.config?.ecommerce?.authorInfo?.picture.id.length > 1 ? state.config?.ecommerce?.authorInfo?.picture.id : false}.jpeg) center center / contain no-repeat`;
            } else if(npsType !== 'purchase' && productIdPicture ) {
                _style.background = `url(${productIdPicture}) center center / contain no-repeat`
            } else if (npsType === 'delivery' && !productInfo?.seller) {
                return ''
            }

            return (
                <Image isNps={state.actualRoute === 'nps'} style={_style} isPurchase={ npsType === 'purchase' }>
                    {(npsType !== 'purchase' && !_style.background) && <strong>{renderInitials(productInfo?.seller)}</strong>}
                </Image >
            );
        }
    }

    const renderIconSeller = () => {
        if(state.actualRoute === 'nps') {
            return (
                <IconSellerPhotoNps>
                    { npsType === 'delivery' ? <ImTruck style={{ width: '18px', height: '16px'}} /> : <BiLaptop style={{ width: '20px', height: '20px' }} />}
                </IconSellerPhotoNps>
            )
        }
    }
    return (
        <ListHeaderItem width='100'>
            <Wrapper>
                {renderImageProductOrSellerImage()}
                {renderIconSeller()}
            </Wrapper>
        </ListHeaderItem>
    );
};

export default ImageProductOrSeller;
