import styled, { css } from 'styled-components';

export const PillItem = styled.div`
    border: 1px solid rgba(195, 198, 205, .3);
    border-radius: 100px;
    color: #000;
    background-color: rgba(195, 198, 205, .3);
    font-size: 14px;
    line-height: 30px;
    padding: 0 22px;
    border-radius: 100px;
    margin: 0 5px 5px 0;
    text-transform: none;
    height: 30px;
    position: relative;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
`
export const CircleScoreOrCase = styled.div`
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 10px;

    ${props => props.recommend && css`
        background-color: rgba(113, 206, 79, 1);
    `}

    ${props => !props.recommend && css`
        background-color: rgba(252, 66, 29, 1);
    `}
`
