import styled from 'styled-components';

const Arrow = styled.div`
    transition: all .2s ease-out;
    width: 20px;
    height: 20px;
    background: #000000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 7px;
    cursor: pointer;

    &:hover {
        background: #243e79;
    }
`


export const Pag = styled.div`
    display: flex;
    justify-content: flex-end;
    height: auto;

    margin: 10px 0 0px 0;
    font-size: 14px ;
    padding-bottom: 5px;
`

export const ArrowLeft = styled(Arrow)`
    position: relative;
    svg {
        width: 20px;
        height: 20px;

        polyline {
            stroke: #fff;
        }
    }
`

export const ArrowRight = styled(Arrow)`
    position: relative;

    svg {
        width: 20px;
        height: 20px;
        polyline {
            stroke: #fff;
        }
    }
`


