import React from 'react';
import {Container, Content, ContentInactive, Item} from './styles';

const Stars = (props) => {

    const renderSvg = () => {
        return (
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                <defs></defs>
                <polygon className='cls-1' fillRule='evenodd' fill='#FFA100'
                         points='12.95 15.62 19.28 19.64 17.4 12.3 23.2 7.49 15.71 7.04 12.95 0 10.18 7.04 2.58 7.49 8.37 12.3 6.49 19.66 12.95 15.62'/>
                <polygon className='cls-1' fillRule='evenodd' fill='#FFA100'
                         points='3.32 23.51 3.97 23.96 3.97 23.97 13.42 19.04 12.89 18.35 4.2 22.97 6.02 13.21 1.05 8.85 0.63 9.36 5.27 13.45 3.32 23.51'/>
            </svg>
        )
    }

    const renderSvgInactive = () => {
        return (
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                <defs></defs>
                <polygon className='cls-1' fillRule='evenodd' fill='#bababa'
                         points='12.95 15.62 19.28 19.64 17.4 12.3 23.2 7.49 15.71 7.04 12.95 0 10.18 7.04 2.58 7.49 8.37 12.3 6.49 19.66 12.95 15.62'/>
                <polygon className='cls-1' fillRule='evenodd' fill='#bababa'
                         points='3.32 23.51 3.97 23.96 3.97 23.97 13.42 19.04 12.89 18.35 4.2 22.97 6.02 13.21 1.05 8.85 0.63 9.36 5.27 13.45 3.32 23.51'/>
            </svg>
        )
    }

    const renderStars = () => {
        let _stars = [];

        for (let i = 0; i < props.rating; i++) {
            _stars.push(<Item key={i}>{renderSvg()}</Item>);
        }

        return _stars

    }

    const renderStarsInactive = () => {
        let _starsInactive = [];
        let _qnt = 5;

        for (let i = 0; i < _qnt; i++) {
            _starsInactive.push(<Item key={i}>{renderSvgInactive()}</Item>);
        }

        return _starsInactive
    }

    return (
        <Container nps={props.nps}>
            <Content>
                {renderStars()}
            </Content>
            <ContentInactive>
                {renderStarsInactive()}
            </ContentInactive>
        </Container>
    );
}

export default Stars;
