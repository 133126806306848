import React, {useContext, useState, useEffect} from 'react';

import {Container} from '../../Containers/styles';

import Tags from '../Tags';

import {
    Avatar,
    Bar,
    Column,
    ColumnRight,
    Comments,
    Content,
    ContentStore,
    Name,
    WrapperAvatar,
    WrapperLeftSide,
    Date
} from './styles'
import {ContextReducer} from "../../reducer/AdminReducer";
import {ReactComponent as SocialIsStore} from '../../assets/icon-isStore.svg';
import getTransformDpr from "../../utils/TransformDpr";
import ButtonsActions from '../ButtonsActions';

const AnswerList = (props) => {
    const {state} = useContext(ContextReducer);
    const renderListAnswer = () => {
        return props?.list?.map((val, i) => {
            return (
                <Content key={i}>
                    <WrapperLeftSide>
                        <Bar store={val?.is_store}/>
                        <Column>
                            <WrapperAvatar>
                                <Avatar
                                    url={`https://${state?.userInfo?.ecommerce.domain}/image/upload/${val.is_store ? 't_favicons' : 't_thumbs'}_dpr_${getTransformDpr(window.devicePixelRatio)}/f_auto/${val.authorInfo?.picture?.id}.jpeg`}/>
                                <Name>{val?.authorInfo?.name}</Name>
                                {val?.is_store &&
                                    <ContentStore>
                                        <SocialIsStore/>
                                        <span>{state?.i18n?.type?.is_store}</span>
                                    </ContentStore>
                                }
                            </WrapperAvatar>
                            <Comments>{val?.description}</Comments>
                        </Column>
                    </WrapperLeftSide>
                    <ColumnRight>
                        <Date>{val?.datePublished}</Date>
                        <ButtonsActions type={state?.actualRoute === 'reviews' ? 'comments' : 'answers'} item={val} status={val?.status} idx={i} />
                    </ColumnRight>
                </Content>
            )
        })
    }

    return (
        <Container area='listanswers' active={props.active}>
            {renderListAnswer()}
        </Container>
    )
}

export default AnswerList;
