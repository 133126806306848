import styled, {css} from 'styled-components';

export const ContainerFilter = styled.div`
    height: auto;
    width: 100%;
`
export const WrapperTags = styled.div`
    height: auto;
    width: 100%;
`
export const Abas = styled.div`
    display: flex;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(15, 28, 59, 0.3);
    position: relative;
    width: max-content;
`

export const Aba = styled.div`
    font-size: 16px;
    color: #000000;
    opacity: .5;
    text-transform: capitalize;
    cursor: pointer;
    margin-right: 40px;
    position: relative;

    &:last-child {
        margin-right: 0;
    }

    ${props => props.actived && css`
        opacity: 1;
        font-weight: bold;

        &:after {
            content: '';
            width: 100%;
            position: absolute;
            height: 3px;
            bottom: -12px;
            left: 0;
            background-color: #FFA100;
        }
    `}
`
