import React, { useRef, useEffect, useContext, useState, useMemo } from 'react';
import { Container, Content, MoreContentTrap, Wrapper, BoxFilter, Message } from './styles';
import More from '../../More';
import Pagination from '../../Pagination';
import { ContextReducer } from '../../../reducer/AdminReducer';
import TagsItem from './TagsItem';
import { setTagsHeader } from '../../../actions/ReviewsAction';
import { format } from 'date-fns';

import { ReactComponent as IconFilter } from '../../../assets/Icon-Filter.svg';
import useShouldRender from '../../../hooks/useShouldRender';
import useResizeObjectsTags from '../../../hooks/useResizeObjectsTags';

const ContentTags = (props) => {
    const { state, dispatch } = useContext(ContextReducer);
    const filtersRef = useRef();
    const [more, setMore] = useState(false);
    const [open, setOpen] = useState(null);
    const [searchingMore, setSearchingMore] = useState(null);
    const [conditional, setConditional] = useState(false);
    const buttonFilterRef = useRef(null);

    const { shouldRender } = useShouldRender(buttonFilterRef, conditional);

    const controller = new AbortController();
    const signal = controller.signal;

    const handleSearching = (value) => {
        setSearchingMore(value)
    }

    useResizeObjectsTags(filtersRef, open, 'sendReview', '', handleSearching);
    useResizeObjectsTags(filtersRef, open, 'sizeObj', setMore, '', setOpen);

    useEffect(() => {
        if(state?.totalTags?.tags?.length) {
            setConditional(!props.activeFilterTags && state?.totalTags?.tags?.length > 0);
        }
    }, [props.activeFilterTags, state?.totalTags?.tags?.length])

    useEffect(() => {
        setSearchingMore('');
    }, [state?.totalTags?.tags?.length ])

    useEffect(() => {
        const element = filtersRef.current;

        const handleFinalScroll = () => {
            if(open && (element.scrollHeight - element.scrollTop === element.clientHeight)) {
                if(state?.totalTags?.lastEvaluatedKey !== null) {
                    setTagsHeader(state.actualRoute, dispatch, state.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, state?.totalTags?.lastEvaluatedKey, signal, (state.rangePeriod[0].startDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', ( state.rangePeriod[0].endDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '', state?.tagsFilterHeader, state?.totalTags?.tags, handleSearching);
                } else if(state?.totalTags?.lastEvaluatedKey === null && element.scrollTop > 1) {
                    setSearchingMore('finalWithoutlast');
                }
            }
        }
        element.addEventListener('scroll', handleFinalScroll);

        return () => {
            element.removeEventListener('scroll', handleFinalScroll);
            controller.abort();
        };
    }, [open, state.actualRoute, state.filters, state.rangePeriod, state?.tagsFilterHeader, state?.totalTags?.tags, state?.userInfo?.ecommerce, state?.userInfo?.token, state?.totalTags?.lastEvaluatedKey]);

    useEffect(() => {
        if(filtersRef.current) {
            filtersRef.current.scrollTop = 0;
        }

        setOpen(false);
    }, [props.active])


    const handleOpen = () => {
        if (!open) {
            setOpen(true);
        } else {
            if(filtersRef.current){
                filtersRef.current.scrollTop = 0;
            }
            setOpen(false);
        }
    }

    const renderFilters = useMemo(() => {
        return state?.totalTags?.tags?.map((value, index) => {
            return (
                <TagsItem
                    key={index}
                    value={value}
                    setOpen={setOpen}
                    filtersRef={filtersRef}
                />
            )
        });
    }, [ state?.totalTags?.tags ])


    const handleMessage = () => {
        switch(searchingMore) {
            case 'finalWithoutlast':
                return state?.i18n?.filterTags?.endOfSearching;
            case 'searching':
                return state?.i18n?.filterTags?.searchingMoreTags;
            default:
                return ''
        }
    }


    return (
        <Container open={open}>
            <Content>
                <Wrapper
                    ref={filtersRef}
                    open={open}
                >
                    {shouldRender &&
                        <BoxFilter
                            ref={buttonFilterRef}
                            active={conditional}
                            onClick={() => props.setActiveFilterTags(true)}
                        >
                            <IconFilter/>
                        </BoxFilter>
                    }
                    {renderFilters}
                    {open && <Message>{handleMessage()}</Message>}
                </Wrapper>
                {more &&
                    <MoreContentTrap onClick={() => handleOpen()}>
                        <More open={open}/>
                    </MoreContentTrap>
                }
            </Content>
            <Pagination handlePagination={props.handlePagination}/>
        </Container>
    );
};

export default ContentTags;
