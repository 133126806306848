import React, {useContext, useState} from 'react';
import {ContextReducer} from '../../../reducer/AdminReducer';
import {Button, Container} from './styles'
import {setEmailAccess, setNotification} from '../../../actions/ReviewsAction';
import Item from './Item';

const WrapperInputsAccess = () => {
    const {state, dispatch} = useContext(ContextReducer);
    const [domain, setDomain] = useState('');
    const [personalEmail, setPersonalEmail] = useState('');

    const [domainChecked, setDomainChecked] = useState('');
    const [personalChecked, setPersonalChecked] = useState('');

    const handleValidations = (value, from) => {
        if (from === 'domain') {
            const domainRegex = /^@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return domainRegex.test(value);
        } else {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return emailRegex.test(value);
        }
    }

    const handleErrorMessage = (from) => {
        const _role = from;

        setNotification(dispatch, {status: true, role: _role});

        setTimeout(() => {
            setNotification(dispatch, {
                'status': false,
                'role': _role
            })
        }, 3000)
    }

    const handleResetInputsAndSuccessMessage = (from) => {
        if (from === 'personal') {
            setPersonalEmail('');
        } else {
            setDomain('')
        }

        handleCheckboxValue('', from);
    }

    const handleAddUserAccess = () => {
        if (!personalEmail && !domain) {
            handleErrorMessage('errorAccessInputEmpty');
            return
        }

        if (personalEmail) {
            if (handleValidations(personalEmail, 'personal')) {
                setEmailAccess(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, personalEmail, personalChecked, state.usersAccessList.page);
                handleResetInputsAndSuccessMessage('personal');
            } else {
                handleErrorMessage('errorPersonalEmailInvalid');
            }
        } else if (domain) {
            if (handleValidations(domain, 'domain')) {
                setEmailAccess(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, domain, domainChecked, state.usersAccessList.page);
                handleResetInputsAndSuccessMessage('domain')
            } else {
                handleErrorMessage('errorDomainInvalid');
            }
        }
    }

    const handleCheckboxValue = (e, from) => {

        if (from === 'domain') {
            if(e === '') {
                setDomainChecked('');
                return
            } else if (domainChecked === e.target.value) {
                setDomainChecked('')
                return
            } else if (e) {
                setDomainChecked(e.target.value);
            }
        } else {
            if(e === '') {
                setPersonalChecked('');
                return
            }
            else if (personalChecked === e.target.value) {
                setPersonalChecked('');
                return
            } else if (e) {
                setPersonalChecked(e.target.value);
            }
        }
    }

    const renderListItem = () => {
        const types = ['domain', 'personal'];
        let _arr = [];

        for(let i = 0; i < types.length; i++) {
            _arr.push(
                <Item
                    key={types[i]}
                    side={types[i]}
                    domain={domain}
                    setDomain={setDomain}
                    setPersonalEmail={setPersonalEmail}
                    personalEmail={personalEmail}
                    handleCheckboxValue={handleCheckboxValue}
                    personalChecked={personalChecked}
                    domainChecked={domainChecked}
                />
            )
        }

        return _arr;
    }

    return (
        <Container>
            {renderListItem()}
            <Button onClick={() => handleAddUserAccess()}>{state?.i18n?.peopleAccess?.addPeople}</Button>
        </Container>
    );
};

export default WrapperInputsAccess;
