import styled, {css} from 'styled-components';

export const Items = styled.div`
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 95%;
    position: relative;
`

export const Item = styled.div`
    display: flex;
    flex-flow: column;
    padding-top: 15px;
    position: relative;

    &:first-child {
        padding-top: 0;
    }

    > div {
        width: 100%;
        display: flex;
        align-items: center;
    }

    &:before {
        content: '';
        background-color: rgba(195, 198, 205, .2) !important;
        width: 2px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 15px;
        z-index: 1;
    }
`

export const Action = styled.strong`
    color: #000000 !important;
    font-weight: bold !important;
    font-size: 14px !important;
    display: block;
    margin-right: 5px;
`

export const Annotation = styled.span`
    font-size: 14px;
    color: rgba(0, 0, 0, .6) !important;
    display: block;
    margin-left: 40px;

`

export const Email = styled.span`
    color: #000000 !important;
    font-size: 14px !important;
    display: block;
    margin-right: 5px;
    text-transform: lowercase;
`

export const Date = styled.span`
    font-size: 11px !important;
    color: rgba(6, 7, 7, .4) !important;
    display: block;
`

export const Svg = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(237, 238, 240);
    margin-right: 10px;
    position: relative;
    z-index: 2;
`

export const Note = styled.textarea`
    width: 100%;
    height: 70px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #c3c6cd4d;
    margin-top: 30px;
    font-size: 14px;
`

export const Send = styled.button`
    background: #edeef0;
    color: #fff !important;
    margin-top: 15px;
    height: 30px;
    width: 100px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    border: none;
    border-radius: 5px;
    cursor: not-allowed;

    ${props => props.active && css`
        background-color: #000 !important;
        cursor: pointer;
    `}
`
