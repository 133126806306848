import styled, {css} from 'styled-components';

export const Container = styled.button`
    width: 90px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #000;
    background-color: #fff;
    border-radius: 5px;
    border: 0;
    font-weight: bold;
    margin: 0 10px;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all ease-in-out 0.5s;

    ${props => props.active && css`
        pointer-events: none;
        opacity: 0.4;
    `}

    :hover {
        color: #fff;
        background-color: #000;
        transition: all ease-in-out 0.5s;
    }
`
