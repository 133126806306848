import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;

    width: auto;
    height: 40px;
    margin-right: 15px;
    font-size: 18px;

    > svg {
        width: 41px;
        height: 41px;
        margin-right: 10px;

        path {
            fill: #000 !important;
            opacity: 1;
        }
    }

    > strong {
        margin-left: 10px;
    }
`
