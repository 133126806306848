import styled from 'styled-components';

export const AnswerReview = styled.div`
    width: 109px;
    height: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 20px;
    margin-top: 10px;

    span {
        font-size: 14px;
        font-family: Open-sans, arial;
        font-weight: bold;
        text-transform: uppercase;
        color: #FFA100;
        display: block;
        margin-left: 5px;
        display: block;
    }
`
