import React, {useContext} from 'react';
import {ContextReducer} from "../../reducer/AdminReducer";
import {Infos} from './styles';

const NoItems = props => {
    const {state} = useContext(ContextReducer);

    if (state.actualRoute === 'stores') {
        return (
            <Infos margin={props.nomargin}>{state?.i18n?.noItemsStore}</Infos>
        );
    } else {
        return (
            <Infos margin={props.nomargin}>{state?.i18n?.noItems}</Infos>
        );
    }


}

export default NoItems;
