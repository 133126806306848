import { createContext } from "react";

export const ContextReducer = createContext();

export const initialUserState = {
    userInfo: {
        "date": null,
        "ecommerceId": null,
        "email": null,
        "isAdmin": false,
        "token": null,
        "userId": null,
        "valid": false
    },
    pin: {
        email: null
    },
    statusSelectedAll: '',
    reviews: {
        lastEvaluatedKey: null,
        keys: [],
        items: [],
        page: 0,
        nextPage: 0
    },
    totalTags: {
        lastEvaluatedKey: null,
        tags: []
    },
    tagsFilterHeader: ['positive', 'negative', 'mixed', 'neutral', 'operator', 'user', 'system'],
    selectedHistoric: 0,
    historic: [],
    annotation: [],
    filters: {
        status: 'PENDING',
        order: 'newer',
        tags: null,
        last: '-1',
        orderUsers: 'newer',
        orderFeatured: 'reviews',
        sortFeatured: 'desc',
        listType: 'default',
        orderNewsletter: 'transactionDate',
        sortNewsletter: 'desc',
        orderSelectSearch: '',
        orderInputUser: '',
        orderSelectExperienceNps: '',
        listFiltersHeader: {},
    },
    modal: {
        index: '',
        status: false
    },
    selecteds: [],
    notification: {
        status: false,
        role: ''
    },
    actualRoute: window.location.pathname.split('/')[1] ? window.location.pathname.split('/')[1] : 'insights',
    formAnswer: {},
    success: false,
    users: {
        lastEvaluatedKey: null,
        keys: [],
        items: [],
        page: 0,
        nextPage: 0
    },
    newsletters: {
        lastEvaluatedKey: null,
        keys: [],
        items: [],
        page: 0,
        nextPage: 0
    },
    featured: {
        lastEvaluatedKey: null,
        keys: [],
        items: [],
        page: 0,
        nextPage: 0
    },
    ecommerce: {
        lastEvaluatedKey: null,
        keys: [],
        ecommerces: [],
        page: 0,
        nextPage: 0,
        totals: {
            store: 0,
            marketplaces: 0,
            mentions: 0,
            marketplace: 0
        },
    },
    attach: {
        index: '',
        status: false
    },
    isLoad: false,
    error: {
        email: false,
        pin: false,
        nouser: false
    },
    ecomName: null,
    exportData: {
        loading: false,
        done: false
    },
    buttonLoading: {
        loading: false,
        done: false
    },
    insights: {},
    newsletterCards: {},
    orderInsightsItem: true,
    orderInsightsId: 0,
    orderNewsletterId: 0,
    orderNewsletterItem: true,
    firstAccess: false,
    graphic: {},
    moreCards: null,
    actualArea: 'store',
    typeEcommerce: {},
    featuredType: 'featured-products',
    config: {
        ecommerce: {
            name: " ",
            store: false,
            domain: " ",
            availableFilters: [],
            authorInfo: {
                name: null,
                userId: " ",
                ppr: 0,
                sil: false,
                picture: {
                    id: " ",
                    url: " "
                },
                logoPicture: {
                    id: " ",
                    url: " "
                },
                emailLogoPicture: {
                    id: " ",
                    url: " "
                },
                useLogoAsEmailLogo: true,
            },
            transactionEmailConfig: {
                "enableEmail": true,
                "sendEmailInDays": 21,
                "style": {}
            },
            id: false,
            mentions: false,
            ecommerceId: false,
            marketplaces: false,
        },
    },
    configError: {
        name: false,
        emailLogoPicture: false,
        logoPicture: false,
        picture: false
    },
    typeChangeStore: 'store',
    searchName: null,
    previewEmail: "",
    usersAccessList: {
        items: [],
        lastEvaluatedKey: null,
        page: 0,
        keys: [],
        nextPage: 0,
        itemsPatch: {
            data: {},
        }
    },
    statusButtonAction: '',
    cx: {
        delivery: {
            topLikes: {
                '...': 0
            },
            topDislikes: {
                '...': 0
            }
        },
        purchase: {
            topLikes: {
                '...': 0
            },
            topDislikes: {
                '...': 0
            }
        }
    },
    visible: false,
    rangePeriod: [{}],
    shouldUpdatePeriodHeaderFilter: false,
    integratedStores: {},
    tagsConfiguration: [],
    tokenAuthMkt: '',
    modalConfirmationLang: {
        active: false,
        button: '',
    },
};

export const AdminReducer = (state, action) => {
    switch (action.type) {
        case 'SET_I18N':
            const _i18n = {...action.payload}
            return {...state, i18n: _i18n};
        case 'SET_USERID':
            return {...state, userInfo: action.payload};
        case 'SET_MODAL':
            return {...state, modal: action.payload};
        case 'SET_REVIEWS':
            const _reviews = action.payload;

            _reviews.page = Number(state.reviews.page + _reviews.nextPage);
            _reviews.keys = state.reviews.keys;

            if (_reviews.nextPage >= 0)
                _reviews.keys[_reviews.page + 1] = _reviews.lastEvaluatedKey;

                delete _reviews?.totalTags

            return {...state, reviews: _reviews};
        case 'SET_HISTORIC':
            let _historic = [...state.historic];

            _historic[action.payload.node] = action.payload.value;

            return {...state, historic: _historic}
        case 'SET_ANNOTATION':
            return {...state, historic: action.payload}
        case 'SET_FILTERS':
            state.reviews.keys = [];
            state.reviews.nextPage = 0;
            state.reviews.page = 0;
            state.newsletters.keys = [];
            state.newsletters.nextPage = 0;
            state.newsletters.page = 0;
            state.users.keys = [];
            state.users.nextPage = 0;
            state.users.page = 0;
            state.featured.keys = [];
            state.featured.nextPage = 0;
            state.featured.page = 0;
            state.ecommerce.keys = [];
            state.ecommerce.nextPage = 0;
            state.ecommerce.page = 0;

            const _newFilter = {...state.filters, ...action.payload}

            return {
                ...state,
                filters: _newFilter,
                newsletters: state.newsletters,
                reviews: state.reviews,
                users: state.users,
                featured: state.featured,
                ecommerce: state.ecommerce
            };
        case 'SET_STATUS':
            const _reviewsStatus = {...state.reviews};
            for (let value in _reviewsStatus.items) {
                for (let actValue in action.payload.items) {
                    if (_reviewsStatus.items[value].author === action.payload.items[actValue].author && _reviewsStatus.items[value].id === action.payload.items[actValue].id) {
                        _reviewsStatus.items[value] = {..._reviewsStatus.items[value], ...action.payload.items[actValue]}
                    }
                }
            }

            _reviewsStatus.totalTags = action.payload.totalTags

            return {...state, reviews: _reviewsStatus}

        case 'SET_ANSWER':
            const _questionReview = state.reviews;

            _questionReview.items.map((obj, index) => {

                if (obj.author === (action.payload.questionId || action.payload.reviewAuthor) && obj.id === action.payload.id) {

                    if (obj.childrenInfo) {
                        obj.childrenInfo.push(action.payload);

                        obj.childrenInfo = obj.childrenInfo.filter((value, index, self) => (
                            index === self.findIndex(t => (
                                t.authorInfo.email === value.authorInfo.email && t.authorInfo.name === value.authorInfo.name && t.description === value.description
                            ))
                        ))
                    }

                    return obj;
                }
            })
            return {...state, formAnswer: action.payload, reviews: {..._questionReview}};
        case 'SET_SELECTEDS':
            const {obj, checked} = action.payload;

            let _selecteds = [...state.selecteds];

            if (!checked) {
                _selecteds = _selecteds.filter(item => {
                   return item.author !== obj.author || item.id !== obj.id;
                })
            } else {
                _selecteds = [..._selecteds, obj];
            }

            return {...state, selecteds: _selecteds}
        case 'CLEAR_SELECTEDS':
            return {...state, selecteds: []}
        case 'SET_NOTIFICATION':
            return {...state, notification: action.payload}
        case 'SET_ROUTE':
            return {...state, actualRoute: action.payload}
        case 'SET_SUCCESS':
            return {...state, success: action.payload}
        case 'SET_USERS':
            const _users = action.payload;

            _users.page = Number(state.users.page + _users.nextPage);
            _users.keys = state.users.keys;

            if (_users.nextPage >= 0) {
                _users.keys[_users.page + 1] = _users.lastEvaluatedKey;
            }

            return {...state, users: _users}
        case 'SET_NEWSLETTERS':
            const _newsletters = action.payload;

            _newsletters.page = Number(state.newsletters.page + _newsletters.nextPage);
            _newsletters.keys = state.newsletters.keys;

            if (_newsletters.nextPage >= 0) {
                _newsletters.keys[_newsletters.page + 1] = _newsletters.lastEvaluatedKey;
            }

            return {...state, newsletters: _newsletters}
        case 'SET_FEATURED':
            const _featured = action.payload;
            _featured.page = state.featured.page + _featured.nextPage;
            _featured.keys = state.featured.keys;

            if (_featured.nextPage >= 0)
                _featured.keys[_featured.page + 1] = _featured.lastEvaluatedKey;

            return {...state, featured: _featured}
        case 'SET_ATTACH':
            return {...state, attach: action.payload}
        case 'GET_USERSACCESS':
            const _usersAccess = action.payload;

            _usersAccess.page = Number(state.usersAccessList.page + _usersAccess.nextPage);
            _usersAccess.keys = state.usersAccessList.keys;

            if (_usersAccess.nextPage >= 0) {
                _usersAccess.keys[_usersAccess.page + 1] = _usersAccess.lastEvaluatedKey;
            } else if (_usersAccess.nextPage < -1) {
                _usersAccess.nextPage -= _usersAccess.nextPage;
            }
            return {...state, usersAccessList: _usersAccess}
        case 'RESET_USERSACCESS':
            const _usersAccessZero = action.payload;
            _usersAccessZero.page = 0;
            _usersAccessZero.nextPage = 0;
            _usersAccessZero.keys = [];
            _usersAccessZero.lastEvaluatedKey = '';
            _usersAccessZero.items = [];
            _usersAccessZero.itemsPatch = {};

            return {...state, usersAccessList: _usersAccessZero}
        case 'SET_PATCHACCESS':
            return {
                ...state,
                usersAccessList: {
                    ...state?.usersAccessList,
                    itemsPatch: {
                        ...state?.usersAccessList?.itemsPatch,
                        data: {...state?.usersAccessList?.itemsPatch?.data, ...action.payload}
                    }
                }
            }
        case 'SET_ORDERINSIGHTSITEM':
            return {...state, orderInsightsItem: action.payload}
        case 'SET_ORDERNEWSLETTERITEM':
            return {...state, orderNewsletterItem: action.payload}
        case 'SET_ORDERINSIGHTSID':
            return {...state, orderInsightsId: action.payload}
        case 'SET_ORDERNEWSLETTERID':
            return {...state, orderNewsletterId: action.payload}
        case 'SET_ISLOAD':
            return {...state, isLoad: action.payload}
        case 'SET_PIN':
            return {...state, pin: action.payload}
        case 'SET_ERROR':
            return {...state, error: action.payload}
        case 'SET_ECOMM':
            const _ecomm = action.payload;

            _ecomm.page = state.ecommerce.page + _ecomm.nextPage;
            _ecomm.keys = state.ecommerce.keys;

            if (_ecomm.nextPage >= 0) {
                _ecomm.keys[_ecomm.page + 1] = _ecomm.lastEvaluatedKey;
            }

            return {...state, ecommerce: _ecomm}
        case 'GET_EXPORTDATA':
            return {...state, exportData: action.payload}
        case 'GET_LODING':
            return {...state, buttonLoading: action.payload}
        case 'GET_NEWSLETTERCARDS':
            return {...state, newsletterCards: action.payload}
        case 'GET_DASHBOARD':
            return {...state, insights: action.payload}
        case 'GET_GRAPHIC':
            return {...state, graphic: action.payload}
        case 'SET_FIRSTACCESS':
            return {...state, firstAccess: action.payload}
        case 'SET_MORECARDS':
            return {...state, moreCards: action.payload}
        case 'SET_CUSTOMER':
            return {...state, typeEcommerce: action.payload}
        case 'SET_ACTUALAREA':
            return {...state, actualArea: action.payload}
        case 'SET_FEATUREDTYPE':
            return {...state, featuredType: action.payload}
        case 'SET_ECOMNAME':
            return {...state, ecomName: action.payload}
        case 'SET_CONFIG':
            return {...state, config: action.payload}
        case 'SET_CONFIGERROR':
            return {...state, configError: action.payload}
        case 'SET_TYPECHANGESTORE':
            return {...state, typeChangeStore: action.payload}
        case 'SET_SEARCHNAME':
            return {...state, searchName: action.payload}
        case 'SET_EMAILPREVIEW':
            return {...state, previewEmail: action.payload}
        case 'SET_STATUSBUTTONACTION':
            return {...state, statusButtonAction: action.payload}
        case 'GET_CARDSCX':
            return {...state, cx: action.payload}
        case 'SET_VISIBLE':
            return {...state, visible: action.payload}
        case 'SET_RANGE':
            return {...state, rangePeriod: action.payload}
        case 'SET_SHOULD_UPDATE_PERIOD_FILTER':
            return {...state, shouldUpdatePeriodHeaderFilter: action.payload}
        case 'SET_INTEGRATEDSTORES':
            return {...state, integratedStores: action.payload}
        case 'SET_TAGSCONFIGURATION':
            return {...state, tagsConfiguration: action.payload}
        case 'SET_TOKENAUTHMKT':
            return {...state, tokenAuthMkt: action.payload }
        case 'SET_STATUS_SELECTALL':
            return {...state, statusSelectedAll: action.payload}
        case 'SET_MODAL_CONFIRMATION_LANG':
            return {...state, modalConfirmationLang: action.payload }
        case 'SET_TOTALTAGS':
            return {...state, totalTags: action.payload }
        case 'SET_TAGS_HEADER_FILTER':
            return {...state, tagsFilterHeader: action.payload }
        default:
            return state;
    }
};
