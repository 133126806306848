export const getDashboard = (dispatch, ecomm, token, filter, dateInitial = '', dateEnd = '', controllerSignal) => {

    let _url = `https://${ecomm.domain}/api/admin/intelligence/cards?last=${filter.last}&listType=${filter.listType}`;

    if(dateEnd !== '') {
        _url += `&dateEnd=${dateEnd}`
    }

    if(dateInitial !== ''){
        _url += `&dateInitial=${dateInitial}`
    }

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    if (controllerSignal) {
        requestOptions.signal = controllerSignal
    }

    fetch(_url, requestOptions).then(async (response) => {
        let resp = await response.json();

        dispatch({
            type: "GET_DASHBOARD",
            payload: resp
        })
    })
}

export const getGraphic = (dispatch, ecomm, token, dateInitial = '', dateEnd = '', controllerSignal) => {
    let _url = `https://${ecomm.domain}/api/admin/intelligence/graphics`;

    if(dateEnd !== '') {
        _url += `?dateEnd=${dateEnd}`
    }

    if(dateInitial !== ''){
        _url += `&dateInitial=${dateInitial}`
    }

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    if (controllerSignal) {
        requestOptions.signal = controllerSignal
    }

    fetch(_url, requestOptions).then(async (response) => {
        let resp = await response.json();

        dispatch({
            type: "GET_GRAPHIC",
            payload: resp
        })
    })
}

export const getHistoric = (dispatch, ecomm, token, id, author, type, index = 0) => {
    let _url = `https://${ecomm.domain}/api/admin/history?id=${id}&author=${author}&type=${type}`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    fetch(_url, requestOptions).then(async (response) => {
        let resp = await response.json();
        dispatch({
            type: "SET_HISTORIC",
            payload: {
                node: index,
                value: resp
            }
        })
    })
}

export const setAnnotation = (dispatch, ecomm, token, id, author, type, index = 0, annotation) => {
    let _url = `https://${ecomm.domain}/api/admin/history/add-annotation?id=${id}&author=${author}&type=${type}&annotation=${annotation}`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
    };

    fetch(_url, requestOptions).then(async (response) => {
        let resp = await response.json();

        dispatch({
            type: "SET_HISTORIC",
            payload: {
                node: index,
                value: resp
            }
        })
    })
}

export const getReviews = (type, dispatch, filter, ecomm, token, lastEvaluatedKey, nextPage = 0, controllerSignal, dateInitial = '', dateEnd = '') => {

    let _url = `https://${ecomm.domain}/api/admin/${type}?status=${filter.status}&order=${filter.order}&listType=${filter.listType}`;

    const limit = 100;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
    };

    if (controllerSignal) {
        requestOptions.signal = controllerSignal
    }

    if (nextPage !== 0 && lastEvaluatedKey) {
        _url += `&lastEvaluatedKey=${lastEvaluatedKey}`
    }

    if (filter.tag) {
        _url += `&originalTag=${filter.tag}&authorTag=${filter.authorTag}&sentimentTag=${filter.sentimentTag}`
    }


    if (filter.orderSelectSearch && filter.orderInputUser) {
        _url += `&${filter.orderSelectSearch}=${filter.orderInputUser}`
    }

    if (filter.orderSelectExperienceNps) {
        _url += `&npsType=${filter.orderSelectExperienceNps}`
    }

    if(dateEnd !== '') {
        _url += `&dateEnd=${dateEnd}`
    }

    if(dateInitial !== ''){
        _url += `&dateInitial=${dateInitial}`
    }

    if(limit) {
        _url += `&limit=${limit} `
    }

    if (Object.keys(filter.listFiltersHeader).length > 0) {
        for (const key in filter.listFiltersHeader) {
            if (key !== filter.orderSelectSearch && filter.listFiltersHeader[key].length > 0 && key !== 'period' && key !== 'tag') {
                _url += `&${key}=${filter.listFiltersHeader[key]}`
            }
        }
    }

    setIsLoad(dispatch, false);

    fetch(_url, requestOptions).then(async (response) => {
        let resp = await response.json();

        resp.nextPage = nextPage;

        setIsLoad(dispatch, true);

        dispatch({
            type: "SET_REVIEWS",
            payload: resp
        })
    }).catch((err) => {
        if(err instanceof DOMException && err.name === 'AbortError') {
            return;
        }
    })
}

export const getCardsCx = (dispatch, ecomm, token, filter, dateInitial = '', dateEnd = '', controllerSignal) => {
    let _url = `https://${ecomm.domain}/api/admin/cx/cards?listType=${filter.listType}`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    if (controllerSignal) {
        requestOptions.signal = controllerSignal
    }

    if (filter.tag) {
        _url += `&originalTag=${filter.tag}&authorTag=${filter.authorTag}&sentimentTag=${filter.sentimentTag}`
    }

    if (filter.orderSelectSearch && filter.orderInputUser) {
        _url += `&${filter.orderSelectSearch}=${filter.orderInputUser}`
    }

    if(dateEnd !== '') {
        _url += `&dateEnd=${dateEnd}`
    }

    if(dateInitial !== ''){
        _url += `&dateInitial=${dateInitial}`
    }

    if (Object.keys(filter.listFiltersHeader).length > 0) {
        for (const key in filter.listFiltersHeader) {
            if (key !== filter.orderSelectSearch && filter.listFiltersHeader[key].length > 0 && key !== 'period' && key !== 'tag') {
                _url += `&${key}=${filter.listFiltersHeader[key]}`
            }
        }
    }

    setIsLoad(dispatch, false);


    fetch(_url, requestOptions).then(async (response) => {
        let resp = await response.json();

        setIsLoad(dispatch, true);

        dispatch({
            type: "GET_CARDSCX",
            payload: resp
        })
    }).catch((err) => {
        if(err instanceof DOMException && err.name === 'AbortError') {
            return;
        }
    })
}

export const clearReviews = (dispatch) => {
    dispatch({
        type: "SET_REVIEWS",
        payload: {
            lastEvaluatedKey: null,
            keys: [],
            items: [],
            page: 0,
            nextPage: 0
        }
    })
}

export const clearFeatured = (dispatch) => {
    dispatch({
        type: "SET_FEATURED",
        payload: {
            lastEvaluatedKey: null,
            keys: [],
            items: [],
            page: 0,
            nextPage: 0
        }
    })
}

export const clearEcomm = (dispatch, obj) => {
    dispatch({
        type: "SET_ECOMM",
        payload: {
            lastEvaluatedKey: null,
            keys: [],
            ecommerces: [],
            page: 0,
            nextPage: 0,
            totals: {
                store: obj.store,
                marketplace: obj.marketplace,
                marketplaces: obj.marketplaces,
                mentions: obj.mentions,
            }
        }
    })
}

export const getEcomm = (dispatch, ecomm, token, listType = 'store', filters = false, name = null, lastEvaluatedKey, nextPage = 0, integration = false) => {

    let _url = `https://${ecomm.domain}/api/admin/ecommerces?${name ? `name=${name}&` : ''}listType=${listType}`;

    const limit = 100;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    if (nextPage !== 0 && lastEvaluatedKey) {
        _url += `&lastEvaluatedKey=${lastEvaluatedKey}`
    }

    if(integration) {
        _url += `&integration=true`
    }

    if(limit) {
        _url += `&limit=${limit} `
    }

    setIsLoad(dispatch, false);

    fetch(_url, requestOptions).then(async (response) => {
        let resp = await response.json();

        if (!filters) {
            let _filtered = resp.ecommerces.filter(ecommId => ecommId.ecommerceId === resp.ecommerceId)

            setEcomName(dispatch, _filtered[0].name)
        }

        resp.nextPage = nextPage;
        setIsLoad(dispatch, true);

        dispatch({
            type: "SET_ECOMM",
            payload: resp
        })
    })
}

export const getUsers = (dispatch, filter, ecomm, token, lastEvaluatedKey, nextPage = 0, controllerSignal, dateInitial = '', dateEnd = '' ) => {
    let _url = `https://${ecomm.domain}/api/admin/users?order=${filter.orderUsers}`

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const limit = 100;

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    if (controllerSignal) {
        requestOptions.signal = controllerSignal
    }

    if (nextPage !== 0 && lastEvaluatedKey) {
        _url += `&lastEvaluatedKey=${lastEvaluatedKey}`
    }

    if (filter.orderSelectSearch && filter.orderInputUser) {
        _url += `&${filter.orderSelectSearch}=${filter.orderInputUser}`
    }

    if(dateEnd !== '') {
        _url += `&dateEnd=${dateEnd}`
    }

    if(dateInitial !== ''){
        _url += `&dateInitial=${dateInitial}`
    }

    if(limit) {
        _url += `&limit=${limit} `
    }

    if (Object.keys(filter.listFiltersHeader).length > 0) {
        for (const key in filter.listFiltersHeader) {
            if (key !== filter.orderSelectSearch && key !== 'period') {
                _url += `&${key}=${filter.listFiltersHeader[key]}`
            }
        }
    }

    setIsLoad(dispatch, false);

    fetch(_url, requestOptions).then(async (response) => {
        let _resp = await response.json();
        setIsLoad(dispatch, true)
        _resp.nextPage = nextPage;

        dispatch({
            type: 'SET_USERS',
            payload: _resp
        })
    }).catch((err) => {
        if(err instanceof DOMException && err.name === 'AbortError') {
            return;
        }
    })

}

export const getNewsletterCards = (dispatch, ecomm, token, filter, controllerSignal, dateInitial = '', dateEnd = '') => {
    let _url = `https://${ecomm.domain}/api/admin/newsletter/cards?last=${filter.last}`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    if (controllerSignal) {
        requestOptions.signal = controllerSignal
    }

    if (filter.orderSelectSearch && filter.orderInputUser) {
        _url += `&${filter.orderSelectSearch}=${filter.orderInputUser}`
    }

    if(dateEnd !== '') {
        _url += `&dateEnd=${dateEnd}`
    }

    if(dateInitial !== ''){
        _url += `&dateInitial=${dateInitial}`
    }


    fetch(_url, requestOptions).then(async (response) => {
        let resp = await response.json();

        dispatch({
            type: "GET_NEWSLETTERCARDS",
            payload: resp
        })
    }).catch((err) => {
        if(err instanceof DOMException && err.name === 'AbortError') {
            return;
        }
    })
}


export const getNewsletters = (dispatch, filter, ecomm, token, lastEvaluatedKey = null, nextPage = 0, controllerSignal, dateInitial = '', dateEnd = '') => {
    let _url = `https://${ecomm.domain}/api/admin/newsletter?order=${filter.orderNewsletter}&sort=${filter.sortNewsletter}&last=${filter.last}`

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const limit = 100;

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    if (controllerSignal) {
        requestOptions.signal = controllerSignal
    }

    if (nextPage !== 0 && lastEvaluatedKey) {
        _url += `&lastEvaluatedKey=${lastEvaluatedKey}`
    }

    if (filter.orderSelectSearch && filter.orderInputUser) {
        _url += `&${filter.orderSelectSearch}=${filter.orderInputUser}`
    }

    if(dateEnd !== '') {
        _url += `&dateEnd=${dateEnd}`
    }

    if(dateInitial !== ''){
        _url += `&dateInitial=${dateInitial}`
    }

    if(limit) {
        _url += `&limit=${limit} `
    }

    if (Object.keys(filter.listFiltersHeader).length > 0) {
        for (const key in filter.listFiltersHeader) {
            if (key !== filter.orderSelectSearch && key !== 'period') {
                _url += `&${key}=${filter.listFiltersHeader[key]}`
            }
        }
    }

    setIsLoad(dispatch, false);

    fetch(_url, requestOptions).then(async (response) => {
        let _resp = await response.json();
        setIsLoad(dispatch, true)
        _resp.nextPage = nextPage;

        setIsLoad(dispatch, true);

        dispatch({
            type: 'SET_NEWSLETTERS',
            payload: _resp
        })
    }).catch((err) => {
        if(err instanceof DOMException && err.name === 'AbortError') {
            return;
        }
    })
}

export const getFeatured = (dispatch, filter, ecomm, token, lastEvaluatedKey = null, nextPage = 0, type = 'featured-products', dateInitial = '', dateEnd = '', controllerSignal ) => {
    let _url = `https://${ecomm.domain}/api/admin/intelligence/${type}?order=${filter.orderFeatured}&sort=${filter.sortFeatured}&listType=${filter.listType}&last=${filter.last}`

    const limit = 100;

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    if (controllerSignal) {
        requestOptions.signal = controllerSignal
    }

    if (nextPage !== 0 && lastEvaluatedKey) {
        _url += `&lastEvaluatedKey=${lastEvaluatedKey}`
    }

    if(dateEnd !== '') {
        _url += `&dateEnd=${dateEnd}`
    }

    if(dateInitial !== ''){
        _url += `&dateInitial=${dateInitial}`
    }

    if(limit) {
        _url += `&limit=${limit} `
    }

    setIsLoad(dispatch, false);

    fetch(_url, requestOptions).then(async (response) => {
        let _resp = await response.json();
        _resp.nextPage = nextPage;

        setIsLoad(dispatch, true);

        dispatch({
            type: 'SET_FEATURED',
            payload: _resp
        })
    })
}

export const getListUsersAccess = (dispatch, ecomm, token, lastEvaluatedKey = null, nextPage = 0, page) => {
    let _url = `https://${ecomm.domain}/api/admin/admin-users`

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    setIsLoad(dispatch, false);

    if (nextPage !== 0 && lastEvaluatedKey) {
        _url += `?lastEvaluatedKey=${lastEvaluatedKey}`
    }

    fetch(_url, requestOptions).then(async (response) => {
        let _resp = await response.json();
        _resp.nextPage = nextPage;

        setIsLoad(dispatch, true);

        dispatch({
            type: 'GET_USERSACCESS',
            payload: _resp
        })
    })
}

export const setEmailAccess = (dispatch, ecomm, token, emails, role = 'reader', page) => {

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const items = {
        "emails": [emails],
        "ecommerceId": ecomm.ecommerceId,
        "role": role ? role : 'reader'
    }

    const raw = JSON.stringify(items);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    let _url = `https://${ecomm.domain}/api/admin/add-admin-user`;

    fetch(_url, requestOptions)
        .then(response => response.json())
        .then(result => {
            result.nextPage = -page + 0;
            const _role = 'configAccessPost';

            dispatch({
                type: 'GET_USERSACCESS',
                payload: result
            })

            setNotification(dispatch, {status: true, role: _role});

            setTimeout(() => {
                setNotification(dispatch, {
                    'status': false,
                    'role': _role
                })
            }, 3000)
        })
        .catch(error => {
            const _role = 'errorOcurred';
            setNotification(dispatch, {status: true, role: _role});

            setTimeout(() => {
                setNotification(dispatch, {
                    'status': false,
                    'role': _role
                })
            }, 3000)
        });
}

export const editAccess = (dispatch, ecomm, token, data, keys, page) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const items = {
        "data": data,
        "ecommerceId": ecomm.ecommerceId,
    }

    const raw = JSON.stringify(items);

    const requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    let _url = `https://${ecomm.domain}/api/admin/add-admin-user`;

    if (keys[page]) {
        _url += `?lastEvaluatedKey=${keys[page]}`
    }

    fetch(_url, requestOptions)
        .then(response => response.json())
        .then(result => {
            const _role = 'configAccessPatch';
            result.nextPage = 0;

            dispatch({
                type: 'GET_USERSACCESS',
                payload: result
            })

            setNotification(dispatch, {status: true, role: _role});

            setTimeout(() => {
                setNotification(dispatch, {
                    'status': false,
                    'role': _role
                })
            }, 3000)
        })
        .catch(error => {
            const _role = 'errorOcurred';
            setNotification(dispatch, {status: true, role: _role});

            setTimeout(() => {
                setNotification(dispatch, {
                    'status': false,
                    'role': _role
                })
            }, 3000)
        });
}


export const changeReview = (type, dispatch, items, status, content, selectedStatus, token, ecomm, listtype, filter, selectAll) => {

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const _changedContent = {
        "pk": items
    }

    _changedContent[status] = content;
    _changedContent.selectedStatus = selectedStatus;
    _changedContent.listType = listtype;

    const raw = JSON.stringify(_changedContent);

    const requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    let _url = `https://${ecomm.domain}/api/admin/${type}`;

    if (filter.orderSelectSearch && filter.orderInputUser) {
        _url += `?${filter.orderSelectSearch}=${filter.orderInputUser}`
    }

    if(selectAll) {
        dispatch({
            type: 'SET_STATUS_SELECTALL',
            payload: _changedContent.status
        })
    }

    fetch(_url, requestOptions)
        .then(response => {
            if (response.status === 401) {
                throw new Error(response.status);
            }

            return response.json();
        })
        .then(result => {
            dispatch({
                type: 'SET_STATUS',
                payload: result
            })
        })
        .catch(error => {
            if (error.message === '401') {
                const _role = 'accessNotAllowed';

                setNotification(dispatch, {status: true, role: _role});
                setTimeout(() => {
                    setNotification(dispatch, {
                        'status': false,
                        'role': _role
                    })
                    setStatusButtonAction(dispatch, _role);
                }, 3000)
                return
            }
        });
}

export const setFilter = (dispatch, filter) => {
    clearChecked(dispatch);
    dispatch({
        type: 'SET_FILTERS',
        payload: filter
    })
}

export const setChecked = (dispatch, id, name, checked) => {
    const _obj = {
        'id': id,
        'author': name
    }

    dispatch({
        type: 'SET_SELECTEDS',
        payload: {
            obj: _obj,
            checked: checked
        }
    })
}

export const clearChecked = (dispatch) => {

    dispatch({
        type: 'CLEAR_SELECTEDS',
        payload: 'fodasse'
    })
}

export const setNotification = (dispatch, notification) => {
    dispatch({
        type: 'SET_NOTIFICATION',
        payload: notification
    })
}

export const setRoute = (dispatch, route) => {
    dispatch({
        type: 'SET_ROUTE',
        payload: route
    })
}

export const setAnswerForm = (dispatch, items, token, ecomm, type) => {

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const _changedContent = items;

    const raw = JSON.stringify(_changedContent);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    let _url;

    if (type === 'questions') {
        _url = `https://${ecomm.domain}/api/admin/answers`
    } else {
        _url = `https://${ecomm.domain}/api/admin/comments`
    }

    fetch(_url, requestOptions)
        .then(response => response.json())
        .then(result => {
            setSuccess(dispatch, true)
            dispatch({
                type: 'SET_ANSWER',
                payload: result
            })

            let _role;

            if (type === 'reviews') {
                _role = 'REVIEWS'
            } else {
                _role = 'ANSWER'
            }

            setNotification(dispatch, {status: true, role: _role});
            setTimeout(() => {
                setNotification(dispatch, {
                    'status': false,
                    'role': _role
                })

            }, 3000)
        })
        .catch(error => console.log('error ', error));
}

export const setLogin = (dispatch, obj, ecomm = 'ium') => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(obj);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    fetch(`https://vurdere.mais.social/api/admin/login`, requestOptions)
        .then(response => {
            if (response.status === 401) {
                throw new Error(response.status);
            }
            return response.json();
        })
        .then(result => {
            localStorage.setItem('vurdere_session', JSON.stringify(result))
            localStorage.setItem('vurdere_ecomm', JSON.stringify(result.ecommerce))

            dispatch({
                type: 'SET_USERID',
                payload: result
            })
        })
        .catch(error => {
            if (error.message === '401') {
                setError(dispatch, {email: false, pin: true})
            }
        });
}

export const setPin = (dispatch, email) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let _email = {
        'email': email
    }

    var raw = JSON.stringify(_email);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    fetch(`https://vurdere.mais.social/api/admin/sendpin`, requestOptions)
        .then(response => {
            if (response.status === 401) {
                throw new Error(response.status);
            }
            response.json();
        })
        .then(result => {
            const resp = {..._email, ...result};
            dispatch({
                type: 'SET_PIN',
                payload: resp
            })
            setError(dispatch, {email: false, pin: false, nouser: false, success: true})
        })
        .catch(error => {
            if (error.message === '401') {
                setError(dispatch, {email: true, pin: false, nouser: true})
            } else {
                setError(dispatch, {email: true, pin: false, nouser: false})
            }
        });
}

export const setModal = (dispatch, value) => {
    dispatch({
        type: 'SET_MODAL',
        payload: value
    })
}

export const setSuccess = (dispatch, value) => {
    dispatch({
        type: 'SET_SUCCESS',
        payload: value
    })
}

export const setAttachment = (dispatch, value) => {
    dispatch({
        type: 'SET_ATTACH',
        payload: value
    })
}

export const setIsLoad = (dispatch, value) => {
    dispatch({
        type: 'SET_ISLOAD',
        payload: value
    })
}

export const setError = (dispatch, value) => {
    dispatch({
        type: 'SET_ERROR',
        payload: value
    })
}

export const setOrderInsightsItem = (dispatch, value) => {
    dispatch({
        type: 'SET_ORDERINSIGHTSITEM',
        payload: value
    })
}

export const setOrderNewsletterItem = (dispatch, value) => {
    dispatch({
        type: 'SET_ORDERNEWSLETTERITEM',
        payload: value
    })
}

export const setOrderNewsletterId = (dispatch, value) => {
    dispatch({
        type: 'SET_ORDERNEWSLETTERID',
        payload: value
    })
}

export const setOrderInsightsId = (dispatch, value) => {
    dispatch({
        type: 'SET_ORDERINSIGHTSID',
        payload: value
    })
}

export const setUser = (dispatch, data) => {
    localStorage.setItem('vurdere_session', JSON.stringify(data));

    dispatch({
        type: 'SET_USERID',
        payload: data,
    })
}

export const setCachePin = (dispatch, data, user = false, handleToInsights) => {
    const _result = {
        ecommerce: data
    }

    localStorage.setItem('vurdere_ecomm', JSON.stringify(_result.ecommerce))

    let newUser = {...user, ..._result}

    if (user) setNewEcom(dispatch, _result.ecommerce, newUser, handleToInsights);
}


export const setNewEcom = (dispatch, ecomm, user, handleToInsights) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${user.token}`);
    let newEcom = {
        "newEcommerce": ecomm.id
    }

    const raw = JSON.stringify(newEcom);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    fetch(`https://${ecomm.domain}/api/admin/change-ecommerce`, requestOptions)
        .then(response => response.json())
        .then(result => {
            let _user = { ...user };

            if(user.isAdmin !== result.isAdmin){
                _user.isAdmin = result.isAdmin;
            }

            const _newUser = {..._user, token: result.token}

            setUser(dispatch, _newUser);
            setCustomer(dispatch, {...result.ecommerce})
            handleToInsights();
        })
        .catch(error => {
            setError(dispatch, {email: true, pin: false, nouser: true})
        });
}


export const setTagsHeader = (type, dispatch, filter, ecomm, token, lastEvaluatedKey, controllerSignal, dateInitial = '', dateEnd = '', filtersTagsHeader, stateTags, handleSearching) => {
    let _url = `https://${ecomm.domain}/api/admin/${type}-tags`;

    const limit = 50;
    const locale = 'ptBr';

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const _body = {};
    const _sentiments = ['positive', 'negative', 'mixed', 'neutral'];

    if(lastEvaluatedKey && handleSearching) handleSearching('searching');

    if(filtersTagsHeader){
        _body.sentimentTag = [];
        _body.authorTag = [];

        filtersTagsHeader.forEach(item => {
            if(_sentiments.includes(item)) {
                _body.sentimentTag = [..._body.sentimentTag, item];
            } else {
                _body.authorTag = [..._body.authorTag, item];
            }
        })
    }

    if (lastEvaluatedKey) _body.lastEvaluatedKey = lastEvaluatedKey;
    if(filter.orderSelectSearch && filter?.orderInputUser.length > 0) _body[filter?.orderSelectSearch] = filter?.orderInputUser;
    if(dateEnd !== '') _body.dateEnd = dateEnd;
    if(dateInitial !== '') _body.dateInitial = dateInitial;
    if(filter?.tag) _body.tag = filter.tag;
    if(filter?.order) _body.order = filter?.order;
    if(filter?.listType) _body.listType = filter?.listType;
    if(filter?.status) _body.status = filter?.status;
    if(limit) _body.limit = limit;
    if(locale) _body.locale = locale;

    if (Object.keys(filter.listFiltersHeader).length > 0) {
        for (const key in filter.listFiltersHeader) {
            if (key !== filter.orderSelectSearch && filter.listFiltersHeader[key].length > 0 && key !== 'period' && key !== 'tag') {
                _body[key] = filter.listFiltersHeader[key];
            }
        }
    }
    const raw = JSON.stringify(_body);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    };

    if (controllerSignal) {
        requestOptions.signal = controllerSignal
    }


    fetch(_url, requestOptions)
    .then(response => response.json())
    .then(result => {
        dispatch({
            type: 'SET_TOTALTAGS',
            payload: {
                tags: !lastEvaluatedKey ? result.totalTags : [...stateTags, ...result.totalTags],
                lastEvaluatedKey: result.lastEvaluatedKey
            }
        })

        if(lastEvaluatedKey && handleSearching) handleSearching('');
    })
    .catch((err) => {
        if(err instanceof DOMException && err.name === 'AbortError') {
            return;
        }
    })
}

export const setExportData = (dispatch, value) => {
    dispatch({
        type: 'GET_EXPORTDATA',
        payload: value
    })
}

export const setLoading = (dispatch, value) => {
    dispatch({
        type: 'GET_LODING',
        payload: value
    })
}

export const setFirstAccess = (dispatch, value) => {
    dispatch({
        type: 'SET_FIRSTACCESS',
        payload: value
    })
}

export const setMoreCards = (dispatch, value) => {
    dispatch({
        type: 'SET_MORECARDS',
        payload: value
    })
}

export const setCustomer = (dispatch, value) => {
    dispatch({
        type: 'SET_CUSTOMER',
        payload: value
    })
}

export const setActualArea = (dispatch, value) => {
    dispatch({
        type: 'SET_ACTUALAREA',
        payload: value
    })
}

export const setFeaturedType = (dispatch, value) => {
    dispatch({
        type: 'SET_FEATUREDTYPE',
        payload: value
    })
}

export const setEcomName = (dispatch, value) => {
    dispatch({
        type: 'SET_ECOMNAME',
        payload: value
    })
}


export const getConfig = (dispatch, ecomm, token) => {
    let _url = `https://${ecomm.domain}/api/admin/client?ecommerceId=${ecomm.id}`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    fetch(_url, requestOptions).then(async (response) => {
        let resp = await response.json();

        dispatch({
            type: "SET_CONFIG",
            payload: resp
        })
    })
}

export const getEmailPreview = (dispatch, ecomm, token, obj) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const params = new URLSearchParams(obj).toString();

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
    };


    fetch(`https://${ecomm.domain}/api/admin/email-preview?ecommerceId=${ecomm.id}&` + params, requestOptions).then(async (response) => {
        let resp = await response.json();
        dispatch({
            type: "SET_EMAILPREVIEW",
            payload: resp
        })
    })
}

export const sendConfigurantion = (dispatch, ecomm, token, items, name, config, warn, description, tags, url, tokenEcomm, mktInitials) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    const formData = new FormData();
    const params = new URLSearchParams({ecommerceId: ecomm.id})

    if(items) {
        if (items?.logoPicture || items?.logoPicture !== undefined) formData.append('logoPicture', items?.logoPicture)
        if (items?.profilePicture || items?.profilePicture !== undefined) formData.append('profilePicture', items?.profilePicture)
        if (items?.emailLogoPicture || items?.emailLogoPicture !== undefined) formData.append('emailLogoPicture', items?.emailLogoPicture)
        if(items?.locales) params.append('locales', items?.locales.join(','))
    }

    if (name) params.append('name', name)
    if (description) params.append('description', description)
    if (tags) params.append('tags', tags)
    if(url && url?.privacyPoliceUrl) params.append('privacyPoliceUrl', url?.privacyPoliceUrl)
    if(url && url?.termsOfUseUrl) params.append('termsOfUseUrl', url?.termsOfUseUrl)

    if(tokenEcomm && mktInitials) params.append(`${mktInitials}Token`, tokenEcomm);

    if(warn) {
        params.append('marketplacesChecked', warn)
    }

    if (config) {
        Object.entries(config).forEach((key) => {
            params.append(key[0], key[1])
        })
    }

    const requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        redirect: 'follow'
    };

    if (formData.has('logoPicture') ||
        formData.has('profilePicture') ||
        formData.has('emailLogoPicture') ||
        formData.has('logoAsEmailLogo')) {
        requestOptions.body = formData
    }

    fetch(`https://${ecomm.domain}/api/admin/client?` + params, requestOptions)
        .then(response => {
            if (response.status === 401 || response.status === 500) {
                throw new Error(response.status);
            }

            return response.json();
        })
        .then(result => {
            dispatch({
                type: 'SET_CONFIG',
                payload: result
            })


            setNotification(dispatch, {status: true, role: 'config'});
            setLoading(dispatch, {
                loading: false,
                done: true
            });

            setTimeout(() => {
                setNotification(dispatch, {
                    'status': false,
                    'role': 'config'
                })

                setLoading(dispatch, {
                    loading: false,
                    done: false
                });
            }, 3000)


        })
        .catch(error => {
            if (error.message === '401') {
                const _role = 'accessNotAllowed';

                setNotification(dispatch, {status: true, role: _role});
                setTimeout(() => {
                    setNotification(dispatch, {
                        'status': false,
                        'role': _role
                    })
                    setStatusButtonAction(dispatch, _role);
                }, 3000)
            } else if(error.message === '500') {
                const _role = 'errorOcurred';

                setNotification(dispatch, {status: true, role: _role});
                setTimeout(() => {
                    setNotification(dispatch, {
                        'status': false,
                        'role': _role
                    })
                    setStatusButtonAction(dispatch, _role);
                }, 3000)
            }
        });
}

export const sendEmailTest = (dispatch, ecomm, token, handleDone, handleSending, handleError) => {
    handleSending(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
    };

    fetch(`https://${ecomm.domain}/api/admin/email?ecommerceId=${ecomm.id}`, requestOptions)
    .then(response => {
        if (response.status === 401) {
            handleSending(false);
            throw new Error(response.status);
        }
        return response.json();
    })
    .then(() => {
        handleSending(false);
        handleDone(true);
        const _role = 'emailTestSended';
            setNotification(dispatch, {status: true, role: _role});

            setTimeout(() => {
                setNotification(dispatch, {
                    'status': false,
                    'role': _role
                })
                handleDone(false);
            }, 3000)
    })
    .catch(error => {
        handleError(true);

        if (error.message === '401') {
            const _role = 'accessNotAllowed';

            setNotification(dispatch, {status: true, role: _role});
            setTimeout(() => {
                setNotification(dispatch, {
                    'status': false,
                    'role': _role
                })
                setStatusButtonAction(dispatch, _role);
                handleError(false);
            }, 3000)
            return
        } else {
            const _role = 'errorOcurred';
            setNotification(dispatch, {status: true, role: _role});

            setTimeout(() => {
                setNotification(dispatch, {
                    'status': false,
                    'role': _role
                })
                handleError(false);
            }, 3000)
        }
    });
}

export const setConfigError = (dispatch, value) => {
    dispatch({
        type: 'SET_CONFIGERROR',
        payload: value
    })
}

export const setSearchName = (dispatch, value) => {
    dispatch({
        type: 'SET_SEARCHNAME',
        payload: value
    })
}

export const setTypeChange = (dispatch, value) => {
    dispatch({
        type: 'SET_TYPECHANGESTORE',
        payload: value
    })
}
export const setStatusButtonAction = (dispatch, value) => {
    dispatch({
        type: 'SET_STATUSBUTTONACTION',
        payload: value
    })
}

export const setVisible = (dispatch, value) => {
    dispatch({
        type: 'SET_VISIBLE',
        payload: value
    })
}

export const setRangePeriod = (dispatch, value) => {
    dispatch({
        type: 'SET_RANGE',
        payload: value
    })
}
export const setShouldUpdateRangeFilter = ( dispatch, value) => {
    dispatch({
        type: 'SET_SHOULD_UPDATE_PERIOD_FILTER',
        payload: value
    })
}

export const setIntegratedStores = ( dispatch, value) => {
    dispatch({
        type: 'SET_INTEGRATEDSTORES',
        payload: value
    })
}

export const setTagsConfiguration = ( dispatch, value) => {
    dispatch({
        type: 'SET_TAGSCONFIGURATION',
        payload: value
    })
}

export const setTokenAuthMkt = (dispatch, value) => {
    dispatch({
        type: 'SET_TOKENAUTHMKT',
        payload: value
    })
}
