import React from 'react';
import { Container, Roll } from './styles';

const Toggle = ({ active, from, handleClick }) => {
    return (
        <Container
            active={active}
            from={from}
            onClick={handleClick}
        >
            <Roll active={active} from={from} />
        </Container>
    );
};

export default Toggle;
