import React, { useContext, useState, useEffect } from 'react';

import { ContextReducer } from '../../../reducer/AdminReducer';

import {
    Name,
    TitleQuestion,
    NameQuestion,
    ContentQuestion,
    ContentStore,
    Small,
    Initials,
    TooltipCopy,
    EmailUser,
    Avatar,
    FlexCol
} from './styles';

import { Align, Flex } from '../styles';

import Verified from '../../Verified';
import Stars from '../../Stars';

import getTransformDpr from "../../../utils/TransformDpr";
import renderInitials from "../../../utils/renderInitials";
import renderName from "../../../utils/renderName";

import {ReactComponent as SocialIsStore} from '../../../assets/icon-isStore.svg';

const UserInfo = (props) => {
    const { state } = useContext(ContextReducer);

    const [activeIdCopy, setActiveIdCopy] = useState('');
    const [copy, setCopy] = useState(false);
    const [noEmail, setNoEmail] = useState(false);

    const { authorInfo, is_store, location, parentInfo, reviewRating, verified } = props.values;

    useEffect(() => {
        const timerCopy = setTimeout(() => {
            setCopy(false)
        }, 5000)

        return () => {
            clearTimeout(timerCopy);
        }
    }, [copy])

    const handleCopy = (email, index) => {
        if(email && email.length > 0) {
            navigator.clipboard.writeText(email);
            navigator.clipboard.readText().then(clipText => {
                if(clipText.length > 0) {
                    setActiveIdCopy(index)
                    setCopy(true)
                }
            })
            setNoEmail(false)
        } else {
            navigator.clipboard.writeText('');
            setActiveIdCopy(index)
            setCopy(true)
            setNoEmail(true)
        }
    }

    const renderTextSmall = () => {
        let _obj = {}
        if(state.actualRoute === 'answers') {
            _obj.small1 = 'Pergunta'
            _obj.small2 = 'Resposta'
        } else if (state.actualRoute === 'comments') {
            _obj.small1 = 'Review'
            _obj.small2 = 'Comentário'
        }

        return _obj
    }

    const renderEmailUser = () => {
        const locationText = location?.city ? state?.i18n?.seller?.email2 + location?.city : '';

        if(state.actualArea === 'store') {
            return (
                <EmailUser onClick={() => handleCopy(authorInfo.email, props.indexItem)}
                    isStore={state.actualArea === 'store'}
                >
                    {authorInfo.email ? authorInfo.email : state?.i18n?.seller?.email3}{locationText}
                </EmailUser>
            )
        }

        return (
            <EmailUser>
                {authorInfo.email ? state?.i18n?.seller?.email : state?.i18n?.seller?.email3}{locationText}
            </EmailUser>
        )
    }

    const renderAvatar = (url, name, questions, email, index, isStorie) => {
        let _styles = {};

        if(url){
            if(isStorie) {
                _styles.background = `url(https://${state?.userInfo?.ecommerce.domain}/image/upload/t_favicons_dpr_${getTransformDpr(window.devicePixelRatio)}/f_auto/${url}.jpeg) center center / contain no-repeat`;
            } else {
                _styles.background = `url(https://${state?.userInfo?.ecommerce.domain}/image/upload/t_thumbs_dpr_${getTransformDpr(window.devicePixelRatio)}/f_auto/${url}.jpeg) center center / contain no-repeat`;
            }

        }

        if(url && url !== ' ') {
            return <Avatar style={_styles} small={questions} onClick={() => (email, index)} />
        } else if (name && name !== ' ') {
            return <Initials small={questions} onClick={() => handleCopy(email, index)}>{renderInitials(name)}</Initials>
        } else {
            return <Initials small={questions} onClick={() => handleCopy(email, index)}></Initials>
        }
    }

    const renderTitleQuestion = () => {
        if(state.actualRoute === 'answers' || state.actualRoute === 'comments')
            return (
                <ContentQuestion>
                    <Flex>
                        {renderAvatar(parentInfo?.authorInfo?.picture?.id, parentInfo?.authorInfo?.name, parentInfo?.authorInfo?.email, true, props.indexItem, is_store)}
                        <NameQuestion>{renderName(parentInfo?.authorInfo?.name)}</NameQuestion>
                        <Small>{renderTextSmall().small1}</Small>
                    </Flex>
                    <Flex>
                        <TitleQuestion>{parentInfo?.description}</TitleQuestion>
                    </Flex>
                </ContentQuestion>
            )
    }

    const renderUserInfoQuestion = () => {
        return (
            <>
                {renderTitleQuestion()}
                {renderUserInfo()}
            </>
        )
    }

    const renderUserInfo = () => {
        return (
            <Align space='10' direction='bottom' posi={state.actualRoute === 'answers' || state.actualRoute === 'comments'} mobile={state.actualRoute === 'nps'}>
                {props.indexItem === activeIdCopy && copy && <TooltipCopy>{noEmail ? state?.i18n?.user?.noemail : state?.i18n?.user?.copy}</TooltipCopy>}
                <Flex isNps={state.actualRoute === 'nps'}>
                    {renderAvatar(authorInfo?.picture?.id, authorInfo?.name, false, authorInfo?.email, props.indexItem, is_store)}
                </Flex>
                <Flex flow={'column'}>
                    <FlexCol>
                        <Name onClick={() => handleCopy(authorInfo.email, props.indexItem)}>{renderName(authorInfo.name)}</Name>
                        {is_store && <ContentStore><SocialIsStore /><span>{state?.i18n?.type?.is_store}</span></ContentStore>}
                        {(state.actualRoute === 'reviews') && <Stars rating={reviewRating} />}
                        {verified && <Verified  />}
                    </FlexCol>
                    <FlexCol>
                        {renderEmailUser()}
                    </FlexCol>
                </Flex>
                {(state.actualRoute === 'answers' || state.actualRoute === 'comments') && <Small>{renderTextSmall().small2}</Small>}
            </Align>
        )
    }


    if(state.actualRoute === 'answers' || state.actualRoute === 'comments') {
        return renderUserInfoQuestion()
    }

    return renderUserInfo();
};

export default UserInfo;
