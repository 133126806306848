export const renderName = (name) => {
    if(name) {
        let _name = name.split(' ');
        let _namesplit = '';

        if(_name.length > 3 && _name[_name.length-1].length > 0) {
            _namesplit = _name[0] + ' ' + _name[_name.length-1];
        }
        else if (_name.length > 2 && _name[_name.length-1].length === 0) {
            _namesplit = _name[0] + ' ' + _name[1];
        } else {
            _namesplit = name;
        }

        return _namesplit;
    }
}

export default renderName;
