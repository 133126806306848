import styled from 'styled-components';

export const Container = styled.div`
    max-width: 100%;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    min-height: 45px;
    margin-bottom: 16px;

    > span {
        font-size: 12px;
        color: #000000;
        opacity: 0.5;
        margin-bottom: 10px;
    }
`
export const WrapperItems = styled.div`
    display: flex;
`
