import React, { useContext, useEffect, useState, useRef } from 'react';

import {setRangePeriod, setFilter, setShouldUpdateRangeFilter} from '../../actions/ReviewsAction'

import {
    addDays,
    endOfDay,
    addMonths,
    differenceInMonths,
    format,
    isToday,
  } from 'date-fns';

//react-date-range
import { DateRangePicker, createStaticRanges} from 'react-date-range';
import { pt } from '../../../node_modules/react-date-range/dist/locale';
import '../../../node_modules/react-date-range/dist/styles.css'; // main style file
import '../../../node_modules/react-date-range/dist/theme/default.css'; // theme css file


import { ContextReducer } from '../../reducer/AdminReducer';

import { AiOutlineCalendar } from "react-icons/ai";
import { Content, Button, BoxCalendar, ContentButtons, Buttons } from './styles';

const Period = ({i18n, visible}) => {

    const {state, dispatch} = useContext(ContextReducer);

    const filterhasValue = state?.filters?.listFiltersHeader['period'];

    const [open, setOpen] = useState(false);
    const [range, setRange] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        color: "#000",
        key: 'selection',
    }]);

    const componentSelectRef = useRef();

    useEffect(() => {
        const handleEnter = (event) => {
            if (open) {
                if (event.key === "Escape" || event.key === "Escape") {
                    setOpen(false)
                }
            }
        };

        window.addEventListener('keydown', handleEnter);

        return () => {
            window.removeEventListener('keydown', handleEnter);
        };

    }, [open])

    useEffect(() => {
        if(!state.shouldUpdatePeriodHeaderFilter || range[0].allMonths) return;

        let textCount;
        let calcDays = range[0].endDate - range[0].startDate;
        const countDays = Math.floor(calcDays / (1000 * 60 * 60 * 24));
        const countMonths = differenceInMonths(range[0].endDate, range[0].startDate);

        if(countMonths < 1){
            switch(countDays) {
                case 6:
                    textCount = i18n?.period?.calendar?.text2;
                break
                case 13:
                    textCount = i18n?.period?.calendar?.text3;
                break
                case 29:
                    textCount = i18n?.period?.calendar?.text4;
                break;
                default:
                    textCount = format(range[0].startDate, 'dd/MM/yyyy') + i18n?.period?.calendar?.to +  format(range[0].endDate, 'dd/MM/yyyy');
            }
        } else {
            switch(countMonths) {
                case 3:
                    textCount = i18n?.period?.calendar?.text5;
                break
                case 12:
                    textCount = i18n?.period?.calendar?.text6;
                break
                default:
                    textCount = format(range[0].startDate, 'dd/MM/yyyy') + i18n?.period?.calendar?.to +  format(range[0].endDate, 'dd/MM/yyyy');
            }
        }


        if(!filterhasValue && state.shouldUpdatePeriodHeaderFilter)  {
            const filter = {
                listFiltersHeader: { ...state?.filters?.listFiltersHeader, 'period': textCount }
            }

            setFilter(dispatch, filter);
            setShouldUpdateRangeFilter(dispatch, false);
        }

    }, [ state.rangePeriod, state?.filters?.listFiltersHeader ])


    useEffect(() => {
        const handleClickOutside = (e) => {
            if (componentSelectRef.current && !componentSelectRef.current.contains(e.target)) {
                setOpen(false);
            }
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };

    }, []);

    const handleOpenCalendar = () => {
        setOpen(!open)
    }

    const handleCancel = () => {
        setRange([
            {
                startDate: new Date(),
                endDate: new Date(),
                color: "#000000",
                key: 'selection'
            }
        ])

        setOpen(false)
    }


    const isTodayOnEndDay = (item) => {

    const startDateItem = item.startDate;
        const endDateItem = item.endDate;

        const startDateItemToFinalOfDay = endOfDay(startDateItem);
        const endDateItemToFinalOfDay = endOfDay(endDateItem);


        if(isToday(startDateItem) && isToday(endDateItem)) {
            if(endDateItemToFinalOfDay.getTime() === endDateItem.getTime() && startDateItemToFinalOfDay.getTime() === startDateItem.getTime()) {
                return true;
            } else {
                return false;
            }
        }

        return false;
    }

    const handleChange = (item) => {
        item.selection.allMonths = isTodayOnEndDay(item.selection);

        setRange([item.selection]);
    }


    const handleSelect = () => {
        if(range[0].allMonths && !state.filters.listFiltersHeader['period'] ) {
            setOpen(false);
            return
        }

        if(filterhasValue) {
            let _filters = {...state?.filters};
            let _filtersHeader = { ...state.filters.listFiltersHeader};

            const filter = {
                ..._filters,
                listFiltersHeader: _filtersHeader,
            }

            delete filter.listFiltersHeader['period'];
            setFilter(dispatch, filter);
        }


        setShouldUpdateRangeFilter(dispatch, true);
        setRangePeriod(dispatch, range);
        setOpen(false)
    }

    const renderStaticRangeLabel = () => {
        return (
            <></>
        )
    }

    const renderStaticRange = () => {
        let staticLabel;
        staticLabel = createStaticRanges([
            {
                label: i18n?.period?.calendar?.text1 + format(new Date(), 'dd/MM/yyyy'),
                range: () => ({
                    startDate: endOfDay(new Date()),
                    endDate: endOfDay(new Date()),
                    color: "#000000",
                }),
                },
                {
                label: i18n?.period?.calendar?.text2,
                range: () => ({
                    startDate: addDays(new Date(), -6),
                    endDate: endOfDay(new Date()),
                    color: "#000000"
                })
                },
                {
                label: i18n?.period?.calendar?.text3,
                range: () => ({
                    startDate: addDays(new Date(), -13),
                    endDate: endOfDay(new Date()),
                    color: "#000000"
                }),
                },
                {
                label: i18n?.period?.calendar?.text4,
                range: () => ({
                    startDate: addDays(new Date(), -29),
                    endDate: endOfDay(new Date()),
                    color: "#000000"
                }),
                },
                {
                label: i18n?.period?.calendar?.text5,
                range: () => ({
                    startDate: addMonths(new Date(), -3),
                    endDate: endOfDay(new Date()),
                    color: "#000000"
                })
                },
                {
                label: i18n?.period?.calendar?.text6,
                range: () => ({
                    startDate: addMonths(new Date(), -12),
                    endDate: endOfDay(new Date()),
                    color: "#000000"
                })
                }
        ])

        return staticLabel
    }

    return (
        <Content ref={componentSelectRef}>
            <Button
                onClick={handleOpenCalendar}
                isNewsletter={state.actualRoute === 'newsletter'}
                visible={visible}
            >
                <AiOutlineCalendar style={{width: '22px', height: '22px', marginRight: '5px'}} />
                {!visible && i18n?.period?.button}
            </Button>
            {open &&
                <BoxCalendar>
                    <DateRangePicker
                        onChange={handleChange}
                        locale={pt}
                        preventSnapRefocus={true}
                        moveRangeOnFirstSelection={false}
                        retainEndDateOnFirstSelection={true}
                        ranges={range}
                        maxDate={new Date()}
                        months={2}
                        direction="horizontal"
                        calendarFocus="backwards"
                        renderStaticRangeLabel={renderStaticRangeLabel}
                        staticRanges={renderStaticRange()}
                    />
                    <ContentButtons>
                        <Buttons onClick={handleCancel} type={'cancel'}>{i18n?.period?.calendar?.cancel}</Buttons>
                        <Buttons onClick={handleSelect} type={'select'}>{i18n?.period?.calendar?.select}</Buttons>
                    </ContentButtons>
                </BoxCalendar>
            }
        </Content>


    );
}

export default Period;
