import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';

import {FiSearch} from "react-icons/fi";
import {GrClose} from 'react-icons/gr';
import {InputText} from './styles';

import {ContextReducer} from '../../reducer/AdminReducer';
import {getEcomm, setFilter, setSearchName, setRangePeriod, setShouldUpdateRangeFilter} from '../../actions/ReviewsAction';

import ButtonAddFilter from './ButtonAddFilter';
import useSearchInfos from '../../hooks/useSearchInfos';


const Input = ({onHandleBoxActive, from}) => {
    const {state, dispatch} = useContext(ContextReducer);
    const [active, setActive] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [placeholder, setPlaceholder] = useState('');

    useSearchInfos();

    const filterhasValue = state?.filters?.listFiltersHeader[state?.filters?.orderSelectSearch];

    const firstRenderRef = useRef(true);

    useEffect(() => {
        if (state.actualRoute === 'stores' || state.actualRoute === 'configuration') {
            setPlaceholder(state?.i18n?.placeholderStores);
        } else {
            setPlaceholder(`${state?.i18n?.placeholderInput + state?.i18n?.filtersSelectInput[state.filters.orderSelectSearch]}`);
        }
    }, [state?.i18n?.placeholderInput, state?.i18n?.filtersSelectInput, state.filters.orderSelectSearch, state.actualRoute, state?.i18n?.placeholderStores]);

    useEffect(() => {
        handleSearchInteractionsOrStores();
    }, [inputValue])

    useEffect(() => {
        const handleEnter = (event) => {
            if (event.key === "Enter" || event.key === "Return") {
                if (state?.filters?.orderInputUser.length > 0 || !filterhasValue) {
                    const filter = {
                        listFiltersHeader: {
                            ...state?.filters?.listFiltersHeader,
                            [state?.filters?.orderSelectSearch]: state?.filters?.orderInputUser
                        }
                    }
                    setFilter(dispatch, filter);
                }
            }
        };

        window.addEventListener('keydown', handleEnter);

        return () => {
            window.removeEventListener('keydown', handleEnter);
        };

    }, [state?.filters?.orderInputUser])


    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
            return
        }

        if (state?.filters?.orderInputUser === '' && !state.filters.listFiltersHeader[state.filters.orderSelectSearch]) {
            setInputValue('');
        }
    }, [state?.filters?.orderInputUser]);

    useEffect(() => {
        if (state?.filters?.orderInputUser.length === 0 && state?.filters?.listFiltersHeader[state?.filters?.orderSelectSearch]) {
            const _filter = {listFiltersHeader: {...state?.filters?.listFiltersHeader}};
            delete _filter.listFiltersHeader[state?.filters?.orderSelectSearch];
            setFilter(dispatch, _filter);
        } else if (filterhasValue) {
            const filter = {
                listFiltersHeader: {
                    ...state?.filters?.listFiltersHeader,
                    [state.filters.orderSelectSearch]: state?.filters?.orderInputUser
                }
            }
            setFilter(dispatch, filter);
        }
    }, [state?.filters?.orderInputUser]);


    useEffect(() => {
        setInputValue('');
    }, [state?.filters?.orderSelectSearch])

    const handleClear = (e) => {
        e.preventDefault();

        setSearchName(dispatch, null)
        setInputValue('');
        let _filter;

        switch (state?.actualRoute) {
            case 'stores':
                getEcomm(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, state.typeChangeStore, true, inputValue);
                break
            default:
                _filter = {orderInputUser: '', listFiltersHeader: {}};

                setRangePeriod(dispatch, [{}]);
                setShouldUpdateRangeFilter(dispatch, false);
                setFilter(dispatch, _filter)
        }
        setActive(false);
        if(state?.actualRoute !== 'stores') {
            onHandleBoxActive(_filter?.listFiltersHeader);
        }
    }


    const handleFocus = (e) => {
        e.preventDefault();
        setActive(true);
    }

    const handleBlur = (e) => {
        e.preventDefault();

        if (inputValue.length > 0) {
            setActive(true);
        } else {
            setActive(false);
        }
    }

    const handleSearchInteractionsOrStores = () => {
        switch (state.actualRoute) {
            case 'stores':
                setSearchName(dispatch, inputValue)
                getEcomm(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, state.typeChangeStore, true, inputValue);
                break
            case 'configuration':
                setSearchName(dispatch, inputValue)
                getEcomm(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, 'marketplace', true, inputValue, '', '', (from === 'integrated') ? true : false);
                break
            default:
                const _filter = { orderInputUser: inputValue }
                setFilter(dispatch, _filter)
        }
    }


    const renderIcons = () => {
        if (inputValue && inputValue.length > 0) {
            return (
                <GrClose
                    onClick={handleClear}
                    style={{width: '20px', height: '20px', cursor: 'pointer'}}
                />
            )
        } else {
            return (
                state.filters.orderInputUser.length === 0 && (state.actualRoute === 'stores' || state.actualRoute === 'configuration') &&
                <FiSearch style={{width: '20px', height: '20px'}} />
            )
        }
    }

    const handleSizePlaceholderCalc = () => {
        if (state.actualRoute === 'stores') return 200;
        if (state.actualRoute === 'configuration') return '100%';

        return (placeholder.length) * 9.3;
    }

    const handleAddHeaderfilter = () => {
        const filter = {
            listFiltersHeader: {
                ...state?.filters?.listFiltersHeader,
                [state.filters.orderSelectSearch]: state?.filters?.orderInputUser
            }
        }

        setFilter(dispatch, filter);
    }

    const renderInputText = useMemo(() => {
        return (
            <InputText large={state.actualRoute === 'configuration' ? false : active} sizePlaceholder={handleSizePlaceholderCalc()}>
                <input
                    type="text"
                    placeholder={placeholder}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                />
                {(state?.filters?.orderInputUser?.length > 0 && (state?.actualRoute !== 'stores' || state?.actualRoute !== 'configuration')) &&
                    <ButtonAddFilter onAddHeaderfilter={handleAddHeaderfilter}/>
                }
                {renderIcons()}
            </InputText>
        );
    }, [active, handleSizePlaceholderCalc, inputValue, state?.i18n?.placeholderStores, state?.filters?.orderSelectSearch, state?.actualRoute, placeholder, state?.filters?.orderInputUser, handleAddHeaderfilter])

    return renderInputText;
}

export default Input;
