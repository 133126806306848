import React, { useState, useContext, useEffect } from 'react';
import { Container, WrapperMain, WrapperSecondary, ContainerSecondary, BoxWarning } from './styles';
import Toggle from '../../../../components/Toggle';
import { ContextReducer } from '../../../../reducer/AdminReducer';
import { Button } from '../../styles';
import { ButtonLoading } from '../../styles';

const BoxService = ({ config, setConfig, handleSubmitForm, setSaveButton, saveButton }) => {
    const { state } = useContext(ContextReducer);
    const [open, setOpen] = useState(false);
    const [ askDeliveryNps, setAskDeliveryNps ] = useState(state?.config?.ecommerce?.transactionEmailConfig?.askDeliveryNps);
    const [ askPurchaseNps, setAskPurchaseNps ] = useState(state?.config?.ecommerce?.transactionEmailConfig?.askPurchaseNps);
    const [bothService, setBothService] = useState(state?.config?.ecommerce?.transactionEmailConfig?.askDeliveryNps || state?.config?.ecommerce?.transactionEmailConfig?.askPurchaseNps || askDeliveryNps || askPurchaseNps);
    const [statusButton, setStatusButton ] = useState({ loading: false, done: false});

    useEffect(() => {
        return () => {
            clearTimeout();
        }
    }, []);

    useEffect(() => {
        setBothService(askDeliveryNps || askPurchaseNps);
    }, [askDeliveryNps, askPurchaseNps]);

    const handlePromise = async () => {
        return new Promise((resolve) => {
            handleSubmitForm()
            resolve();
        })
    }

    const handleClickSave = async () => {
        setStatusButton({...statusButton, loading: true})
        await handlePromise();
        setStatusButton({...statusButton, done: true, loading: false});

        setTimeout(() => {
            setStatusButton({...statusButton, done: false});
        }, 3000);

        setSaveButton({...saveButton, service: false});
    }

    const handleUpdateConfig = (name, value) => {
        setSaveButton({...saveButton, service: true});
        setConfig({...config, [name]: value})
    }

    const handleServiceState = (from, value) => {
        if(from === 'askPurchaseNps'){
            setAskPurchaseNps(value);
        } else if(from === 'askDeliveryNps') {
            setAskDeliveryNps(value);
        }

        handleUpdateConfig(from, value);
    }

    const handleBothService = (event) => {
        event.stopPropagation();

        if(!bothService){
            setSaveButton({...saveButton, service: true});
            setAskDeliveryNps(true);
            setAskPurchaseNps(true);
            setConfig({...config, askPurchaseNps: true, askDeliveryNps: true });
        } else {
            setSaveButton({...saveButton, service: true});
            setAskDeliveryNps(false);
            setAskPurchaseNps(false);
            setConfig({...config, askPurchaseNps: false, askDeliveryNps: false });
        }
    }

    const renderNameButton = () => {
        if (statusButton.loading) {
            return state?.i18n?.buttonSave.loading
        } else if (statusButton.done) {
            return state?.i18n?.buttonSave.done
        } else {
            return state?.i18n?.buttonSave.save
        }
    }

    return (
        <>
            <Container open={open}>
                <WrapperMain
                    onClick={() => setOpen((prevState) => !prevState)}
                    open={open}
                >
                    <span>{state.i18n.configuration.newsletter.collectService}</span>
                    <Toggle
                        from={'newsletterConfig'}
                        active={bothService}
                        handleClick={(event) => handleBothService(event)}
                    />
                </WrapperMain>
                {open &&
                    <ContainerSecondary>
                        <WrapperSecondary>
                            <span>{state.i18n.configuration.newsletter.experienceStore}</span>
                            <Toggle
                                active={askPurchaseNps}
                                from={'newsletterConfig'}
                                handleClick={() => handleServiceState('askPurchaseNps', askPurchaseNps === true ? false : true)}
                            />
                        </WrapperSecondary>
                        <WrapperSecondary>
                            <span>{state.i18n.configuration.newsletter.experienceDelivery}</span>
                            <Toggle
                                active={askDeliveryNps}
                                from={'newsletterConfig'}
                                handleClick={() => handleServiceState('askDeliveryNps', askDeliveryNps === true ? false : true)}
                            />
                        </WrapperSecondary>
                        <BoxWarning>
                            <p><strong>{state.i18n.configuration.newsletter.WarningMessageAtention}</strong>{state.i18n.configuration.newsletter.warningMessageService}</p>
                        </BoxWarning>
                    </ContainerSecondary>
                }
            </Container>
            {(saveButton['service'] || (statusButton.loading || statusButton.done)) &&
                <Button
                    from={'newsletter'}
                    type="button"
                    done={statusButton.done}
                    loading={statusButton.loading}
                    disabled={(statusButton.loading || statusButton.done)}
                    onClick={() => handleClickSave()}
                >
                    <strong>{renderNameButton()}</strong>
                    {statusButton.loading && <ButtonLoading/>}
                </Button>
            }
        </>
    );
};

export default BoxService;
