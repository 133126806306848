import styled from 'styled-components';

export const ContainerError = styled.div`
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    h2 {
        color: #000000;
        font-size: 20px;
        font-weight: bold;
        margin-top: 21px;
        font-family: 'Open Sans', sans-serif;

        :nth-child(3){
            margin-top: 0;
        }
    }
    span {
        color: #000000;
        font-size: 16px;
        margin-top: 11px;
        font-family: 'Open Sans', sans-serif;
    }

    > svg {
        width: 22px;
        height: 19px;
    }
`

export const Wrapper = styled.div`
    position: absolute;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 214px;
`
export const ButtonBack = styled.button`
    width: 301px;
    height: 50px;
    margin-top: 40px;
    border-radius: 5px;
    border: none;
    background-color: #000000;
    color: #fff;
    font-family: 'Open Sans', sans-serif;


    &:hover{
        border: 1px solid #000000;
        background: #fff;
        color: #000000;
        transition: all ease .5s;
    }
`