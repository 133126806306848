import React, {useContext, useMemo, useEffect} from 'react';
import {Container} from '../styles';

import ListHeader from '../../components/ListHeader';
import Filter from '../../components/Filter';
import Pagination from "../../components/Pagination";
import ButtonsActions from '../../components/ButtonsActions';
import Items from '../../components/Items';
import LoaderInfos from '../../components/LoaderInfos';

import {ContextReducer} from "../../reducer/AdminReducer";
import NoItems from '../../components/NoItems';
import HeaderFilter from "../../components/HeaderFilter";
import useInitializeInfos from '../../hooks/useInitializeInfos';
import { setNotification } from '../../actions/ReviewsAction';

const Reviews = () => {
    const {state, dispatch} = useContext(ContextReducer)
    const {selectAll, setSelectAll} = useInitializeInfos();

    const handlePagination = () => {
        setSelectAll(false);
    }

    useEffect(() => {
        return () => {
            clearTimeout();
        }
    }, [])

    const handleClickCheckBox = (e = '', ref = '', statusSelect) => {
        if(!state?.typeEcommerce?.store && !state?.userInfo?.isAdmin) {
            setSelectAll(!selectAll);

            const _role = 'accessNotAllowed';
            setNotification(dispatch, {status: true, role: _role});

            setTimeout(() => {
                setNotification(dispatch, {
                    'status': false,
                    'role': _role
                })
                setSelectAll(false);
                if(ref.current) {
                    ref.current.checked = false;
                }
            }, 3000)
            return
        }

        if(statusSelect === false) {
            setSelectAll(false);
            return
        }

        setSelectAll((c) => !c);
    };

    const renderReviewList = () => {
        if (state?.reviews?.items.length > 0) {
            return state?.reviews?.items.map((value, index) => {
                return <Items value={value} index={index} key={index} selected={selectAll} handleClickCheckBox={handleClickCheckBox}/>
            })
        } else {
            return <NoItems/>
        }
    }

    return useMemo(() => {
        return (
            <>
                <HeaderFilter/>
                <Container area='filter'>
                    <Filter handlePagination={handlePagination}/>
                </Container>
                <Container area='list'>
                    <ListHeader
                        selectAll={selectAll}
                        handleClickCheckBox={handleClickCheckBox}
                    />
                    {!state?.isLoad ? <LoaderInfos/> : renderReviewList()}
                    <ButtonsActions
                        fixed="true"
                        checkeds={state?.selecteds}
                        allItems={true}
                    />
                </Container>

                <Container area='pagination'>
                    <Pagination handlePagination={handlePagination}/>
                </Container>
            </>
        );

    }, [state.reviews, state.selecteds, selectAll, state.isLoad, state.actualRoute])
}

export default Reviews;
