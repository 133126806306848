import React, { useContext, useState, useEffect } from 'react';
import { ContextReducer } from '../../../../reducer/AdminReducer';

import getTransformDpr from "../../../../utils/TransformDpr";
import { FiChevronLeft } from 'react-icons/fi';
import { HiOutlineBellAlert } from "react-icons/hi2";
import { IoMdCheckboxOutline } from "react-icons/io";

import { WrapperWarn, Button, Content, Logo, Back } from './styles';

import { setIntegratedStores, sendConfigurantion } from '../../../../actions/ReviewsAction';
import ContainerButtonAndStatus from './ContainerButtonAndStatus';

const Warn = () => {
    const { state, dispatch } = useContext(ContextReducer);
    const [active, setActive] = useState(false);

    const marketplacesAble = ['mlv'];

    const handleBack = () => {
        setIntegratedStores(dispatch, {});
    }

    useEffect(() => {
        return () => {
            handleBack();
        }
    }, [])

    const handleWarn = (name) => {
        setActive(!active)

        let _obj = {}

        _obj[name] = {warnmme: active}

        let _arr = {...state.config?.ecommerce?.marketplacesChecked, ..._obj}

        sendConfigurantion(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, undefined, null, null, JSON.stringify(_arr))
    }

    return (
        <WrapperWarn>
            <Back onClick={handleBack}>
                <FiChevronLeft style={{width: '20px', height: '20px'}}/>
                {state?.i18n?.success?.return}
            </Back>
            <Content>
                {state?.integratedStores?.authorInfo?.logoPicture?.id !== " " && <Logo style={{background: `url(https://${state.userInfo.ecommerce.domain}/image/upload/t_favicons_dpr_${getTransformDpr(window.devicePixelRatio)}/f_auto/${state?.integratedStores?.authorInfo?.logoPicture?.id}.jpeg) center center / contain no-repeat`}} /> }
                <h2>{state?.integratedStores?.name}</h2>
            </Content>
            { marketplacesAble.includes(state?.integratedStores?.id) ?
                <ContainerButtonAndStatus storeName={state?.integratedStores?.name} /> :
                <>
                    <strong>{state?.i18n?.configuration?.integratedStores?.warnTitle}</strong>
                    <Button type="button" onClick={() => handleWarn(state?.integratedStores?.ecommerceId)} active={state.config?.ecommerce?.marketplacesChecked[state?.integratedStores?.ecommerceId]?.warnmme}>
                        {state.config?.ecommerce?.marketplacesChecked[state?.integratedStores?.ecommerceId]?.warnmme ? <IoMdCheckboxOutline style={{width: '20px', height: '20px', marginRight: '10px'}} /> : <HiOutlineBellAlert style={{width: '20px', height: '20px', marginRight: '10px'}} />}
                        {state.config?.ecommerce?.marketplacesChecked[state?.integratedStores?.ecommerceId]?.warnmme ? state?.i18n?.configuration?.integratedStores?.warnSend : state?.i18n?.configuration?.integratedStores?.warnButton}
                    </Button>
                </>
            }
        </WrapperWarn>
    );
};

export default Warn;
