import React, { useContext, useEffect, useRef } from 'react';
import { ContextReducer } from '../../../../reducer/AdminReducer';
import { Container} from './styles';

const Checkbox = ({label, cb, selectedLanguages}) => {
    const { state, dispatch } = useContext(ContextReducer);
    const lastCheckRef = useRef();

    const handleChange = (e) => {
        dispatch({
            type: 'SET_MODAL_CONFIRMATION_LANG',
            payload: {
                active: true,
                button: '',
            }
        });

        lastCheckRef.current = e.target.value;
    }

    useEffect(() => {
        if(state.modalConfirmationLang.button === '') return;

        if(state.modalConfirmationLang.button === 'change') {
            if(lastCheckRef.current){
                cb(lastCheckRef.current);
                lastCheckRef.current = ''
            }
        }

        if(state.modalConfirmationLang.button === 'cancel') {
            lastCheckRef.current = ''
        }
    }, [state.modalConfirmationLang.button])

    const handleToIso = (value) => {
        let transformed = value.slice(0, 2) + '-' + value.slice(2);
        transformed = transformed.slice(0, 3) + transformed.slice(3).toUpperCase();
        return transformed;
    }

    return (
        <Container>
            <input
                type="checkbox"
                value={label}
                disabled={!selectedLanguages?.includes(label) && selectedLanguages.length >= 3}
                checked={selectedLanguages?.includes(label)}
                onChange={(e) => handleChange(e)}
            />
            <label>{handleToIso(label)}</label>
        </Container>
    );
};

export default Checkbox;
