import styled, {css} from "styled-components";

import IconComputer from '../../assets/ico-computer.svg'
import IconTruck from '../../assets/ico-metro-truck.svg'



export const Container = styled.div`
    background-color: #fff;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    padding: 30px;
    border-radius: 20px;
    width: calc(100% - ${props => props.width}px);

    ${props => props.small && css`
        max-width: ${props => props.small}px;
    `}

    ${props => props.marginRight && css`
        margin-right: 20px;
    `}
`

export const Title = styled.span`
    font-size: 17px;
    font-weight: bold;
    display: block;
    color: #000;

    &:before {
        content: url(${IconComputer});
        margin-right: 10px;
        vertical-align: middle;
    }

    ${props => props.delivery && css`
        &:before {
            content: url(${IconTruck});
        }
    `}
`
export const SubTitle = styled.span`
    font-size: 14px;
    color: #000;
    margin-bottom: 10px;
`
export const Content = styled.div`
    display: flex;
    flex-flow: column;
    margin-top: 30px;
`
export const Num = styled.span`
    font-size: 22px;
    color: #000000;
    font-weight: bold;
`

export const Pills = styled.div`
    background-color: #EDEEF0;
    border-radius: 25px;
    padding: 5px 20px;
    color: #000000;
    font-size: 14px;
    display: flex;
    width: fit-content;
    margin-bottom: 5px;
    align-items: center;

    ${props => props.liked && css`
        &:before {
            content: '';
            margin-right: 10px;
            width: 5px;
            height: 5px;
            background-color: #71CE4F;
            border-radius: 50%;
        }
    `}

    ${props => props.disliked && css`
        &:before {
            content: '';
            margin-right: 10px;
            width: 5px;
            height: 5px;
            background-color: #FC0D1B;
            border-radius: 50%;
        }
    `}
`

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

export const WrapperLeft = styled.div`
    box-sizing: border-box;
    min-width: 50%;
`

export const WrapperRight = styled.div`
    box-sizing: border-box;
    min-width: 50%;
`

export const Citys = styled.div`
    display: flex;
    flex-flow: column;
`

export const ItemCity = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 5px;
    border-top: 1px solid #EDEEF0;
    justify-content: space-between;


    ${props => props.type === 'high' && css`
        span {
            color: #1EC44F;
        }
    `}

    ${props => props.type === 'medium' && css`
        span {
            color: #FDA629;
        }
    `}

    ${props => props.type === 'low' && css`
        span {
            color: #FF0000;
        }
    `}


    svg { 
        margin-right: 10px;
    }

    div {
        display: flex;
        align-items: center;
    }

    span {
        font-size: 16px;
        font-weight: bold;
    }

    &:last-child {
        border-bottom: 1px solid #EDEEF0;
    }
`

export const City = styled.span`
    color: #000 !important;
    font-size: 14px;
    font-weight: bold;
    display: block;

`