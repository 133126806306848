import React, {useContext} from 'react';

import {Notification, Text} from './styles';

import {ContextReducer} from '../../reducer/AdminReducer';


const Notifications = props => {

    const {state} = useContext(ContextReducer)

    const renderRole = (role) => {
        switch (role) {
            case 'APPROVED':
                return state?.i18n?.notification.APPROVED
            case 'DELETED':
                return state?.i18n?.notification.DELETED
            case 'REJECTED':
                return state?.i18n?.notification.REJECTED
            case 'COMMENTS':
                return state?.i18n?.notification.COMMENTS
            case 'ANSWER':
                return state?.i18n?.notification.ANSWER
            case 'REVIEWS':
                return state?.i18n?.notification.REVIEWS
            case 'config':
                return state?.i18n?.notification.config
            case 'configAccessPatch':
                return state?.i18n?.notification.accessAjusted
            case 'configAccessPost':
                return state?.i18n?.notification.freeAccess
            case 'emailTestSended':
                return state?.i18n?.notification?.emailTestSended
            case 'errorDomainInvalid':
                return state?.i18n?.notification?.error.errorDomainInvalid
            case 'errorPersonalEmailInvalid':
                return state?.i18n?.notification?.error.errorPersonalEmailInvalid
            case 'errorAccessInputEmpty':
                return state?.i18n?.notification?.error.errorAccessInputEmpty
            case 'errorOcurred':
                return state?.i18n?.notification?.error.errorOcurred
            case 'accessNotAllowed':
                return state?.i18n?.notification?.error.accessNotAllowed
            default:
                return ''
        }
    }

    const renderText = () => {
        let _pathname = props.area.replace('/', '').charAt(0).toUpperCase() + props.area.slice(2);

        const roles = [ 'COMMENTS', 'ANSWER', 'REVIEWS', 'config', 'configAccessPatch', 'configAccessPost', 'errorDomainInvalid', 'errorPersonalEmailInvalid', 'errorAccessInputEmpty', 'errorOcurred', 'accessNotAllowed', 'emailTestSended'];

        if(roles.includes(props.role)) {
            return renderRole(props.role);
        } else {
            return _pathname + ' ' + renderRole(props.role)
        }
    }

    return (
        <Notification
            role={props.role}
            active={props.active}
        >
            <Text>{renderText()}</Text>
        </Notification>
    )
}

export default Notifications;
