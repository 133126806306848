import React, { useContext } from 'react';
import { ContextReducer } from '../../../../../reducer/AdminReducer';
import { ContentBlock, Block, Name, InputColorContent, ContentButtons, ButtonFake } from '../../../styles';

const ContentColors = ({ config, color, buttonRadius }) => {
    const { state } = useContext(ContextReducer);
    return (
        <>
            <ContentBlock>
                <Block
                    percentage={'50'}
                    from={'colorsInput'}
                >
                    <Name>{state?.i18n?.configuration?.newsletter?.title3}</Name>
                    <InputColorContent>
                        <input
                            type={'text'}
                            maxLength={7}
                            name={'styleHeaderBgColor'}
                            value={config['styleHeaderBgColor']}
                            placeholder={color['styleHeaderBgColor'] ? color['styleHeaderBgColor'] : state?.i18n?.configuration?.newsletter?.placeholder1}
                        />
                        <input
                            type={'color'}
                            name={'styleHeaderBgColor'}
                            value={config['styleHeaderBgColor']}
                            defaultValue={color['styleHeaderBgColor']}
                            placement="right"
                        />
                    </InputColorContent>
                </Block>
                <Block
                    percentage={'50'}
                    from={'colorsInput'}
                >
                    <Name>{state?.i18n?.configuration?.newsletter?.title4}</Name>
                    <InputColorContent>
                        <input
                            type={'text'}
                            maxLength={7}
                            name={'styleHeaderBorderTopColor'}
                            value={config['styleHeaderBorderTopColor']}
                            placeholder={color['styleHeaderBorderTopColor'] ? color['styleHeaderBorderTopColor'] : state?.i18n?.configuration?.newsletter?.placeholder1}
                        />
                        <input
                            type={'color'}
                            name={'styleHeaderBorderTopColor'}
                            value={config['styleHeaderBorderTopColor']}
                            defaultValue={color['styleHeaderBorderTopColor']}
                            placement="right"
                        />
                    </InputColorContent>
                </Block>
                <Block
                    percentage={'50'}
                    from={'colorsInput'}
                >
                    <Name>{state?.i18n?.configuration?.newsletter?.title5}</Name>
                    <InputColorContent>
                        <input
                            type={'text'}
                            maxLength={7}
                            name={'styleHeaderLogoDarkShadowColor'}
                            value={config['styleHeaderLogoDarkShadowColor']}
                            placeholder={color['styleHeaderLogoDarkShadowColor'] ? color['styleHeaderLogoDarkShadowColor'] : state?.i18n?.configuration?.newsletter?.placeholder1}
                        />
                        <input
                            type={'color'}
                            name={'styleHeaderLogoDarkShadowColor'}
                            value={config['styleHeaderLogoDarkShadowColor']}
                            defaultValue={color['styleHeaderLogoDarkShadowColor']}
                            placement="right"
                        />
                    </InputColorContent>
                </Block>
                <Block
                    percentage={'50'}
                    from={'colorsInput'}
                >
                    <Name>{state?.i18n?.configuration?.newsletter?.title6}</Name>
                    <InputColorContent color={config['styleHeaderLogoDarkShadowColor'] ? config['styleHeaderLogoDarkShadowColor'] : color['styleHeaderLogoDarkShadowColor']}>
                        <input
                            type={'range'}
                            min={0}
                            max={100}
                            step={10}
                            name={'styleHeaderLogoDarkShadowOpacity'}
                            value={config['styleHeaderLogoDarkShadowOpacity']}
                            defaultValue={color['styleHeaderLogoDarkShadowOpacity']}
                            placeholder={color['styleHeaderLogoDarkShadowOpacity'] ? color['styleHeaderLogoDarkShadowOpacity'] : state?.i18n?.configuration?.newsletter?.placeholder1}
                        />
                        <span>{config['styleHeaderLogoDarkShadowOpacity'] ? config['styleHeaderLogoDarkShadowOpacity'] : ''}%</span>
                    </InputColorContent>
                </Block>
            </ContentBlock>
            <Block from={'buttonsEmail'}>
                <Name>{state?.i18n?.configuration?.newsletter?.title7}</Name>
                <ContentButtons>
                    <ButtonFake
                        active={buttonRadius === '0px'}
                        data-value="0px"
                    >
                        <input
                            type={'radio'}
                            name={'styleButtonsBorderRadius'}
                            value={'0px'}
                        />
                        {state?.i18n?.configuration?.newsletter?.placeholder2}
                    </ButtonFake>
                    <ButtonFake
                        data-value="25px"
                        active={buttonRadius !== '0px'}
                        radius={true}
                    >
                        <input
                            type={'radio'}
                            name={'styleButtonsBorderRadius'}
                            value={'30px'}
                        />
                        {state?.i18n?.configuration?.newsletter?.placeholder3}
                    </ButtonFake>
                </ContentButtons>
            </Block>
            <ContentBlock>
                <Block
                    percentage={'50'}
                    from={'colorsInput'}
                >
                    <Name>{state?.i18n?.configuration?.newsletter?.title8}</Name>
                    <InputColorContent>
                        <input
                            type={'text'}
                            name={'styleReviewButtonBg'}
                            value={config['styleReviewButtonBg']}
                            placeholder={color['styleReviewButtonBg'] ? color['styleReviewButtonBg'] : state?.i18n?.configuration?.newsletter?.placeholder1}
                        />
                        <input
                            type={'color'}
                            name={'styleReviewButtonBg'}
                            value={config['styleReviewButtonBg']}
                            defaultValue={color['styleReviewButtonBg']}
                            placement="right"
                        />
                    </InputColorContent>
                </Block>
                <Block
                    percentage={'50'}
                    from={'colorsInput'}
                >
                    <Name>{state?.i18n?.configuration?.newsletter?.title17}</Name>
                    <InputColorContent>
                        <input
                            type={'text'}
                            name={'styleReviewButtonFontColor'}
                            value={config['styleReviewButtonFontColor']}
                            placeholder={color['styleReviewButtonFontColor'] ? color['styleReviewButtonFontColor'] : state?.i18n?.configuration?.newsletter?.placeholder1}
                        />
                        <input
                            type={'color'}
                            name={'styleReviewButtonFontColor'}
                            value={config['styleReviewButtonFontColor']}
                            defaultValue={color['styleReviewButtonFontColor']}
                            placement="right"
                        />
                    </InputColorContent>
                </Block>
                <Block
                    percentage={'50'}
                    from={'colorsInput'}
                >
                    <Name>{state?.i18n?.configuration?.newsletter?.title9}</Name>
                    <InputColorContent>
                        <input
                            type={'text'}
                            name={'styleReviewButtonBorderColor'}
                            value={config['styleReviewButtonBorderColor']}
                            placeholder={color['styleReviewButtonBorderColor'] ? color['styleReviewButtonBorderColor'] : state?.i18n?.configuration?.newsletter?.placeholder1}
                        />
                        <input
                            type={'color'} name={'styleReviewButtonBorderColor'}
                            value={config['styleReviewButtonBorderColor']}
                            defaultValue={color['styleReviewButtonBorderColor']}
                            placement="right"
                        />
                    </InputColorContent>
                </Block>
                <Block
                    percentage={'50'}
                    from={'colorsInput'}
                >
                    <Name>{state?.i18n?.configuration?.newsletter?.title10}</Name>
                    <InputColorContent>
                        <input
                            type={'text'}
                            name={'styleReviewButtonHover'}
                            value={config['styleReviewButtonHover']}
                            placeholder={color['styleReviewButtonHover'] ? color['styleReviewButtonHover'] : state?.i18n?.configuration?.newsletter?.placeholder1}
                        />
                        <input
                            type={'color'}
                            name={'styleReviewButtonHover'}
                            value={config['styleReviewButtonHover']}
                            defaultValue={color['styleReviewButtonHover']}
                            placement="right"
                        />
                    </InputColorContent>
                </Block>
                <Block
                    percentage={'50'}
                    from={'colorsInput'}
                >
                    <Name>{state?.i18n?.configuration?.newsletter?.title11}</Name>
                    <InputColorContent>
                        <input
                            type={'text'}
                            name={'styleNpsBgColor'}
                            value={config['styleNpsBgColor']}
                            placeholder={config['styleNpsBgColor'] ? config['styleNpsBgColor'] : (color['styleNpsBgColor'] ? color['styleNpsBgColor'] : state?.i18n?.configuration?.newsletter?.placeholder1)}
                        />
                        <input
                            type={'color'}
                            name={'styleNpsBgColor'}
                            value={config['styleNpsBgColor']}
                            defaultValue={color['styleNpsBgColor']}
                            placement="right"
                        />
                    </InputColorContent>
                </Block>
                <Block
                    percentage={'50'}
                    from={'colorsInput'}
                >
                    <Name>{state?.i18n?.configuration?.newsletter?.title12}</Name>
                    <InputColorContent>
                        <input
                            type={'text'}
                            name={'styleNpsButtonBg'}
                            value={config['styleNpsButtonBg']}
                            placeholder={config['styleNpsButtonBg'] ? config['styleNpsButtonBg'] : (color['styleNpsButtonBg'] ? color['styleNpsButtonBg'] : state?.i18n?.configuration?.newsletter?.placeholder1)}
                        />
                        <input
                            type={'color'}
                            name={'styleNpsButtonBg'}
                            value={config['styleNpsButtonBg']}
                            defaultValue={color['styleNpsButtonBg']}
                            placement="right"
                        />
                    </InputColorContent>
                </Block>
                <Block
                    percentage={'50'}
                    from={'colorsInput'}
                >
                    <Name>{state?.i18n?.configuration?.newsletter?.title13}</Name>
                    <InputColorContent>
                        <input
                            type={'text'}
                            name={'styleNpsButtonBorderColor'}
                            value={config['styleNpsButtonBorderColor']}
                            placeholder={config['styleNpsButtonBorderColor'] ? config['styleNpsButtonBorderColor'] : (color['styleNpsButtonBorderColor'] ? color['styleNpsButtonBorderColor'] : state?.i18n?.configuration?.newsletter?.placeholder1)}
                        />
                        <input
                            type={'color'}
                            name={'styleNpsButtonBorderColor'}
                            value={config['styleNpsButtonBorderColor']}
                            defaultValue={color['styleNpsButtonBorderColor']}
                            placement="right"
                        />
                    </InputColorContent>
                </Block>
                <Block
                    percentage={'50'}
                    from={'colorsInput'}
                >
                    <Name>{state?.i18n?.configuration?.newsletter?.title14}</Name>
                    <InputColorContent>
                        <input
                            type={'text'}
                            name={'styleNpsButtonFontColor'}
                            value={config['styleNpsButtonFontColor']}
                            placeholder={config['styleNpsButtonFontColor'] ? config['styleNpsButtonFontColor'] : (color['styleNpsButtonFontColor'] ? color['styleNpsButtonFontColor'] : state?.i18n?.configuration?.newsletter?.placeholder1)}
                        />
                        <input
                            type={'color'}
                            name={'styleNpsButtonFontColor'}
                            value={config['styleNpsButtonFontColor']}
                            defaultValue={color['styleNpsButtonFontColor']}
                            placement="right"
                        />
                    </InputColorContent>
                </Block>
                <Block
                    percentage={'50'}
                    from={'colorsInput'}
                >
                    <Name>{state?.i18n?.configuration?.newsletter?.title15}</Name>
                    <InputColorContent>
                        <input
                            type={'text'}
                            name={'styleNpsButtonHover'}
                            value={config['styleNpsButtonHover']}
                            placeholder={config['styleNpsButtonHover'] ? config['styleNpsButtonHover'] : (color['styleNpsButtonHover'] ? color['styleNpsButtonHover'] : state?.i18n?.configuration?.newsletter?.placeholder1)}
                        />
                        <input
                            type={'color'}
                            name={'styleNpsButtonHover'}
                            value={config['styleNpsButtonHover']}
                            defaultValue={color['styleNpsButtonHover']}
                            placement="right"
                        />
                    </InputColorContent>
                </Block>
            </ContentBlock>
        </>
    );
};

export default ContentColors;
