import styled, { css } from 'styled-components';

export const WrapperStatus = styled.div`
    width: calc(100% - 181px);
    height: auto;
    display: flex;

    @media screen and (max-width: 900px){
        flex-direction: column;
        align-items: flex-start;
    }
`

export const ContainerTexts = styled.div`
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 15px;

    @media screen and (max-width: 900px){
        margin: 5px 15px 20px 0;
    }
`
export const Text = styled.span`
    ${props => props.bigText && css`
        color: #2BC549;
        font-size: 18px;
    `}
    ${props => props.smallText && css`
        color: #000;
        font-size: 14px;
    `}
`

