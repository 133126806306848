import React, {useContext, useEffect, useMemo} from 'react';
import {ContextReducer} from '../../../reducer/AdminReducer';

import {ButtonSave, Container, Message, WrapperTexts} from './styles';

import Item from './Item';
import Pagination from '../../../components/Pagination';
import LoaderBox from '../../LoaderBox';

import {editAccess, getListUsersAccess} from '../../../actions/ReviewsAction';

const WrapperListAccess = () => {
    const {state, dispatch} = useContext(ContextReducer);

    useEffect(() => {
        getListUsersAccess(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token);

        return () => {
            dispatch({
                type: 'RESET_USERSACCESS',
                payload: {}
            })
        }
    }, [state?.userInfo?.ecommerce])

    const renderItems = useMemo(() => {
        if (state.usersAccessList?.items?.length > 0) {
            const _items = state.usersAccessList.items;

            return _items.map(item => {
                return <Item key={item.email} item={item}/>;
            });
        } else if (state.usersAccessList?.items?.length === 0) {
            return <Message><span>{state?.i18n?.peopleAccess?.notUsersItems}</span></Message>;
        }
    }, [state.usersAccessList?.items]);

    const handlePatchItems = () => {
        editAccess(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, state?.usersAccessList?.itemsPatch.data, state?.usersAccessList?.keys, state?.usersAccessList?.page);
    }

    const handlePagination = () => {
        dispatch({
            type: 'SET_PATCHACCESS',
            payload: ''
        })
    }

    const renderLoading = () => {
        let items = [];
        for (let i = 0; i < 10; i++) {
            items.push(<LoaderBox key={i} from={'barinfos'}/>);
        }
        return <>{items}</>;
    }
    return (
        <Container>
            <h1>{state?.i18n?.peopleAccess?.titleListAccess}</h1>
            {(state.usersAccessList?.items?.length > 0) && <Pagination handlePagination={handlePagination}/>}
            <WrapperTexts nonBorder={state?.isLoad}>
                <span>{state?.i18n?.peopleAccess?.editList}</span>
                <span>{state?.i18n?.peopleAccess?.activated}</span>
            </WrapperTexts>
            {state?.isLoad ? renderItems : renderLoading()}
            {(state.usersAccessList?.items?.length > 0) && <Pagination handlePagination={handlePagination}/>}
            {(state.usersAccessList?.items?.length > 0) &&
                <ButtonSave active={!!state?.usersAccessList?.itemsPatch?.data}
                            onClick={handlePatchItems}
                >
                    {state?.i18n?.peopleAccess?.save}
                </ButtonSave>}
        </Container>
    );
};

export default WrapperListAccess;
