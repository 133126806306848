import React from 'react';
import {StyledBurguer} from './styles';

const Hamburguer = props => {
    return (
        <StyledBurguer open={props.value}>
            <div/>
            <div/>
            <div/>
        </StyledBurguer>
    );
}

export default Hamburguer;
