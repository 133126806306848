import React, {useEffect, useRef} from "react";

const Checkbox = ({id, type, name, handleClick, selected, from = '', uncheck = () => {}, check = () => {}}) => {
    const checkRef = useRef();

    uncheck(() => {
        checkRef.current.checked = false
    })

    check(() => {
        checkRef.current.checked = true;
    })

    useEffect(() => {
        if (type === 'checkbox') {
            if (selected && !checkRef.current.checked)
                checkRef.current.click();

            if (!selected && checkRef.current.checked)
                checkRef.current.click();
        }
    }, [selected]);

    return (
        <input
            ref={checkRef}
            id={id}
            name={name}
            type={type}
            onChange={(e) => handleClick(e, checkRef)}
        />
    );
};

export default Checkbox;
