import styled, {css} from "styled-components";

export const Card = styled.div`
    background: #fff;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    width: 195px;
    height: 260px;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
    cursor: pointer;

    ${props => props.type === 'integratedStores' && css`
        width: 100%;
        height: 90px;
        flex-flow: row;
        padding: 20px 10px;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        position: relative;
        
        label {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;
        }

        ${Photo} {
            width: 50px;
            height: 50px;
            margin: 0 10px 0 0;

        }

        ${CircleUser} {
            width: 50px;
            height: 50px;
            margin-right: 10px;

            svg {
                width: 20px;
                height: 20px;
            }
        }

        ${CircleBG} {
            width: 50px;
            height: 50px;
            margin-right: 10px;
        }

        ${CircleInitials} {
            width: 50px;
            height: 50px;
            font-size: 20px;
        }

        ${Infos} {
            flex-flow: row;
            align-items: center;
        }
    `}
`

export const Photo = styled.div`
    width: 110px;
    height: 110px;
    border-radius: 50%;
    position: relative;
    margin: 0 auto;
`

export const Name = styled.span`
    display: block;
    font-weight: bold;
    font-size: 15px;
    color: #000;
    text-align: center;
`

export const CircleUser = styled.div`
    background-color: #fff;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    position: relative;
    z-index: 2;

    svg {
        width: 60px;
        height: 60px;
    }
`
export const CircleBG = styled.div`
    border-radius: 50%;
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    border: 0.1px solid #d8d8d8;
`

export const Infos = styled.div`
    display: flex;
    flex-flow: column;
`

export const Info = styled.div`
    display: flex;
    margin-top: 10px;

    &:first-child {
        margin-top: 0;
    }

    span {
        color: #20BFF0;
        font-size: 14px;
        display: block;
        margin-left: 5px;
    }
`
export const CircleInitials = styled.div`
    font-size: 50px;
    border-radius: 50%;
    border: 0.1px solid #000000;
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    font-weight: bold;
    color: #000000;
`

export const Description = styled.div`
    max-width: 400px;
    width: 100%;
    font-size: 12px;
    color: rgba(0, 0, 0, .5);
`

export const Store = styled.div`
    display: flex;
    align-items: center;
`
export const Checkbox = styled.input`
    width: 20px;
    height: 20px;
    max-width: 20px;
    max-height: 20px;
    min-width: 20px;
    min-height: 20px;
    margin-left: 40px;
    display: block;
`