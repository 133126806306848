import React, { useContext, useRef, useState, useEffect } from 'react';
import useShouldRender from '../../../../../hooks/useShouldRender';
import { ContextReducer } from '../../../../../reducer/AdminReducer';

import { WrapperIA, Triangle, Banner } from './styles';
import video from '../../../../../assets/ia-vurdere.mp4';

import { IoIosClose } from "react-icons/io";

const IAWrapper = ({ timesRender, setTimesRender }) => {
    const { state } = useContext(ContextReducer);
    const [ activeBanner, setActiveBanner ] = useState(false);

    const bannerRef = useRef();

    const { shouldRender } = useShouldRender(bannerRef, activeBanner);

    useEffect(() => {
        if(timesRender === 0){
            setActiveBanner(true);
            setTimesRender(1);
        } else {
            setActiveBanner(false);
        }
    }, []);

    return (
        <WrapperIA>
            {shouldRender &&
                <Banner
                    ref={bannerRef}
                    activeBanner={activeBanner}
                >
                    <IoIosClose onClick={() => setActiveBanner(false)} />
                    <span>{state?.i18n?.filterTags?.IAVurdere}</span>
                    <Triangle />
                </Banner>
            }
            <video
                onClick={() => setActiveBanner((c) => !c)}
                autoPlay loop muted playsInline
            >
                <source
                    src={video}
                    type="video/mp4"
                />
            </video>
        </WrapperIA>
    );
};

export default IAWrapper;
