import React, {useContext} from 'react';
import {GrClose} from 'react-icons/gr';
import {Background, Container, Content, WrapperIcon} from './styles';

import {ContextReducer} from '../../reducer/AdminReducer';
import {setAttachment} from '../../actions/ReviewsAction';

const ModalAttachment = (props) => {

    const {dispatch, state} = useContext(ContextReducer);

    const handleClose = () => {
        setAttachment(dispatch, {
            index: '',
            status: false
        })
    }

    return (
        <Container>
            <WrapperIcon onClick={handleClose}>
                <GrClose size={40} color='#fff'/>
            </WrapperIcon>
            <Background onClick={handleClose}/>
            <Content>
                <img src={props.value}/>
            </Content>
        </Container>
    );
}

export default ModalAttachment;
