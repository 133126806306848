import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
`
export const Button = styled.button`
    width: 113px;
    height: 43px;
    background-color: #000;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    border-radius: 5px;
    border: none;
    margin: 25px 0px;
    cursor: pointer;
    transition: all ease-in-out 0.5s;

    :hover {
        opacity: 0.8;
        transition: all ease-in-out 0.5s;
    }
`
