import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';

import {Item, OldSelect, Selects} from './styles';
import {ContextReducer} from "../../reducer/AdminReducer";
import {setFilter} from "../../actions/ReviewsAction";
import {IoIosArrowDown, IoIosArrowUp} from 'react-icons/io';
import {ReactComponent as IconOrder} from '../../assets/iconOrder.svg';
import {BsCheck} from 'react-icons/bs'

const Select = (props) => {
    const {state, dispatch} = useContext(ContextReducer);
    const [active, setActive] = useState(false);
    const [options, setOptions] = useState([]);
    const [firstItem, setFirstItem] = useState((state.actualRoute !== 'newsletter' && state.actualRoute !== 'insights') ? 'default' : -1);
    const [change, setChange] = useState(false);

    const renderItemsReviewsAndNps = ['default', 'newer', 'older', 'relevance', 'positive', 'negative'];
    const renderItemsUsers = ['default', 'newer', 'older'];
    const renderItemsStores = ['default', 'newer', 'older', 'relevance', 'positive', 'negative'];
    const renderItems = ['default', 'newer', 'older'];

    const componentSelectRef = useRef();

    useEffect(() => {
        if(firstItem === 'default') {
            setChange(false)
        } else {
            setChange(true)
        }

    }, [state?.filters])

    useEffect(() => {
        if ((state.actualRoute === 'reviews') || (state.actualRoute === 'nps')) {
            setOptions(renderItemsReviewsAndNps);
        } else if (state.actualRoute === 'users') {
            setOptions(renderItemsUsers);
        } else if (state.actualRoute === 'stores') {
            setOptions(renderItemsStores);
        } else {
            setOptions(renderItems);
        }
    }, [state.actualRoute])

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (componentSelectRef.current && !componentSelectRef.current.contains(e.target)) {
                setActive(false);
            }
        }
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };

    }, []);

    const handleChangeFilter = (type, index, e) => {
        const _filter = {...state.filters};

        if (state.actualRoute === 'insights' || state.actualRoute === 'newsletter') {
            _filter.last = e.target.value;
            setFilter(dispatch, _filter);
            return
        }

        let from
        setActive(false);

        if (type === firstItem) {
            return;
        }

        if (type === 'default') {
            from = 'default';
            type = 'newer';
        }

        if (state.actualRoute === 'users') {
            _filter.orderUsers = type;
        } else {
            _filter.order = type;
        }

        if (from === 'default') {
            type = 'default';
        }

        setFirstItem(type);

        setFilter(dispatch, _filter);
    }

    const renderArrowDownOrUp = useCallback(() => {
        return active ? <IoIosArrowUp style={{width: '14px', height: '14px', marginRight: '1px'}}/> :
            <IoIosArrowDown style={{width: '14px', height: '14px', marginRight: '1px'}}/>
    }, [active, state.visible])

    const renderOptions = useMemo(() => {
        return options.map((item, index) => {
            return (
                <Item
                    key={item}
                    onClick={() => handleChangeFilter((state.actualRoute === 'insights' || state.actualRoute === 'newsletter') ? index - 1 : item, index)}
                    showOnlyFirstItem={!active}
                    isNewsletterAndInsights={(state.actualRoute === 'insights' || state.actualRoute === 'newsletter') ? true : false}
                >
                    {(item === firstItem) && <BsCheck/>}
                    {(state.actualRoute === 'insights' || state.actualRoute === 'newsletter') ?
                        <span>{state?.i18n?.months[item]}</span> : <span>{state?.i18n?.order[item]}</span>}
                </Item>
            )
        })
    }, [active, options, state.actualRoute, handleChangeFilter])

    const renderNameOrder = () => {
        if(!state?.visible) {
            return (
                <>
                    {firstItem === 'default' && <IconOrder style={{marginLeft: '6px'}}/>}
                    <span>{state?.i18n?.order[firstItem]}</span>
                </>
            )
        } else if (state?.visible && !change) {
            return <IconOrder style={{width: '20px', height: '20px', marginLeft: '6px'}}/>
        } else if (state?.visible && change) {
            return <span>{state?.i18n?.order[firstItem]}</span>
        }
    }

    const renderSelectRoute = () => {
        if (state.actualRoute !== 'newsletter' && state.actualRoute !== 'insights') {
            return (
                <Selects
                    firstItem={firstItem === 'default'}
                    visible={state?.visible}
                    ref={componentSelectRef}
                    active={active}
                    nomargin={props.nomargin}
                    isNewsletterAndInsights={(state.actualRoute === 'insights' || state.actualRoute === 'newsletter') ? true : false}
                >
                    <Item onClick={() => setActive(!active)}>
                        {renderNameOrder()}
                        {renderArrowDownOrUp()}
                    </Item>
                    {renderOptions}
                </Selects>
            );
        }

        return '';
    }


    return renderSelectRoute();
}

export default Select;
