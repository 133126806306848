import styled, {css} from 'styled-components'

export const InputText = styled.div`
    display: flex;
    align-items: center;
    width: auto;
    height: 55px;
    background-color: #fff;
    font-size: 14px;
    transition: all ease-in-out .5s;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    ${props => (props.integratedStores && props.large) && css`
        width: 100%;
    `}

    input {
        border: none;
    }

    > svg {
        margin-right: 10px;
        min-width: 17px;
        min-height: 17px;
    }

    ${props => props.sizePlaceholder && css`
        width: ${props.sizePlaceholder + 'px'};
    `}

    ${props => props.large && css`
        width: 300px;

        input {
            font-size: 11px !important;
        }
    `}

    input {
        display: flex;
        align-items: center;
        width: 99.1%;
        height: 55px;
        border-radius: 5px;
        font-size: 14px;
        padding: 0px 10px 0px 20px;
        outline: none;
        border: none;
    }
`
