import React, { useContext, useState, useEffect, useRef } from 'react';
import { ContextReducer } from '../../../reducer/AdminReducer';
import { Align } from '../styles';
import {Other, Svg, SubTitle, Container } from './styles';

import { BiLike, BiDislike } from "react-icons/bi";
import { BsLightningCharge } from "react-icons/bs";

import {ReactComponent as IconBox} from '../../../assets/IconBox.svg';
import {ReactComponent as IconCalendar} from '../../../assets/IconCalendar.svg';
import {ReactComponent as IconIp} from '../../../assets/IconIp.svg';

import Historic from '../../Historic';
import useShouldRender from '../../../hooks/useShouldRender';

const MoreInfo = (props) => {

    const { state } = useContext(ContextReducer);

    const {  helpful, unhelpful, tqi, transaction, location, author, id } = props.values;

    const containerRef = useRef(null);

    const { shouldRender } = useShouldRender(containerRef, props.showMore, 'number');

    const renderAlign = () => {
        return (
            <Align space='15' direction='top' wrap>
                <Other>
                    <Svg>
                        <BiLike />
                    </Svg>
                    <span>{state?.i18n?.other?.helpful} {helpful}</span>
                </Other>
                <Other>
                    <Svg>
                        <BiDislike />
                    </Svg>
                    <span>{state?.i18n?.other?.unhelpful} {unhelpful}</span>
                </Other>
                <Other>
                    <Svg>
                        <BsLightningCharge />
                    </Svg>
                    <span>{state?.i18n?.other?.tqi} {tqi}</span>
                </Other>
                {transaction?.id ? <Other>
                    <Svg>
                        <IconBox />
                    </Svg>
                    <span>{state?.i18n?.other?.id} {transaction.id}</span>
                </Other> : <></>}
                {transaction?.date ? <Other>
                    <Svg>
                        <IconCalendar />
                    </Svg>
                    <span>{state?.i18n?.other?.date} {transaction.date}</span>
                </Other> : <></>}
                {location?.locationIp ? <Other>
                    <Svg>
                        <IconIp />
                    </Svg>
                    <span>{state?.i18n?.other?.ip} {location?.locationIp}</span>
                </Other> : <></>}
            </Align>
        )
    }

    if(!shouldRender) {
        return null;
    }

    return (
        <>
            <Container
                isLeaving={props.showMore === props.indexItem}
                ref={containerRef}
            >
                <SubTitle>{state?.i18n?.more?.infos}</SubTitle>
                {renderAlign()}
                <SubTitle>{state?.i18n?.more?.historic}</SubTitle>
                <Align
                    space='15'
                    direction='top'
                    wrap
                >
                    <Historic
                        id={id}
                        index={props.indexItem}
                        author={author}
                        type={state.actualArea}
                    />
                </Align>
            </Container>
        </>
    );
};

export default MoreInfo;
