import styled, {css, keyframes} from "styled-components";

export const ContentCards = styled.div`
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, 195px);
    
    ${props => props.noItem && css`
        display: flex;
    `}
`

export const AnimateLoader = keyframes`
    to{transform: rotate(1turn)}
`
export const Loader = styled.div`
    width:50px;
    height:50px;
    border-radius:50%;
    border:8px solid;
    border-color:#E4E4ED;
    border-right-color: #000;
    display: inline-block;
    animation:${AnimateLoader} 1s infinite linear;
    margin: 50px 0;
`