import {createGlobalStyle, css} from 'styled-components'


const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    #root {
        background-color: #EFF0F0;
        height: 100%;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    html {
        padding-top: 60px;
    }

    ${props => props.padding === '' && css`
        html {
            padding-top: 0;
        }
    `}
    body > iframe {
        display: none
    }

    html, body {
        font: 300 13px/1.2, sans-serif;
        font-family: 'Open Sans', sans-serif;
        background-color: #EFF0F0;
        overflow-x: hidden !important;
        scroll-behavior: smooth;
        height: 100%;
    }


    input[type="text"] {
        background: #fff;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    textarea:focus-visible {
        outline: #000 auto 1px !important;
    }

    textarea:focus, input:focus, select:focus {
        outline: none;
    }

    h1, h2, h3, h4, h5, h6 {
        line-height: 1.2;
        margin-bottom: .9em;
    }

    p {
        line-height: 1.25;
    }

    h1, h2, h3, h4, h5, h6,
    p {
        margin: 0;
    }

    fieldset {
        border: none;
        padding-left: 0;
        padding-right: 0;
    }
`

export default GlobalStyles
