import styled from "styled-components";

export const StyledBurguer = styled.nav`
    width: 30px;
    height: 30px;
    margin-right: 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    overflow: hidden;
    position: relative;

    div {
        width: 30px;
        height: 3px;
        background: #fff;
        border-radius: 10px;
        transform-origin: 1px;
        transition: all 0.3s linear;

        &:nth-child(1) {
           transform: ${({open}) => open ? 'rotate(45deg)' : 'rotate(0)'};
        }

        &:nth-child(2) {
            transform: ${({open}) => open ? 'translateX(100%)' : 'translateX(0)'};
            opacity:  ${({open}) => open ? 0 : 1};
        }

        &:nth-child(3){
            transform: ${({open}) => open ? 'rotate(-45deg)' : 'rotate(0)'};
        }
    }
`
