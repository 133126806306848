import React, {useContext, useState} from 'react';

import {ButtonExport} from './styles';
import {setExportData} from '../../actions/ReviewsAction';
import {ContextReducer} from "../../reducer/AdminReducer";
import CountUp from 'react-countup';
import {FiDownload} from 'react-icons/fi';
import TableUsers from './TableUsers';
import TableDash from './TableDash';
import TableNewsletter from './TableNewsletter';
import TableInteractions from './TableInteractions';

const ExportTable = (props) => {
    const {state, dispatch} = useContext(ContextReducer);
    const [active, setActive] = useState(false);

    const handleExport = () => {
        setActive(true)
        setExportData(dispatch, {
            loading: true,
            done: false
        });


        const handleStatusToName = () => {
            if(state.actualRoute === 'newsletter' || state.actualRoute === 'users' ) return '';

            if(state.actualRoute !== 'insights'){
                return state?.filters?.status.toLowerCase();
            }

            return state?.i18n?.exportTable?.typesInsights[state?.featuredType].toLowerCase();
        }

        setTimeout(() => {
            let downloadLink;
            let dataType = 'application/vnd.ms-excel;charset=utf-8';
            let tableSelect = document.getElementById('tableID');
            let tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

            let filename = `${state?.userInfo?.ecommerce?.id}_${state?.actualRoute}_${handleStatusToName()}_${state?.reviews?.page}.xls`;
            downloadLink = document.createElement("a");

            document.body.appendChild(downloadLink);

            if (navigator.msSaveOrOpenBlob) {
                let blob = new Blob(['\uFEFF' + tableHTML], { type: dataType });
                navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
                downloadLink.download = filename;
                downloadLink.click();
            }

            setExportData(dispatch, {
                loading: false,
                done: true
            });

            setActive(false);

        }, 5000);
    }

    const renderNameButton = () => {
        if(state?.visible && props.itemDownDash && !state?.exportData?.loading) return state?.i18n?.export

        if(!state?.visible) {
            if (state?.exportData?.loading) {
                return state?.i18n?.exporting
            } else if (state?.exportData?.done) {
                return state?.i18n?.done
            } else {
                return state?.i18n?.export
            }
        } else {
            if (state?.exportData?.loading) {
                return state?.i18n?.exporting
            } else if (state?.exportData?.done) {
                return state?.i18n?.done
            }
        }
    }

    const renderLoadingButton = () => {
        if (state?.exportData?.loading)
            return (
                <>
                    <CountUp start={0} duration={5} end={100}/>%
                </>
            )
    }

    const handleExportData = () => {
        if (!state?.exportData?.loading && state?.exportData?.done) {
            setTimeout(() => {
                setExportData(dispatch, {
                    loading: false,
                    done: false
                });
            }, 2000)
        }
    }

    const renderTables = () => {
        const interactions = [ 'reviews', 'questions', 'comments', 'answers', 'nps'];

        if (interactions.includes(state.actualRoute)) {
            return <TableInteractions from={state.actualRoute} handleExportData={handleExportData} />
        } else if (state?.actualRoute === 'users') {
            return <TableUsers handleExportData={handleExportData}/>
        } else if (state?.actualRoute === 'insights') {
            return <TableDash handleExportData={handleExportData} from={state?.featuredType} />;
        } else if (state?.actualRoute === 'newsletter') {
            return <TableNewsletter handleExportData={handleExportData} />;
        }
    }

    return (
        <>
            <ButtonExport
                visible={state.visible}
                dash={props.dash}
                itemDownDash={props.itemDownDash}
                done={state?.exportData?.done}
                loadingButton={state?.exportData?.loading}
                inactive={(state?.reviews?.items?.length !== 0 || state?.users?.items?.length !== 0 || state?.featuredProducts?.items?.length !== 0 || state?.featuredSeller?.items?.length !== 0 || state?.featuredBrand?.items?.length !== 0) ? false : true}
                onClick={() => handleExport()}
            >
                {(!state?.exportData?.loading && !state?.exportData?.done) &&
                    <FiDownload style={{width: '21px', height: '21px', marginRight: '10px'}}/>}
                {renderNameButton()}
                {renderLoadingButton()}
            </ButtonExport>
            {renderTables()}
        </>
    )
}

export default ExportTable;
