import styled, { css} from 'styled-components';

export const Container = styled.div`
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    max-height: 64px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;

    ${props => props.open && css`
        max-height: 225px;
        min-height: 225px;
    `}
`

export const WrapperMain = styled.div`
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;

    ${props => props.open && css`
        border-bottom: 1px solid #70707033;
    `}
`
export const ContainerSecondary = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 157px;
`

export const WrapperSecondary = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding: 0 20px;
    font-size: 16px;
`
export const BoxWarning = styled.div`
    width: 100%;
    height: 61px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -4px;
    left: 0;
    padding: 10px 20px;
    background-color: #FC0D1B1A;
    color: #FC0D1B;
    font-size: 14px;
    border-radius: 0px 0px 5px 5px;
`

