import React, {useContext} from 'react';
import { ContextReducer } from '../../../reducer/AdminReducer';
import { Align } from '../styles';
import { Wrapper } from './styles';

const ProductName = (props) => {
    const { state } = useContext(ContextReducer);

    const { productInfo, npsType } = props.values;

    const renderProductNameConditions = () => {
        if(state.actualRoute === 'nps') {
            if(npsType === 'delivery') {
                return productInfo?.name;
            } else {
                return ''
            }
        }

        return productInfo?.name
    }

    return (
        <Align space='10' direction='bottom'>
            <Wrapper href={productInfo?.url} target='_blank'>{renderProductNameConditions()}</Wrapper>
        </Align>
    );
};

export default ProductName;
