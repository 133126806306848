import React, {useContext, useMemo, useCallback, useEffect} from 'react';
import {Link} from "react-router-dom";
import {ButtonBack, ContainerInitial, Wrapper} from './styles';

import {setRoute} from "../../actions/ReviewsAction";

import video from '../../assets/estrela-vurdere.mp4'

import {ContextReducer} from '../../reducer/AdminReducer';

const InitialScreen = () => {
    const {state, dispatch} = useContext(ContextReducer);

    const _availableAreas = ['store', 'marketplaces', 'mentions'];

    const handleClick = useCallback((type) => {
        setRoute(dispatch, type)
    }, [dispatch])

    useEffect(() => {
        if(_availableAreas.includes(state?.actualArea)) {
            window.localStorage.setItem('vurdere-type', state?.actualArea);
        }
    }, [state?.actualArea]);

    const renderTexts = useCallback(() => {
        if (state?.typeEcommerce[state?.actualArea]) {
            return (
                <>
                    <h2>{state?.i18n?.seller?.screens[state.actualArea]?.title}</h2>
                    <h3>{state?.i18n?.seller?.screens[state?.actualArea]?.body}</h3>
                    <ButtonBack>
                        <Link
                            to={'/insights'}
                            onClick={() => handleClick('insights')}
                        >
                            {state?.i18n?.seller?.screens[state?.actualArea]?.button}
                        </Link>
                    </ButtonBack>
                </>
            )
        }

        return (
            <>
                <h2>{state?.i18n?.seller?.screens[state?.actualArea]?.blocked?.title}</h2>
                <h3>{state?.i18n?.seller?.screens[state?.actualArea]?.blocked?.body}</h3>
                <ButtonBack>
                    <a
                        href="https://www.vurdere.com/contact"
                        target="_blank"
                        rel="noopener noreferrer">
                        {state?.i18n?.seller?.screens[state?.actualArea]?.blocked?.button}
                    </a>
                </ButtonBack>
            </>
        )

    }, [state?.i18n?.seller?.screens, state?.actualArea, handleClick, state?.typeEcommerce])

    return useMemo(() => {
        return (
            <ContainerInitial>
                <Wrapper>
                    {renderTexts()}
                </Wrapper>
                <video
                    width="420"
                    height="550"
                    autoPlay
                    loop
                    muted
                    playsInline
                >
                    <source src={video} type="video/mp4"/>
                </video>
            </ContainerInitial>
        )
    }, [renderTexts])
}

export default InitialScreen;
