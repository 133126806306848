import styled, {css} from "styled-components";

const getStatus = (status) => {
    switch (status) {
        case 'APPROVED':
            return css`
                background-color: #0000001a;
                color: #000;
            `
        case 'DELETED':
            return css`
                background-color: rgba(237, 93, 93, .1);
                color: #ED5D5D;
            `
        case 'REJECTED':
            return css`
                background-color: rgba(255, 114, 0, .1);
                color: #ff7200;
            `
        case 'PENDING':
            return css`
                border: 1px solid #000;
                background-color: #ffffff;
                color:#000
            `
        case 'default':
            return css`
                border: 1px solid #B6BAC4;
                background-color: #ffffff;
                color:#000
            `
    }
}

export const Status = styled.div`
    border: 1px solid #B6BAC4;
    min-width: 30px;
    width: auto;
    max-height: 30px;
    box-sizing: border-box;
    font-size: 14px;
    text-align: center;
    border-radius: 5px;
    position:relative;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 25px 5px 25px;
    margin-right: 10px;
    margin-bottom: 10px;
    border: none;

    &:last-child {
        margin-right: 0;
    }


    ${props => props.nomargin && css`
        margin-bottom: 0;
    `}

    ${props => props.authorSystem && css`
        border: 1px dashed #B6BAC4 !important;
    `}

    ${props => (!props.authorSystem && props.statusBorder) && css`
        border: 1px solid #B6BAC4 !important;
    `}

    ${props => getStatus(props.status)}
`

export const Delete = styled.div`
    position:absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(92,92,92,0.5);
    font-weight: bold;
    right: 2px;
    top:50%;
    transform:translateY(-50%);
    font-size:10px;
    cursor:pointer;
    width: 18px;
    height: 18px;

    &:hover{
        transition: all ease-in .15s;
        border-radius: 50%;
        background-color: #000000;

        > svg {
            path{
                height: inherit;
                width: inherit;
                stroke: #fff;
            }
        }
    }
`

export const Sentiment = styled.span`
    width: 5px;
    height: 5px;
    background-color: #808080;
    display: block;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;


    ${props => props.type === 'negative' && css`
        background-color: #FF4208 !important;
    `}

    ${props => props.type === 'positive' && css`
        background-color: #71CE4F !important;
    `}

    ${props => props.type === 'mixed' && css`
        background-color: #ffa100 !important;
    `}
`
