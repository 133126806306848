import React from 'react';

const Radio = ({ value, defaultChecked, name }) => {
    return (
        <input
            type="radio"
            value={value}
            name={name}
            defaultChecked={defaultChecked}
        />
    );
};

export default Radio;
