import styled, {css, keyframes} from 'styled-components';


const growAndSlide = keyframes`
    from {
        transform: scaleX(0);
        transform-origin: right;
    }
    to {
        transform: scaleX(1);
        transform-origin: right;
    }
`;

const backSlide = keyframes`
    from {
        transform: scaleX(1);
        opacity: 1;
        transform-origin: right;
    }

    to {
        transform: scaleX(0);
        opacity: 0;
        transform-origin: right;
    }
`;

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: none;
    margin: 0 10px 0px 0px;
    height: 55px;
    position: relative;
    overflow: hidden;

    ${props => !props.open && css`
        border-radius: 5px;
    `}

    ${props => props.visible && css`
        margin: 0 15px 0px;
    `}

    ${props => props.open && css`
        width: auto;
        margin-left: 15px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        animation: ${growAndSlide} 0.5s ease-in-out;
    `}

    @media (max-width: 768px) {
        margin: 10px 10px 10px;
    }
`
export const BoxFilter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    height: inherit;

    ${props => props.open && css`
        animation: ${growAndSlide} 0.5s ease-in-out;
    `}

    ${props => !props.open && css`
        animation: ${backSlide} 0.3s ease-in-out;
    `}
`
export const Division = styled.div`
    width: 1px;
    height: 80%;
    background-color: #000;
    opacity: 0.3;
    margin-left: 10px;
`
export const BoxZoom = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 55px;
    background-color: #fff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    ${props => !props.open && css`
        border-radius: 5px;
    `}

    ${props => props.open && css`
        cursor: pointer;
        width: 40px;

        span {
            display: none;
        }

        > svg {
            margin: 0;
        }
    `}

    span {
        margin: 0 10px;
        font-size: 12px;
        font-weight: bold;
    }

    > svg {
        width: 21px;
        height: 21px;

        ${props => (!props.open && !props.visible) && css`
            margin-left: 10px;
        `}
    }
`
