import styled, {css} from "styled-components";

const getArea = (area) => {
    switch (area) {
        case 'actions':
            return css`
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                margin-top: 40px;

                @media (max-width: 768px) {
                    flex-flow: column;
                }

                ${props => props.visible && !props.isStore && css`
                    position: fixed;
                    top: 60px;
                    left: 0;
                    background-color: #fff;
                    z-index: 8;
                    margin: 0;
                    padding: 10px 30px;
                `}
            `
        case 'actions-insights':
            return css`
                display: flex;
                justify-content: center;
                align-items: flex-start;
                margin-top: 30px;
                flex-direction: column;

                ${props => props.isNewsletter && css`
                    height: auto;
                `}

                @media (max-width: 768px) {
                    flex-flow: column;
                }
        `
        case 'filter':
            return css`
                margin: 30px auto 0px auto;
                height: auto;
            `

        case 'config':
            return css`
                margin: 30px 0 0 0;
                display: flex;
            `
        case 'listheader':
            return css`
                display: flex;
                min-height: 30px;
                max-height: 30px;
                border-radius: 10px;
                align-items: center;
                padding: 15px 30px;
                justify-content: space-between;
                width: 100%;
                margin: 20px 0px 5px 0px;
                background-color: #fff;

                @media (max-width: 768px) {
                    display: none;
                }
            `
        case 'listheaderNewsletter':
            return css`
                display: flex;
                min-height: 60px;
                max-height: 60px;
                border-radius: 10px;
                align-items: center;
                padding: 15px 30px;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 10px;
                background-color: #fff;

                @media (max-width: 768px) {
                    display: none;
                }
            `
        case 'listitems':
            return css`
                position: relative;
                background-color: #fff;
                display: flex;
                border-radius: 10px;
                align-items: center;
                padding: 30px;
                justify-content: space-between;
                width: 100%;
                height: 100%;
                margin-bottom: 31px;
                transition: all ease .5s;

                ${props => props.isNpsRoute && css`
                    align-items: flex-start;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                `}

                ${props => props.active && css`
                    height: auto;
                `}

                @media(max-width: 768px) {
                    flex-wrap: wrap;
                    justify-content: center;
                }
            `
        case 'listanswers':
            return css`
                    background-color: #fff;
                    display: flex;
                    flex-flow: column;
                    border-radius: 5px;
                    justify-content: space-between;
                    width: 100%;
                    max-height: 0;
                    position: relative;
                    opacity: 0;
                    transition: all ease-in .25s;
                    padding: 0;

                    ${props => props.active && css`
                        opacity: 1;
                        max-height: inherit;
                        visibility: visible;
                        margin-bottom: 10px;
                    `}
            `

        case 'insights':
            return css`
                    display: flex;
                    flex-flow: row;
                    justify-content: space-between;
                    width: 100%;
                    overflow: hidden;
                    margin-top: 10px;
            `

        case 'graphics':
            return css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 30px;
                    border-radius: 15px;

                    > div {
                        background-color: #fff;
                        width: 100%;
                        border-radius: 15px;
                    }
            `

        case 'pagination-dash':
            return css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 30px;
                    border-radius: 15px;
            `

        case 'table-dash':
            return css`
                    min-height: 550px;
            `

        case 'stores':
            return css`
                    text-align: center;
                `
        case 'cx':
            return css`
                display: flex;
                flex-flow: row;
                margin-top: 20px;
            `
        case 'cxfilter':
            return css`
                margin-top: 20px;
            `
        default:
           return ''
    }
}

export const Container = styled.div`
    width: 100%;
    position: relative;
    margin: 0 auto;
    padding: 0 30px;
    transition: all ease .25s;

    ${props => getArea(props.area)}
`
export const ListHeaderItem = styled.div`

    ${props => props.nomobile && css`
        @media (max-width: 768px) {
            display: none;
        }
    `}

    ${props => props.isNpsRoute && css`
        align-self: center;
    `}

    ${props => props.type === 'description' && css`
        width: 66%;
    `}

    ${props => props.width && css`
        width: ${props => props.width}px;

        @media (max-width: 768px) {
            width: 100%;
        }
    `}
    ${props => (props.width && props.porcentage) && css`
        width: ${props => props.width}%;

        @media (max-width: 768px) {
            width: 100%;
        }
    `}

    ${props => props.align && css`
        text-align: ${props => props.align};
    `}
    ${props => (props.align && props.isNpsRoute) && css`
        text-align: ${props => props.align};
        align-self: center;
    `}

    ${props => props.type === 'date' && css`
        margin: 0 25px;
    `}

    ${props => props.type === 'items' && css`
        width: 80%;
    `}

    ${props => props.type === 'buttons' && css`
        display: flex;
        align-items: center;
        justify-content: center;
    `}

    ${props => (props.type === 'buttons' &&  props.allItems) && css`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-left: 10px;
    `}

    ${props => props.type === 'tags' && css`
        display: flex;
        flex-wrap: wrap;
        align-items: start;
        margin-top: 25px;
        position: relative;
        min-height: auto;
        height: auto;
        overflow: hidden;

        @media (max-width: 768px) {
            justify-content: align-items;
            flex-flow: column;
            align-items: flex-start;
            margin: 15px 0;
        }
    `}

    span {
        color: #000000;
        font-size: 14px;
    }

    ${props => (props.type === 'tags' && props.activeTags) && css`
        height: auto;
    `}

    ${props => props.fx && css`
        position: fixed;
        top: 50%;
        left: -45px;
        width: 40px !important;
        transform: translateY(-50%);
        transition: all ease .25s;
    `}

    ${props => props.active && css`
        left: 10px;
    `}

    p {
        font-size: 14px;
        color: #000000;
    }
    ${props => getArea(props.area)}
`
