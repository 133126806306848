import React, {useContext} from 'react';

import {Item} from './styles';

import {ContextReducer} from "../../reducer/AdminReducer";

const Verified = (props) => {

    const {state} = useContext(ContextReducer)

    const renderIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path className="a" style={{fill: '#40AA60', opacity: 1}} fill="#40AA60"
                  d="M8,0a8,8,0,1,0,8,8A8.024,8.024,0,0,0,8,0ZM7,11.4,3.6,8,5,6.6l2,2,4-4L12.4,6Z"/>
        </svg>
    }

    return (
        <Item>
            {renderIcon()}
            <span>{state?.i18n?.listItem?.verified}</span>
        </Item>
    );
}

export default Verified;
