import React, {useContext} from 'react'

import {Container, ListHeaderItem} from '../../Containers/styles';
import {Align} from '../Items/styles';
import {Fake} from './styles'
import {ContextReducer} from '../../reducer/AdminReducer';


const LoaderInfos = () => {

    const {state, dispatch} = useContext(ContextReducer);

    const renderUsers = () => {
        return (
            <Container area='list'>
                <Container area='listitems'>
                    <ListHeaderItem width='30' height='30' style={{borderRadius: '50%'}} align='center'>
                        <Fake/>
                    </ListHeaderItem>
                    <ListHeaderItem width='170' align='center'>
                        <Fake/>
                    </ListHeaderItem>
                    <ListHeaderItem width='440' align='left'>
                        <Align space='10' direction='bottom'>
                            <Fake/>
                        </Align>
                    </ListHeaderItem>
                    <ListHeaderItem>
                        <Fake height='100'/>
                    </ListHeaderItem>
                    <ListHeaderItem width='80' align='center'>
                        <Fake/>
                    </ListHeaderItem>
                </Container>
            </Container>
        );
    }

    const renderDefault = () => {
        return (
            <Container area='list'>
                <Container area='listitems'>
                    <ListHeaderItem width='50' align='center'>
                        <Fake/>
                    </ListHeaderItem>
                    <ListHeaderItem width='100'>
                        <Fake height='100'/>
                    </ListHeaderItem>
                    <ListHeaderItem width='440' align='left'>
                        <Align space='10' direction='bottom'>
                            <Fake/>
                        </Align>
                        <Align space='10' direction='bottom'>
                            <Fake/>
                        </Align>
                        <Fake/>
                    </ListHeaderItem>
                    <ListHeaderItem>
                        <Fake height='100'/>
                    </ListHeaderItem>
                    <ListHeaderItem width='80' align='center'>
                        <Fake/>
                    </ListHeaderItem>
                    <ListHeaderItem width='165' align='left'>
                        <Fake/>
                    </ListHeaderItem>
                    <ListHeaderItem width='40' align='center'>
                        <Fake height='100'/>
                    </ListHeaderItem>
                </Container>
                <Container area='listitems'>
                    <ListHeaderItem width='50' align='center'>
                        <Fake/>
                    </ListHeaderItem>
                    <ListHeaderItem width='100'>
                        <Fake height='100'/>
                    </ListHeaderItem>
                    <ListHeaderItem width='440' align='left'>
                        <Align space='10' direction='bottom'>
                            <Fake/>
                        </Align>
                        <Align space='10' direction='bottom'>
                            <Fake/>
                        </Align>
                        <Fake/>
                    </ListHeaderItem>
                    <ListHeaderItem>
                        <Fake height='100'/>
                    </ListHeaderItem>
                    <ListHeaderItem width='80' align='center'>
                        <Fake/>
                    </ListHeaderItem>
                    <ListHeaderItem width='165' align='left'>
                        <Fake/>
                    </ListHeaderItem>
                    <ListHeaderItem width='40' align='center'>
                        <Fake height='100'/>
                    </ListHeaderItem>
                </Container>
            </Container>
        )
    }

    return (state?.actualRoute === 'users') ? renderUsers() : renderDefault();
}

export default LoaderInfos;
