import styled, {css, keyframes} from 'styled-components';


export const openBox = keyframes`
    from {
        transform: scaleY(0.2);
        transform-origin: right;
    }
    to {
        transform: scaleY(1);
        transform-origin: right;
    }
`

export const Container = styled.ul`
    min-width: ${props => props.optionsWidth}px;
    max-height: 55px;
    background-color: #fff;
    cursor: pointer;
    position: relative;

    display: flex;
    align-items: space-between;
    flex-direction: column;

    border: none;
    border-radius: 5px;

    z-index: 2;
    margin-left: 10px;
    margin-right: 10px;

    ${props => props.active && css`
        min-width: 330px;
        height: 270px;
        animation: ${openBox} 0.5s ease;
    `}

    ${props => !props.noItemsSelect && css`
        display: none;
    `}

    ${props => props.visible && css`
        animation: none;
        transition: none;
    `}

    ${props => (props.visible && !props.active) && css`
        width: 60px;
        max-width: 60px;
        min-width: 60px;


        div > span {
            margin-right: 5px;
        }
    `}

    @media screen and (max-width: 768px){
        margin: 10px 0px 0px 0px;
    }


`
export const Item = styled.li`
    width: 100%;
    min-width: 150px;
    min-height: 55px;

    ${props => (props.visible && !props.active) && css`
        width: 60px;
        max-width: 60px;
        min-width: 60px;
    `}

    font-size: 12px;
    background-color: #fff;

    border-top: 1px solid #dfdfdf;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 10px;
    padding-right: 0;

    font-size: 12px;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;


    cursor: pointer;
    opacity: 1;

    ${props => props.visible && css`
        transition: none;
        animation: none;
        margin-left: 15px;
    `}

    ${props => props.active && css`
        min-width: 330px;
        height: 270px;
    `}

    :nth-child(1) {
        border-top: 0;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;

        :hover{
            background-color: #fff;
            color: #000;

            > svg {
                path {
                    background-color: #000;
                }
            }
        }
    }

    :last-child {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    ${props => props.showOnlyFirstItem && css`
        opacity: 0;
        pointer-events: none;
        display: none;
    `}

    ${props => props.isFirstItemAlone && css`
        border-radius: 5px;
    `}

    div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    svg {
        margin-right: 1px;
    }

    span {
        width: inherit;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-right: 10px;
    }


    :hover{
            background-color: #000;
            color: #fff;

            svg {
                path {
                    background-color: #fff;
                }
            }
        }
`
