import React, {useContext} from 'react';
import { GrAddCircle, GrSubtractCircle } from 'react-icons/gr';
import { MoreInfo, MoreInfoDefaut } from './styles';
import {ContextReducer} from "../../reducer/AdminReducer";
import {ReactComponent as IconArrow} from '../../assets/icon-arrow.svg';

const More = (props) => {
    const {state} = useContext(ContextReducer);

    if (state.actualRoute !== 'newsletter') {
        return (
            <MoreInfo active={props.open}>
                <IconArrow/>
            </MoreInfo>
        );
    } else {
        return (
            <MoreInfoDefaut>
                {!props.more ?
                    <GrAddCircle style={{height: '16px', width: '16px', color: '#000000'}}/> :
                    <GrSubtractCircle style={{height: '16px', width: '16px', color: '#000000'}}/>
                }
            </MoreInfoDefaut>
        );
    }

}

export default More;
