import { sr } from "react-date-range/dist/locale";
import styled, {css} from "styled-components";

const getActions = (actions) => {
    switch (actions) {
        case 'ocultar':
            return css`
                background-color: #000000;
                opacity: 0.5;
            `
    }
}
export const Align = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    ${props => props.space && css`
        margin-${props => props.direction}: ${props => props.space}px;
    `}

    ${props => props.wrap && css`
        flex-wrap: wrap;
    `}

    ${props => props.between && css`
        justify-content: space-between;
    `}

    ${props => props.posi && css`
        position: relative;
    `}

    ${props => props.column && css`
        flex-flow: column;
    `}

    ${props => props.mobile && css`

        @media (max-width: 768px) {
            display: flex;
        }
    `}


`

export const Date = styled.p`
    font-size: 14px;
    color: rgba(15, 28, 59, .5);
`

export const Flex = styled.div`
    display: flex;
    align-items: center;

    ${props => props.flow === 'column' && css`
        flex-flow: column;
        width: 100%;
    `}

    ${props => props.isNps && css`
        @media (max-width: 768px) {
            display: flex;
            justify-content: flex-start;
        }
    `}
`

export const Image = styled.div`
    width: 100px;
    height: 100px;

    ${props => props.isNps && css`
        width: 95px;
        height: 95px;
        border-radius: 50%;
        border: 0.1px solid #d6d6d6;
    `}

    ${props => (props.isNps && !props.isPurchase) && css`
        background-color: #fff;
        border: 0.1px solid #d6d6d6;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 50px;
        text-align: center;
    `}

    ${props => props.attachments && css`
        width: 70px;
        height: 70px;
    `}
`

export const ActionsAttachment = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-top: 5px;
    border: none;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    transition: all ease .25s;
    position: relative;

    &:hover {
        ${props => getActions(props.actions)}
        transform: scale(1.1);
    }

    ${props => props.act && css`
        ${props => getActions(props.actions)}
    `}
    &:first-child {
        margin-top: 0;
    }
`

export const TooltipAttachment = styled.div`
    position: absolute;
    top: 50%;
    left: 33px;
    transform: translateY(-50%);
    color: #ffff;
    font-size: 10px;
    text-transform: uppercase;
    border-radius: 5px;
    padding: 5px;
    ${props => getActions(props.actions)};
    white-space: nowrap;
    overflow: hidden;
    transition: all ease .25s;
    width: 0;
    height: 0;
    padding: 0;

    ${ActionsAttachment}:hover {
        width: auto;
        height: auto;
        padding: 5px;
    }
`

export const WrapperProductStatistics = styled.div`
    width: auto;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export const WrapperItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const WrapperItemLeft = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 90%;
`

export const WrapperItemRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
`
