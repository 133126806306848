import {useEffect, useState} from "react";

const useShouldRender = (ref, condition, type = '') => {
    const [ shouldRender, setShouldRender] = useState(false);

    useEffect(() => {

        const containerRefValue = ref.current;

        if(type === 'number'){
            if(condition !== null) {
                setShouldRender(true);
            }
        } else {
            if(condition) {
                setShouldRender(true);
            }
        }

        if(containerRefValue) {
            if(type === 'number'){
                if(condition === null){
                    containerRefValue.addEventListener('animationend', () => {
                        setShouldRender(false);
                    });
                }
            } else {
                if(!condition) {
                    containerRefValue.addEventListener('animationend', () => {
                        setShouldRender(false);
                    });
                }
            }
        }

        return () => {
            if(containerRefValue){
                containerRefValue.removeEventListener('animationend', () => {
                    setShouldRender(false)
                });
            }
        }

    }, [condition]);

    return { shouldRender };
}

export default useShouldRender;
