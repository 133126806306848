import React, {useContext, useEffect, useMemo} from "react";
import {ContextReducer} from "../../reducer/AdminReducer";
import {Container} from '../styles';
import {ContentCards, Loader} from './styles';
import Cards from "../../components/Cards";
import Filter from '../../components/Filter';
import HeaderFilter from "../../components/HeaderFilter";
import {getEcomm} from "../../actions/ReviewsAction";
import Pagination from "../../components/Pagination";
import NoItems from '../../components/NoItems';

const Stores = () => {
    const {state, dispatch} = useContext(ContextReducer);

    useEffect(() => {
        getEcomm(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, state.typeChangeStore, true, state?.searchName);
    }, [ state?.userInfo?.ecommerce, state.typeChangeStore, state?.searchName, state?.userInfo?.token, dispatch ]);

    const renderCards = useMemo(() => {
        if (state.ecommerce?.ecommerces?.length > 0) {
            return state.ecommerce.ecommerces.map((val, i) => {
                return <Cards card={val} key={i} index={i}/>
            })
        } else {
            return <NoItems/>
        }
    }, [state.ecommerce?.ecommerces]);

    return useMemo(() => {
        return (
            <>
                <HeaderFilter/>
                <Container area='filter'>
                    <Filter/>
                </Container>
                <Container area='pagination'>
                    <Pagination/>
                </Container>
                <Container area='stores'>
                    {state.isLoad ?
                        <ContentCards noItem={state.ecommerce?.ecommerces?.length > 0 ? false : true}>
                            {renderCards}
                        </ContentCards> :
                        <Loader/>
                    }
                </Container>
                <Container area='pagination'>
                    <Pagination/>
                </Container>
            </>
        )
    }, [state.ecommerce?.ecommerces, renderCards, state.isLoad]);
}

export default Stores;
