import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {
    CircleBG,
    CircleUser,
    CircleUser2,
    Content,
    ContentAreaTitle,
    ContentCircles,
    ContentDesk,
    ContentDeskUser,
    ContentMobile,
    ContentOver,
    Error,
    Header,
    Help,
    IconMenu,
    Items,
    ItemSeller,
    List,
    ListSeller,
    Logo,
    Mask,
    Menu,
    TitleArea,
    User,
    UserName
} from './styles';
import Notifications from '../../components/Notifications'
import Hambuger from '../../components/Hamburguer';
import {GrHelp} from 'react-icons/gr';
import {MdKeyboardArrowDown, MdKeyboardArrowUp} from 'react-icons/md';
import {
    getConfig,
    getEcomm,
    setActualArea,
    setConfigError,
    setCustomer,
    setFilter,
    setFirstAccess,
    setRoute,
    setSearchName,
    setTypeChange,
    setUser,
    setNotification,
    setRangePeriod
} from "../../actions/ReviewsAction";
import {ContextReducer} from "../../reducer/AdminReducer";
import {FaUserCircle} from 'react-icons/fa'
import {ReactComponent as SocialAnswers} from '../../assets/Social-Answers.svg';
import {ReactComponent as SocialReviews} from '../../assets/Social-Reviews.svg';
import {ReactComponent as SocialQuestions} from '../../assets/Social-Questions.svg';
import {ReactComponent as SocialUsers} from '../../assets/Social-Users.svg';
import {ReactComponent as SocialNps} from '../../assets/Social-NPS.svg';
import {ReactComponent as SocialCollections} from '../../assets/Social-Collections.svg';
import {ReactComponent as SocialComments} from '../../assets/Social-Comments.svg';
import {ReactComponent as SocialGamification} from '../../assets/Social-Gamification.svg';
import {ReactComponent as SocialIntelligence} from '../../assets/Social-Intelligence.svg';
import {ReactComponent as SocialMonetisation} from '../../assets/Social-Monetisation.svg';
import {ReactComponent as SocialNewsletter} from '../../assets/Social-Newsletters.svg';
import {ReactComponent as IconStore} from '../../assets/IconStore.svg';
import {ReactComponent as IconMarketPlace} from '../../assets/IconMarketPlace.svg';
import {ReactComponent as IconMentions} from '../../assets/IconMentions.svg';
import {ReactComponent as IconUser} from '../../assets/iconUser.svg';
import {ReactComponent as IconEcom} from '../../assets/iconEcom.svg';
import {IoWarning} from 'react-icons/io5';
import getTransformDpr from "../../utils/TransformDpr";

const Wrapper = props => {
    const {state, dispatch} = useContext(ContextReducer)
    const match = useLocation()
    const [open, setOpen] = useState(false)
    const [intro, setIntro] = useState(false)
    const [over, setOver] = useState(false);
    const [overUser, setOverUser] = useState(false)

    useEffect(() => {
        if (state?.userInfo?.isAdmin && (state.actualRoute !== 'configuration')) {
            getEcomm(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, state.typeChangeStore, true, state.searchName);
        }
    }, [])

    useEffect(() => {
        if(state.actualRoute !== 'configuration') {
            getConfig(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token);
        }
    }, [state?.userInfo])

    useEffect(() => {
        setCustomer(dispatch, state?.userInfo?.ecommerce)
    }, [])

    useEffect(() => {
        let _obj = {
            'name': state.config?.ecommerce?.name,
            'emailLogoPicture': state.config?.ecommerce?.authorInfo?.emailLogoPicture.id,
            'logoPicture': state.config?.ecommerce?.authorInfo?.logoPicture.id,
            'picture': state.config?.ecommerce?.authorInfo?.picture.id
        }
        setConfigError(dispatch, _obj)
    }, [state.config])


    useEffect(() => {
        if (state?.actualRoute === 'store' || state?.actualRoute === 'marketplaces' || state?.actualRoute === 'mentions') {
            setOpen(true)
            setIntro(false)
        } else {
            setOpen(false)
            setIntro(true)
        }
    }, [state?.actualRoute])


    const renderError = () => {
        if (!state.config?.ecommerce?.name || state.config?.ecommerce?.authorInfo?.emailLogoPicture.id === " " || state.config?.ecommerce?.authorInfo?.logoPicture.id === " " || state.config?.ecommerce?.authorInfo?.picture.id === " ") {
            return true
        } else {
            return false
        }
    }

    const _menuCustomers = [
        {
            'id': 0,
            'name': state?.i18n?.seller?.menu?.store,
            'path': '/store',
            'icon': <IconStore/>,
            'active': true
        },
        {
            'id': 1,
            'name': state?.i18n?.seller?.menu?.seller,
            'path': '/marketplaces',
            'icon': <IconMarketPlace/>,
            'active': true
        },
        {
            'id': 2,
            'name': state?.i18n?.seller?.menu?.mentions,
            'path': '/mentions',
            'icon': <IconMentions/>,
            'active': true
        }
    ]

    const _menu = [
        {
            'id': 0,
            'name': 'Insights',
            'path': '/insights',
            'icon': <SocialIntelligence/>,
            'active': state?.typeEcommerce[state?.actualArea],
        },
        {
            'id': 1,
            'name': 'Reviews',
            'path': '/reviews',
            'icon': <SocialReviews/>,
            'active': state?.typeEcommerce[state?.actualArea],
        },
        {
            'id': 2,
            'name': 'Comments',
            'path': '/comments',
            'icon': <SocialComments/>,
            'active': state?.typeEcommerce[state?.actualArea],
        },
        {
            'id': 3,
            'name': 'Questions',
            'path': '/questions',
            'icon': <SocialQuestions/>,
            'active': state?.typeEcommerce[state?.actualArea],
        },
        {
            'id': 4,
            'name': 'Answers',
            'path': '/answers',
            'icon': <SocialAnswers/>,
            'active': state?.typeEcommerce[state?.actualArea],
        },
        {
            'id': 5,
            'name': 'CX',
            'path': '/nps',
            'icon': <SocialNps/>,
            'active': state?.typeEcommerce['store'] && state?.actualArea === 'store',
        },
        {
            'id': 6,
            'name': 'Newsletter',
            'path': '/newsletter',
            'icon': <SocialNewsletter/>,
            'active': state?.typeEcommerce['store'] && state?.actualArea === 'store'
        },
        {
            'id': 7,
            'name': 'Collections',
            'path': '/collections',
            'icon': <SocialCollections/>,
            'active': false,
        },
        {
            'id': 8,
            'name': 'Gamification',
            'path': '/gamification',
            'icon': <SocialGamification/>,
            'active': false,
        },
        {
            'id': 9,
            'name': 'Monetisation',
            'path': '/monetisation',
            'icon': <SocialMonetisation/>,
            'active': false,
        },
        {
            'id': 10,
            'name': 'Users',
            'path': '/users',
            'icon': <SocialUsers/>,
            'active': state?.typeEcommerce['store'] && state?.actualArea === 'store',
        }
    ]

    const handleOpen = () => {
        setOpen(!open)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleClickLinkSeller = (type) => {
        setRoute(dispatch, type.split('/')[1])
        setActualArea(dispatch, type.split('/')[1])
        setFilter(dispatch, {
            status: 'PENDING',
            order: 'newer',
            tags: null,
            orderUsers: 'newer',
            orderFeaturedProducts: 'reviews',
            sortFeaturedProducts: 'desc',
            listType: type,
            orderNewsletter: 'transactionDate',
            sortNewsletter: 'desc'
        });
        setTypeChange(dispatch, 'store')
        setSearchName(dispatch, null)
    }

    const handleClickLink = (link) => {
        setRoute(dispatch, link.split('/')[1])
        setFilter(dispatch, {
            status: 'PENDING',
            order: 'newer',
            tags: null,
            tag: '',
            sentimentTag: '',
            authorTag: '',
            orderUsers: 'newer',
            orderFeaturedProducts: 'reviews',
            sortFeaturedProducts: 'desc',
            listType: state?.actualArea,
            orderNewsletter: 'transactionDate',
            sortNewsletter: 'desc',
            orderInputUser: '',
            orderSelectSearch: '',
            orderSelectExperienceNps: '',
            listFiltersHeader: {}
        });

        setRangePeriod(dispatch, [{}]);

        dispatch({
            type: 'SET_TAGS_HEADER_FILTER',
            payload: ['positive', 'negative', 'mixed', 'neutral', 'operator', 'user', 'system']
        })

        dispatch({
            type: 'SET_TOTALTAGS',
            payload: {
                tags: [],
                lastEvaluatedKey: null
            }
        })
        setFirstAccess(dispatch, false);
        setTypeChange(dispatch, 'store')
        setSearchName(dispatch, null)
    }

    const handleLogout = () => {
        localStorage.removeItem('vurdere_session')
        localStorage.removeItem('vurdere_ecomm')
        localStorage.removeItem('vurdere-type')
        setUser(dispatch, {
            "date": null,
            "ecommerceId": null,
            "email": null,
            "isAdmin": false,
            "token": null,
            "userId": null,
            "valid": false
        })
        setFirstAccess(dispatch, false);

    }

    const renderSellersItems = () => {
        return _menuCustomers.map((v, i) => {
            return (
                <ItemSeller key={v.id} active={state?.actualArea === v.path.split('/')[1]}>
                    <Link to={v.path} onClick={() => handleClickLinkSeller(v.path)}>
                        {v.icon}
                        <strong>{v.name}</strong>
                    </Link>
                </ItemSeller>
            )
        })
    }

    const renderMenuItems = () => {
        return _menu.map((v, i) => {
            return (
                <Items
                    key={v.id}
                    active={(match.pathname === v.path && v.active) ? true : false}
                    comming={v.active}
                >
                    {
                        v.active ?
                        <Link to={v.path} onClick={() => handleClickLink(v.path)}>
                            {v.icon} Social+ <strong>{v.name}</strong>
                        </Link> :
                        <a>{v.icon} Social+ <strong>{v.name}</strong></a>
                    }
                </Items>
            )
        })
    };

    const handleClickLinkStore = (type) => {
        if((state?.userInfo?.isAdmin && type === 'configuration') || type === 'stores') {
            setRoute(dispatch, type)
            setFilter(dispatch, {
                status: 'PENDING',
                order: 'newer',
                tags: null,
                tag: '',
                sentimentTag: '',
                authorTag: '',
                orderUsers: 'newer',
                orderFeaturedProducts: 'reviews',
                sortFeaturedProducts: 'desc',
                listType: state?.actualArea,
                orderNewsletter: 'transactionDate',
                sortNewsletter: 'desc',
                orderInputUser: '',
                listFiltersHeader: {}
            });
            setFirstAccess(dispatch, false);
            setTypeChange(dispatch, 'store');
            setSearchName(dispatch, null);
        } else {
            const _role = 'accessNotAllowed';

            setNotification(dispatch, {status: true, role: _role});
            setTimeout(() => {
                setNotification(dispatch, {
                    'status': false,
                    'role': _role
                })
            }, 3000);
        }
    }

    const handleMouseEnter = (type) => {
        if (type === 'desk') {
            setOver(true)
        } else {
            setOverUser(true)
        }
    }

    const handleMouseLeave = (type) => {
        if (type === 'desk') {
            setOver(false)
        } else {
            setOverUser(false)
        }
    }

    const handleToLink = (to) => {
        if(state?.userInfo?.isAdmin && to === 'configuration') {
            return 'configuration'
        } else if(to === 'stores') {
            return 'stores'
        }
    }

    return useMemo(() => {
        return (
            <>
                <Notifications
                    area={match.pathname}
                    role={state?.notification.role}
                    active={state?.notification.status}
                />
                <Header>
                    <Content>
                        <IconMenu onClick={handleOpen}>
                            <Hambuger value={open}></Hambuger>
                        </IconMenu>
                        <ContentAreaTitle>
                            <Logo/>
                            <TitleArea>{state?.actualArea === 'store' ? 'Minha Loja' : state?.actualArea}</TitleArea>
                        </ContentAreaTitle>
                        <User>
                            <ContentDesk
                                onMouseEnter={() => handleMouseEnter('desk')}
                                onMouseLeave={() => handleMouseLeave('desk')}
                            >
                                <ContentCircles>
                                    <CircleBG style={{background: `url(https://${state.userInfo.ecommerce.domain}/image/upload/t_favicons_dpr_${getTransformDpr(window.devicePixelRatio)}/f_auto/${state.config?.ecommerce?.authorInfo?.picture.id.length > 1 ? state.config?.ecommerce?.authorInfo?.picture.id : false}.jpeg) center center / contain no-repeat`}} />
                                    <CircleUser>
                                        <IconEcom/>
                                    </CircleUser>
                                    {renderError() ?
                                        <Error>
                                            <IoWarning style={{height: '16px', width: '16px', color: '#FF4421'}}/>
                                        </Error> :
                                        <></>
                                    }
                                </ContentCircles>
                                <span>{state.config?.ecommerce?.name}</span>
                                {over ?
                                    <MdKeyboardArrowUp style={{color: '#000 ', marginLeft: '10px'}}/> :
                                    <MdKeyboardArrowDown style={{color: '#fff ', marginLeft: '10px'}}/>
                                }
                                <ContentOver>
                                    <Link
                                        to={handleToLink('configuration')}
                                        onClick={() => handleClickLinkStore('configuration')}
                                    >
                                        {state?.i18n?.stores?.config}
                                        {renderError() && <span>{state?.i18n?.configuration?.error?.add}</span>}
                                    </Link>
                                    <Link
                                        to={handleToLink('stores')}
                                        onClick={() => handleClickLinkStore('stores')}
                                    >
                                        {state?.i18n?.stores?.change}
                                    </Link>
                                </ContentOver>
                            </ContentDesk>
                            <ContentDeskUser
                                onMouseEnter={() => handleMouseEnter('user')}
                                onMouseLeave={() => handleMouseLeave('user')}
                            >
                                <CircleUser2>
                                    <IconUser/>
                                </CircleUser2>
                                <UserName>{state?.userInfo?.email.split('@')[0]}</UserName>
                                {overUser ?
                                    <MdKeyboardArrowUp style={{color: '#000 ', marginLeft: '10px'}}/> :
                                    <MdKeyboardArrowDown style={{color: '#fff ', marginLeft: '10px'}}/>
                                }
                                <ContentOver>
                                    <a onClick={handleLogout}>{state?.i18n?.stores?.logout}</a>
                                </ContentOver>
                            </ContentDeskUser>
                            <ContentMobile>
                                <FaUserCircle/>
                            </ContentMobile>
                        </User>
                    </Content>
                    <Menu active={open}>
                        <ListSeller>
                            {renderSellersItems()}
                        </ListSeller>
                        <List>
                            {renderMenuItems()}
                        </List>
                    </Menu>
                </Header>
                {props.children}
                <Help type="button">
                    <GrHelp/>
                </Help>
                {(open && intro) && <Mask onClick={handleClose}/>}
            </>
        );
    }, [open, intro, match, over, overUser, state.notification, state.configError, state.actualRoute, state.config, state.searchName, state.config?.ecommerce?.authorInfo, state.userInfo, state.filters, state?.typeEcommerce, state?.userInfo?.ecommerce, state?.actualArea, state.i18n, state.config, state.typeChangeStore])
}

export default Wrapper;
