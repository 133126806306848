import styled, { css } from 'styled-components';


export const FlexCol = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`
export const Avatar = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 0.1px solid #d8d8d8;
    margin-right: 5px;
    cursor: pointer;

    ${props => props.small && css`
        width: 20px;
        height: 20px;
    `}
`

export const Name = styled.p`
    color: #000000;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;

    span {
        color: #20BFF0;
        font-size: 12px;
        display: block;
        margin-left: 5px;
        font-weight: normal;
    }
`

export const TitleQuestion = styled.h4`
    font-size: 10px;
    color: #000000;
    display: block;
    margin-top: 10px;
    font-weight: normal;
`

export const ContentQuestion = styled.div`
    display: flex;
    flex-flow: column;
    align-items: start;
    position: relative;
    border-bottom: 1px solid #bebebe;
    margin-bottom: 15px;
    padding-bottom: 15px;
    margin-top: 15px;
`

export const Small = styled.p`
    font-size: 10px;
    text-transform: uppercase;
    color: #000000;
    letter-spacing: 1px;
    position: absolute;
    top: 0;
    right: 0;
`

export const Initials = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    border-radius: 50%;
    margin-right: 5px;
    background-color: #000000;
    color: #fff;
    cursor: pointer;

    @media (max-width: 768px) {
        max-width: 30px;
        min-width: 30px;
    }

    ${props => props.small && css`
        width: 20px;
        height: 20px;
        font-size: 10px;
    `}

    ${props => props.pointer && css`
        cursor: pointer;
    `}
`
export const TooltipCopy = styled.div`
    background-color: #d4edda;
    color: #155724;
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: 12px;
    border-radius: 5px;
    position: absolute;
    top: -40px;
    left: 0;

    &:before {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #d4edda;
    }
`

export const EmailUser = styled.p`
    font-size: 11px !important;
    color: rgba(15, 28, 59, .6) !important;

    ${props => props.isStore && css`
        cursor: pointer;
    `}
`
export const ContentStore = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

    span {
        color: #20BFF0;
        font-size: 12px;
        display: block;
        margin-left: 5px;
    }
`

export const NameQuestion = styled.p`
    font-size: 10px !important;
    color: #000000;
    display: block;
    margin-left: 5px;
    display: block;
    font-weight: bold;
`
