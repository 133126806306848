import React, {useCallback, useContext, useState, useMemo} from 'react';
import {ContextReducer} from '../../../reducer/AdminReducer';
import Item from './Item';
import {Container, WrapperItems} from './styles';
import {getDashboard, getGraphic, getFeatured, getNewsletterCards, getNewsletters, getReviews, getUsers, setFilter, getCardsCx, setRangePeriod} from '../../../actions/ReviewsAction';
import { format } from 'date-fns';

const ListFiltersSearch = () => {
    const {state, dispatch} = useContext(ContextReducer);
    const [show, setShow] = useState(false);

    const handleExcludeItem = useCallback((index) => {
        let resetPeriod = false;

        let _filters = {...state?.filters};
        let _filtersHeader = { ...state.filters.listFiltersHeader};

        const filter = {
            ..._filters,
            listFiltersHeader: _filtersHeader,
            orderInputUser: index === state.filters.orderSelectSearch ? '' : state.filters.orderInputUser
        }

        if(index === 'period') {
            setRangePeriod(dispatch, [{}]);
            resetPeriod = true;
        }

        if(index === 'tag') {
            filter.tag = "";
            filter.sentimentTag = null;
            filter.authorTag = null;
        }

        delete filter.listFiltersHeader[index];

        setFilter(dispatch, filter);

        if (state.actualRoute === 'users') {
            getUsers(dispatch, filter, state?.userInfo?.ecommerce, state?.userInfo?.token, '', '', '',  (state.rangePeriod[0].startDate && !resetPeriod && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', (state.rangePeriod[0].endDate && !resetPeriod && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '');
        } else if (state.actualRoute === 'newsletter') {
            getNewsletterCards(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, filter, '', (state.rangePeriod[0].startDate && !resetPeriod && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', (state.rangePeriod[0].endDate && !resetPeriod && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '');
            getNewsletters(dispatch, filter, state?.userInfo?.ecommerce, state?.userInfo?.token, '', '', '', (state.rangePeriod[0].startDate && !resetPeriod && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', (state.rangePeriod[0].endDate && !resetPeriod && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '');
        } else if (state.actualRoute === 'nps') {
            getReviews(state.actualRoute, dispatch, filter, state?.userInfo?.ecommerce, state?.userInfo?.token, '', '', '', (state.rangePeriod[0].startDate && !resetPeriod && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', (state.rangePeriod[0].endDate && !resetPeriod && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '');
            getCardsCx(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token,  filter, (state.rangePeriod[0].startDate && !resetPeriod && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', (state.rangePeriod[0].endDate && !resetPeriod && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '', '')
        } else if(state.actualRoute === 'insights') {
            getDashboard(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, filter, (state.rangePeriod[0].startDate && !resetPeriod && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', (state.rangePeriod[0].endDate && !resetPeriod && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '');
            getGraphic(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, (state.rangePeriod[0].startDate && !resetPeriod && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', (state.rangePeriod[0].endDate && !resetPeriod && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '');
            getFeatured(dispatch, state?.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, null, 0, state.featuredType, (state.rangePeriod[0].startDate && !resetPeriod && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', (state.rangePeriod[0].endDate && !resetPeriod && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '');
        } else {
            getReviews(state.actualRoute, dispatch, filter, state?.userInfo?.ecommerce, state?.userInfo?.token, '', '', '', (state.rangePeriod[0].startDate && !resetPeriod && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', (state.rangePeriod[0].endDate && !resetPeriod && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '');
        }

        setShow(Object.keys(state?.filters?.listFiltersHeader).length === 0 ? false : true);
        window.document.body.scrollTop = 0;
    }, [dispatch, state.actualRoute, state.featuredType, state.filters, state.rangePeriod, state?.userInfo?.ecommerce, state?.userInfo?.token])


    const listFilters = useMemo(() => {
        setShow(Object.keys(state?.filters?.listFiltersHeader).length === 0 ? false : true);

        return Object.keys(state?.filters?.listFiltersHeader).map(key => {
            return (
                <Item
                    key={key}
                    indexKey={key}
                    text={state.filters.listFiltersHeader[key]}
                    onExcludeItem={handleExcludeItem}
                    tag={key === 'tag' ? { authorTag: state?.filters?.authorTag, sentimentTag: state?.filters?.sentimentTag } : ''}
                />
            )
        })
    }, [ state?.filters?.listFiltersHeader, handleExcludeItem, state?.filters?.authorTag, state?.filters?.sentimentTag ]);

    const renderContainer = useMemo(() => {
        if (show && state.actualRoute !== 'stores') {
            return (
                <Container>
                    <span>{state?.i18n?.tagsAbas?.searchFor}</span>
                    <WrapperItems>
                        {listFilters}
                    </WrapperItems>
                </Container>
            )
        } else {
            return ''
        }
    }, [listFilters, show, state.actualRoute, state?.i18n?.tagsAbas?.searchFor])

    return renderContainer;
};

export default ListFiltersSearch;
