import React, { useContext, useRef, useState, useEffect } from 'react';
import { ContextReducer } from '../../../reducer/AdminReducer';
import { Container, Comment, ButtonSaveComment } from './styles';
import { setNotification } from '../../../actions/ReviewsAction';
import { changeReview } from '../../../actions/ReviewsAction';

const Description = (props) => {
    const { state, dispatch } = useContext(ContextReducer);
    const [saveComment, setSaveComment] = useState(null);
    const [published, setPublished] = useState(false);

    const InputComment = useRef();

    const { id, author, description } = props.values;

    useEffect(() => {
        const timerPublished= setTimeout(() => {
            setPublished(false);
        }, 500);

        return () => {
            clearTimeout(timerPublished)
        }
    }, [published])

    const handleComment = (idx) => {
        setTimeout(()=>{
            setSaveComment(idx);
        }, 100)
    }
    const handleChangeDescription = () => {
        setTimeout(() => {
            setPublished(true);
        }, 500);

        setNotification(dispatch, {
            'status': true,
            'role': 'COMMENTS'
        })

        setTimeout(() => {
            setNotification(dispatch, {
                'status': false,
                'role': ''
            })
        }, 3000)

        changeReview(state.actualRoute, dispatch, [
            {
                "id": id,
                "author": author
            }
        ], 'description', InputComment.current.innerText, state.filters.status, state?.userInfo?.token, state?.userInfo?.ecommerce, state?.actualArea, state?.filters);
    }

    return (
        <Container>
            <Comment contentEditable={(state?.actualArea === 'marketplaces' || state?.actualArea === 'mentions') ? false : true}
                ref={InputComment}
                onClick={() => handleComment(props.indexItem)}
                onBlur={() => handleComment(null)}
                suppressContentEditableWarning={true} published={published}
            >
                {description}</Comment>
            {(props.indexItem === saveComment && state?.actualArea === 'store') && <ButtonSaveComment onClick={handleChangeDescription}>{state?.i18n?.buttonSave?.save}</ButtonSaveComment>}
        </Container>
    )
};

export default Description;
