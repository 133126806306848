import styled, { css } from 'styled-components';

export const Container = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    max-height: 30px;
    padding: 6px 20px;
    margin: 0px 10px 10px 0px;

    color: #000000;
    background-color: #EDEEF0;
    font-size: 14px;
    border: 1px solid #B6BAC4;
    border-radius: 5px;
    transition: all .25s ease;

    cursor: pointer;

    ::first-letter {
        text-transform: uppercase !important;
    }

    &:hover {
        background-color: #000000;
        color: #fff;
        border: 1px solid #000;
    }

    ${props => props.author === 'system' && css`
        border: 1px dashed #B6BAC4;
    `}
    ${props => props.author === 'user' && css`
        border: 1px solid #B6BAC4;
        border-radius: 15px;
    `}
    ${props => props.author === 'operator' && css`
        border: 1px solid #B6BAC4;
        border-radius: 5px;
    `}

    ${props => props.actived && css`
        background-color: #000000;
        color: #fff;
        border: 1px solid #000;
    `}

    @media (max-width: 768px) {
        margin-bottom: 10px;
    }
`
export const Sentiment = styled.div`
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 10px;

    ${props => (props.sentiment === 'positive') && css`
        background-color: #71CE4F;
    `}
    ${props => props.sentiment === 'negative' && css`
        background-color: #FC0D1B;
    `}
    ${props => props.sentiment === 'mixed' && css`
        background-color: #FFA100;
    `}
    ${props => props.sentiment === 'neutral' && css`
        background-color: #B6BAC4;
    `}
`
