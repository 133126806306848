import styled, {css} from 'styled-components';


export const Container = styled.div`
    width: auto;
    padding: 5px;
    height: 30px;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    margin-right: 10px;
    transition: all ease-in-out 0.5;

    ${props => props.tag && css`
        ${props => props.tag?.authorTag === 'system' && css`
            border: 1px dashed #fff;
        `}
        ${props => props.tag?.authorTag === 'user' && css`
            border-radius: 15px;
        `}
    `}

    > span {
        color: #fff;
        font-size: 14px;
        margin-right: 10px;
    }

    svg {
        :hover{
            background-color: #fff;
            border-radius: 50%;
            padding: 1px;

            path {
                color: #000;
            }
        }
    }
`

export const Sentiment = styled.div`
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 0 7px;

    ${props => props?.sentimentTag === 'positive' && css`
        background-color: #71CE4F;
    `}
    ${props => props?.sentimentTag === 'negative' && css`
        background-color: #FC0D1B;
    `}
    ${props => props?.sentimentTag === 'mixed' && css`
        background-color: #FFA100;
    `}
    ${props => props.sentimentTag === 'neutral' && css`
        background-color: #B6BAC4;
    `}
`
