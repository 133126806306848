import React, {useContext} from 'react';
import {ContextReducer} from '../../../reducer/AdminReducer';
import {Container, Icon} from './styles';

import {BsBox} from 'react-icons/bs'
import {HiOutlineCalendar} from 'react-icons/hi'


const HeaderTransactionInfos = (props) => {
    const {state} = useContext(ContextReducer);

    const {transaction} = props;

    const renderWrapper = (type) => {
        return (
            <>
                <Icon>
                    {type === 'id' ? <BsBox style={{width: '13px', height: '13px'}}/> :
                        <HiOutlineCalendar style={{width: '13px', height: '13px'}}/>}
                </Icon>
                <span>{type === 'date' ? state?.i18n?.tagsAbas?.nps?.headerTransactionInfos?.transactionDate : state?.i18n?.tagsAbas?.nps?.headerTransactionInfos?.transactionId} </span>
                <strong>{type === 'date' ? transaction?.date : transaction?.id}</strong>
            </>
        )
    }

    return (
        <Container>
            {renderWrapper('id')}
            {renderWrapper('date')}
        </Container>
    );
};

export default HeaderTransactionInfos;
