import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import { Navigate } from "react-router-dom";
import config from '../../config/config';

import {
    ButtonLogin,
    Container,
    Content,
    ContentInput,
    Img,
    Infos,
    Input,
    MsgError,
    Star,
    WrapperInfos,
    WrapperLeft,
    WrapperLogin,
    WrapperMobile,
    WrapperRight,
    WrapperStar,
    WrapperTop
} from './styles'

import {ReactComponent as IconMobile} from '../../assets/IconMobile.svg';

import {ContextReducer} from "../../reducer/AdminReducer";
import {setError, setFirstAccess, setLogin, setPin, setRoute} from "../../actions/ReviewsAction";

import {GrFormClose} from 'react-icons/gr'


//icons
const renderIconEdit = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27.324" height="27.324" viewBox="0 0 27.324 27.324">
            <defs></defs>
            <g transform="translate(1 1)">
                <path className="a" fill="none" stroke="#FFA100" strokeLinecap="round" strokeLinejoin="round"
                      strokeWidth="2"
                      d="M14.327,6H5.517A2.517,2.517,0,0,0,3,8.517v17.62a2.517,2.517,0,0,0,2.517,2.517h17.62a2.517,2.517,0,0,0,2.517-2.517v-8.81"
                      transform="translate(-3 -3.33)"/>
                <path className="a" fill="none" stroke="#FFA100" strokeLinecap="round" strokeLinejoin="round"
                      strokeWidth="2" d="M25.215,3.6A2.67,2.67,0,0,1,28.99,7.376L17.034,19.332,12,20.59l1.259-5.034Z"
                      transform="translate(-4.449 -2.818)"/>
            </g>
        </svg>
    );
}

const RenderIconLamp = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21.5" height="28" viewBox="0 0 21.5 28">
            <defs></defs>
            <path className="a" fill="none" stroke="#FFA100" strokeWidth="2"
                  d="M13.292,29.146A1.358,1.358,0,0,0,14.646,30.5H20.6a1.358,1.358,0,0,0,1.354-1.354V27.927H13.292ZM17.625,4.5c-5.4,0-9.75,4.056-9.75,9.073a8.954,8.954,0,0,0,4.157,7.489V24a1.353,1.353,0,0,0,1.395,1.3h8.389A1.349,1.349,0,0,0,23.211,24V21.061a8.945,8.945,0,0,0,4.164-7.489C27.375,8.556,23.021,4.5,17.625,4.5Z"
                  transform="translate(-6.875 -3.5)"/>
        </svg>
    );
}

const RenderIconResponse = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27.176" height="24.09" viewBox="0 0 27.176 24.09">
            <defs></defs>
            <path className="a" fill="none" stroke="#FFA100" strokeWidth="2px"
                  d="M.409,10.011,9.063,2.538a1.181,1.181,0,0,1,1.952.893V7.367c7.9.09,14.162,1.673,14.162,9.159a10.052,10.052,0,0,1-4.1,7.579.876.876,0,0,1-1.381-.916c2.23-7.131-1.058-9.024-8.683-9.133v4.323a1.181,1.181,0,0,1-1.952.893L.409,11.8a1.181,1.181,0,0,1,0-1.786Z"
                  transform="translate(1 -1.224)"/>
        </svg>
    );
}

const renderIconVurdere = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="190" height="25.392" viewBox="0 0 190 25.392">
            <defs>
                <style>.</style>
            </defs>
            <path className="a" fill="#000000"
                  d="M115.637,443.164,106.622,420.5h-1.53l-23.383.008v1.406h23.949l9.359,23.4h1.2l9.925-24.813h-1.489Z"
                  transform="translate(-81.709 -420.502)"/>
            <path className="a" fill="#000000"
                  d="M373.24,433.86c0,7.444-3.391,10.711-8.436,10.711-5,0-8.436-3.267-8.436-10.711V420.5h-1.406v13.316c0,8.313,3.929,12.076,9.842,12.076,5.955,0,9.842-3.763,9.842-12.076V420.5H373.24Z"
                  transform="translate(-303.457 -420.502)"/>
            <path className="a" fill="#000000"
                  d="M642.843,420.5h-6.824v24.813h6.824c9.1,0,12.861-5.542,12.861-12.365S651.941,420.5,642.843,420.5Zm.041,23.49h-5.459V421.825h5.459c8.519,0,11.414,5.252,11.414,11.124C654.3,438.781,651.4,443.992,642.884,443.992Z"
                  transform="translate(-531.54 -420.502)"/>
            <path className="a" fill="#000000"
                  d="M781.924,445.315h13.192v-1.282H783.33V432.908h9.718v-1.282H783.33v-9.842h11.786V420.5H781.924Z"
                  transform="translate(-649.944 -420.502)"/>
            <path className="a" fill="#000000"
                  d="M909.828,427.615c0-3.722-2.192-7.113-7.237-7.113h-7.237v24.813h1.406V434.687h5.583l7.361,10.628h1.654L903.832,434.6C907.967,434.108,909.828,431.047,909.828,427.615ZM896.76,433.4V421.784h5.831c4.384,0,5.831,2.978,5.831,5.831,0,2.895-1.447,5.79-5.831,5.79Z"
                  transform="translate(-741.994 -420.502)"/>
            <path className="a" fill="#000000"
                  d="M521.7,427.615c0-3.722-2.192-7.113-7.237-7.113h-7.237v24.813h1.406V434.687h5.583l7.361,10.628h1.654L515.707,434.6C519.842,434.108,521.7,431.047,521.7,427.615Zm-13.068,5.79V421.784h5.831c4.384,0,5.831,2.978,5.831,5.831,0,2.895-1.448,5.79-5.831,5.79Z"
                  transform="translate(-427.025 -420.502)"/>
            <path className="a" fill="#000000"
                  d="M1032.948,421.784V420.5h-13.192v24.813h13.192v-1.282h-11.786V432.908h9.718v-1.282h-9.718v-9.842Z"
                  transform="translate(-842.948 -420.502)"/>
        </svg>
    );
}

export default function Login(props) {
    const {state, dispatch} = useContext(ContextReducer);
    const [open, setOpen] = useState(false);
    const [number, setNumber] = useState('');
    const [emails, setEmails] = useState('');
    const _email = useRef();

    const isFirstRender = useRef(true);

    useEffect(() => {
        handleClear();
    }, [])

    useEffect(() => {
        return () => {
            setRoute(dispatch, state.firstAccess ? 'store' : state.actualRoute);
        }
    }, [state?.userInfo?.userId, dispatch, state.actualRoute, state.firstAccess])

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        if (!state?.error?.nouser && !state?.error?.email && !state?.error?.pin && state?.error?.success) {
            setOpen(true);
        }
    }, [state?.error?.nouser, state?.error?.email, state?.error?.pin, state?.error?.success]);

    const handleSendPin = useCallback((e) => {
        e.preventDefault();

        if (handleValidateEmail(_email.current.value)) {
            setPin(dispatch, _email.current.value);
        } else {
            setError(dispatch, {email: true, pin: false, nouser: false})
        }
    }, [dispatch])

    const handleEmails = useCallback((e) => {
        if (_email !== e.target.value)
            setOpen(false)

        setEmails(e.target.value);
    }, [])

    const handleClear = useCallback(() => {
        setEmails('');
        setError(dispatch, {email: false, pin: false, nouser: false})
        setOpen(false)
        setNumber('');
    }, [dispatch])

    const handleClickLogin = useCallback((e) => {
        e.preventDefault()
        setFirstAccess(dispatch, true)
        setLogin(dispatch, {
            'email': _email.current.value,
            'pin': number
        }, state.userInfo.ecommerce)
    }, [dispatch, state.userInfo.ecommerce, number])

    const handleOnlyNumber = e => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
            setNumber(e.target.value);
        }
    }

    const handleValidateEmail = (email) => {
        return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const renderWrapperLogin = useCallback(() => {
        return (
            <WrapperLogin>
                <span>{state?.i18n?.login?.title}</span>
                <form>
                    <ContentInput>
                        <Input type="text" placeholder={state?.i18n?.login?.placeholder?.email} onChange={handleEmails}
                               value={emails} ref={_email} completed={open} erro={state?.error?.email}/>
                        {open && <GrFormClose onClick={handleClear}/>}
                    </ContentInput>
                    {open && !state?.error?.email &&
                        <Input type="text" placeholder={state?.i18n?.login?.placeholder?.pin} maxLength='6'
                               value={number} erro={state?.error?.pin} onChange={handleOnlyNumber}/>}
                    {open ? <ButtonLogin type="submit"
                                         onClick={handleClickLogin}>{state?.i18n?.login?.button?.login}</ButtonLogin> :
                        <ButtonLogin type="submit"
                                     onClick={handleSendPin}>{state?.i18n?.login?.button?.email}</ButtonLogin>}
                </form>
                {state?.error.email && !state?.error.nouser && <MsgError>{state?.i18n?.error?.email}</MsgError>}
                {state?.error.pin && <MsgError>{state?.i18n?.error?.pin}</MsgError>}
                {state?.error.email && state?.error.nouser && <MsgError>{state?.i18n?.error?.nouser}</MsgError>}
            </WrapperLogin>
        )
    }, [state?.error.pin, state?.i18n?.login?.title, state?.error.nouser, state?.error?.email, state?.i18n?.login?.placeholder?.email, handleEmails, open, handleClickLogin, handleSendPin, state?.i18n?.error?.nouser, number, handleClear, emails, state?.i18n?.error?.email, state?.i18n?.error?.pin, state?.i18n?.login?.button?.email, state?.i18n?.login?.button?.login, state?.i18n?.login?.placeholder?.pin])

    const renderLeftSide = useCallback(() => {
        return (
            <WrapperLeft>
                <WrapperTop>
                    {renderIconVurdere()}
                </WrapperTop>
                <WrapperMobile>
                    <IconMobile/>
                    <h4>Use um computador para acessar</h4>
                    <p>Versão disponível apenas para desktop</p>
                </WrapperMobile>
                {renderWrapperLogin()}
            </WrapperLeft>
        );
    }, [renderWrapperLogin])

    const renderWrapperInfos = useCallback(() => {
        return (
            <WrapperInfos>
                <Infos>
                    <Img>{renderIconEdit()}</Img>
                    <p>{state?.i18n?.login?.edit}</p>
                </Infos>
                <Infos>
                    <Img>{RenderIconResponse()}</Img>
                    <p>{state?.i18n?.login?.response}</p>
                </Infos>
                <Infos>
                    <Img>{RenderIconLamp()}</Img>
                    <p>{state?.i18n?.login?.idea}</p>
                </Infos>
            </WrapperInfos>
        );
    }, [state?.i18n?.login?.edit, state?.i18n?.login?.response, state?.i18n?.login?.idea])

    const renderStars = useCallback(() => {
        return (
            <WrapperStar>
                <Star/>
            </WrapperStar>
        );
    }, [])

    const renderRightSide = useCallback(() => {
        return (
            <WrapperRight>
                {renderWrapperInfos()}
                {renderStars()}
            </WrapperRight>
        );
    }, [renderWrapperInfos, renderStars])

    const renderContainer = useCallback(() => {
        return (
            <Container>
                <Content>
                    {renderLeftSide()}
                    {renderRightSide()}
                </Content>
            </Container>
        );
    }, [renderLeftSide, renderRightSide])

    return useMemo(() => {
        if (state?.userInfo?.userId) {
            return <Navigate to={`/${state.firstAccess ? 'store' : state.actualRoute}`}/>
        }
        return renderContainer();
    }, [renderContainer, state?.userInfo?.userId, state.firstAccess, state.actualRoute]);
}
