import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 61px;
    padding: 12px 20px;
    background-color: #D6E6FC;
    font-size: 14px;
    color: #1475FB;
    margin-bottom: 26.5px;
    position: absolute;
    bottom: -87px;
    border-radius: 0px 0px 5px 5px;
`
