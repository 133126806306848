import styled, {css} from 'styled-components'

export const Content = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #bebebe;
    padding-top: 10px;
    margin-bottom: 20px;

    &:nth-child(1) {
        border-top: none;
        margin-top: 0;
    }
`

export const Date = styled.p`
    font-size: 14px;
    color: rgba(15, 28, 59, .5);
    display: block;
    margin: 0 25px;
`

export const Name = styled.div`
    color: #000000;
    font-size: 14px;
    font-weight: bold;
`
export const Comments = styled.p`
    font-size: 14px;
    color: #000000;
    line-height: 19px;
    max-width: 440px;
    width: 100%;
    margin-top: 20px;
`

export const Avatar = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 0.1px solid #d8d8d8;
    margin-right: 5px;
    background:url(${props => props.url}) no-repeat center center;
    background-size:cover;
    background-color:#000000;
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;
`
export const ColumnRight = styled.div`
    display: flex;
    align-items: center;
`
export const WrapperAvatar = styled.div`
    display: flex;
    align-items: center;
`

export const Bar = styled.div`
    width: 4px;
    height: inherit;
    background-color: #bebebe;
    margin-right: 15px;

    ${props => props.store && css`
        background-color: #FFA100;
    `}
`
export const WrapperLeftSide = styled.div`
    display: flex;
`
export const ContentStore = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

    span {
        color: #20BFF0;
        font-size: 12px;
        display: block;
        margin-left: 5px;
    }
`
