import React from 'react';

import { Container, Title, SubTitle,  Content, Num, Pills, Wrapper, WrapperLeft, WrapperRight, Citys, ItemCity, City } from './styles';
import { FiMapPin } from "react-icons/fi";
import CountUp from 'react-countup';
import Bar from '../Bar';

const Box = ({i18n, data}) => {

    const renderAvg = (avg) => {
        if (avg >= 9) {
            return 'high'
        } else if (avg >= 7 && avg < 9) {
            return 'medium'
        } else {
            return 'low'
        }
    }


    const renderObjects = (type, obj) => {
        if(obj) {
            if(type === 'liked') {
                return Object.entries(obj).map((arr, key) => {
                    return <Pills liked={true} key={key}>{arr[0] + ' (' + arr[1] + ')'}</Pills>
                })
            } else if (type === 'disliked') {
                return Object.entries(obj).map((arr, key) => {
                    return <Pills disliked={true} key={key}>{arr[0] + ' (' + arr[1] + ')'}</Pills>
                })
            } else {
                return Object.entries(obj).map((arr, key) => {
                    return (
                        <ItemCity
                            type={renderAvg(arr[1]['rating'])}
                            key={key}
                        >
                            <div>
                                <FiMapPin /><City key={key}>{arr[0]} ({arr[1]['count']})</City>
                            </div>
                            <span>
                                {arr[1]['rating']}
                            </span>
                        </ItemCity>
                    )
                })
            }
        }
    }

    return (
    <>
        <Container marginRight={true} width={'760'} small={'440'}>
            <Title>{i18n?.headerCx?.title}</Title>
            <Wrapper>
                <WrapperLeft>
                    <Content>
                        <SubTitle>{i18n?.headerCx?.subtitle}</SubTitle>
                        <Num>
                            <CountUp start={0} duration={1} delay={0}
                                end={data.purchase?.total}
                            />
                        </Num>
                    </Content>
                    <Content>
                        <SubTitle>{i18n?.headerCx?.subtitle2}</SubTitle>
                        <Bar avg={data.purchase?.avg} />
                    </Content>
                    {(data.purchase?.topLikes && Object.entries(data.purchase?.topLikes).length > 0) &&
                    <Content>
                        <SubTitle>{i18n?.headerCx?.subtitle3}</SubTitle>
                        {renderObjects('liked', data.purchase?.topLikes)}
                    </Content>}
                    {(data.purchase?.topDislikes && Object.entries(data.purchase?.topDislikes).length > 0) &&
                    <Content>
                        <SubTitle>{i18n?.headerCx?.subtitle4}</SubTitle>
                        {renderObjects('disliked', data.purchase?.topDislikes)}
                    </Content>}
                </WrapperLeft>
            </Wrapper>
        </Container>

        {((data.delivery?.topLikes && Object.entries(data.delivery?.topLikes).length > 0) && (data.delivery?.topDislikes && Object.entries(data.delivery?.topDislikes).length > 0)) &&
        <Container marginRight={false} width={'440'}>
            <Title delivery={true}>{i18n?.headerCx?.title2}</Title>
            <Wrapper>
                <WrapperLeft>
                    <Content>
                        <SubTitle>{i18n?.headerCx?.subtitle}</SubTitle>
                        <Num>
                            <CountUp start={0} duration={1} delay={0}
                                end={data.delivery?.total}
                            />
                        </Num>
                    </Content>
                    <Content>
                        <SubTitle>{i18n?.headerCx?.subtitle2}</SubTitle>
                        <Bar avg={data?.delivery?.avg} />
                    </Content>

                    {(data?.delivery?.topLikes && Object.entries(data?.delivery?.topLikes).length > 0) &&
                    <Content>
                        <SubTitle>{i18n?.headerCx?.subtitle3}</SubTitle>
                        {renderObjects('liked', data.delivery?.topLikes)}
                    </Content>
                    }
                    {(data?.delivery?.topDislikes && Object.entries(data?.delivery?.topDislikes).length > 0) &&
                    <Content>
                        <SubTitle>{i18n?.headerCx?.subtitle4}</SubTitle>
                        {renderObjects('disliked', data.delivery?.topDislikes)}
                    </Content>
                    }
                </WrapperLeft>
                <WrapperRight>
                    {(data?.delivery?.topCities && Object.entries(data?.delivery?.topCities).length > 0) &&
                    <Content>
                        <SubTitle>{i18n?.headerCx?.subtitle5}</SubTitle>
                        {renderObjects('city', data?.delivery?.topCities)}
                    </Content>
                    }
                    {(data?.delivery?.worstCities && Object.entries(data?.delivery?.worstCities).length > 0) &&
                    <Content>
                        <SubTitle>{i18n?.headerCx?.subtitle6}</SubTitle>
                        <Citys>
                            {renderObjects('city', data?.delivery?.worstCities)}
                        </Citys>
                    </Content>
                    }
                </WrapperRight>
            </Wrapper>
        </Container>
        }
    </>
    )
}

export default Box;
