import React, { useContext } from 'react';
import { ContextReducer } from '../../../reducer/AdminReducer';
import { Container, Table } from '../styles';

const TableDash = ({from, handleExportData}) => {
    const { state } = useContext(ContextReducer);

    const placeholderToEmptyValues = state?.i18n?.exportTable?.placeholderToEmptyValues;
    const placeholderi18nFeatured = state?.i18n?.exportTable?.featured

    const sellersAndBrands = ['featured-sellers', 'featured-brands'];

    const renderBody = () => {
        return state?.featured?.items.map((obj, idx) => {
            return (
                <tr
                    style={{border: '1px solid #000'}}
                    key={idx}
                >
                    {from === 'featured-products' && <td>{!obj?.productId ? placeholderToEmptyValues?.noProductId : obj?.productId}</td>}
                    {from === 'featured-products' && <td>{!obj?.name ? placeholderToEmptyValues?.noName : obj?.name}</td>}
                    {from === 'featured-brands' && <td>{!obj?.brand ? placeholderToEmptyValues?.noBrand : obj?.brand}</td>}
                    {from === 'featured-sellers' && <td>{!obj?.seller ? placeholderToEmptyValues?.noSeller : obj?.seller}</td>}
                    <td>{!obj?.reviewTotal ? '0' : obj?.reviewTotal}</td>
                    {sellersAndBrands.includes(from) && <td>{!obj?.reviewTotal ? '0' : obj?.totalSkus}</td>}
                    <td>{!obj?.commentTotal ? '0' : obj?.commentTotal}</td>
                    <td>{!obj?.questionTotal ? '0' : obj?.questionTotal}</td>
                    <td>{!obj?.answerTotal ? '0' : obj?.answerTotal}</td>
                    <td>{!obj?.cxWs ? '0' : obj?.cxWs}</td>
                    <td>{!obj?.cxHs ? '0' : obj?.cxHs}</td>
                    <td>{!obj?.reviewAvg ? '0' : obj?.reviewAvg}</td>
                   {sellersAndBrands.includes(from) && <td>{!obj?.reviewRatingStd ? '0' : obj?.reviewRatingStd}</td>}
                </tr>
            )
        })
    }

    if (state?.exportData?.loading) {
        return (
            <Container id="tableID">
                <meta charSet="utf-8"/>
                <Table border={'1'}>
                    <thead>
                        <tr style={{border: '1px solid #000'}}>
                            {from === 'featured-products' && <th>{placeholderi18nFeatured?.featuredProducts}</th>}
                            {from === 'featured-brands' && <th>{placeholderi18nFeatured?.featuredBrands}</th>}
                            {from === 'featured-sellers' && <th>{placeholderi18nFeatured?.featuredSellers}</th>}
                            {from === 'featured-products' && <th>{placeholderi18nFeatured?.featured0}</th>}
                            <th>{placeholderi18nFeatured?.featured1}</th>
                            {sellersAndBrands.includes(from) && <th>{placeholderi18nFeatured?.featured2}</th>}
                            <th>{placeholderi18nFeatured?.featured3}</th>
                            <th>{placeholderi18nFeatured?.featured4}</th>
                            <th>{placeholderi18nFeatured?.featured5}</th>
                            <th>{placeholderi18nFeatured?.featured6}</th>
                            <th>{placeholderi18nFeatured?.featured7}</th>
                            <th>{placeholderi18nFeatured?.featured8}</th>
                            {sellersAndBrands.includes(from) && <th>{placeholderi18nFeatured?.featured9}</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {renderBody()}
                    </tbody>
                </Table>
            </Container>
        )
    }

    handleExportData();


    return <></>;
};

export default TableDash;
