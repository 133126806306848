import styled, {css} from 'styled-components';

export const Container = styled.div`
    width: 100%;
    min-height: 200px;
    height: auto;
    margin-bottom: 85px;
    padding-right: 12px;

    > h1 {
        margin-bottom: 29px;
        font-size: 30px;
    }

`
export const WrapperTexts = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 0px 0px 10px 0;

    span {
        :nth-child(1){
            font-size: 14px;
        }
        :nth-child(2){
            font-size: 12px;
        }
    }
`
export const ButtonSave = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 330px;
    height: 50px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    background-color: #000;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    transition: all ease-in-out 0.5s;

    :hover {
        opacity: 0.8;
        transition: all ease-in-out 0.5s;
    }

    ${props => !props.active && css`
        opacity: 0.3;
        pointer-events: ${props => (!props.active ? 'none' : 'auto')};
        transition: all ease-in-out 0.5s;
    `}
`

export const Message = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 50px;
`
