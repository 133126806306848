import React, { useRef, useState } from 'react';
import ListCheckboxes from './ListCheckboxes';
import { Container } from './styles';
import useShouldRender from '../../../hooks/useShouldRender';

const ContentFilterTags = ({activeFilterTags, setActiveFilterTags }) => {
    const contentFilterTagsRef = useRef();
    const { shouldRender } = useShouldRender(contentFilterTagsRef, activeFilterTags);
    const [timesRender, setTimesRender ] = useState(0);

    if(!shouldRender) return <></>

    return (
        <Container
            ref={contentFilterTagsRef}
            active={activeFilterTags}
        >
            <ListCheckboxes
                setActiveFilterTags={setActiveFilterTags}
                activeFilterTags={activeFilterTags}
                timesRender={timesRender}
                setTimesRender={setTimesRender}
            />
        </Container>
    );
};

export default ContentFilterTags;
