import React from 'react';
import { Container, Item, Content } from './styles';

const StarsNps = (props) => {

    const setType = () => {
        if (props.rating >= 0 && props.rating <= 6) {
            return 'low';
        } else if (props.rating === 7 || props.rating === 8) {
            return 'medium';
        } else {
            return 'high';
        }
    }

    const renderStars = () => {
        let _stars = [];

        for (let i = 0; i < 11; i++) {
            _stars.push(<Item type={props.rating === i && setType()} key={i}>{i}</Item>);
        }

        return _stars
    }


    return (
        <Container>
            <Content>
                {renderStars()}
            </Content>
        </Container>
    );
}

export default StarsNps;
