import styled, { css } from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    transition: all ease-in-out 0.5s;

    ${props => !props.active && css`
        transition: all ease-in-out 0.5s;
        pointer-events: ${props => (!props.active ? 'none' : 'auto')};
        opacity: 0.4;
    `}

    > span {
        font-size: 14px;
        font-weight: bold;
    }

    > label {
        font-size: 12px;
        color: #000;
        opacity: 0.8;
    }

    input[type=text], input[type=email] {
        width: 100%;
        height: 50px;
        border-radius: 5px;
        padding: 10px;
        border: 1px solid #DDD;
        margin-top: 8px;
    }

    :nth-child(2) {
        margin-top: 40px;
    }
`

export const WrapperCheckBox = styled.div`
    display: flex;
    align-items: center;
    margin-top: 11px;
`


