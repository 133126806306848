import styled, {css} from 'styled-components'

const getRole = (role) => {
    switch (role) {
        case 'APPROVED':
            return css`
                background-color: #d4edda;
                color: #155724;
            `
        case 'config':
            return css`
                background-color: #d4edda;
                color: #155724;
            `
        case 'COMMENTS':
            return css`
                background-color: #d4edda;
                color: #155724;
            `
        case 'DELETED':
            return css`
                background-color: #f8d7da;
                color: #721c24;
            `
        case 'REJECTED':
            return css`
                background-color: #fff3cd;
                color: #856404;
            `
        case 'ANSWER':
            return css`
                background-color: #d4edda;
                color: #155724;
            `
        case 'REVIEWS':
            return css`
                background-color: #d4edda;
                color: #155724;
            `
        case 'configAccessPatch':
            return css`
                background-color: #d4edda;
                color: #155724;
            `
        case 'configAccessPost':
            return css`
                background-color: #d4edda;
                color: #155724;
            `
        case 'emailTestSended':
            return css`
                background-color: #d4edda;
                color: #155724;
            `
        case 'errorDomainInvalid':
            return css`
                background-color: #f8d7da;
                color: #721c24;
            `
        case 'errorPersonalEmailInvalid':
            return css`
                background-color: #f8d7da;
                color: #721c24;
            `
        case 'errorAccessInputEmpty':
            return css`
                background-color: #f8d7da;
                color: #721c24;
            `
        case 'errorOcurred':
            return css`
                background-color: #f8d7da;
                color: #721c24;
            `
        case 'accessNotAllowed':
            return css`
                background-color: #f8d7da;
                color: #721c24;
            `
    }
}

export const Notification = styled.div`
    width: 100%;
    position: fixed;
    top: -45px;
    left: 0;
    height: 45px;
    padding: 0 20px;
    color: #fff;
    z-index: 20;
    display: flex;
    align-items: center;
    transition: all ease-in .45s;

    ${props => getRole(props.role)}

    ${props => props.active && css`
        top: 0;
    `}
`

export const Text = styled.div`
    font-size: 14px;
`
