import { useEffect, useRef, useContext } from 'react';
import { ContextReducer } from '../reducer/AdminReducer';
import { getUsers, getNewsletterCards, getNewsletters, getCardsCx, setTagsHeader, getReviews } from '../actions/ReviewsAction';
import { format } from 'date-fns';

const useSearchInfos = () => {
    const { state, dispatch } = useContext(ContextReducer);


    const controller = new AbortController();
    const signal = controller.signal;

    const firstRenderRef = useRef(true);
    const orderSelectSearchChangedRef = useRef(false);
    const lastSelectSearch = useRef('');

    useEffect(() => {
        if(firstRenderRef.current) {
            firstRenderRef.current = false;

            lastSelectSearch.current = state?.filters?.orderSelectSearch;
            return;
        }

        if((state?.filters?.orderSelectSearch === lastSelectSearch.current) && !orderSelectSearchChangedRef.current) {
            orderSelectSearchChangedRef.current = false;

            if (state.actualRoute === 'users') {
                getUsers(dispatch, state.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, '', '', signal, (state.rangePeriod[0].startDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', ( state.rangePeriod[0].endDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '');
            } else if (state.actualRoute === 'newsletter') {
                getNewsletterCards(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, state.filters, signal, (state.rangePeriod[0].startDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', ( state.rangePeriod[0].endDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '');
                getNewsletters(dispatch, state.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, '', '', signal, (state.rangePeriod[0].startDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', ( state.rangePeriod[0].endDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '');
            } else {
                if(state.actualRoute === 'nps') {
                    getCardsCx(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token,  state.filters, (state.rangePeriod[0].startDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', ( state.rangePeriod[0].endDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '', signal)
                }
                setTagsHeader(state.actualRoute, dispatch, state.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, '', signal, (state.rangePeriod[0].startDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', ( state.rangePeriod[0].endDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '', state?.tagsFilterHeader);
                getReviews(state.actualRoute, dispatch, state.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, '', '', signal, (state.rangePeriod[0].startDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', ( state.rangePeriod[0].endDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '');
            }
        } else if ((state?.filters?.orderSelectSearch === lastSelectSearch.current) && orderSelectSearchChangedRef.current ) {
            orderSelectSearchChangedRef.current = false;
        } else if (state?.filters?.orderSelectSearch !== lastSelectSearch.current){
            if(state.filters.orderInputUser.length !== 0)  {
                orderSelectSearchChangedRef.current = true;
                lastSelectSearch.current = state?.filters?.orderSelectSearch
                return
            }

            lastSelectSearch.current = state?.filters?.orderSelectSearch
        }

        return () => {
            controller.abort();
        }

    }, [state?.filters?.orderSelectSearch, state.filters.orderInputUser]);
};

export default useSearchInfos;
