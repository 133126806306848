import React, { useContext } from 'react';
import { Button as ButtonSend } from '../../../../../../Containers/Configuration/styles';
import { sendConfigurantion, setTokenAuthMkt } from '../../../../../../actions/ReviewsAction';
import { ContextReducer } from '../../../../../../reducer/AdminReducer';

const ButtonIntegrate = () => {
    const { state, dispatch } = useContext(ContextReducer);
    const configTokenMkt = state?.config?.ecommerce[`${state?.integratedStores?.id}Token`];


    const handleCallAuth = (e, mkpInitials) => {
        e.preventDefault();

        if(configTokenMkt && configTokenMkt !== 'false') {
            sendConfigurantion(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, undefined, null, null, null, null, null, null, 'false', mkpInitials);
            setTokenAuthMkt(dispatch, '');
            return
        }

        window.open('https://8kg7guqlil.execute-api.sa-east-1.amazonaws.com/dev/auth');
    }

    const renderStoreIntegrateButtonText = () => {
        if(!configTokenMkt || configTokenMkt === 'false') {
            return state?.i18n?.configuration?.integratedStores?.buttonToIntegrate;
        }

        return state?.i18n?.configuration?.integratedStores?.buttonRemoveIntegration;
    }

    return (
        <ButtonSend
            onClick={(e) => handleCallAuth(e, state?.integratedStores?.id)}
            secundary={configTokenMkt && configTokenMkt !== 'false'}
        >
            {renderStoreIntegrateButtonText()}
        </ButtonSend>
    );
};

export default ButtonIntegrate;
