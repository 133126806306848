import styled, { css, keyframes} from 'styled-components';

const growAndSlide = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const decreaseAndSlide = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;


export const WrapperIA = styled.div`
    height: 100%;
    width: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    video {
        cursor: pointer;
        width: 50px;
        height: 50px;
    }
`

export const Triangle = styled.div`
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-top: 12px solid #000;
    position: absolute;
    bottom: -10px;
    right: 82px;
`

export const Banner = styled.div`
    width: auto;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: -32px;
    right: -72px;

    padding: 5px 12px;
    background-color: #000;
    border-radius: 10px;

    white-space: nowrap;
    font-size: 11px;
    color: #FFA100;

    > svg {
        width: 20px;
        height: 20px;
        cursor: pointer;

        path {
            color: #ffa100;
        }
    }
    ${props => props.activeBanner && css`
        animation: ${growAndSlide} 0.25s ease-in;
    `}
    ${props => !props.activeBanner && css`
        animation: ${decreaseAndSlide} 0.25s ease-in-out;
    `}
`

