import React, { useEffect, useContext } from 'react';

import { Block, Name } from '../styles';
import { WrapperChecks } from './styles';

import Checkbox from './Checkbox';
import {ContextReducer} from '../../../reducer/AdminReducer';


const LanguageCheckbox = ({ selectedLanguages, setSelectedLanguages }) => {
    const { state } = useContext(ContextReducer);

    const languagesAvailable = ['ptBr', 'ptPt', 'enUs', 'enUk', 'esEs'];

    useEffect(() => {
        if(state?.config?.ecommerce?.locales?.length > 0){
            setSelectedLanguages(state.config.ecommerce.locales);
        }
    }, [state.config.ecommerce.locales])

    const handleAddLang = (value) => {
        if(!selectedLanguages?.includes(value)) {
            setSelectedLanguages([...selectedLanguages, value]);
        } else {
            const _newArr = selectedLanguages?.filter(item => value !== item)
            setSelectedLanguages(_newArr);
        }
    }

    const renderChecksbox = () => {
        return languagesAvailable?.map((item) => {
            return (
                <Checkbox
                    key={item}
                    label={item}
                    selectedLanguages={selectedLanguages}
                    cb={handleAddLang}
                />
            )
        })
    }

    return (
        <Block>
            <Name>{state?.i18n?.configuration?.languages}</Name>
            <WrapperChecks>
                {renderChecksbox()}
            </WrapperChecks>
        </Block>
    );
};

export default LanguageCheckbox;
