import React, {useContext, useMemo, useState} from 'react';

import {Aba, Abas, ContainerFilter, WrapperTags } from './styles';

import {clearEcomm, getEcomm, setFilter, setTypeChange, setNotification} from "../../actions/ReviewsAction";
import {ContextReducer} from "../../reducer/AdminReducer";
import LoaderBox from '../LoaderBox';
import ListFiltersSearch from './ListFiltersSearch';
import ContentTags from './ContentTags';
import ContentFilterTags from './ContentFilterTags';


const Filter = (props) => {
    const {state, dispatch} = useContext(ContextReducer)
    const [active, setActive] = useState(0)
    const [activeFilter, setActiveFilter] = useState('')
    const [total, setTotal] = useState({});
    const [activeFilterTags, setActiveFilterTags] = useState(false);

    const defaultFilters = ['PENDING', 'APPROVED', 'REJECTED', 'DELETED'];
    const storesFilters = ['store', 'marketplace', 'marketplaces', 'mentions'];

    const hasAbasTabs = () => state?.i18n?.tagsAbas;
    const hasStoresTitle = () => state?.ecommerce?.totals?.store;

    const handleActiveAbas = (i, value) => {
        if (state.actualRoute === 'stores') {
            setActive(i)
            setActiveFilter(-1)

            setTypeChange(dispatch, value);

            clearEcomm(dispatch, {
                'store': state?.ecommerce?.totals[storesFilters[0]],
                'marketplace': state?.ecommerce?.totals[storesFilters[1]],
                'marketplaces': state?.ecommerce?.totals[storesFilters[2]],
                'mentions': state?.ecommerce?.totals[storesFilters[3]],
            })

            getEcomm(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, value, true, state?.searchName);
        } else {
            dispatch({
                type: 'SET_TOTALTAGS',
                payload: {
                    tags: [],
                    lastEvaluatedKey: null
                }
            })

            if((value === 'DELETED' || value === 'REJECTED') && !state?.typeEcommerce?.store && !state?.userInfo?.isAdmin) {
                const _role = 'accessNotAllowed';
                setNotification(dispatch, {status: true, role: _role});

                setTimeout(() => {
                    setNotification(dispatch, {
                        'status': false,
                        'role': _role
                    })
                }, 3000)
                return
            }

            setActive(i)
            setActiveFilter(-1)
            let _filters = { ...state.filters };

            _filters = { ..._filters, status: value, order: state.filters.order, tag: '', authorTag: null, sentimentTag: null }

            if(_filters.listFiltersHeader['tag']) {
                delete _filters.listFiltersHeader['tag'];
            }

            setFilter(dispatch, _filters);
        }
    }



    const checkIfIsActiveAba = (index) => {
        if (index === active) return true;

        return false;
    }

    const renderNumberAba = (from) => {
        if (state.reviews.totals) {
            return ` (${state?.reviews?.totals[from]})`;
        } else {
            return ''
        }
    }

    const renderName = (status, idx) => {
        switch (status) {
            case 'APPROVED':
                return checkIfIsActiveAba(idx) ? state?.i18n?.tagsAbas[state.actualRoute]?.title + ' ' + state?.i18n?.tagsAbas[state.actualRoute]?.actions?.APPROVED + renderNumberAba('APPROVED') : state?.i18n?.tagsAbas[state.actualRoute]?.actions?.APPROVED + renderNumberAba('APPROVED')
            case 'REJECTED':
                return checkIfIsActiveAba(idx) ? state?.i18n?.tagsAbas[state.actualRoute]?.title + ' ' + state?.i18n?.tagsAbas[state.actualRoute]?.actions?.REJECTED + renderNumberAba('REJECTED') : state?.i18n?.tagsAbas[state.actualRoute]?.actions?.REJECTED + renderNumberAba('REJECTED')
            case 'DELETED':
                return checkIfIsActiveAba(idx) ? state?.i18n?.tagsAbas[state.actualRoute]?.title + ' ' + state?.i18n?.tagsAbas[state.actualRoute]?.actions?.DELETED + renderNumberAba('DELETED') : state?.i18n?.tagsAbas[state.actualRoute]?.actions?.DELETED + renderNumberAba('DELETED')
            case 'PENDING':
                return checkIfIsActiveAba(idx) ? state?.i18n?.tagsAbas[state.actualRoute]?.title + ' ' + state?.i18n?.tagsAbas[state.actualRoute]?.actions?.PENDING + renderNumberAba('PENDING') : state?.i18n?.tagsAbas[state.actualRoute]?.actions?.PENDING + renderNumberAba('PENDING')
            default:
                return status;
        }
    }

    const renderAbas = () => {
        return defaultFilters.map((val, i) => {
            return (
                <Aba
                    onClick={() => handleActiveAbas(i, val)}
                    actived={checkIfIsActiveAba(i)}
                    named={val}
                    key={i}
                >
                    {renderName(val, i)}{checkIfIsActiveAba(i) === true}
                </Aba>
            )
        })
    }

    const renderAbasStores = () => {
        return storesFilters?.map((val, i) => {
            return (
                <Aba
                    onClick={() => handleActiveAbas(i, val)}
                    actived={checkIfIsActiveAba(i)}
                    named={val}
                    key={i}
                >
                    {`${state?.i18n?.stores[val]} ${`(${total[val] > 0 ? total[val] : state?.ecommerce?.totals[val]})`}`}
                </Aba>
            )
        })
    }

    return useMemo(() => {
        return (
            <ContainerFilter>
                {state.actualRoute === 'nps' ? <></> : <ListFiltersSearch/>}
                {(hasAbasTabs() || hasStoresTitle()) ?
                    <Abas>{state.actualRoute !== 'stores' ? renderAbas() : renderAbasStores()}</Abas> :
                    <LoaderBox from={state?.actualRoute === 'stores' ? 'stores' : 'abas'}/>
                }
                {state.actualRoute !== 'stores' &&
                    <WrapperTags>
                        <ContentFilterTags
                            activeFilterTags={activeFilterTags}
                            setActiveFilterTags={setActiveFilterTags}
                        />
                        <ContentTags
                            active={active}
                            pagination={props.handlePagination}
                            activeFilterTags={activeFilterTags}
                            setActiveFilterTags={setActiveFilterTags}
                        />
                    </WrapperTags>
                }
            </ContainerFilter>
        );
    }, [state.reviews.totalTags, active, activeFilter, storesFilters, total, state?.typeEcommerce, state.searchName, state.filters, state.actualRoute, state?.userInfo, state.ecommerce, state.typeChangeStore, state.reviews])
}

export default Filter;
