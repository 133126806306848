import React, { useContext, useState, useEffect } from 'react';

import { ContextReducer } from '../../../reducer/AdminReducer';

import { ActionsAttachment, TooltipAttachment, Image} from '../styles';
import { ListHeaderItem } from '../../../Containers/styles';

import { AttachmentProduct,
        ImageAttachment,
        AttachmenteProduct,
        WrapperLookAttachment
} from './styles';

import getTransformDpr from '../../../utils/TransformDpr';

import {ReactComponent as IconEyeClosed} from '../../../assets/IconEyeClose.svg';
import {ReactComponent as IconEyeOpened} from '../../../assets/IconEyeOpen.svg';

import {setAttachment, changeReview } from "../../../actions/ReviewsAction";

const AttachmentImage = (props) => {
    const { state, dispatch } = useContext(ContextReducer);
    const [ activeLookAttachmentId, setActiveLookAttachmentId ] = useState('');
    const [ lookAttachment, setLookAttachment] = useState(false);

    const { attachment, id, author } = props.values;

    useEffect(() => {
        if(attachment?.length > 0) {
            setLookAttachment(!attachment[0].approved);
        }

        setActiveLookAttachmentId(props.index);
    }, [])

    const handleAttachment = (idx, value) => {
        if(value){
            setAttachment(dispatch, {
                index: idx,
                status: true
            })
        }
    }

    const handleLookAttachment = (index) => {
        changeReview(state.actualRoute, dispatch, [
            {
                "id": id,
                "author": author
            }
        ], 'attachmentApproved', lookAttachment, state.filters.status, state?.userInfo?.token, state?.userInfo?.ecommerce, state?.actualArea, state?.filters);

        setLookAttachment(!lookAttachment);
        setActiveLookAttachmentId(index);
    }

    const renderLookAttachment = (index) => {
        return(
            <WrapperLookAttachment actions='ocultar' >
                <ActionsAttachment>
                    {props.index === activeLookAttachmentId && lookAttachment ? <IconEyeClosed onClick={() => handleLookAttachment(index)}/> : <IconEyeOpened onClick={() => handleLookAttachment(index)} />}
                    <TooltipAttachment actions='ocultar'>{lookAttachment ? state?.i18n?.lookAtachment?.SHOW : state?.i18n?.lookAtachment?.HIDE}</TooltipAttachment>
                </ActionsAttachment>
            </WrapperLookAttachment>
        );
    }

    const renderContainer = () =>  {
        let _styles = {};

            if(attachment)
                _styles.background = `url(https://${state?.userInfo?.ecommerce.domain}/image/upload/e_trim:25/c_fill,g_face,h_70,w_70/dpr_${getTransformDpr(window.devicePixelRatio)}/fl_progressive:semi,q_auto:eco/f_auto/${attachment[0]?.id}.jpeg) center center / contain no-repeat`;

            if(attachment && attachment?.length > 0) {
                return (
                    <ListHeaderItem>
                        <AttachmentProduct>
                            <ImageAttachment onClick={() => handleAttachment(props.index, attachment)}  active={lookAttachment}>
                                {attachment?.length > 0  && <Image style={_styles} attachments="true" />}
                            </ImageAttachment>
                            {attachment?.length > 0 && renderLookAttachment(props.index)}
                        </AttachmentProduct>
                    </ListHeaderItem>
                )
            } else {
                return (
                    <ListHeaderItem nomobile={true}>
                        <AttachmenteProduct>
                            <ImageAttachment />
                        </AttachmenteProduct>
                    </ListHeaderItem>
                )
            }
    }

    return renderContainer();
};

export default AttachmentImage;
