import styled, {css, keyframes} from "styled-components";


export const Box = styled.div`
    background-color: #fff;
    width: 100%;
    height: 100%;
    max-width: 19%;
    min-height: 200px;
    overflow: hidden;
    padding: 25px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    border-radius: 15px;
`

export const Infos = styled.div`
    display: flex;
    flex-flow: row;
    min-height: 55px;

    > svg {
        width: 30px;
        height: 30px;
        display: block;
        margin-right: 10px;

        path {
            fill: #000000;
        }
    }
`

export const Texts = styled.div`
    display: flex;
    flex-flow: column;
`

export const Text = styled.p`
    color: #000000;
    font-size: 14px;
`
export const Small = styled.p`
    color: #000000;
    font-size: 14px;
    opacity: .5;
`
export const Numbers = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;

    a {
        font-size: 14px;
        color: #FFA100;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
`
export const Num = styled.span`
    font-size: 25px;
    color: #000000;
    font-weight: bold;
`

export const Links = styled.a`
    font-size: 14px;
    color: #FFA100;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`

export const Types = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    border-top: 1px solid #cecece;
    padding-top: 20px;
    margin-top: 20px;
    position:relative;

`

export const Item = styled.div`
    display: flex;
    flex-flow: column;
    margin-bottom: 15px;
`

export const ItemText = styled.div`
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
`

export const Bar = styled.div`
    display: flex;
    border: ${props => props.border};
    height: 15px;
    background-color: ${props => props.colors};
    width: ${props => props.porcentage}%;
    position: relative;
    transition: width linear .25s;
    max-width: 85%;

    &:after {
        content: '${props => props.porcentage}%';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -40px;
        color: ${props => props.textColor};
        font-size: 16px;

        ${props => props.porcentage === 100 && css`
            right: -45px;
        `}

        ${props => props.porcentage === 0 && css`
            right: -24px;
        `}
    }
`
export const Table = styled.table`
    position: relative;
    width: 100%;
    margin-top: 30px;

    tbody {
        position: relative;
        top: 10px;

        img {
            vertical-align: middle;
            margin-right: 10px;
        }
    }

    thead tr {
        background: #fff;
    }

    thead :nth-child(1) {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    thead :last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    tbody tr:nth-child(even) {
        background-color: #F8F9FA;
    }

    tbody tr:nth-child(odd) {
        background-color: #FFF;
    }

    tr td {
        font-size: 16px;
        font-weight: bold;
    }

    tbody tr {
        :nth-child(1) {
            td {
                :nth-child(1){
                    border-top-left-radius: 10px;
                }

                :last-child {
                    border-top-right-radius: 10px;
                }
            }
        }
        :last-child {
            td {
                :nth-child(1){
                    border-bottom-left-radius: 10px;
                }

                :last-child {
                    border-bottom-right-radius: 10px;
                }
            }
        }
    }

    tr td span:last-child {
        font-size: 14px;
        font-weight: normal;
        opacity: .5;
        width: 60px;
        display: inline-block;
        text-align: left;
        margin-left: 10px;
    }

    th, tr, td {
        padding: 15px 10px;
        text-align: center;
        vertical-align: middle;
        height: 70px;
        box-sizing: border-box;
    }

    img {
        width: 40px;
        height: 40px;
    }
`

export const ImageProduct = styled.span`
    background: url(${props => props.bg}) center center no-repeat;
    background-size: contain;
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
`

export const Col = styled.th`
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;

    ${props => props.act && css`
        background-color: #000000;
        color: #fff;
        font-weight: bold;

        span {
            svg {

                path {
                    fill: #fff;
                }
            }
        }
    `}

    span {
        margin-left: 5px;
    }
`

export const Arrow = styled.span`
    svg {
        transform: none;
    }


    ${props => props.flip && css`
        svg {
            transform: scale(-1, -1);
        }
    `}
`

export const Avg = styled.div`
    height: 34px;
    width: 100%;
    position: relative;
    overflow: hidden;
`

export const AvgInfos = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
`

export const AvgText = styled.span`
    font-size: 14px;
    color: rgba(15, 28, 59, .5)
`

export const AvgScore = styled.span`
    font-size: 18px;
    font-weight: bold;

    ${props => props.color && css`
        color: ${props.color}
    `}
`

export const AvgGradient = styled.div`
    background: transparent linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(255, 206, 0, 1) 50%, rgba(20, 196, 81, 1) 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    width: 100%;
    height: 5px;
`

export const More = styled.p`
    cursor: pointer;
    font-size: 14px;
    color: #FFA100;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    &:hover {
        text-decoration: underline;
    }

    svg {
        margin-right: 5px;

        path {
            stroke: #FFA100;
        }
    }
`

export const Abas = styled.div`
    display: flex;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(15, 28, 59, 0.3);
    position: relative;
    width: max-content;
`

export const Aba = styled.div`
    font-size: 16px;
    color: #000000;
    opacity: .5;
    text-transform: capitalize;
    cursor: pointer;
    margin-right: 40px;
    position: relative;

    &:last-child {
        margin-right: 0;
    }

    ${props => props.actived && css`
        opacity: 1;
        font-weight: bold;

        &:after {
            content: '';
            width: 100%;
            position: absolute;
            height: 3px;
            bottom: -12px;
            left: 0;
            background-color: #FFA100;
        }
    `}
`

export const AnimateLoader = keyframes`
    to{transform: rotate(1turn)}
`
export const Loader = styled.div`
    width:50px;
    height:50px;
    border-radius:50%;
    border:8px solid;
    border-color:#E4E4ED;
    border-right-color: #000;
    display: inline-block;
    animation:${AnimateLoader} 1s infinite linear;
`
