import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 30px;
    background-color: #E4E7ED;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    font-size: 11px;
    color: #000;

    span {
        margin-left: 5px;
    }

    strong {
        font-weight: bold;
        margin: 0px 30px 0px 5px;
    }
`
export const Icon = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #fff;
    border: none;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 10px;
`
