import styled, {css} from 'styled-components';

export const Content = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 30px;
    position: relative;
    margin: 30px auto 30px auto;
    align-items: center;

    @media (max-width: 768px) {
        flex-flow: column;
        margin: 30px auto 30px auto;
    }
`

export const ContentUsers = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 30px;
    position: relative;
    margin: 0 auto;
    align-items: center;

`

export const Title = styled.h2`
    color: #000000;
    font-size: 20px;
    font-weight: bold;
    margin: 20px 20px 20px 0;

`

export const Items = styled.div`
    width: 100%;
    padding: 0 30px;
    position: relative;
    margin: 0 auto 10px auto;
    background-color: #fff;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 10px;

    @media (max-width: 768px) {
        flex-flow: column;
    }
`

export const ItemsProducts = styled.div`
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    height: 0;
    transition: all ease-in .25s;
    opacity: 0;
    padding: 0;
    margin: 0;
    border-radius: 5px;

    ${props => props.active && css`
        padding: 10px 20px;
        margin: 0 auto 10px auto;
        height: 100%;
        opacity: 1;
    `}

    @media (max-width: 768px) {
        flex-flow: column;
    }
`

export const Name = styled.p`
    font-size:14px;
    font-weight: bold;
    color: #000000;
    margin: 0 15px;
    cursor: pointer;
`


export const Picture = styled.div`
    width: 30px;
    height: 30px;
    border-radius:100%;
    overflow: hidden;
    position: relative;
    cursor: pointer;
`

export const Email = styled.p`
    cursor: pointer;
    position: relative;

    p {
        font-size:14px;
        color: #000000;
    }

    ${props => props.porcentage && css`
        width: ${props => props.porcentage}%;

        @media (max-width: 768px) {
            width: 100%;
            margin-top: 10px;
        }
    `}
`

export const ListItem = styled.p`
    font-size:14px;
    color: #000000;
    text-align: center;
    cursor: pointer;

    ${props => props.act && css`
        color: #fff !important;
        background-color: #000;
        font-weight: bold;
        height: 60px;
        line-height: 60px;

        svg {
            path {
                fill: #fff;
            }
        }
    `}

    ${props => props.porcentage && css`
        width: ${props => props.porcentage}%;

        @media (max-width: 768px) {
            width: 100%;
        }
    `}
`
export const Id = styled.div`
    font-size:14px;
    color: rgba(0, 0, 0, .6);
    display: block;
    padding: 0 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
        width: 40px;
        height: 40px;
    }

    div {
        margin-left: 10px;
    }

    ${props => props.upper && css`
        text-transform: uppercase;
    `}

    ${props => props.ativo === 'sent' && css`
        color: #000;
        font-weight: bold;
        font-size: 16px;
    `}

    ${props => props.porcentage && css`
        width: ${props => props.porcentage}%;

        @media (max-width: 768px) {
            width: 100%;
            margin: 10px 0;
        }
    `}
`

export const Flex = styled.div`
    display: flex;
    align-items: center;

    ${props => props.porcentage && css`
        width: ${props => props.porcentage}%;

        @media (max-width: 768px) {
            width: 100%;
        }
    `}
`

export const Tooltip = styled.div`
    background-color: #d4edda;
    color: #155724;
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: 12px;
    border-radius: 5px;
    position: absolute;
    top: -40px;
    left: 0;

    &:before {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #d4edda;
    }
`

export const Input = styled.input`
    border:none;
    background: #fff !important;
    border-radius: 5px;
    margin-right: 15px;
    padding: 0 15px;
    height: 55px;
    line-height: 55px;
    width: 210px;
    box-sizing: border-box;
    font-size: 12px;
`

export const Boxes = styled.div`
    padding: 20px 0 0 0;
    display: flex;
    position: relative;
    margin-bottom: 30px;
`

export const Box = styled.div`
    position: relative;
    background-color: #fff;
    border-radius: 15px;
    padding: 25px;
    margin-right: 20px;
    box-sizing: border-box;
    max-width: 220px;
    width: 100%;

    span {
        color: #0F1C3B;
        font-size: 14px;
        display: block;
        margin-bottom: 10px;
    }

    strong {
        color: #000000;
        display: block;
        font-weight: bold;
        font-size: 30px;
    }
`
export const Arrow = styled.span`
    margin-left: 10px;

    svg {
        transform: none;
    }


    ${props => props.flip && css`
        svg {
            transform: scale(-1, -1);
        }
    `}
`
