import React, {useContext, useEffect, useRef, useState} from 'react';
import {ContextReducer} from '../../../../reducer/AdminReducer';
import Checkbox from './Checkbox';
import {Actions, Container, Email, Initials, NameAndLast, Roll, Toggle, WrapperInfos} from './styles';

const Item = (props) => {
    const {state, dispatch} = useContext(ContextReducer);

    const {role, email, type, userProfile, addedBy, active} = props.item;
    const [activeToggle, setActiveToggle] = useState(active);
    const [check, setCheck] = useState(role);
    const firstAccess = useRef(false);

    const handleInitials = () => {
        let _userProfileName = userProfile.name;
        let _userSeparated = _userProfileName.trim().split(' ');

        if (type !== 'personal') return state?.i18n?.peopleAccess.accessGeneralInitials;

        if (_userSeparated.length === 1) {
            return userProfile.name[0].toUpperCase();
        } else if (_userSeparated.length > 1) {
            return _userSeparated[0][0].toUpperCase() + _userSeparated[1][0].toUpperCase();
        }

        return userProfile.name[0].toUpperCase();
    }

    useEffect(() => {
        if (firstAccess.current === false) {
            firstAccess.current = true;
            return
        } else {
            handlePatchAccess(check);
        }
    }, [activeToggle])

    const handlePatchAccess = (payload) => {
        setCheck(payload);
        dispatch({
            type: 'SET_PATCHACCESS',
            payload: {
                [email]: {
                    active: activeToggle,
                    role: payload
                },
            }
        })
    }

    return (
        <Container active={activeToggle}>
            <WrapperInfos>
                <Initials>{handleInitials()}</Initials>
                <NameAndLast>
                    <h5>{(userProfile?.name && type === 'personal') ? userProfile?.name : state?.i18n?.peopleAccess?.generalAccessList}</h5>
                    <span>{(type === 'personal' && userProfile.lastAccess) && state?.i18n?.peopleAccess?.lastAccess + userProfile.lastAccess.split(' ')[0]}</span>
                </NameAndLast>
            </WrapperInfos>
            <Email>
                <span>{email}</span>
                <span>{state?.i18n?.peopleAccess?.addedBy + (addedBy.email ? addedBy.email : addedBy.name)}</span>
            </Email>
            <Actions>
                <div>
                    <Checkbox
                        type={'reader'}
                        check={check}
                        handlePatchAccess={handlePatchAccess}
                    />
                    <Checkbox
                        type={'writer'}
                        check={check}
                        handlePatchAccess={handlePatchAccess}
                    />
                    <Checkbox
                        type={'admin'}
                        check={check}
                        handlePatchAccess={handlePatchAccess}
                    />
                </div>
                <Toggle onClick={() => setActiveToggle(prevState => !prevState)}>
                    <Roll active={activeToggle}/>
                </Toggle>
            </Actions>
        </Container>
    );
};

export default Item;
