import styled, { css, keyframes } from 'styled-components';

const fadeOut = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`
const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1
    }
`

export const Container = styled.div`
    position: relative;
    overflow: hidden;

    ${props => !props.isLeaving && css`
        animation: ${fadeOut} 0.25s;
    `}

    ${props => props.isLeaving && css`
        height: auto;
        animation: ${fadeIn} 0.5s;
    `}

`

export const Other = styled.div`
    margin-right: 30px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &:first-child {
        margin-left: 0;
    }

    span {
        color: #000000;
        font-size: 14px;
        display:block;
        margin-left: 5px;
    }
`

export const Svg = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(195, 198, 205, .3);
`

export const SubTitle = styled.span`
    font-size: 12px !important;
    color: #060707;
    display: block;
    margin-top: 15px;
`
