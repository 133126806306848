import styled, { css } from 'styled-components';

const getStatus = (item) => {
    switch(item) {
        case 'positive':
            return css`
                background-color: #71CE4F;
            `
        case 'negative':
            return css`
                background-color: #FC0D1B;
            `
        case 'mixed':
            return css`
                background-color: #FFA100;
            `
        case 'neutral':
            return css`
                background-color: #B6BAC4;
            `
        default:
            return css`
                display: none;
            `
    }
}

export const Container = styled.div`
    width: auto;
    max-width: 100%;
    min-height: 55px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 11px 0px 20px;
    border-radius: 5px;
    background-color: #fff;
    flex-wrap: wrap;

    @media screen and (max-width: 812px) {
        padding-top: 10px;
    }

    > span {
        font-size: 14px;
        opacity: 0.5;
        color: #000;
        margin-right: 11px;
        white-space: nowrap;

        @media screen and (max-width: 1111px) {
            font-size: 0.8rem;
        }
    }

    > svg {
        margin-right: 10px;

        @media screen and (max-width: 1111px) {
            width: 15px;
            height: 15px;
        }
    }
`
export const CloseButton = styled.div`
    width: auto;
    height: auto;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 37px;

    > svg {
        margin-right: 0;
        font-size: 25px;
    }

    @media screen and (max-width: 1111px) {
        margin-left: 15px;
    }

`
export const BoxCheck = styled.div`
    display: flex;
    align-items: center;
    margin-right: 37px;

    @media screen and (max-width: 1111px) {
        margin-right: 15px;
    }

    input[type="checkbox"] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        margin-right: 5px;
        width: 20px;
        height: 20px;
        border-radius: 5px;
        background-color: #fff;
        border: 1px solid #0F1C3B;
        position: relative;
        cursor: pointer;


        @media screen and (max-width: 1111px) {
            width: 15px;
            height: 15px;
        }
    }

    input[type="checkbox"]:checked {
        background-color: #000;
    }

    input[type="checkbox"]:checked::after {
        content: '';
        position: absolute;
        width: 4px;
        height: 8px;
        top: 3px;
        left: 6px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);

        @media screen and (max-width: 1111px) {
            top: 2px;
            left: 4px;
            height: 6px;
            width: 2px;
        }
    }

    label {
        font-size: 14px;

        @media screen and (max-width: 1111px) {
            font-size: 0.8rem;
        }
    }

    ${props => props.last && css`
        margin-right: 5px;
    `}
`

export const Status = styled.div`
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 0 2px 0px 0px;

    ${props => getStatus(props.item)}
`

