import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    margin-top: 5px;

    @media(max-width: 768px){
        margin-bottom: 10px;
    }

    h4 {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        opacity: 0.5;
        margin: 0 3px;
    }

    span {
        opacity: 0.5;
        font-size: 9px;
    }
`
