import React, { useContext } from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {ContextReducer} from "../../reducer/AdminReducer";
import {ReactComponent as IconEcom} from '../../assets/iconEcom.svg';
import {ReactComponent as SocialIsStore} from '../../assets/icon-isStore.svg';
import {Card, CircleBG, CircleInitials, CircleUser, Info, Infos, Name, Photo, Description, Store, Checkbox} from './styles';
import {setActualArea, setCachePin, setRoute, setIntegratedStores} from "../../actions/ReviewsAction";
import getTransformDpr from "../../utils/TransformDpr";

const Cards = (props) => {
    const {state, dispatch} = useContext(ContextReducer)
    const match = useLocation();
    const navigate = useNavigate();

    const handleChangeFilter = (obj) => {
        const _filter = JSON.parse(obj);

        if(!_filter.store && _filter.marketplaces) {
            window.localStorage.setItem('vurdere-type', 'marketplaces');
        } else if(!_filter.store && !_filter.marketplaces && _filter.mentions) {
            window.localStorage.setItem('vurdere-type', 'mentions');
        } else {
            window.localStorage.setItem('vurdere-type', 'stores');
        }

        setCachePin(dispatch, _filter, state.userInfo, handleToInsights);

        match.state = 'default';
    }

    const handleIntegratedStores = (card) => {
        setIntegratedStores(dispatch, card)
    }

    const handleToInsights = () => {
        setRoute(dispatch, 'insights')
        setActualArea(dispatch, state.typeChangeStore)
        navigate('/insights');
    }

    const renderName = (name) => {
        if (name) {
            let _name = name.split(' ');
            let _namesplit = '';
            if (_name.length >= 3 && _name[_name.length - 1].length > 0) {
                _namesplit = _name[0] + ' ' + _name[_name.length - 1];
            } else if (_name.length >= 2 && _name[_name.length - 1].length === 0) {
                _namesplit = _name[0] + ' ' + _name[1];
            } else {
                _namesplit = name;
            }

            return _namesplit;
        }
    }

    const renderInitials = (initials) => {
        let _initials = renderName(initials).trim();

        _initials = _initials.split(' ').reduce((ac, inn) => ac + inn[0], '').toUpperCase();

        return _initials;
    }

    const renderPicture = () => {
        if ((props.card.authorInfo?.picture.id === " ") && !props.card.name) {
            return (
                <Photo>
                    <CircleUser>
                        <IconEcom/>
                    </CircleUser>
                </Photo>
            )
        } else if(props.type === 'integratedStores' && props.card.authorInfo?.logoPicture?.id !== " ") {
            return (
                <Photo>
                    <CircleBG
                        style={{background: `url(https://${state.userInfo.ecommerce.domain}/image/upload/t_favicons_dpr_${getTransformDpr(window.devicePixelRatio)}/f_auto/${props.card.authorInfo.logoPicture.id}.jpeg) center center / contain no-repeat`}}/>
                </Photo>
            )
        } else if ((props.card.authorInfo?.picture.id === " ") && props.card.name) {
            return (
                <Photo>
                    <CircleInitials>{renderInitials(props.card.name)}</CircleInitials>
                </Photo>
            )
        } else {
            return (
                <Photo>
                    <CircleBG
                        style={{background: `url(https://${state.userInfo.ecommerce.domain}/image/upload/t_favicons_dpr_${getTransformDpr(window.devicePixelRatio)}/f_auto/${props.card.authorInfo.picture.id}.jpeg) center center / contain no-repeat`}}/>
                </Photo>
            )
        }
    }


    if(props.type === 'integratedStores') {
        const configTokenMkt = state?.config?.ecommerce[`${props.card.id}Token`];
        const tokenExists = configTokenMkt && configTokenMkt !== 'false';

        return (
            <Card type={props.type} onClick={() => handleIntegratedStores(props.card)}>
                <Store>
                    {renderPicture()}
                    <Name>{props.card.name ? props.card.name : state?.i18n?.noName}</Name>
                </Store>
                <Infos>
                    <Description>{props.card.description}</Description>
                    <Checkbox
                        type={'checkbox'}
                        id={props?.card?.name}
                        checked={((props?.check && props?.check[props?.card?.ecommerceId]?.warnmme) || tokenExists ) ? 'checked' : ''}
                        disabled={!tokenExists}
                    />
                </Infos>
            </Card>
        )
    } else {
        return (
            <Link onClick={() => handleChangeFilter(JSON.stringify(props.card))}>
                <Card>
                    {renderPicture()}
                    <Name>{props.card.name ? props.card.name : state?.i18n?.noName}</Name>
                    <Infos>
                        {props.card.store && <Info>
                            <SocialIsStore/>
                            <span>{state?.i18n?.stores?.type?.store}</span>
                        </Info>}
                        {props.card.marketplaces && <Info>
                            <SocialIsStore/>
                            <span>{state?.i18n?.stores?.type?.seller}</span>
                        </Info>}
                        {props.card.mentions && <Info>
                            <SocialIsStore/>
                            <span>{state?.i18n?.stores?.type?.brand}</span>
                        </Info>}
                    </Infos>
                </Card>
            </Link>
        )
    }
}

export default Cards;

