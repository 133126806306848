import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';

import {Container} from '../styles';

import ListHeader from '../../components/ListHeader';
import Filter from '../../components/Filter';
import Pagination from "../../components/Pagination";
import ButtonsActions from '../../components/ButtonsActions';
import Items from '../../components/Items';
import LoaderInfos from '../../components/LoaderInfos';
import {ContextReducer} from "../../reducer/AdminReducer";
import NoItems from '../../components/NoItems';
import HeaderFilter from "../../components/HeaderFilter";
import Box from '../../components/Box';
import ListFiltersSearch from '../../components/Filter/ListFiltersSearch';

import useInitializeInfos from '../../hooks/useInitializeInfos';
import { setNotification } from '../../actions/ReviewsAction';

const Nps = ({ref}) => {
    const {state, dispatch} = useContext(ContextReducer)
    const {selectAll, setSelectAll} = useInitializeInfos();

    useEffect(() => {
        return () => {
            clearTimeout();
        }
    }, [])
    
    const handlePagination = () => {
        setSelectAll(false);
    }

    const handleClickCheckBox = (e = '', ref = '', statusSelect) => {
        if(!state?.typeEcommerce?.store && !state?.userInfo?.isAdmin) {
            setSelectAll(!selectAll);

            const _role = 'accessNotAllowed';
            setNotification(dispatch, {status: true, role: _role});

            setTimeout(() => {
                setNotification(dispatch, {
                    'status': false,
                    'role': _role
                })
                setSelectAll(false);
                ref.current.checked = false;
            }, 3000)
            return
        }

        if(statusSelect === false) {
            setSelectAll(false);
            return
        }

        setSelectAll((c) => !c);
    };

    const renderReviewList = () => {
        if (state?.reviews?.items.length > 0) {
            return state?.reviews?.items.map((value, index) => {
                return <Items value={value} index={index} key={index} selected={selectAll} handleClickCheckBox={handleClickCheckBox} />
            })
        } else {
            return <NoItems/>
        }
    }

    return useMemo(() => {
        return (
            <>
                <HeaderFilter/>
                <Container area="cxfilter">
                    {(state.actualRoute === 'nps' && !state?.visible) && <ListFiltersSearch />}
                </Container>
                <Container area="cx">
                    <Box i18n={state?.i18n} data={state?.cx} />
                </Container>
                <Container area='filter'>
                    <Filter handlePagination={handlePagination}/>
                </Container>
                <Container area='list'>
                    <ListHeader
                        checkBoxSelected={selectAll}
                        handleClickCheckBox={handleClickCheckBox}
                    />
                    {!state?.isLoad ? <LoaderInfos/> : renderReviewList()}
                    <ButtonsActions
                        fixed="true"
                        checkeds={state?.selecteds}
                        allItems={true}
                    />
                </Container>
                <Container area='pagination'>
                    <Pagination handlePagination={handlePagination}/>
                </Container>
            </>
        );
    }, [state.i18n, state.reviews, state.cx, state.selecteds, selectAll, state.isLoad, state.actualRoute, state?.visible])
}

export default Nps;
