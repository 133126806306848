import styled, { css, keyframes} from 'styled-components';

const growAndSlide = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const decreaseAndSlide = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

export const Container = styled.div`
    width: 100%;
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: inherit;
    height: auto;
    max-height: 120px;
    display: flex;
    transition: all ease-in-out 0.5s;
    align-items: flex-end;

    ${props => props.open && css`
        transition: all ease-in-out 0.5s;
        height: auto !important;
        max-height: 350px;
    `}
`

export const Content = styled.div`
    display: flex;
    max-width: 100%;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    height: auto;
`

export const Wrapper = styled.div`
    width: auto;
    height: auto;
    max-height: 120px;
    min-width: 98%;
    max-width: 98%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    transition: all ease-in-out 0.5s;

    ${props => props.open && css`
        max-height: 350px;
        overflow-y: scroll;

        transition: all ease-in-out 0.5s;
        padding-right: 5px;
    `}

    @media (max-width: 768px) {
        flex-wrap: wrap;
    }
`

export const MoreContentTrap = styled.div`
    width: 100px;
    height: 15px;
    background-color: #EFF0F0;
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 10% 100%);

    position: absolute;
    bottom: -35px;
    left: calc(50% - 40px);
    transform: translateX(-50%);
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    > div:hover {
        svg {
            path {
                fill: #fff;
            }
        }
    }

    svg {
        path {
            fill: #000;
        }
    }
`
export const BoxFilter = styled.button`
    width: 30px;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #fff;
    cursor: pointer;

    border: none;
    border-radius: 5px;

    margin-right: 10px;

    ${props => props.active && css`
        animation: ${growAndSlide} 0.5s ease-in;
    `}
    ${props => !props.active && css`
        animation: ${decreaseAndSlide} 0.5s ease-in-out;
    `}
`

export const Message = styled.span`
    color: #000000;
    opacity: 0.4;
    display: flex;
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    position: relative;

`

