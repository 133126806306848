import React, { useContext } from 'react';

import { Container, Wrapper, WrapperButtons } from './styles';
import { Name } from '../../styles';

import Button from './Button';

import { ReactComponent as AtentionItem } from "../../../../assets/atentionIcon.svg";

import { ContextReducer } from '../../../../reducer/AdminReducer';

const ModalConfirmationLang = () => {
    const { state, dispatch } = useContext(ContextReducer);

    const handleModal = (from) => {
        dispatch({
            type: 'SET_MODAL_CONFIRMATION_LANG',
            payload: {
                active: false,
                button: from,
            },
        })
    }

    return (
        <>
            <Container />
            <Wrapper>
                <AtentionItem />
                <Name>{state?.i18n?.configuration?.langTitle}</Name>
                <span>{state?.i18n?.configuration?.langTextConfirmation}</span>
                <WrapperButtons>
                    <Button
                        type={'cancel'}
                        cb={handleModal}
                        text={state?.i18n?.configuration?.buttonLangCancel}
                    />
                    <Button
                        type={'change'}
                        cb={handleModal}
                        text={state?.i18n?.configuration?.buttonLangChange}
                    />
                </WrapperButtons>
            </Wrapper>
        </>
    );
};

export default ModalConfirmationLang;
