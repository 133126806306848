import styled, {css} from 'styled-components';

export const Selects = styled.ul`
    width: 120px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: space-between;
    flex-direction: column;
    z-index: 2;
    max-height: 55px;

    ${props => props.active && css`
        min-height: 55px !important;
    `}

    ${props => props.isNewsletterAndInsights && css`
        width: 200px;
        height: 100px;
    `}

    ${props => props.ecom && css`
        height: auto !important;
        width: auto;
        background-color: transparent;
        font-size: 14px;
        border-radius: 0;
        padding: 0;
        color: #fff;
        outline: none;

        option {
            color: #000;
        }
    `}
    ${props => props.nomargin && css`
        margin-right: 0;
    `}
    ${props => (props.visible && props.active && !props.firstItem) && css`
        opacity: 1;
        height: auto;
        width: 120px;
    `}

    ${props => (props.visible && !props.active && props.firstItem) && css`
        width: 50px;
    `}


    @media(max-width: 768px) {
        margin-bottom: 10px;
    }
`
export const Item = styled.li`
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    color: #000;
    font-size: 13px;
    font-weight: bold;
    border-top: 1px solid #dfdfdf;
    padding: 10px;

    cursor: pointer;

    span {
        margin-left: 5px;
    }

    ${props => props.showOnlyFirstItem && css`
        opacity: 0;
        pointer-events: none;
    `}
    ${props => (props.showOnlyFirstItem && props.isNewsletterAndInsights) && css`
        display: none;
    `}
    ${props => props.isNewsletterAndInsights && css`
        height: 10px;
        font-size: 9px;
    `}
    ${props => !props.showOnlyFirstItem && css`
        :nth-child(1) {
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 0px;
            border-bottom-left-radius: 0px;
        }
    `}
    :nth-child(1) {
        border-top: 0;
        border-radius: 5px;
        justify-content: space-between;
        min-height: 55px;
        padding: 0;

        span {
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    :last-child {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    :hover {
        transition: all ease-in-out 0.5s;
        background-color: #000;
        color: #fff;

        svg {
            color: #fff;
            stroke: #fff;

            path {
                color: #fff;
            }
        }

        :nth-child(1) {
            background-color: #fff;
            color: #000;

            svg {
                path {
                    color: #000;
                }
            }
        }
    }
`

export const OldSelect = styled.select`
    width: 190px;
    height: 55px;
    border: none;
    border-radius: 5px;
    padding-left: 17px;

    ${props => props.ecom && css`
        height: auto ! impo;
        width: auto;
        background-color: transparent;
        font-size: 14px;
        border-radius: 0;
        padding: 0;
        color: #fff;
        outline: none;

        option {
            color: #000;
        }
    `}

    ${props => props.nomargin && css`
        margin-right: 0;
    `} @media(max-width: 768px) {
    margin-bottom: 10px;
}
`
