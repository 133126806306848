import React, { useContext, useMemo, useCallback, useRef } from 'react';
import {Container, BoxCheck, Status, CloseButton} from './styles'

import { ReactComponent as IconFilter } from '../../../../assets/Icon-Filter.svg';
import { IoIosClose } from "react-icons/io";

import {ContextReducer} from '../../../../reducer/AdminReducer';

import IAWrapper from './IAWrapper';

const ListCheckboxes = ({ setActiveFilterTags, timesRender, setTimesRender }) => {
    const _availableFilters = ['positive', 'negative', 'mixed', 'neutral', 'operator', 'user', 'system'];

    const { state, dispatch } = useContext(ContextReducer);

    const handleChangeFilters = useCallback((item) => {
        let _arr = [...state?.tagsFilterHeader];

        if(_arr.includes(item)){
            _arr = _arr.filter(i => i !== item);
        } else {
            _arr = [..._arr, item ];
        }

        dispatch( {
            type: 'SET_TAGS_HEADER_FILTER',
            payload: _arr
        })
    }, [state?.tagsFilterHeader]);

    const renderTranslateFilters = useCallback((item) => {
        switch(item){
            case 'positive':
                return 'Positivo'
            case 'negative':
                return 'Negativo'
            case 'mixed':
                return 'Misto'
            case 'operator':
                return 'SAC'
            case 'user':
                return 'Clientes'
            case 'system':
                return 'IA'
            default:
                return 'Neutro'
        }
    }, []);

    const renderChecks = useMemo(() => {
        return _availableFilters.map((item, index) => {
            const isChecked = state?.tagsFilterHeader.some(_item => _item === item);

            return (
                <BoxCheck
                    key={item}
                    last={index === (_availableFilters.length - 1)}
                >
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={() => handleChangeFilters(item)}
                    />
                    <Status item={item} />
                    <label>{renderTranslateFilters(item)}</label>
                </BoxCheck>
            )
        })
    }, [ state?.tagsFilterHeader, handleChangeFilters, renderTranslateFilters ])


    return (
        <Container>
            <IconFilter
                style={{ cursor: 'pointer'}}
                onClick={() => setActiveFilterTags(false)}
            />
            <span>{state?.i18n?.filterTags?.filterByTags}</span>
            {renderChecks}
            <IAWrapper
                timesRender={timesRender}
                setTimesRender={setTimesRender}
            />
            <CloseButton>
                <IoIosClose onClick={() => setActiveFilterTags(false)} />
            </CloseButton>
        </Container>
    );
};

export default ListCheckboxes;
