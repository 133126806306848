import React, { useContext } from 'react';

import { ContextReducer } from '../../../reducer/AdminReducer';

import { Align } from '../styles';
import { Answers } from './styles';

import { setModal } from '../../../actions/ReviewsAction';

import { GrAddCircle, GrSubtractCircle } from 'react-icons/gr';

import Answer from '../../Answer';

const ButtonsInfo = (props) => {
    const { state, dispatch } = useContext(ContextReducer);

    const { childrenInfo } = props.values;

    const renderButtonAnswer = (val) => {
        if(val && val.length > 0){
            return(
                <Answers onClick={() => props.onAnswer(props.indexItem)}>
                    {!props.answer ? <GrAddCircle  style={{ height: '16px', width: '16px', color: '#FFA100'}} /> : <GrSubtractCircle  style={{ height: '16px', width: '16px', color: '#FFA100'}} />}
                    <span>{state?.i18n?.buttonView[state?.actualRoute]}</span>
                </Answers>
            );
        }
    }

    return (
        <Align space='20' direcion='top'>
            {(state.actualRoute === 'questions' || state.actualRoute === 'reviews') &&
                <div onClick={() => setModal(dispatch, {index: props.indexItem, status: true, type: state.actualRoute})}>
                    <Answer />
                </div>
            }
            {(state.actualRoute === 'questions' || state.actualRoute === 'reviews') && renderButtonAnswer(childrenInfo)}
        </Align>
    );
};

export default ButtonsInfo;
