import {useContext, useEffect, useRef, useState} from 'react';
import {ContextReducer} from '../reducer/AdminReducer';
import { getDashboard, getGraphic, getFeatured, getNewsletterCards, getNewsletters, getReviews, getUsers, setFilter, setIsLoad, getCardsCx, setRangePeriod, setTagsHeader} from '../actions/ReviewsAction';

import { format, addMonths, endOfDay } from 'date-fns';

const useInitializeInfos = () => {
    const {state, dispatch} = useContext(ContextReducer);
    const [selectAll, setSelectAll] = useState(false);
    const [isFinishLastUseEffect, setIsFinishLastUseEffect] = useState(false);

    const firstRenderRef = useRef(true);

    const controller = new AbortController();
    const signal = controller.signal;

    useEffect(() => {
        if(state.selecteds.length > 0) return;

        setSelectAll(false);
    }, [ state?.filters?.status, state.selecteds ]);

    useEffect(() => {
        if (state.actualRoute === 'nps') {
            const filter = {
                orderSelectSearch: 'transactionId',
                tag: '',
                listFiltersHeader: { ...state?.filters?.listFiltersHeader, 'period': 'Últimos 3 meses'}
            }

            setFilter(dispatch, filter);

            setRangePeriod(dispatch, [{
                startDate: addMonths(new Date(), -3),
                endDate: endOfDay(new Date()),
                color: "#000000"
            }])

            getReviews(state.actualRoute, dispatch, state.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, '', '', signal, format(addMonths(new Date(), -3), 'dd/MM/yyyy'), format(endOfDay(new Date()), 'dd/MM/yyyy'));
            getCardsCx(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, state.filters, format(addMonths(new Date(), -3), 'dd/MM/yyyy'), format(endOfDay(new Date()), 'dd/MM/yyyy'), signal);
            setTagsHeader(state.actualRoute, dispatch, state.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, '', signal, format(addMonths(new Date(), -3), 'dd/MM/yyyy'), format(endOfDay(new Date()), 'dd/MM/yyyy'), state?.tagsFilterHeader);
        } else if (state.actualRoute === 'users') {
            const filter = {orderSelectSearch: 'userName', tag: '', listFiltersHeader: {}}
            setFilter(dispatch, filter)
            getUsers(dispatch, state.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, '', '', signal);
        } else if (state.actualRoute === 'newsletter') {
            const filter = {orderSelectSearch: 'transactionId', tag: '', listFiltersHeader: {}}
            setFilter(dispatch, filter)
            getNewsletterCards(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, state.filters, signal);
            getNewsletters(dispatch, state.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, '', '', signal);
        } else if(state.actualRoute === 'insights') {
            const filter = {
                orderSelectSearch: '',
                tag: '',
                listFiltersHeader: {},
                status: 'PENDING',
                order: 'newer',
                tags: null,
                orderUsers: 'newer',
                url: null,
                orderFeaturedProducts: 'reviews',
                sortFeaturedProducts: 'desc',
                listType: state?.actualArea,
                orderNewsletter: 'transactionDate',
                sortNewsletter: 'desc'
            }
            setFilter(dispatch, filter)
            getDashboard(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, state.filters, '', '', signal);
            getGraphic(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, '', '', signal);
            getFeatured(dispatch, state?.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, null, 0, state.featuredType, '', '', signal);
        } else {
            const filter = {orderSelectSearch: 'productName', tag: '', listFiltersHeader: {}}
            setFilter(dispatch, filter)
            getReviews(state.actualRoute, dispatch, state.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, '', '', signal);
            setTagsHeader(state.actualRoute, dispatch, state.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, '', signal,'', '', state?.tagsFilterHeader);
        }

        return () => {
            controller.abort();
        }
    }, []);

    useEffect(() => {
        if (firstRenderRef.current === true) {
            setIsFinishLastUseEffect(true);
            return
        }

        if(!firstRenderRef.current) {
            if (state.actualRoute === 'users') {
                getUsers(dispatch, state.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, '', '', signal,  (state.rangePeriod[0].startDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', ( state.rangePeriod[0].endDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '');
            } else if (state.actualRoute === 'newsletter') {
                getNewsletterCards(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, state.filters, signal, (state.rangePeriod[0].startDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', ( state.rangePeriod[0].endDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '');
                getNewsletters(dispatch, state.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, '', '', signal, (state.rangePeriod[0].startDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', ( state.rangePeriod[0].endDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '');
            } else if(state.actualRoute === 'insights') {
                getDashboard(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, state.filters, (state.rangePeriod[0].startDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', (state.rangePeriod[0].endDate  && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '', signal);
                getGraphic(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token, (state.rangePeriod[0].startDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', (state.rangePeriod[0].endDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '', signal);
                getFeatured(dispatch, state?.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, null, 0, state.featuredType, (state.rangePeriod[0].startDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', ( state.rangePeriod[0].endDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '', signal);
            } else {
                getReviews(state.actualRoute, dispatch, state.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, '', '', signal, (state.rangePeriod[0].startDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', ( state.rangePeriod[0].endDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '');

                if(state.actualRoute === 'nps') {
                    getCardsCx(dispatch, state?.userInfo?.ecommerce, state?.userInfo?.token,  state.filters, (state.rangePeriod[0].startDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', ( state.rangePeriod[0].endDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '', signal)
                }
                setTagsHeader(state.actualRoute, dispatch, state.filters, state?.userInfo?.ecommerce, state?.userInfo?.token, '', signal, (state.rangePeriod[0].startDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].startDate, 'dd/MM/yyyy') : '', ( state.rangePeriod[0].endDate && !state.rangePeriod[0].allMonths) ? format(state.rangePeriod[0].endDate, 'dd/MM/yyyy') : '', state?.tagsFilterHeader);
            }
        }

        return () => {
            controller.abort();
        }

    }, [state.rangePeriod, state?.filters?.status, state?.filters?.order, state?.filters?.orderUsers, state?.filters?.orderFeatured, state?.filters?.sortFeatured, state?.filters?.sortNewsletter, state?.filters?.tag, state?.filters?.last, state?.filters?.orderNewsletter, state?.filters?.orderSelectExperienceNps, state?.userInfo?.ecommerce, state?.userInfo?.token, state.featuredType, state?.tagsFilterHeader ]);


    useEffect(() => {
        if (firstRenderRef.current && isFinishLastUseEffect ) {
            firstRenderRef.current = false;

            return;
        }
    }, [isFinishLastUseEffect])

    useEffect(() => {
        setIsLoad(dispatch, false);
    }, [state.actualRoute])

    return {
        selectAll,
        setSelectAll,
    };
};

export default useInitializeInfos;
