import styled, { css } from 'styled-components';


export const FormButtonsTags = styled.form`
    position: relative;
`
export const Input = styled.input`
    width: 30px;
    border:none;
    border:1px solid #000;
    height: 30px;
    line-height: 30px;
    box-sizing: border-box;
    font-size: 12px;
    padding: 0 10px;
    border-radius: 5px;
    margin: 0 5px 0 0;
    transition: all ease .25s;
    margin-bottom: 10px;

    ${props => props.active && css`
        width: 350px;
    `}
`

export const ButtonTypeTagsContent = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 5px;
    z-index: 7;
`

export const ButtonTypeTags = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    ${props => props.typeButton === 'positive' && css`
        border: 1px solid rgba(113, 206, 79, .3) !important;
        border-radius: 5px;
        width: 20px;
        height: 20px;
        background-color: #fff;
        margin-right: 10px;
        cursor: pointer;
        transition: all ease .25s;

        &:hover {
            background-color: rgba(113, 206, 79, 1) !important;

            &:before {
                background-color: #fff !important;
            }
        }

        &:before {
            background-color: rgba(113, 206, 79, 1) !important;
            content: '';
            width: 5px;
            height: 5px;
            background-color: #808080;
            border-radius: 50%;
        }
    `}

    ${props => (props.typeButton === 'positive' && !props.act) && css`
        border: 1px solid #edeef0 !important;
        background-color: #edeef0;

        &:hover {
            background-color:#edeef0 !important;

            &:before {
                background-color: #fff !important;
            }
        }

        &:before {
            background-color: #fff !important;
        }
    `}



    ${props => props.typeButton === 'negative' && css`
        border: 1px solid rgba(252, 66, 29, .3) !important;
        border-radius: 5px;
        width: 20px;
        height: 20px;
        background-color: #fff;
        margin-right: 10px;
        cursor: pointer;

        &:hover {
            background-color: rgba(252, 66, 29, 1) !important;

            &:before {
                background-color: #fff !important;
            }
        }

        &:before {
            background-color: rgba(252, 66, 29, 1) !important;
            content: '';
            width: 5px;
            height: 5px;
            background-color: #808080;
            border-radius: 50%;
        }
    `}

    ${props => (props.typeButton === 'negative' && !props.act) && css`
        border: 1px solid #edeef0 !important;
        background-color: #edeef0;

        &:hover {
            background-color:#edeef0 !important;

            &:before {
                background-color: #fff !important;
            }
        }

        &:before {
            background-color: #fff !important;
        }
    `}

    ${props => props.typeButton === 'mixed' && css`
        border: 1px solid rgba(255, 161, 0, .3) !important;
        border-radius: 5px;
        width: 20px;
        height: 20px;
        background-color: #fff;
        margin-right: 10px;
        cursor: pointer;

        &:hover {
            background-color: rgba(255, 161, 0, 1) !important;

            &:before {
                background-color: #fff !important;
            }
        }

        &:before {
            background-color: rgba(255, 161, 0, 1) !important;
            content: '';
            width: 5px;
            height: 5px;
            background-color: #808080;
            border-radius: 50%;
        }
    `}

    ${props => (props.typeButton === 'mixed' && !props.act) && css`
        border: 1px solid #edeef0 !important;
        background-color: #edeef0;

        &:hover {
            background-color:#edeef0 !important;

            &:before {
                background-color: #fff !important;
            }
        }

        &:before {
            background-color: #fff !important;
        }
    `}

    ${props => props.typeButton === 'add' && css`
        border: 1px solid #B6BAC4 !important;
        border-radius: 5px;
        width: 30px;
        height: 30px;
        background-color: #000;
        cursor: pointer;

        &:before {
            content: '+';
            font-size: 12px;
            color: #fff;
            font-family: math, serif;
        }

        &:hover {
            background-color: #000 !important;
            border: 1px solid #000 !important;

            &:before {
                color: #fff !important;
            }
        }
    `}

    ${props => (props.typeButton === 'add' && !props.act) && css`
        background-color: #edeef0;
        border-top: 1px solid #000 !important;
        border-bottom: 1px solid #000 !important;
        border-right: 1px solid #000 !important;
        border-left: 1px solid #000 !important;
    `}

    ${props => (props.typeButton === 'add' && props.active && !props.act) && css`
        &:hover {
            background-color: #edeef0 !important;
            border-top: 1px solid #000 !important;
            border-bottom: 1px solid #000 !important;
            border-right: 1px solid #000 !important;
            border-left: 1px solid #000 !important;

            &:before {
                color: #fff !important;
            }
        }
    `}

    ${props => (props.typeButton === 'add' && !props.active) && css`
        background-color: #fff !important;
        border: 1px solid #B6BAC4 !important;

        &:before {
            color: #000 !important;
        }
    `}

`

export const MoreContent = styled.div`
    width: 100px;
    height: 15px;
    background-color: #fff;
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 10% 100%);

    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    justify-content: center;
    align-items: center;

    > div:hover {
        svg {
            path {
                fill: #fff;
            }
        }
    }

    svg {
        path {
            fill: #000;
        }
    }
`

export const ContainerTags = styled.div`
    display: flex;
    width: auto;
    max-width: 100%;
    height: auto;
    flex-wrap: wrap;
    padding-right: 10px;
`
