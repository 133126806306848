import renderName from './renderName';

const renderInitials = (initials) => {
    if(!initials) return '';

    let _initials = renderName(initials).trim();


    _initials = _initials.split(' ').reduce((ac, inn) => ac + inn[0], '').toUpperCase();

    return _initials;

}

export default renderInitials;


