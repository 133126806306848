import React, {useContext, useMemo, useState, useCallback, useEffect} from 'react';
import { Container, ListHeaderItem } from '../../Containers/styles';

import { Align, Date, WrapperProductStatistics, WrapperItem, WrapperItemLeft, WrapperItemRight } from './styles';

import ButtonsActions from '../ButtonsActions';
import Pills from '../Pills';
import Checkbox from '../Checkbox';
import AnswerList from '../AnswerList';
import ModalResponse from '../ModalResponse';
import ModalAttachment from '../ModalAttachment';
import EditTags from './EditTags'
import MoreInfo from './MoreInfo';
import HeaderTransactionInfos from './HeaderTransactionInfosNps';
import Sold from './Sold';
import StarsNps from "../StarsNps";
import ImageProductOrSeller from './ImageProductOrSeller';

import {ContextReducer} from "../../reducer/AdminReducer";

import Statistics from './Statistics';
import ProductName from './ProductName';
import AttachmentImage from './AttachmentImage';

import UserInfo from './UserInfo';
import ButtonsInfo from './ButtonsInfo';
import Description from './Description';
import More from '../More';

import { MoreContent } from './EditTags/styles';

import { setChecked, setNotification, clearChecked } from '../../actions/ReviewsAction';

const Items = (props) => {
    const { state, dispatch } = useContext(ContextReducer)
    const [showMore, setShowMore] = useState(null);
    const [answer, setAnswer] = useState(false);
    const [answerActiveId, setAnswerActiveId] = useState('');

    let setUncheck;

    useEffect(() => {
        return () => {
            clearTimeout();
        }
    }, [])

    useEffect(() => {
        return () => {
            dispatch({
                type: 'SET_STATUS_SELECTALL',
                payload: ''
            })
        }
    }, [state.filters.status]);

    useEffect(() => {
        setUncheck();
        clearChecked(dispatch);
        props.handleClickCheckBox('', '', false);
    }, [ state?.filters?.status ]);


    const handleMore = useCallback((idx) => {
        if(showMore === null) {
            setShowMore(idx);
        } else {
            setShowMore(null);
        }
    }, [showMore])


    const handleAnswer = useCallback((idx) => {
        setAnswer(!answer)
        setAnswerActiveId(idx)
    }, [answer]);

    const handleClick = useCallback((e, ref) => {
        if(!state?.typeEcommerce?.store && !state?.userInfo?.isAdmin) {

            const _role = 'accessNotAllowed';
            setNotification(dispatch, {status: true, role: _role});

            setTimeout(() => {
                setNotification(dispatch, {
                    'status': false,
                    'role': _role
                })
                if(ref.current) {
                    ref.current.checked = false;
                }
            }, 3000)
            return
        }

        const { id, name, checked } = e.target;

        setChecked(dispatch, id, name, checked)

    }, [dispatch, state?.typeEcommerce?.store, state?.userInfo?.isAdmin]);

    const renderCheckBox = () => {
        return (
            <ListHeaderItem
                width='50'
                align='center'
                isNpsRoute={state.actualRoute === 'nps'}
                nomobile={true}
            >
                <Checkbox
                    handleClick={handleClick}
                    type='checkbox'
                    id={props.value?.id}
                    name={props.value?.author}
                    selected={props.selected}
                    uncheck={uncheck => setUncheck = uncheck}
                />
            </ListHeaderItem>
        )
    }


    const renderTagsPills = useCallback(() => {
        if(props.value?.liked || props.value?.recommend || props.value?.use_cases || props.value?.categories) {
            return (
                <>
                    <Align space='20' direction='top' wrap="true">
                        <Pills
                            pills={props.value?.liked}
                            recommend={props.value?.recommend}
                            use_cases={props.value?.use_cases}
                            categories={props.value?.categories}
                        />
                    </Align>
                </>
            )
        }

        return <></>
    }, [props.value.liked, props.value?.recommend, props.value?.use_cases, props.value?.categories])

    const renderDate = useCallback(() => {
        return(
            <ListHeaderItem type='date' align='center' isNpsRoute={state.actualRoute === 'nps'} nomobile={true}>
                <Date>{props.value?.datePublished}</Date>
            </ListHeaderItem>
        )
    }, [ state.actualRoute, props.value?.datePublished])

    const renderAnswerList = useCallback(() => {
        if(state.actualRoute === 'questions' || state.actualRoute === 'reviews') {
            return(
                <AnswerList list={props?.value?.childrenInfo} active={answerActiveId === props.index && answer} />
            )
        }
    }, [props?.value?.childrenInfo, answer, answerActiveId, props.index, state.actualRoute])


    return useMemo(() => {
        const value = props.value;

        return (
            <>
                {state.actualRoute === 'nps' && <HeaderTransactionInfos transaction={props.value.transaction}/>}
                <Container
                    area='listitems'
                    isNpsRoute={state.actualRoute === 'nps'}
                >
                    {renderCheckBox()}
                    <WrapperProductStatistics>
                        <ImageProductOrSeller values={props.value} />
                        {state.actualRoute === 'reviews' && <Statistics values={props.value} />}
                    </WrapperProductStatistics>
                    <ListHeaderItem
                        type="items"
                        align='left'
                        showMore={showMore}
                        moreInfo={showMore === props.index}
                    >
                        <WrapperItem  moreInfo={showMore === props.index}>
                            <WrapperItemLeft>
                                <Sold values={props.value} />
                                <ProductName values={props.value}/>
                                <UserInfo
                                    indexItem={props.index}
                                    values={props.value}
                                />
                                {(state.actualRoute === 'nps') && <StarsNps rating={props.value?.reviewRating} />}
                                {renderTagsPills()}
                                <Description
                                    indexItem={props.index}
                                    values={props.value}
                                />
                                <ButtonsInfo
                                    indexItem={props.index}
                                    values={props.value}
                                    answer={answer}
                                    onAnswer={handleAnswer}
                                    answerActiveId={answerActiveId}
                                />
                                {renderAnswerList()}
                                <EditTags
                                    values={props.value}
                                    indexItem={props.index}
                                    showMore={showMore}
                                />
                                <MoreInfo
                                    values={props.value}
                                    indexItem={props.index}
                                    showMore={showMore}
                                />
                            </WrapperItemLeft>
                            <WrapperItemRight>
                                {(state.actualRoute === 'reviews' && props.value.attachment) &&
                                    <AttachmentImage
                                        values={props.value}
                                        index={props.index}
                                    />
                                }
                                {renderDate()}
                                <ButtonsActions
                                    type={state?.actualRoute}
                                    item={props.value}
                                    idx={props.index}
                                    status={props.value?.status}
                                    valueId={props?.value?.id}
                                    from={'items'}
                                />
                            </WrapperItemRight>
                        </WrapperItem>
                    </ListHeaderItem>
                    {props.type !== 'configuration' &&
                        <MoreContent onClick={() => handleMore(props.index)}>
                            <More more={showMore} />
                        </MoreContent>
                    }
                </Container>
                {state?.attach.index === props.index && state?.attach.status ? <ModalAttachment value={value.attachment && value.attachment[0]?.value}/> : <></> }
                {state?.modal.index === props.index && state?.modal.status ?
                    <ModalResponse
                        values={value}
                        active={state?.modal.index === props.index && state?.modal.status}
                    /> :
                    <></>
                }
            </>
        )
    }, [answer, answerActiveId, handleAnswer, handleMore, props.index, props.value, renderAnswerList, renderCheckBox, renderDate, renderTagsPills, state.actualRoute, state.attach.status,state.attach.index, state.modal.index,state.modal.status, showMore ])
}

export default Items;
