import React from 'react';
import {Container, Sentiment} from './styles';
import {CgClose} from 'react-icons/cg';
import { ImCheckmark } from "react-icons/im";

const Item = (props) => {
    const {text, indexKey, tag} = props;

    const renderContainer = () => {
        const userUtil = tag?.authorTag === 'user' && tag?.sentimentTag === 'neutral';

        if (text.length === 0) return <></>

        return (
            <Container tag={tag}>
                {(userUtil && indexKey === 'tag') && <ImCheckmark style={{ margin: '0px 7px', width: '11px', height: '9px', color: '#fff' }}/>}
                {(!userUtil && indexKey === 'tag') && <Sentiment sentimentTag={tag?.sentimentTag} />}
                <span>{text?.charAt(0).toUpperCase() + text?.slice(1).toLowerCase()}</span>
                <CgClose
                    style={{color: '#fff', width: '12px', height: '12px'}}
                    onClick={() => props.onExcludeItem(indexKey)}
                />
            </Container>
        );
    }

    return renderContainer()
};

export default Item;
