import React, {useContext, useState} from 'react';
import {ContextReducer} from '../../../reducer/AdminReducer';

const ListIntegratedStores = () => {
    const {state, dispatch} = useContext(ContextReducer);


    return <></>
};

export default ListIntegratedStores;
