import React, { useContext } from 'react';
import { ContextReducer } from '../../../reducer/AdminReducer';
import { Container, Table } from '../styles';

const TableNewsletter = ({handleExportData}) => {
    const { state } = useContext(ContextReducer);

    const newsletteri18n = state?.i18n?.newsletter;
    const placeholderToEmptyValues = state?.i18n?.exportTable?.placeholderToEmptyValues;
    const placeholderToFullValues = state?.i18n?.exportTable?.placeholderToFullValues

    const renderItemsProducts = (item) => {
        const _arr = [];

        item?.productList.forEach((obj, index) => {
            _arr[index] = { productId: obj?.id, review: !obj.review ? placeholderToEmptyValues?.reviewdoNotDone : placeholderToFullValues?.reviewDone, productName: !obj.name ? placeholderToEmptyValues?.noName : obj.name};
        });

        return JSON.stringify(_arr).replace(/"/g, '').replace(/\[|\]/g, '').replace(/:/g, ': ').replace(/,/g, ', ');
    }
    const renderBody = () => {
        return state?.newsletters?.items.map((obj, idx) => {
            return (
                <tr
                    key={idx}
                    style={{border: '1px solid #000'}}
                >
                    <td>{!obj?.transactionId ? placeholderToEmptyValues?.transactionId : obj?.transactionId}</td>
                    <td>{!renderItemsProducts(obj) ? placeholderToEmptyValues?.noProducts : renderItemsProducts(obj)}</td>
                    <td>{!obj?.transactionDate ? placeholderToEmptyValues?.transactionDate : obj?.transactionDate}</td>
                    <td>{!obj?.status ? state?.i18n?.newsletter?.pending : state?.i18n?.newsletter?.sent}</td>
                    <td>{!obj?.emailSentDate ? placeholderToEmptyValues?.emailSentDate : obj?.emailSentDate}</td>
                    <td>{!obj?.opened ? placeholderToEmptyValues?.notOpened : state?.i18n?.newsletter?.opnened}</td>
                    <td>{!obj?.attempts ? placeholderToEmptyValues?.notOnce : obj?.attempts + (obj?.attempts > 1 ? placeholderToFullValues?.attemptsManyTimes : placeholderToFullValues?.attemptOnce)}</td>
                    <td>{obj?.review}</td>
                    <td>{!obj?.nps ? placeholderToEmptyValues?.notNps : placeholderToFullValues?.npsOkay}</td>
                </tr>
            )
        })
    }

    if (state?.exportData?.loading) {
        return (
            <Container id="tableID">
                <meta charSet="utf-8"/>
                <Table border={'1'}>
                    <thead>
                        <tr style={{border: '1px solid #000'}}>
                            <th>{newsletteri18n?.table1}</th>
                            <th>{newsletteri18n?.table10}</th>
                            <th>{newsletteri18n?.table2}</th>
                            <th>{newsletteri18n?.table4}</th>
                            <th>{newsletteri18n?.table5}</th>
                            <th>{newsletteri18n?.table6}</th>
                            <th>{newsletteri18n?.table7}</th>
                            <th>{newsletteri18n?.table8}</th>
                            <th>{newsletteri18n?.table9}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderBody()}
                    </tbody>
                </Table>
            </Container>
        )
    }

    handleExportData();

    return <></>;
};

export default TableNewsletter;
