import styled, {css, keyframes} from "styled-components";

export const Animation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

export const ContentRight = styled.div`
    max-width: 700px;
    width: 100%;
    position: sticky;
    top: 0;
    right: 0;
    height: fit-content;
`

export const ContentLeft = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 410px;
    width: 100%;
    margin-right: 100px;
    flex-flow: column;
`

export const Title = styled.h3`
    font-size: 30px !important;
    font-weight: bold;
    color: #000;
    text-align: left;
    display: block;
    margin-bottom: 30px;
`

export const FormNews = styled.form`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    max-width: 410px;
    width: 100%;
`

export const Label = styled.label`
    display: flex;
    align-items: center;
    max-width: 90px;
    width: 100%;
    margin-bottom: 10px;
    cursor: pointer;

    span {
        margin-left: 5px;
    }

    ${props => props.from === 'emailsGeneral' && css`
        font-size: 14px;
        margin-right: 50px;

        :nth-child(3n) {
            margin-right: 0;
        }
    `}

    ${props => props.from === 'weeks' && css`
        max-width: 112px;
        font-size: 14px;
        margin-right: 17px;

        :nth-child(3n) {
            margin-right: 0;
        }
    `}

    ${props => props.from === 'maxAttempts' && css`
        max-width: 41px;
        font-size: 14px;
        margin-right: 90px;

        :nth-child(3n) {
            margin-right: 0;
        }
    `}

    ${props => props.from === 'activeEmail' && css`
        width: auto;
        max-width: 100%;
        font-size: 14px;
    `}


    input {
        width: 20px;
        height: 20px;
    }
`
export const Block = styled.div`
    width: 410px;
    margin-bottom: 50px;
    position: relative;

    ${props => props.from === 'emailConfigs' && css`
        margin-bottom: 30px;
        width: 100%;

        :focus {
            outline: none;
        }
    `}

    ${props => props.w && css`
        width: ${props => props.w}px;
    `}

    ${props => props.percentage && css`
        width: ${props => props.percentage}%;
    `}

    ${props => props.from === 'buttonsEmail' && css`
        width: 100%;
        margin-bottom: 30px;
    `}
    ${props => props.from === 'colorsInput' && css`
        margin-bottom: 30px;
        white-space: nowrap;
    `}
`
export const SubBlock = styled.div`
    width: 100%;
    display: flex;
    flex-flow: wrap;
`

export const InputColorContent = styled.div`
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    width: 170px;
    height: 50px;
    background-color: #fff;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
        accent-color: #000;
    }

    > input[type="text"] {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
    }

    input[type="color"] {
        width: 30px;
        height: 30px;
        border: none;
        outline: none;
    }

    span {
        font-size: 12px;
        font-weight: bold;
    }
`

export const Name = styled.span`
    font-size: 14px;
    color: #000;
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
`

export const SubTitle = styled.span`
    font-size: 14px;
    color: #333;
    display: block;
    margin-bottom: 20px;
    width: 100%;
`

export const IPText = styled.input`
    width: 100%;
    height: 50px;
    background-color: #fff;
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    padding: 0 20px;
    line-height: 50px;
    font-size: 14px;
    color: #333;
`

export const TextArea = styled.textarea`
    width: 100%;
    height: 150px;
    background-color: #fff;
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    padding: 20px;
    line-height: 50px;
    font-size: 14px;
    color: #333;
    font-family: 'Open Sans',sans-serif;
    line-height: 18px;
`

export const IPFile = styled.input`
    width: 100%;
    height: 50px;
    background-color: transparent;
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    padding: 0 20px;
    line-height: 50px;
    font-size: 14px;
    color: #333;
    opacity: 0;
    position: relative;
    z-index: 2;
    cursor: pointer;

    ${props => props.disabled && css`
        cursor: not-allowed;
    `}
`

export const IPFileFake = styled.div`
    width: 100%;
    height: 50px;
    background-color: #fff;
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    padding: 0 20px;
    line-height: 50px;
    font-size: 14px;
    color: #333;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    overflow: hidden;

    span {
        width: 90%;
        display: block;
        position: relative;
        overflow: hidden;
    }

    ${props => props.disable && css`
        opacity: .3;
    `}

    ${props => props.fake && css`
        cursor: default;
        z-index: 3;

        svg {
            cursor: pointer;
        }
    `}

    ${props => (props.email && props.disable) && css`
        cursor: not-allowed;
        z-index: 1;

    `}

`

export const Previews = styled.div`
    width: 100%;
    display: flex;
    flex-flow: wrap column;
    height: 310px;
    position: relative;
    overflow: hidden;
`

export const Preview = styled.div`
    width: ${props => props.vw}px;
    height: ${props => props.vh}px;
    margin-bottom: ${props => props.mg}px;
    position: relative;

    ${props => props.bg && css`
        background: url(${props => props.bg}) no-repeat center center;
        background-size: contain;
    `}

    ${props => props.min && css`
        padding: 20px 30px;
        display: flex;
        align-items: center;
        background-color: #fff;
    `}
`

export const EmailPreview = styled.div`
    width: 100%;
    max-width: 700px;
    position: sticky;
    top: 20px;
    right: 0;
    margin-bottom: 30px;
`

export const PreviewLeft = styled.div`
    margin-right: 20px;
`

export const PreviewRight = styled.div`
    display: flex;
    flex-flow: column;

    strong {
        color: #BBBBBB;
        font-size: 18px;
        font-weight: bold;
        display: block;
        margin-bottom: 5px;
    }

    span {
        color: #20BFF0;
        font-size: 12px;
        display: flex;
        align-items: center;

        svg {
            margin-right: 5px;
        }
    }
`

export const Picture = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
`

export const Fake = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #BBBBBB;
`

export const Img = styled.div`
    background: url(${props => props.img}) no-repeat center center;
    background-size: contain;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
`

export const Button = styled.button`
    width: 330px;
    height: 50px;
    background: #000;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 50px;
    text-transform: uppercase;
    cursor: pointer;
    border: transparent;
    position: relative;

    ${props => props.loading && css`
        cursor: not-allowed;
        font-size: 14px;
        background-color: #bbbbbb;
    `}

    ${props => props.done && css`
        cursor: not-allowed;
        font-size: 14px;
        background: #bbbbbb;
    `}


    ${props => props.cancel && css`
        background: transparent;
        color: #000;
        border: 1px solid #000;
    `}

    ${props => props.emailTest && css`
        width: 265.14px;
    `}

    ${props => props.from === 'newsletter' && css`
        width: 100%;
        margin-bottom: 20px;
    `}

    ${props => props.secundary && css`
        width: 181px;
        background-color: #fff;
        border: 1px solid #000;
        color: #000;
        text-transform: initial;
        height: 43px;
    `}

    strong {
        position: relative;
        z-index: 2;
    }
`
export const ButtonLoading = styled.div`
    top: 50%;
    right: 20px;
    position: absolute;
    display: inline-block;
    width: 30px;
    height: 30px;
    transform: translateY(-50%);

    &:after {
        content: " ";
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid #FFF;
        border-color: #FFF transparent #FFF transparent;
        animation: ${Animation} 1.2s linear infinite;
    }

`

export const NumberLoading = styled.span`
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
`

export const ImgProfile = styled.div`
    position: relative;
    position: absolute;
    top: 64px;
    right: 63px;
    width: 160px;
    height: 60px;
    background: url(${props => props.img}) no-repeat center center;
    background-size: contain;
`

export const FakeProfile = styled.div`
    position: relative;
    position: absolute;
    top: 64px;
    right: 63px;
    width: 160px;
    height: 60px;
    background: #BBBBBB;
`

export const ImgLogo = styled.div`
    position: absolute;
    top: 47px;
    left: 23px;
    width: 120px;
    height: 50px;
    background: url(${props => props.img}) no-repeat center center;
    background-size: contain;
`

export const FakeLogo = styled.div`
    position: absolute;
    top: 47px;
    left: 23px;
    width: 120px;
    height: 50px;
    background: #BBBBBB;
`

export const SmallError = styled.span`
    display: block;
    font-size: 12px;
    color: #FF4421;
    bottom: -20px;
    left: 0;
    position: absolute;

`

export const ContentSelect = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;

    input {
        width: 20px;
        height: 20px;
    }

    label {
        color: #000;
        font-size: 14px;
        margin-left: 5px;
        display: block;
    }
`
export const ContentButtons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const ContentBlock = styled.div`
    width: 100%;
    max-width: 410px;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: space-between;
`

export const Item = styled.div`
    font-size: 18px;
    border-top: 1px solid rgba(112, 112, 112, .2);
    border-bottom: 1px solid rgba(112, 112, 112, .2);
    color: #000;
    position: relative;
    display: flex;
    flex-flow: column;
    overflow: hidden;
    cursor: pointer;
    transition: all ease .25s;

    &:first-child {
        border-bottom: none !important;
    }

    > span {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0px;
        transition: all ease .5s;
    }

    ${props => props.active && css`
        > span {
            margin-bottom: 10px;
        }
    `}
`

export const ButtonFake = styled.div`
    width: 170px;
    height: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(221, 221, 221, 1);
    color: rgba(0, 0, 0, .8);
    background-color: #fff;
    position: relative;
    overflow: hidden;

    > input {
        display: block;
        width: 100%;
        height: 100%;
        max-width: 200px;
        max-height: 50px;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        background: #000;
        overflow: hidden;
        cursor: pointer;

    }

    &:hover {
        border: 2px solid rgba(20, 117, 251, 1);
    }

    ${props => props.active && css`
        border: 2px solid rgba(20, 117, 251, 1);
    `}

    ${props => props.radius && css`
        border-radius: 25px;
    `}
`

export const Tag = styled.div`
    border: 1px solid #000000;
    min-width: 30px;
    width: auto;
    min-height: 30px;
    box-sizing: border-box;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    border-radius: 5px;
    position:relative;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 25px 5px 25px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #fff;
`
export const ContentTag = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
`

export const Delete = styled.div`
    position:absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(92,92,92,0.5);
    font-weight: bold;
    right: 2px;
    top:50%;
    transform:translateY(-50%);
    font-size:10px;
    cursor:pointer;
    width: 18px;
    height: 18px;

    &:hover{
        transition: all ease-in .15s;
        border-radius: 50%;
        background-color: #000000;

        > svg {
            path{
                height: inherit;
                width: inherit;
                stroke: #fff;
            }
        }
    }
`
